// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__dbe {
  display: block;
  width: 100%;
}

.header__c71.header__c71 {
  align-self: center;
  margin: 0;
}

.contentContainer__ac5.bordered__abc {
  border: 1px solid var(--ring-borders-color);
  border-radius: var(--ring-border-radius);
  overflow: hidden;

  .subHeader__dc1 + .content__cb7 {
    border-top: 1px solid var(--ring-borders-color);
  }
}

.subHeader__dc1 {
  min-height: calc(var(--ring-unit) * 4);
  display: flex;
  align-items: center;
}

.headerContainer__dcf {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.headerActionContainer__eb8 {
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/setting-block.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,2CAA2C;EAC3C,wCAAwC;EACxC,gBAAgB;;EAEhB;IACE,+CAA+C;EACjD;AACF;;AAEA;EACE,sCAAsC;EACtC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".container {\n  display: block;\n  width: 100%;\n}\n\n.header.header {\n  align-self: center;\n  margin: 0;\n}\n\n.contentContainer.bordered {\n  border: 1px solid var(--ring-borders-color);\n  border-radius: var(--ring-border-radius);\n  overflow: hidden;\n\n  .subHeader + .content {\n    border-top: 1px solid var(--ring-borders-color);\n  }\n}\n\n.subHeader {\n  min-height: calc(var(--ring-unit) * 4);\n  display: flex;\n  align-items: center;\n}\n\n.headerContainer {\n  display: flex;\n  align-items: center;\n  margin-bottom: 12px;\n}\n\n.headerActionContainer {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__dbe`,
	"header": `header__c71`,
	"contentContainer": `contentContainer__ac5`,
	"bordered": `bordered__abc`,
	"subHeader": `subHeader__dc1`,
	"content": `content__cb7`,
	"headerContainer": `headerContainer__dcf`,
	"headerActionContainer": `headerActionContainer__eb8`
};
export default ___CSS_LOADER_EXPORT___;
