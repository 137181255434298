import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
export default function confirmResetAgreement() {
    return confirm({
        text: 'Reset Data Collection Statement?',
        description: 'Are you sure you would like to reset the uploaded data collection statement to the default?',
        confirmLabel: 'Reset',
        rejectLabel: 'Cancel',
        cancelIsDefault: true
    });
}
