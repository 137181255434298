import { getServiceClient } from '../components/auth/auth';
function extractPlugins(data) {
    return data.data.plugins.plugins;
}
async function searchPlugins(body) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return getServiceClient().post('/api/marketplace-plugins/search', body);
}
/**
 * Fetch all the plugins by pluginName
 */
export async function fetchPluginsByPluginName(pluginName) {
    const name = pluginName
        .trim()
        .substring(0, 80)
        .toLowerCase()
        .replaceAll('"', '')
        .replaceAll('`', '');
    const sort = pluginName === '' ? 'RELEVANCE' : 'DOWNLOADS';
    const query = `query {
  plugins(search: { query: "${name}", max: 20, sortBy: ${sort} }) {
    plugins {
      xmlId
      name
      products
      link
    }
  }
}
`;
    const res = await searchPlugins({ query });
    return extractPlugins(res.data);
}
/**
 * Fetch all the plugins where id exists in given ids
 */
export async function fetchPluginsByIds(xmlIds) {
    const ids = `${xmlIds.map(x => `"${x}"`).join()}`;
    const max = xmlIds.length;
    const query = `query {
  plugins(
    search: {
      filters: [{ field: "xmlId", value: [${ids}] }]
      max: ${max}
    }
  ) {
    plugins {
      xmlId
      name
      products
      link
    }
  }
}
`;
    const res = await searchPlugins({ query });
    return extractPlugins(res.data);
}
export async function fetchBundledPlugins() {
    var _a;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const response = await getServiceClient().get('/api/marketplace-plugins/bundled');
    return (_a = response.data) === null || _a === void 0 ? void 0 : _a.plugins;
}
