// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activationBlock__ea0 + .settingsContainer__f90 {
  margin-top: calc(var(--ring-unit) * 3);
}

.settingsContainer__f90 {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 320px));
  gap: calc(var(--ring-unit) * 3);
}

.banners__b0f {
}

.banners__b0f:empty {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/profile-ide-provisioner-page.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,sDAAsD;EACtD,+BAA+B;AACjC;;AAEA;AACA;;AAEA;EACE,aAAa;AACf","sourcesContent":[".activationBlock + .settingsContainer {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.settingsContainer {\n  display: grid;\n  grid-template-columns: repeat(4, minmax(200px, 320px));\n  gap: calc(var(--ring-unit) * 3);\n}\n\n.banners {\n}\n\n.banners:empty {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activationBlock": `activationBlock__ea0`,
	"settingsContainer": `settingsContainer__f90`,
	"banners": `banners__b0f`
};
export default ___CSS_LOADER_EXPORT___;
