export const settingTypeOptions = [
    { key: 'include', label: 'Include' },
    { key: 'exclude', label: 'Exclude' }
];
export const restrictionTypeOptions = [
    { key: 'include', label: 'Restrict' },
    { key: 'exclude', label: "Don't restrict" }
];
export const architectureOptions = [
    { key: 'x64', label: 'x64' },
    { key: 'arm64', label: 'arm64' }
];
export const operatingSystemOptions = [
    { key: 'windows', label: 'windows' },
    { key: 'linux', label: 'linux' },
    { key: 'macOs', label: 'macOs' }
];
export const qualityInequalityTypeOptions = [
    { key: 'le', label: 'is more or equally stable as' },
    { key: 'lt', label: 'is more stable than' },
    { key: 'gt', label: 'is less stable than' },
    { key: 'ge', label: 'is less or equally stable as' }
];
