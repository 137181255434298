import { jsx as _jsx } from "react/jsx-runtime";
import Select from '@jetbrains/ring-ui/components/select/select';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import { toPluginSelectItem } from '@app/pages/profiles/profile-details/profile-ide-provisioner/ide-plugins/formatters';
/**
 * Component which allows choosing plugins from the TBE source
 * @param onPluginSelected it handles plugin with version in case original plugins contained version
 * @param selectedPlugin
 * @param plugins
 * @param rest
 * @return {JSX.Element}
 * @constructor
 */
function TbePluginsSelect({ onPluginSelected, selectedPlugin, plugins, ...rest }) {
    const data = (plugins || []).map(x => toPluginSelectItem(x, false));
    const selected = selectedPlugin ? toPluginSelectItem(selectedPlugin) : undefined;
    return (_jsx(Select, { label: "Choose plugin", data: data, onSelect: item => {
            if (item !== null) {
                onPluginSelected(item);
            }
        }, filter: { fuzzy: true }, size: Size.L, selected: selected, ...rest }));
}
export default TbePluginsSelect;
