// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formFields__c3e {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 3);
}

.titleContainer__bec {
  display: flex;
  flex-direction: row;
  gap: calc(var(--ring-unit) * 3);
}

.titleContainer__bec > div:not(:first-child) {
  padding-top: calc(var(--ring-unit) * 0.25);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/user-edit-form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".formFields {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 3);\n}\n\n.titleContainer {\n  display: flex;\n  flex-direction: row;\n  gap: calc(var(--ring-unit) * 3);\n}\n\n.titleContainer > div:not(:first-child) {\n  padding-top: calc(var(--ring-unit) * 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formFields": `formFields__c3e`,
	"titleContainer": `titleContainer__bec`
};
export default ___CSS_LOADER_EXPORT___;
