import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DescriptionField from './common/description-field';
import ForcedField from './common/forced-field';
import KindField from './common/kind-field';
import ScopeField from './common/scope-field';
import KeyField from './common/key-field';
import ValueField from './common/value-field';
import styles from './common/common.css';
function RegistryFields({ settingIndex }) {
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.flexRow, children: [_jsx(DescriptionField, { settingIndex: settingIndex }), _jsx(KindField, { settingIndex: settingIndex })] }), _jsx(KeyField, { settingIndex: settingIndex }), _jsx(ValueField, { settingIndex: settingIndex }), _jsx(ScopeField, { settingIndex: settingIndex }), _jsx(ForcedField, { settingIndex: settingIndex })] }));
}
export default RegistryFields;
