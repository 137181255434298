import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import { useUnassignedUsersCount } from '@app/queries/users';
import { useGetIdeProvisioning } from '../../queries/dashboard';
import NavigationLink from '../../components/navigation/navigation-link';
import { WidgetLayout } from './common/widget-layout';
import styles from './popular-profiles.css';
function usersCountText(usersLength) {
    return usersLength === 1 ? 'One user ' : `${usersLength} users `;
}
export function PopularProfiles() {
    const { data, isLoading: isLoadingProfiles, isLoadingError: isLoadingProfilesError } = useGetIdeProvisioning();
    const { data: usersCount, isLoading: isLoadingUsers, isLoadingError: isLoadingUsersError } = useUnassignedUsersCount();
    const isLoading = isLoadingUsers || isLoadingProfiles;
    const isLoadingError = isLoadingUsersError || isLoadingProfilesError;
    return (_jsx(WidgetLayout, { title: "Popular Profiles", dataTest: "popular-profiles", loader: isLoading, isLoadingError: isLoadingError, children: _jsxs("div", { className: styles.contentContainer, children: [usersCount && usersCount.total > 0 && (_jsx("div", { className: styles.bottomContainer, children: _jsxs(NavigationLink, { className: styles.warningText, href: "users?profileId=no-profile", children: [usersCountText(usersCount.total), " without any assigned profiles"] }) })), !isLoading && !(data === null || data === void 0 ? void 0 : data.topProfiles.length) && (_jsxs("div", { children: [_jsx(Text, { info: true, children: "No profiles" }), "\u00A0\u00A0\u00A0", _jsx(NavigationLink, { href: "/profiles", children: "Create..." })] })), _jsx("div", { className: styles.listContainer, children: data === null || data === void 0 ? void 0 : data.topProfiles.map(({ id, title, usersAmount }) => (_jsxs("div", { children: [_jsx(Text, { children: _jsx(NavigationLink, { href: `/profiles/${id}`, children: title }) }), "\u00A0\u00A0", _jsx(Text, { info: true, size: "s", children: _jsx(NavigationLink, { secondary: true, href: `/profiles/${id}/users`, children: usersCountText(usersAmount) }) })] }, `top-profile-${id}`))) })] }) }));
}
