import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import userWarningIcon from '@jetbrains/icons/user-warning';
import { Size } from '@jetbrains/ring-ui/components/icon/icon__constants';
import Button from '@jetbrains/ring-ui/components/button/button';
import { ControlsHeight } from '@jetbrains/ring-ui/components/global/controls-height';
import styles from './base-notification.css';
export function BaseNotification(props) {
    const { name, overLimit, currentLimit, licenseType, onAction } = props;
    return (_jsxs("div", { className: styles.wrapper, children: [_jsx(BaseNotificationHeader, { name: name }), licenseType === 'PREPAID' && (_jsxs("p", { className: styles.text, children: [overLimit, " more ", name, " licenses are required to accommodate the requested number of users. Contact the JetBrains IDE Services sales team to increase the user limits."] })), licenseType === 'POSTPAID' && (_jsxs(_Fragment, { children: [_jsxs("p", { className: styles.text, children: ["Current limit: ", currentLimit, ". Increase the user limit for ", name, " to supply", ' ', overLimit === 1 ? `one more user` : `${overLimit} more users`, " with licenses."] }), _jsx("div", { className: styles.buttons, children: _jsx(Button, { onClick: onAction, primary: true, height: ControlsHeight.L, children: "Update limits..." }) })] }))] }));
}
export function BaseNotificationHeader({ name }) {
    return (_jsxs("header", { className: styles.header, children: [_jsx(Icon, { className: styles.icon, glyph: userWarningIcon, size: Size.Size16, suppressSizeWarning: true }), _jsx("span", { className: styles.title, children: name })] }));
}
