// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warningText__aee {
  color: var(--ring-warning-color);
  text-align: justify;
}

.warningIcon__da5 {
  margin-right: var(--ring-unit);
}

.infoText__ec6 {
  text-align: justify;
}

.formContainer__e5e {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
}

.dialogContainer__d50.dialogContainer__d50 > div > div {
  min-width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/vm-options/profiles-vm-options-form.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".warningText {\n  color: var(--ring-warning-color);\n  text-align: justify;\n}\n\n.warningIcon {\n  margin-right: var(--ring-unit);\n}\n\n.infoText {\n  text-align: justify;\n}\n\n.formContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.dialogContainer.dialogContainer > div > div {\n  min-width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningText": `warningText__aee`,
	"warningIcon": `warningIcon__da5`,
	"infoText": `infoText__ec6`,
	"formContainer": `formContainer__e5e`,
	"dialogContainer": `dialogContainer__d50`
};
export default ___CSS_LOADER_EXPORT___;
