// Generated by ts-to-zod
import { z } from 'zod';
export const tSettingScopeSchema = z.union([z.literal('APPLICATION'), z.literal('PROJECT')]);
export const tProvisionModeSchema = z.union([
    z.literal('READ_ONLY'),
    z.literal('CUSTOMIZED_DEFAULT')
]);
export const tPersistentStateXmlKindSchema = z.literal('PERSISTENT_STATE_XML');
export const tPersistentStateXpathKindSchema = z.literal('PERSISTENT_STATE_XPATH');
export const tRegistryKindSchema = z.literal('REGISTRY');
export const tPropertiesComponentKindSchema = z.literal('PROPERTIES_COMPONENT');
export const tCodeStyleKindSchema = z.literal('CODESTYLE_XML');
export const tInspectionProfileKindSchema = z.literal('INSPECTION_PROFILE_XML');
export const tKeyValueListKindSchema = z.literal('KEY_VALUE_LIST');
export const tCustomKindSchema = z.string();
export const tSettingKindSchema = z.union([
    tPersistentStateXmlKindSchema,
    tPersistentStateXpathKindSchema,
    tRegistryKindSchema,
    tPropertiesComponentKindSchema,
    tCodeStyleKindSchema,
    tInspectionProfileKindSchema,
    tKeyValueListKindSchema,
    tCustomKindSchema
]);
export const tKindAwareSchema = z.object({
    kind: tSettingKindSchema,
    description: z.string().min(1, { message: 'Description must be non-empty' }).optional().nullable()
});
export const tMetadataSchema = tKindAwareSchema.extend({
    scope: tSettingScopeSchema,
    forced: z.boolean()
});
export const tXmlPayloadSettingSchema = tMetadataSchema.extend({
    xmlPayload: z.string()
});
export const tXpathSpecSchema = z.object({
    xpath: z.string().min(1, { message: 'XPath must be non-empty' })
});
export const tXpathSpecWithReplaceTextActionSchema = tXpathSpecSchema.extend({
    action: z.literal('replace-text'),
    text: z.string().min(1, { message: 'Text must be non-empty' }),
    cdata: z.boolean().optional()
});
export const tXpathSpecWithSetAttributeActionSchema = tXpathSpecSchema.extend({
    action: z.literal('set-attribute'),
    name: z.string().min(1, { message: 'Name must be non-empty' }),
    value: z.string()
});
export const tXpathSpecWithReplaceChildActionSchema = tXpathSpecSchema.extend({
    action: z.literal('replace-child'),
    'child-xml': z.string(),
    'keep-attributes': z.boolean().optional(),
    'keep-name': z.boolean().optional(),
    'keep-content': z.boolean().optional(),
    'child-rel-xpath': z
        .string()
        .optional()
        .transform(value => (value === null || value === void 0 ? void 0 : value.trim()) || undefined),
    'insert-before-rel-xpath': z
        .string()
        .optional()
        .transform(value => (value === null || value === void 0 ? void 0 : value.trim()) || undefined),
    'insert-after-rel-xpath': z
        .string()
        .optional()
        .transform(value => (value === null || value === void 0 ? void 0 : value.trim()) || undefined)
});
export const tXpathSpecWithRemoveActionSchema = tXpathSpecSchema.extend({
    action: z.literal('remove')
});
export const tAnyXpathSpecSchema = z.union([
    tXpathSpecWithReplaceTextActionSchema,
    tXpathSpecWithSetAttributeActionSchema,
    tXpathSpecWithReplaceChildActionSchema,
    tXpathSpecWithRemoveActionSchema
]);
export const tXpathPayloadSettingSchema = tMetadataSchema.extend({
    specs: z.array(tAnyXpathSpecSchema)
});
export const tKeyValueSettingSchema = tMetadataSchema.extend({
    key: z.string(),
    value: z.any()
});
export const tPersistentStateXmlSettingSchema = tXmlPayloadSettingSchema.extend({
    fqName: z.string(),
    pluginId: z.string().optional().nullable()
});
export const tPersistentStateXpathSettingSchema = tXpathPayloadSettingSchema.extend({
    fqName: z.string(),
    pluginId: z.string().optional().nullable()
});
export const tCodeStyleSettingSchema = tXmlPayloadSettingSchema;
export const tInspectionProfileSettingSchema = tXmlPayloadSettingSchema;
export const tRegistrySettingSchema = tKeyValueSettingSchema;
export const tPropertiesComponentSettingSchema = tKeyValueSettingSchema;
export const tKeyValueListSettingSchema = tKindAwareSchema.extend({
    entries: z.object({}).passthrough(),
    provisionMode: tProvisionModeSchema.optional()
});
export const tSettingSchema = z.union([
    tPersistentStateXmlSettingSchema,
    tPersistentStateXpathSettingSchema,
    tCodeStyleSettingSchema,
    tInspectionProfileSettingSchema,
    tRegistrySettingSchema,
    tPropertiesComponentSettingSchema,
    tKeyValueListSettingSchema,
    tKindAwareSchema
]);
