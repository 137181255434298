import { createSlice } from '@reduxjs/toolkit';
import { addApplications, deleteNotification, deleteSetting, excludeApplication, resetProfileState, setNewProfiles, updateApplicationFilters, updateApplications, updateAutoInstalledPlugins, updateDisabledPlugin, updateIdeSettings, updateNotification, updateProfileSettings, updateSettingFilters, updateAutoinstalledTools, updateTools, updateVMOptions, updateToolboxSettings, updateCwmConstituent, updateAiConstituent, updateIDEPConstituent } from './actions';
import { initialState } from './initial-state';
import { addOrReplace, findMainApplicationsRule, findSetting } from './utils';
const newProfiles = createSlice({
    initialState,
    name: 'newProfiles',
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(setNewProfiles, (state, action) => {
            return action.payload;
        })
            .addCase(deleteSetting, (state, action) => {
            switch (action.payload.settingType) {
                case 'vmOptions':
                    state.settings.vmOptions = state.settings.vmOptions.filter(({ id }) => id !== action.payload.settingId);
                    break;
                case 'toolboxSetting':
                    state.settings.toolbox = state.settings.toolbox.filter(({ id }) => id !== action.payload.settingId);
                    break;
                case 'ide':
                    state.settings.ide = state.settings.ide.filter(({ id }) => id !== action.payload.settingId);
                    break;
                case 'autoinstalledTools':
                    state.settings.autoinstalledTools = state.settings.autoinstalledTools.filter(({ id }) => id !== action.payload.settingId);
                    break;
                case 'autoInstalled':
                    state.plugins.autoInstalled = state.plugins.autoInstalled.filter(({ id }) => id !== action.payload.settingId);
                    break;
                case 'disabled':
                    state.plugins.disabled = state.plugins.disabled.filter(({ id }) => id !== action.payload.settingId);
                    break;
                default:
                    break;
            }
        })
            .addCase(updateVMOptions, (state, action) => {
            state.settings.vmOptions = addOrReplace(state.settings.vmOptions, action.payload);
        })
            .addCase(updateToolboxSettings, (state, action) => {
            state.settings.toolbox = addOrReplace(state.settings.toolbox, action.payload);
        })
            .addCase(updateAutoinstalledTools, (state, action) => {
            state.settings.autoinstalledTools = addOrReplace(state.settings.autoinstalledTools, action.payload);
        })
            .addCase(updateDisabledPlugin, (state, action) => {
            state.plugins.disabled = addOrReplace(state.plugins.disabled, action.payload);
        })
            .addCase(updateAutoInstalledPlugins, (state, action) => {
            state.plugins.autoInstalled = addOrReplace(state.plugins.autoInstalled, action.payload);
        })
            .addCase(excludeApplication, (state, action) => {
            const excludedApplication = state.applications.content.find(item => item.product === action.payload);
            if (excludedApplication) {
                const excludedAppObject = {
                    ...excludedApplication,
                    excluded: true,
                    notifications: undefined
                };
                state.applications.content = state.applications.content.map(application => application.product === excludedApplication.product ? excludedAppObject : application);
            }
        })
            .addCase(updateApplications, (state, action) => {
            state.applications.content = state.applications.content.map(application => application.product === action.payload.product ? action.payload : application);
        })
            .addCase(addApplications, (state, action) => {
            state.applications.content = state.applications.content.map(application => application.product === action.payload.product
                ? { ...action.payload, excluded: false }
                : application);
        })
            .addCase(updateIdeSettings, (state, action) => {
            state.settings.ide = addOrReplace(state.settings.ide, action.payload);
        })
            .addCase(deleteNotification, (state, action) => {
            state.applications.rules = state.applications.rules.filter(rule => rule.id !== action.payload);
            state.restrictions.rules = state.restrictions.rules.filter(rule => rule.id !== action.payload);
        })
            .addCase(updateNotification, (state, action) => {
            if (state.restrictions.rules.some(rule => rule.id === action.payload.id)) {
                state.restrictions.rules = state.restrictions.rules.map(rule => {
                    return rule.id === action.payload.id
                        ? {
                            ...rule,
                            notificationData: action.payload.notification
                        }
                        : rule;
                });
                state.applications.rules = state.applications.rules.map(rule => {
                    return rule.id === action.payload.id
                        ? {
                            ...rule,
                            notification: action.payload.notification,
                            filters: action.payload.filters
                        }
                        : rule;
                });
            }
            else {
                state.restrictions.rules = [
                    ...state.restrictions.rules,
                    {
                        id: action.payload.id,
                        notificationData: action.payload.notification,
                        tools: []
                    }
                ];
                state.applications.rules = [
                    ...state.applications.rules,
                    {
                        id: action.payload.id,
                        notification: action.payload.notification,
                        filters: action.payload.filters
                    }
                ];
            }
        })
            .addCase(updateSettingFilters, (state, action) => {
            const { settingId, settingType, filters } = action.payload;
            const found = findSetting(state, settingId, settingType);
            if (found) {
                found.filters = filters;
            }
        })
            .addCase(updateApplicationFilters, (state, action) => {
            const found = findMainApplicationsRule(state.applications.rules);
            if (found) {
                found.filters = action.payload.filters;
            }
        })
            .addCase(updateTools, (state, action) => {
            const { settingId, settingType, tools } = action.payload;
            const found = findSetting(state, settingId, settingType);
            if (found) {
                found.tools = tools;
            }
        })
            .addCase(updateProfileSettings, (state, action) => {
            return {
                ...state,
                isDefault: action.payload.isDefault,
                title: action.payload.title,
                description: action.payload.description
            };
        })
            .addCase(updateCwmConstituent, (state, action) => {
            return {
                ...state,
                constituents: { ...state.constituents, cwm: { ...state.constituents.cwm, ...action.payload } }
            };
        })
            .addCase(updateAiConstituent, (state, action) => {
            return {
                ...state,
                constituents: { ...state.constituents, ai: { ...state.constituents.ai, ...action.payload } }
            };
        })
            .addCase(updateIDEPConstituent, (state, action) => {
            return {
                ...state,
                constituents: {
                    ...state.constituents,
                    idep: { ...state.constituents.idep, ...action.payload }
                }
            };
        })
            .addCase(resetProfileState, () => {
            return initialState;
        });
    }
});
export default newProfiles.reducer;
