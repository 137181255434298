import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import Table from '@jetbrains/ring-ui/components/table/table';
import Pager from '@jetbrains/ring-ui/components/pager/pager';
import Selection from '@jetbrains/ring-ui/components/table/selection';
import styles from '@components/tables/full-width-table-view.css';
export function FullWidthTableView(props) {
    const { pagerProps, tableClassName, selection, tableWrapperClassName, ...restProps } = props;
    const customSelection = useMemo(() => {
        return selection !== null && selection !== void 0 ? selection : new Selection();
    }, [selection]);
    return (_jsxs("div", { className: classNames(styles.tableWrapper, tableWrapperClassName), children: [_jsx(Table, { ...restProps, className: classNames(styles.table, tableClassName), scrollOnTableFocus: false, selection: customSelection, stickyHeader: false }), pagerProps ? _jsx(Pager, { ...pagerProps }) : null] }));
}
