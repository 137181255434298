import { jsx as _jsx } from "react/jsx-runtime";
import AceEditor from 'react-ace';
import classNames from 'classnames';
import useEditorTheme from './use-editor-theme';
import styles from './ace-editor.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'ace-builds/src-noconflict/mode-json';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'ace-builds/src-noconflict/theme-github';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'ace-builds/src-noconflict/theme-one_dark';
function JsonEditor({ className, ...restProps }) {
    const theme = useEditorTheme();
    return (_jsx(AceEditor, { showPrintMargin: true, showGutter: true, highlightActiveLine: true, width: "100%", mode: "json", name: "JSON Editor", theme: theme, className: classNames(styles.aceEditor, className), editorProps: { $blockScrolling: true }, setOptions: {
            showLineNumbers: true,
            useWorker: false
        }, ...restProps }));
}
export default JsonEditor;
