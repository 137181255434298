import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DescriptionField from './common/description-field';
import ForcedField from './common/forced-field';
import KindField from './common/kind-field';
import ScopeField from './common/scope-field';
import PluginIdField from './common/plugin-id-field';
import FqNameField from './common/fq-name-field';
import XmlPayloadField from './common/xml-payload-field';
import styles from './common/common.css';
function PersistentStateXmlFields({ settingIndex }) {
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.flexRow, children: [_jsx(DescriptionField, { settingIndex: settingIndex }), _jsx(KindField, { settingIndex: settingIndex })] }), _jsx(PluginIdField, { settingIndex: settingIndex }), _jsx(FqNameField, { settingIndex: settingIndex }), _jsx(ScopeField, { settingIndex: settingIndex }), _jsx(ForcedField, { settingIndex: settingIndex }), _jsx(XmlPayloadField, { settingIndex: settingIndex })] }));
}
export default PersistentStateXmlFields;
