import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useGetUsersForGroup } from '@app/queries/groups';
import NavigationLink from '@components/navigation/navigation-link';
import { EnumerableTextElements } from '@components/text-formattings/enumerable-text-elements';
const ELEMENTS_COUNT = 3;
export function GroupUsersCell({ name, id }) {
    const { data, isLoading } = useGetUsersForGroup(id, ELEMENTS_COUNT);
    if (isLoading) {
        return _jsx(LoaderInline, {});
    }
    if (data === undefined || (data === null || data === void 0 ? void 0 : data.total) === 0) {
        return _jsx(Text, { info: true, children: "No users" });
    }
    return (_jsxs(_Fragment, { children: [_jsx(EnumerableTextElements, { getKey: user => `group-${name}-user-${user.id}`, getValue: getUserLink, maxVisibleElems: ELEMENTS_COUNT, items: data.items }), data.total - ELEMENTS_COUNT > 0 && (_jsxs("span", { children: [' ', "and", ' ', _jsxs(NavigationLink, { href: `/users?groupId=${id}`, children: [data.total - ELEMENTS_COUNT, " more"] })] }))] }));
}
function getUserLink(user) {
    return _jsx(NavigationLink, { href: `/users/${user.id}`, children: user.username });
}
