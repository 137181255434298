import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Text from '@jetbrains/ring-ui/components/text/text';
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import CopiedCode from '../../../components/clipboard/copied-code/copied-code';
import { copyToClipboard } from '../../../components/clipboard/copy-to-clipboard';
import RingFieldsList from '../../../components/form/ring-fields-list';
import { getConfigMachineToolboxUrl } from '../../../components/machine-config';
import { useMachineConfig } from '../../../queries/machine-config';
import styles from './configuration-file-popup.css';
export function InvitationLinkPopup({ onReject }) {
    const { data, isLoading } = useMachineConfig();
    const link = data && getConfigMachineToolboxUrl(data.tbeServerUrl, data.proxyRequired);
    const handleCopy = () => {
        if (link) {
            copyToClipboard(link);
        }
        onReject();
    };
    return (_jsxs(Dialog, { show: true, onCloseAttempt: onReject, contentClassName: styles.contentContainer, children: [_jsx(Header, { children: "Invite Developers" }), isLoading && _jsx(InlineLoader, {}), !isLoading && (_jsx(Content, { children: _jsxs(RingFieldsList, { children: [_jsx(Text, { children: "Share this link with the developers in your organization. Following the link launches the Toolbox App, allowing them to sign in and enable Enterprise mode." }), link ? _jsx(CopiedCode, { text: link, inline: true }) : _jsx(Text, { children: " Failed to load data " })] }) })), _jsxs(Panel, { children: [data && (_jsx(Button, { primary: true, onClick: handleCopy, children: "Copy path" })), _jsx(Button, { onClick: onReject, children: "Close" })] })] }));
}
