// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__f41 {
  display: flex;
  padding: 0 var(--ring-unit);
  width: 100%;

  min-width: 0;
}

.filterText__b28 {
  flex: 0 0 auto;
  margin-right: var(--ring-unit);
}

.placeholderText__e5d {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tagWrapper__f63 {
  margin-left: auto;
  min-width: 0;
  flex: 0 1 auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/applications/filters-panel/filters-panel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,WAAW;;EAEX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".wrapper {\n  display: flex;\n  padding: 0 var(--ring-unit);\n  width: 100%;\n\n  min-width: 0;\n}\n\n.filterText {\n  flex: 0 0 auto;\n  margin-right: var(--ring-unit);\n}\n\n.placeholderText {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.tagWrapper {\n  margin-left: auto;\n  min-width: 0;\n  flex: 0 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__f41`,
	"filterText": `filterText__b28`,
	"placeholderText": `placeholderText__e5d`,
	"tagWrapper": `tagWrapper__f63`
};
export default ___CSS_LOADER_EXPORT___;
