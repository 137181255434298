// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listItem__a64 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  line-height: var(--ring-line-height-lower);
  padding: 0 calc(var(--ring-unit) * 2) 2px calc(var(--ring-unit) * 2);
}

.listTitle__f64 {
  margin-left: calc(2 * var(--ring-unit));
}

.listButton__b16 {
  line-height: var(--ring-line-height);
  padding-right: 2px;
}

.listButtonIcon__b1e {
  vertical-align: text-bottom;
}

.listButtonIcon__b1e svg {
  color: var(--ring-secondary-color);
}

.listButtonIcon__b1e:hover svg {
  color: var(--ring-link-hover-color);
}

.listContainer__e28 {
  margin-right: calc(var(--ring-unit) * -4);
  margin-left: calc(var(--ring-unit) * -4);
}

.loader__e1d {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  margin: calc(2 * var(--ring-unit)) 0;
}

.dialogContainer__a79 {
  width: calc(var(--ring-unit) * 58);
  height: 85vh;
}

.userInfo__a3a {
  display: flex;
  flex-direction: column;
}

.separator__f4c {
  width: 100%;
  border-bottom: 1px solid var(--ring-line-color);
}

.textContainer__d25 {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
  width: fit-content;
}

.warning__af9 {
  color: var(--ring-warning-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-users/assign-dialog/assign-user-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,0CAA0C;EAC1C,oEAAoE;AACtE;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,yCAAyC;EACzC,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,kCAAkC;EAClC,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,+CAA+C;AACjD;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".listItem {\n  display: flex;\n  justify-content: space-between;\n  align-items: baseline;\n  line-height: var(--ring-line-height-lower);\n  padding: 0 calc(var(--ring-unit) * 2) 2px calc(var(--ring-unit) * 2);\n}\n\n.listTitle {\n  margin-left: calc(2 * var(--ring-unit));\n}\n\n.listButton {\n  line-height: var(--ring-line-height);\n  padding-right: 2px;\n}\n\n.listButtonIcon {\n  vertical-align: text-bottom;\n}\n\n.listButtonIcon svg {\n  color: var(--ring-secondary-color);\n}\n\n.listButtonIcon:hover svg {\n  color: var(--ring-link-hover-color);\n}\n\n.listContainer {\n  margin-right: calc(var(--ring-unit) * -4);\n  margin-left: calc(var(--ring-unit) * -4);\n}\n\n.loader {\n  display: flex;\n  flex-direction: column;\n  row-gap: 6px;\n  margin: calc(2 * var(--ring-unit)) 0;\n}\n\n.dialogContainer {\n  width: calc(var(--ring-unit) * 58);\n  height: 85vh;\n}\n\n.userInfo {\n  display: flex;\n  flex-direction: column;\n}\n\n.separator {\n  width: 100%;\n  border-bottom: 1px solid var(--ring-line-color);\n}\n\n.textContainer {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n  width: fit-content;\n}\n\n.warning {\n  color: var(--ring-warning-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": `listItem__a64`,
	"listTitle": `listTitle__f64`,
	"listButton": `listButton__b16`,
	"listButtonIcon": `listButtonIcon__b1e`,
	"listContainer": `listContainer__e28`,
	"loader": `loader__e1d`,
	"dialogContainer": `dialogContainer__a79`,
	"userInfo": `userInfo__a3a`,
	"separator": `separator__f4c`,
	"textContainer": `textContainer__d25`,
	"warning": `warning__af9`
};
export default ___CSS_LOADER_EXPORT___;
