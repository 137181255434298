import { jsx as _jsx } from "react/jsx-runtime";
import TagsInput from '@jetbrains/ring-ui/components/tags-input/tags-input';
import { isEmpty } from 'lodash';
import { useFieldArray, useFormContext } from 'react-hook-form';
const getProfilesFieldPlaceholder = (fieldValue) => {
    return isEmpty(fieldValue) ? 'No profiles' : '';
};
export function ProfilesTagsField({ profileOptions, disabled }) {
    const { watch, control } = useFormContext();
    const { append, remove } = useFieldArray({
        name: 'profiles',
        control
    });
    const profileIds = watch('profiles');
    const tagOptions = profileOptions.map(p => ({ key: p.id, label: p.title }));
    const tags = tagOptions.filter(option => profileIds.map(({ id }) => id).includes(option.key));
    const addTag = (tag) => {
        if (typeof tag.key !== 'string') {
            return;
        }
        append({ id: tag.key, title: `${tag.label}` });
    };
    const removeTag = (tag) => {
        if (typeof tag.key !== 'string') {
            return;
        }
        remove(profileIds.indexOf({ id: tag.key, title: `${tag.label}` }));
    };
    return (_jsx(TagsInput, { filter: true, label: "Profiles", tags: tags, disabled: disabled, dataSource: () => tagOptions, onAddTag: ({ tag }) => addTag(tag), onRemoveTag: ({ tag }) => removeTag(tag), placeholder: getProfilesFieldPlaceholder(profileIds) }));
}
