import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useIdeProvisionerConstituent } from '@app/queries/ide-provisioner';
import { useOpenModal } from '@components/modals/hooks';
import { IdeProvisionerEnabled } from './ide-provisioner-enabled/ide-provisioner-enabled';
import { IdePSetupDialog } from './dialogs/idep-settings-dialog/idep-setup-dialog';
import { IdePEnableDialog } from './dialogs/idep-settings-dialog/idep-enable-dialog';
import { IdePChangeSettingsDialog } from './dialogs/idep-settings-dialog/idep-change-settings-dialog';
import { IdePDisableDialog } from './dialogs/idep-disable-dialog/idep-disable-dialog';
import { IdeProvisionerDisabled } from './ide-provisioner-disabled/ide-provisioner-disabled';
export function IdeProvisionerConstituent(props) {
    const { className, licenseType } = props;
    const { data } = useIdeProvisionerConstituent();
    const openDisableModal = useOpenModal(IdePDisableDialog, {});
    const openSetupModal = useOpenModal(IdePSetupDialog, {});
    const openEnableModal = useOpenModal(IdePEnableDialog, {});
    const openChangeSettingsModal = useOpenModal(IdePChangeSettingsDialog, {
        initialValues: (data === null || data === void 0 ? void 0 : data.status) === 'enabled' ? data.data : undefined
    });
    const handleEnableClick = useCallback(() => {
        if (licenseType === 'POSTPAID') {
            openSetupModal();
        }
        else {
            openEnableModal();
        }
    }, [licenseType, openEnableModal, openSetupModal]);
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled') {
        return _jsx(IdeProvisionerDisabled, { className: className, onEnabledClick: handleEnableClick });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'enabled') {
        return (_jsx(IdeProvisionerEnabled, { onDisableClick: openDisableModal, onEditClick: openChangeSettingsModal, allowEditing: licenseType === 'POSTPAID', data: data.data, className: className }));
    }
    return null;
}
