// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__ba7 {
  display: flex;
  align-items: center;
}

.toggleContainer__c6f {
  flex-grow: 1;
}

.helpIcon__ebf {
  margin-left: calc(var(--ring-unit) * 0.75);
}

.input__b65 {
  width: 70px;
}

.error__d7d {
  color: var(--ring-error-color);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/settings-update-interval-setting-field.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,8BAA8B;EAC9B,wCAAwC;EACxC,2CAA2C;AAC7C","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n}\n\n.toggleContainer {\n  flex-grow: 1;\n}\n\n.helpIcon {\n  margin-left: calc(var(--ring-unit) * 0.75);\n}\n\n.input {\n  width: 70px;\n}\n\n.error {\n  color: var(--ring-error-color);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__ba7`,
	"toggleContainer": `toggleContainer__c6f`,
	"helpIcon": `helpIcon__ebf`,
	"input": `input__b65`,
	"error": `error__d7d`
};
export default ___CSS_LOADER_EXPORT___;
