// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itemContainer__aba {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 0.5);
}

.productLogo__ada {
  width: calc(var(--ring-unit) * 2.5);
}

.productContainer__e63 {
  display: flex;
  gap: var(--ring-unit);
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/applications-tab/applications-list-item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".itemContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 0.5);\n}\n\n.productLogo {\n  width: calc(var(--ring-unit) * 2.5);\n}\n\n.productContainer {\n  display: flex;\n  gap: var(--ring-unit);\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemContainer": `itemContainer__aba`,
	"productLogo": `productLogo__ada`,
	"productContainer": `productContainer__e63`
};
export default ___CSS_LOADER_EXPORT___;
