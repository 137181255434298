import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { RouteLeavingGuard } from '@components/route-leaving-guard/route-leaving-guard';
import { PageTitle } from '../../components/page-layout/page-title';
import auth from '../../components/auth/auth';
import { DeactivateUserField } from './form-fields/deactivate-user-field';
import { ProfilesList } from './profiles-list';
import { tUserSchema } from './schemas/user-schema';
import { UserEditFormFields } from './user-edit-form-fields';
import { UserEditPageBanner } from './user-edit-page-banner';
import { UserMachineInfoList } from './user-machine-info-list';
import styles from './user-edit-form.css';
export function UserEditForm({ userData, machineInfo, onSubmit, disabled }) {
    var _a;
    const form = useForm({
        defaultValues: userData,
        resolver: zodResolver(tUserSchema)
    });
    const { handleSubmit, reset, watch, formState: { isDirty, isSubmitting } } = form;
    useEffect(() => {
        if (!isDirty) {
            reset(userData);
        }
    }, [isDirty, reset, userData]);
    const submit = async (values) => {
        await onSubmit(values);
        reset(values);
    };
    const activeUser = watch('isActive');
    const isFormDisabled = disabled || !activeUser;
    const areAnalyticsEnabled = auth.getFeatures().analyticsEnabled === true;
    return (_jsxs(_Fragment, { children: [_jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(submit), children: [_jsx(PageTitle, { parts: [{ title: 'Users', href: '/users' }, { title: (_a = userData === null || userData === void 0 ? void 0 : userData.username) !== null && _a !== void 0 ? _a : 'Unknown' }], className: styles.titleContainer, children: _jsx(DeactivateUserField, {}) }), _jsxs("div", { className: styles.formFields, children: [_jsx(UserEditPageBanner, { userName: userData.username, show: isDirty, onSubmit: handleSubmit(submit), onDiscard: () => reset(userData), isSubmitting: isSubmitting }), _jsx(UserEditFormFields, { disabled: isFormDisabled }), _jsx(ProfilesList, { disabled: isFormDisabled }), areAnalyticsEnabled && _jsx(UserMachineInfoList, { ...machineInfo })] })] }) }), _jsx(RouteLeavingGuard, { when: isDirty })] }));
}
