// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pluginSearchPanel__f0a {
  display: flex;
  align-items: end;
}

.fetchingLoader__e71 {
  height: var(--ring-line-height);
  padding: var(--ring-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/plugins/plugin-search-panel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,+BAA+B;EAC/B,kCAAkC;EAClC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".pluginSearchPanel {\n  display: flex;\n  align-items: end;\n}\n\n.fetchingLoader {\n  height: var(--ring-line-height);\n  padding: var(--ring-border-radius);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pluginSearchPanel": `pluginSearchPanel__f0a`,
	"fetchingLoader": `fetchingLoader__e71`
};
export default ___CSS_LOADER_EXPORT___;
