import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { Size as IconSize } from '@jetbrains/ring-ui/components/icon/icon__constants';
import NavigationLink from '@components/navigation/navigation-link';
import auth from '../auth/auth';
import HelpIcon from '../util/help-icon';
import { useToolboxInfo } from '../../queries/toolbox';
import { ExternalLinks } from '../constants/constants';
export function ToolboxAppLink({ className, tooltip = true }) {
    const { data: toolboxInfo, isFetching } = useToolboxInfo();
    let toolboxVersionText;
    if (isFetching) {
        toolboxVersionText = 'Loading minimal toolbox version';
    }
    else if (toolboxInfo) {
        toolboxVersionText = `Minimal required version: ${toolboxInfo.minSupportedVersion}`;
    }
    else {
        toolboxVersionText = 'For the best experience install the latest version';
    }
    let tbaLink;
    if (auth.getFeatures().offlineModeEnabled) {
        tbaLink = 'Toolbox App';
    }
    else {
        tbaLink = (_jsx(NavigationLink, { className: className, href: ExternalLinks.TOOLBOX_APP, children: "Toolbox App" }));
    }
    return (_jsxs(_Fragment, { children: [tbaLink, !auth.getFeatures().offlineModeEnabled && tooltip && (_jsxs(Tooltip, { title: toolboxVersionText, children: [' ', _jsx(HelpIcon, { size: IconSize.Size14 })] }))] }));
}
