import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import classNames from 'classnames';
import Button from '@jetbrains/ring-ui/components/button/button';
import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import { useMachineConfig, useMachineConfigMutation } from '@app/queries/machine-config';
import { CompanyLogo, LogoSize } from '@components/company-logo/company-logo';
import { useModalsController } from '@components/modals';
import { UploadLogoDialog } from './upload-logo-dialog/upload-logo-dialog';
import { ImageUpload } from './image-upload/image-upload'; // <-- ugly imports
import styles from './logo-editor.css';
export function LogoEditor(props) {
    const { className } = props;
    const modalsController = useModalsController();
    const { data, isError, isLoading } = useMachineConfig();
    const mutation = useMachineConfigMutation();
    const logoUrl = data === null || data === void 0 ? void 0 : data.companyLogoUrl;
    const handleNewLogoUpload = useCallback((newLogoUrl) => {
        mutation.mutate({ companyLogoUrl: newLogoUrl });
    }, [mutation]);
    const handleUploadClick = useCallback(async () => {
        const modal = modalsController.openModal(UploadLogoDialog, {
            currentImage: logoUrl
        });
        const newLogoUrl = await modal.promise;
        if (newLogoUrl) {
            handleNewLogoUpload(newLogoUrl);
        }
        modal.closeModal();
    }, [modalsController, logoUrl, handleNewLogoUpload]);
    const handleDeleteClick = useCallback(async () => {
        try {
            await confirm({
                text: 'Delete Company Logo?',
                description: 'Deleting the company logo will update the client machine configuration. You will have to redistribute the configuration file to developer machines.',
                confirmLabel: 'Delete',
                rejectLabel: 'Cancel',
                cancelIsDefault: true
            });
            mutation.mutate({ companyLogoUrl: null });
        }
        catch (e) {
            // do nothing
        }
    }, [mutation]);
    if (isLoading) {
        return _jsx("div", { className: classNames(styles.wrapper, className), children: "..." });
    }
    if (isError) {
        return _jsx("div", { className: classNames(styles.wrapper, className), children: "-" });
    }
    return (_jsxs("div", { className: classNames(styles.wrapper, className), children: [_jsx("p", { children: "Company logo" }), logoUrl ? (_jsx(CompanyLogo, { className: styles.logo, logoUrl: logoUrl, size: LogoSize.M })) : (_jsx(ImageUpload, { className: styles.logoUploader, onUploaded: handleNewLogoUpload })), _jsxs("div", { className: styles.actions, children: [_jsx(Button, { text: true, className: styles.button, onClick: handleUploadClick, "data-test": "upload-logo-button", children: logoUrl ? 'Edit...' : 'Upload your logo...' }), logoUrl && (_jsx(Button, { text: true, className: styles.button, onClick: handleDeleteClick, "data-test": "delete-logo-button", children: "Delete" }))] })] }));
}
