// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vmOptionContainer__c46 {
  display: flex;
  align-items: flex-end;
}

.vmOptionContainer__c46:has(div > div[class*='error']) {
  display: flex;
  align-items: center;
}

.hiddenButton__ebc {
  visibility: hidden;
}

.addVmOptionButton__f2c {
  padding: 0;
}

.addVmOptionButton__f2c > span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.errorText__c38 {
  color: var(--ring-error-color);
  text-align: justify;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/vm-options/vm-options-field-array.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":[".vmOptionContainer {\n  display: flex;\n  align-items: flex-end;\n}\n\n.vmOptionContainer:has(div > div[class*='error']) {\n  display: flex;\n  align-items: center;\n}\n\n.hiddenButton {\n  visibility: hidden;\n}\n\n.addVmOptionButton {\n  padding: 0;\n}\n\n.addVmOptionButton > span {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.errorText {\n  color: var(--ring-error-color);\n  text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vmOptionContainer": `vmOptionContainer__c46`,
	"hiddenButton": `hiddenButton__ebc`,
	"addVmOptionButton": `addVmOptionButton__f2c`,
	"errorText": `errorText__c38`
};
export default ___CSS_LOADER_EXPORT___;
