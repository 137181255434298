import { createSelector } from '@reduxjs/toolkit';
const select = (state) => state.immutableProfiles;
export const getImmutableProfile = createSelector(select, state => state);
export const getImmutablePluginIds = createSelector(select, state => {
    return [
        ...state.plugins.disabled.flatMap(x => x.content.map(y => y.pluginId)),
        ...state.plugins.autoInstalled.flatMap(x => x.content.map(y => y.pluginId))
    ];
});
export const getImmutableProfileConstituents = createSelector(select, state => state.constituents);
