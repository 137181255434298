import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatApiError } from '@api/errors';
import { useCreateProfileMutation } from '@app/queries/profiles';
import { useDialogCancelConfirmation } from '@app/hooks/use-dialog-cancel-confirmation';
import SubmitError from '@components/errors/submit-error';
import { tCreateProfileSchema } from '../profile-details/profile-ide-provisioner/schemas/profile-settings';
import ProfileEditFields from '../profile-details/profile-ide-provisioner/profile-edit-fields';
const initialValues = {
    title: '',
    isDefault: false,
    description: ''
};
export default function ProfileCreatePopup({ onConfirm, onReject }) {
    const [errorMessage, setErrorMessage] = useState('');
    const createMutation = useCreateProfileMutation();
    const form = useForm({
        defaultValues: initialValues,
        resolver: zodResolver(tCreateProfileSchema)
    });
    const { handleSubmit, formState: { isDirty, isSubmitting } } = form;
    const submit = async (values) => {
        await createMutation.mutateAsync(values, {
            onSuccess: data => {
                onConfirm(data.id);
            },
            onError: e => {
                setErrorMessage(formatApiError(e, 'Failed to create profile'));
            }
        });
    };
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onReject, isDirty);
    return (_jsxs(Dialog, { show: true, label: "New Profile", autoFocusFirst: true, onCloseAttempt: onCloseAttempt, children: [_jsx(Header, { children: "Create New Profile" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(submit), children: [_jsx(SubmitError, { error: errorMessage }), _jsx(ProfileEditFields, {}), _jsx("input", { type: "submit", hidden: true, disabled: !isDirty })] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, loader: isSubmitting, disabled: !isDirty, onClick: handleSubmit(submit), children: "Create" }), _jsx(Button, { onClick: onReject, ref: cancelButtonRef, children: "Cancel" })] })] }));
}
