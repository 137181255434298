import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useEditorPlugin } from '../hooks/use-plugin-names';
import settingPreviewStyles from './settings-preview.css';
export default function PluginDescription({ content }) {
    const pluginId = 'pluginId' in content ? content.pluginId : '';
    const { data, isFetching } = useEditorPlugin(pluginId || '');
    return (_jsxs("div", { children: [isFetching && _jsx(InlineLoader, {}), !isFetching && (_jsx(Text, { className: settingPreviewStyles.boxText, children: (data === null || data === void 0 ? void 0 : data.label) || pluginId }))] }));
}
