// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pullRight__cc9 {
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  flex-direction: row-reverse;
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics/collected-data-history.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,iBAAiB;EACjB,2BAA2B;AAC7B","sourcesContent":[".pullRight {\n  display: flex;\n  align-items: flex-end;\n  margin-left: auto;\n  flex-direction: row-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pullRight": `pullRight__cc9`
};
export default ___CSS_LOADER_EXPORT___;
