// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rootContainer__f09 {
  width: 100%;
  height: 100%;
}

.uploadButton__aa1 {
  margin-bottom: var(--ring-unit);
  width: 100%;
  height: 100%;
  border: solid 1px var(--ring-line-color);
}

.uploadButton__aa1.dragActive__e09 {
  border-color: var(--ring-border-hover-color);
  background-color: var(--ring-selected-background-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/client-machine-configuration/logo-editor/image-upload/image-upload.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,+BAA+B;EAC/B,WAAW;EACX,YAAY;EACZ,wCAAwC;AAC1C;;AAEA;EACE,4CAA4C;EAC5C,uDAAuD;AACzD","sourcesContent":[".rootContainer {\n  width: 100%;\n  height: 100%;\n}\n\n.uploadButton {\n  margin-bottom: var(--ring-unit);\n  width: 100%;\n  height: 100%;\n  border: solid 1px var(--ring-line-color);\n}\n\n.uploadButton.dragActive {\n  border-color: var(--ring-border-hover-color);\n  background-color: var(--ring-selected-background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootContainer": `rootContainer__f09`,
	"uploadButton": `uploadButton__aa1`,
	"dragActive": `dragActive__e09`
};
export default ___CSS_LOADER_EXPORT___;
