import { openDB as openIndexedDB } from 'idb';
/**
 * Exclusive locks (mutexes) supporting multiple browser tabs.
 *
 * They are implemented using IndexedDB which supports transactions.
 * There is a database TBE_LOCKS with a store TBE_LOCKS.
 * The store contains objects in format `{name: string, expiresAt: number}`,
 * for example:
 * +-----------------------------------------------+
 * | TBE_LOCKS                                     |
 * +-----------------------------------------------+
 * | {name: 'MY_LOCK_1', expiresAt: 1636662375342} |
 * | {name: 'MY_LOCK_2', expiresAt: 1636662368683} |
 * +-----------------------------------------------+
 * Locks expire after 2 seconds but are prolonged every 1 second by a timer.
 * If a user closes a tab, locks automatically expire.
 */
const DATABASE_NAME = 'TBE_LOCKS';
const STORE_NAME = 'TBE_LOCKS';
const MAX_LOCK_DURATION = 2000;
const PROLONG_INTERVAL = 1000;
const ACQUIRE_LOOP_INTERVAL = 50;
async function prolongLock(database, name) {
    const store = database.transaction(STORE_NAME, 'readwrite').objectStore(STORE_NAME);
    await store.put({ name, expiresAt: Date.now() + MAX_LOCK_DURATION });
    console.debug(`Lock: Prolonged ${name}`);
}
async function releaseLock(database, name) {
    const store = database.transaction(STORE_NAME, 'readwrite').objectStore(STORE_NAME);
    await store.delete(name);
    console.debug(`Lock: Released ${name}`);
}
async function delay(time) {
    await new Promise(resolve => {
        setTimeout(resolve, time);
    });
}
async function acquireLock(database, name) {
    const store = database.transaction(STORE_NAME, 'readwrite').objectStore(STORE_NAME);
    const lock = await store.get(name);
    const lockIsAcquired = lock == null || lock.expiresAt < Date.now();
    if (lockIsAcquired) {
        await store.put({ name, expiresAt: Date.now() + MAX_LOCK_DURATION });
        console.debug(`Lock: Acquired ${name}`);
    }
    else {
        await delay(ACQUIRE_LOOP_INTERVAL);
        await acquireLock(database, name);
    }
}
async function openDatabase() {
    try {
        return await openIndexedDB(DATABASE_NAME, 1, {
            upgrade(database) {
                database.createObjectStore(STORE_NAME, { keyPath: 'name' });
            }
        });
    }
    catch (error) {
        console.error(error);
        return null;
    }
}
/**
 * Acquires an exclusive lock in all browser tabs and executes `callback`.
 * @param {string} name
 * @param {() => Promise<T = void>} callback
 * @returns {Promise<T = void>}
 * @template T
 */
export default async function withLock(name, callback) {
    const database = await openDatabase();
    if (database != null) {
        await acquireLock(database, name);
        const intervalId = setInterval(() => prolongLock(database, name), PROLONG_INTERVAL);
        try {
            return await callback();
        }
        finally {
            clearInterval(intervalId);
            await delay(0);
            await releaseLock(database, name);
        }
    }
    // Old browser, IndexedDB not supported.
    // Execute callback without locks.
    const result = await callback();
    return result;
}
