import { useQuery } from '@tanstack/react-query';
import { getAnalyticsForPlugin } from '../api/plugins-analytics';
import { formatApiError } from '../api/errors';
import { getQueryAlertMessages } from './formatters/alerts';
const keys = {
    plugin: (id) => ['analytics', 'plugin', id]
};
export function useAnalyticsForPlugin(pluginId, filteringParameters) {
    return useQuery({
        queryKey: keys.plugin(pluginId),
        queryFn: () => getAnalyticsForPlugin(pluginId, filteringParameters),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load analytics for plugin')),
        initialData: []
    });
}
