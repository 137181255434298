import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { isEqual } from 'lodash';
import { useDialogCancelConfirmation } from '@app/hooks/use-dialog-cancel-confirmation';
import { RouteLeavingGuard } from '@components/route-leaving-guard/route-leaving-guard';
import { useUploadPluginsMutation } from '../../../queries/plugins';
import { Collapsible } from '../../../components/collapsible/collapsible';
import { formatApiError } from '../../../api/errors';
import { AlertTimeouts } from '../../../components/constants/constants';
import { UploadPluginForm } from './upload-plugin-form';
import { UploadingFilePreviews } from './uploading-file-previews';
import { UploadPluginInfo } from './upload-plugin-info';
import styles from './upload-plugin.css';
export default function UploadPlugin({ onClose, title }) {
    const [channels, setChannels] = useState([]);
    const [generalUploadingError, setGeneralUploadingError] = useState('');
    const [files, dispatchFile] = useReducer((state, action) => {
        if (action.type === 'update') {
            return state.map(it => (it.id === action.file.id ? action.file : it));
        }
        if (action.type === 'remove') {
            return state.filter(it => it.id !== action.file.id);
        }
        return [...state, action.file];
    }, []);
    const [expirationDate, setExpirationDate] = useState(null);
    const uploadPluginsMutation = useUploadPluginsMutation();
    const onSubmit = useCallback(async () => {
        const updateFileModel = (updatedFileWrapper) => {
            dispatchFile({
                type: 'update',
                file: updatedFileWrapper
            });
            return updatedFileWrapper;
        };
        const uploadFile = (fileWrapper) => {
            return uploadPluginsMutation
                .mutateAsync({
                file: fileWrapper.file,
                channels: channels.map(({ name }) => name),
                expirationDate,
                onPluginLoadingProgressChanged: (_, progress) => updateFileModel({
                    ...fileWrapper,
                    progress
                })
            })
                .then(() => updateFileModel({ ...fileWrapper, progress: 1 }))
                .catch(err => updateFileModel({
                ...fileWrapper,
                progress: 1,
                error: formatApiError(err, 'Failed to upload file')
            }));
        };
        return Promise.all(files.map(uploadFile));
    }, [channels, expirationDate, files, uploadPluginsMutation]);
    const isUploading = useMemo(() => files.some(({ progress }) => progress >= 0 && progress < 1), [files]);
    const isFinishedUploading = useMemo(() => files.length && !files.some(({ progress }) => progress !== 1), [files]);
    const isFormDirty = expirationDate !== null || files.length > 0 || !isEqual(channels, ['Stable']) || isUploading;
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onClose, isFormDirty);
    useEffect(() => {
        if (isFinishedUploading) {
            const filesWithErrors = files.filter(file => !!file.error);
            if (filesWithErrors.length) {
                const getGeneralUploadingMessage = () => {
                    if (filesWithErrors.length === files.length) {
                        return filesWithErrors.length === 1
                            ? 'File Was Not Uploaded'
                            : 'No Files Were Uploaded';
                    }
                    return filesWithErrors.length === 1
                        ? `1 from ${files.length} Files Was Not Uploaded`
                        : `${filesWithErrors.length} from ${files.length} Files Were Not Uploaded`;
                };
                setGeneralUploadingError(getGeneralUploadingMessage());
            }
            else {
                alert.successMessage(files.length === 1
                    ? `Plugin "${files[0].file.name}" was successfully uploaded`
                    : `${files.length} plugins were successfully uploaded`, AlertTimeouts.SHORT);
                onClose();
            }
        }
    }, [files, isFinishedUploading, onClose]);
    const addFile = useCallback((file) => dispatchFile({ type: 'add', file }), []);
    const removeFile = useCallback((file) => dispatchFile({ type: 'remove', file }), []);
    if (generalUploadingError) {
        return (_jsxs(Dialog, { show: true, "data-test": "upload-plugin-error", contentClassName: styles.dialogContent, onCloseAttempt: onCloseAttempt, children: [_jsx(Header, { children: generalUploadingError }), _jsx(Content, { children: _jsx(UploadingFilePreviews, { files: files }) }), _jsx(Panel, { children: _jsx(Button, { primary: true, onClick: onClose, autoFocus: true, children: "Close" }) })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Dialog, { show: true, "data-test": "upload-plugin-dialog", onCloseAttempt: onCloseAttempt, contentClassName: styles.dialogContent, children: [_jsx(Header, { children: title }), _jsxs(Content, { children: [_jsx(UploadPluginForm, { isLoading: uploadPluginsMutation.isLoading, channels: channels, onChannelsUpdated: setChannels, expirationDate: expirationDate, files: files, addFile: addFile, removeFile: removeFile, onExpirationDateUpdated: setExpirationDate }), _jsx(Collapsible, { title: "How to distribute plugins automatically?", className: styles.instructions, children: _jsx(UploadPluginInfo, { channels: channels, expirationDate: expirationDate }) })] }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: onSubmit, loader: isUploading, disabled: !files.length, "data-test": "upload-plugin-submit", children: "Upload" }), _jsx(Button, { onClick: onClose, disabled: isUploading, ref: cancelButtonRef, children: "Cancel" })] })] }), _jsx(RouteLeavingGuard, { when: isUploading })] }));
}
