import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@jetbrains/ring-ui/components/button/button';
import addIcon from '@jetbrains/icons/add';
import classNames from 'classnames';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import styles from './image-upload.css';
export function getDefaultImageDropzoneOptions() {
    return {
        accept: ['image/png', 'image/svg+xml', 'image/jpeg'],
        maxSize: 2 * 1024 * 1024,
        multiple: false,
        noClick: true,
        noKeyboard: true
    };
}
export function formatRejection(rejection, acceptedFormats = 'PNG, SVG, JPG', masSizeStr = '2MB') {
    const { errors } = rejection;
    if (errors.length > 0) {
        const errorCode = errors[0].code;
        if (errorCode === 'file-invalid-type') {
            return `Only ${acceptedFormats} formats are supported`;
        }
        if (errorCode === 'file-too-large') {
            return `File can't be larger that ${masSizeStr}`;
        }
    }
    return '';
}
export function ImageUpload(props) {
    const { className, onUploaded } = props;
    const [errorMsg, setErrorMsg] = useState();
    useEffect(() => {
        if (errorMsg) {
            alert.error(errorMsg);
            setErrorMsg(undefined);
        }
    }, [errorMsg]);
    const handleDropAccepted = (files) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                onUploaded(reader.result);
            }
        };
        reader.readAsDataURL(files[0]);
        setErrorMsg(undefined);
    };
    const handleDropRejected = (rejections) => {
        setErrorMsg(formatRejection(rejections[0]));
    };
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        ...getDefaultImageDropzoneOptions(),
        onDropAccepted: handleDropAccepted,
        onDropRejected: handleDropRejected
    });
    return (_jsxs("div", { ...getRootProps(), className: classNames(styles.rootContainer, className), children: [_jsx("input", { ...getInputProps() }), _jsx(Button, { icon: addIcon, title: "Add company logo", onClick: open, className: classNames(styles.uploadButton, isDragActive ? styles.dragActive : '') })] }));
}
