import { isApiError } from '@api/is-api-error';
import { getServiceClient } from '@components/auth/auth';
export async function getAiState() {
    var _a, _b;
    const serviceClient = getServiceClient();
    try {
        const res = await serviceClient.get('/api/constituents/ai');
        return { status: 'enabled', data: res.data };
    }
    catch (e) {
        if (isApiError(e)) {
            if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                return { status: 'disabled' };
            }
            if (((_b = e.response) === null || _b === void 0 ? void 0 : _b.status) === 403) {
                return { status: 'disabled_jba' };
            }
        }
        throw e;
    }
}
export async function enableAi(params) {
    const serviceClient = getServiceClient();
    const res = await serviceClient.post('/api/constituents/ai', params);
    return { status: 'enabled', data: res.data };
}
export async function changeAiSettings(params) {
    const serviceClient = getServiceClient();
    const res = await serviceClient.put('/api/constituents/ai', params);
    return { status: 'enabled', data: res.data };
}
export async function disableAi() {
    await getServiceClient().delete('/api/constituents/ai');
    return { status: 'disabled' };
}
