// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__c8b {
  position: relative;
}

.sectionHeader__a07 {
  display: flex;
  align-items: center;
}

.logoWrapper__cb2 {
  width: 16px;
  height: 16px;
}

.h3__fbb {
  margin-top: 0;
}

.logoWrapper__cb2 + .h3__fbb {
  margin-left: calc(var(--ring-unit) / 2);
}

.blockContent__bf1 {
  margin-top: calc(var(--ring-unit) * 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/components/titled-block/titled-block.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.sectionHeader {\n  display: flex;\n  align-items: center;\n}\n\n.logoWrapper {\n  width: 16px;\n  height: 16px;\n}\n\n.h3 {\n  margin-top: 0;\n}\n\n.logoWrapper + .h3 {\n  margin-left: calc(var(--ring-unit) / 2);\n}\n\n.blockContent {\n  margin-top: calc(var(--ring-unit) * 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__c8b`,
	"sectionHeader": `sectionHeader__a07`,
	"logoWrapper": `logoWrapper__cb2`,
	"h3": `h3__fbb`,
	"blockContent": `blockContent__bf1`
};
export default ___CSS_LOADER_EXPORT___;
