import * as z from 'zod';
export const tFiltersSchema = z
    .array(z.object({
    products: z.array(z.string()).nullable().optional(),
    builds: z.array(z.string()).nullable().optional(),
    type: z.union([
        z.literal('include'),
        z.literal('exclude'),
        z.literal('include-all'),
        z.literal('exclude-all')
    ]),
    os: z.array(z.string()).nullable().optional(),
    arch: z.array(z.string()).nullable().optional(),
    quality: z
        .object({
        name: z.string(),
        orderValue: z.number(),
        condition: z.string()
    })
        .nullable()
        .optional()
}))
    .min(1, { message: 'Please add at least one filter option' });
export const tFiltersFormSchema = z.object({ filters: tFiltersSchema });
