import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import { useAutomationTokens, useDeleteTokenMutation } from '@app/queries/automation';
import LoadingError from '@components/errors/loading-error';
import auth from '@components/auth/auth';
import { useModalsController } from '@components/modals';
import NavigationLink from '@components/navigation/navigation-link';
import { AutomationTokenCreatePopup } from './automation-token-popups/automation-token-create-popup';
import { AutomationTokenCopyPopup } from './automation-token-popups/automation-token-copy-popup';
import { AutomationTokenUpdatePopup } from './automation-token-popups/automation-token-update-popup';
import { AutomationTokensTable } from './automation-tokens-table/automation-tokens-table';
import { confirmTokenDeletion } from './confirm-token-deletion';
import styles from './automation-tokens.css';
export function AutomationTokens() {
    const modalsController = useModalsController();
    const { data, isError, isFetching } = useAutomationTokens();
    const tokens = useMemo(() => data !== null && data !== void 0 ? data : [], [data]);
    const deleteMutation = useDeleteTokenMutation();
    const handleRemoveToken = useCallback(async (token) => {
        try {
            await confirmTokenDeletion();
            await deleteMutation.mutateAsync({
                tokenId: token.id,
                clientId: token.clientId
            });
        }
        catch (e) {
            hideConfirm();
        }
    }, [deleteMutation]);
    const handleOpenCreateTokenClick = useCallback(async () => {
        const createTokenModal = modalsController.openModal(AutomationTokenCreatePopup, {});
        const token = await createTokenModal.promise;
        createTokenModal.closeModal();
        if (token) {
            const copyTokenModal = modalsController.openModal(AutomationTokenCopyPopup, { token });
            copyTokenModal.promise.then(() => copyTokenModal.closeModal());
        }
    }, [modalsController]);
    const handleEditToken = useCallback(async (token) => {
        const editTokenModal = modalsController.openModal(AutomationTokenUpdatePopup, { token });
        editTokenModal.promise.then(() => editTokenModal.closeModal());
    }, [modalsController]);
    if (isError) {
        return _jsx(LoadingError, {});
    }
    const isSwaggerEnabled = auth.getFeatures().swaggerEnabled === true;
    return (_jsxs("main", { children: [_jsx("p", { children: "Automation tokens let you send HTTP requests to JetBrains IDE Services API." }), isSwaggerEnabled ? (_jsxs("p", { children: ["To check for available APIs, use", ' ', _jsx(NavigationLink, { href: `${auth.getBaseURL()}/swagger-ui.html`, target: "_blank", children: "OpenAPI documentation." })] })) : (_jsxs("p", { children: [_jsx("i", { children: "Note:" }), " make sure ", _jsx("b", { children: "springdoc.api-docs.enabled" }), " property is set to ", _jsx("b", { children: "true" }), " in server configuration to be able to get OpenAPI documentation."] })), _jsx(Button, { className: styles.createTokenBtn, onClick: handleOpenCreateTokenClick, inline: false, children: "Create Automation Token" }), _jsx(AutomationTokensTable, { className: styles.tokens, tokens: tokens, loading: isFetching, onEditTokenClick: handleEditToken, onDeleteTokenClick: handleRemoveToken })] }));
}
