// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listItem__b81.listItem__b81 {
  padding-left: calc(4 * var(--ring-unit)) !important;
}

.listItemHovered__ab9.listItemHovered__ab9 {
  background-color: var(--ring-hover-background-color);
}

.listItemSelected__a45 {
  background-color: var(--ring-selected-background-color);
}

.listItemTitle__a5f {
  margin: var(--ring-unit) 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/app-layout/sidebar/sidebar-menu/sidebar-menu.css"],"names":[],"mappings":"AAAA;EACE,mDAAmD;AACrD;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".listItem.listItem {\n  padding-left: calc(4 * var(--ring-unit)) !important;\n}\n\n.listItemHovered.listItemHovered {\n  background-color: var(--ring-hover-background-color);\n}\n\n.listItemSelected {\n  background-color: var(--ring-selected-background-color);\n}\n\n.listItemTitle {\n  margin: var(--ring-unit) 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": `listItem__b81`,
	"listItemHovered": `listItemHovered__ab9`,
	"listItemSelected": `listItemSelected__a45`,
	"listItemTitle": `listItemTitle__a5f`
};
export default ___CSS_LOADER_EXPORT___;
