import { jsx as _jsx } from "react/jsx-runtime";
import Button from '@jetbrains/ring-ui/components/button/button';
import classNames from 'classnames';
import tableStyles from './full-width-table-view.css';
export function TableActionsContainer({ children }) {
    return _jsx("span", { className: tableStyles.tableActionContainer, children: children });
}
export function TableActionButton({ className, action, onKeyDown, ...restProps }) {
    return (_jsx(Button, { className: classNames(tableStyles.tableHoverAction, className), onClick: action, onKeyDown: evt => {
            if (evt.keyCode === 13) {
                evt.stopPropagation();
                evt.preventDefault();
                action();
            }
            if (onKeyDown) {
                onKeyDown(evt);
            }
        }, ...restProps }));
}
