import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import { NavigationLinkOutsideRouter } from '@components/navigation/navigation-link-outside-router';
export function confirmUserRemoval(user, profileName) {
    return confirm({
        text: 'Remove User from Profile',
        description: (_jsxs(_Fragment, { children: ["Do you really want to remove", ' ', _jsx(NavigationLinkOutsideRouter, { href: `users/${user.id}`, children: user.username }), ' ', "from profile ", _jsx("b", { children: profileName }), "?"] })),
        confirmLabel: 'Remove',
        rejectLabel: 'Cancel',
        cancelIsDefault: true
    });
}
