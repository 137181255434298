import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import Button from '@jetbrains/ring-ui/components/button/button';
import SmartToolsPreview from './smart-tools-preview';
import PluginDescription from './plugin-description';
import { getDescription } from './preview-description';
import styles from './settings-preview.css';
export function SettingsPreview(props) {
    const { settings, settingType } = props;
    const settingsList = 'content' in settings ? mapToSettingList(settings) : undefined;
    return (_jsxs("div", { className: styles.boxContainer, children: [_jsx(SmartToolsPreview, { tools: settings.tools, isLoading: false }), settingsList && _jsx(SettingsList, { settings: settingsList, settingType: settingType })] }));
}
function mapToSettingList(settingsContent) {
    if (!isToolboxSettings(settingsContent)) {
        return settingsContent.content;
    }
    return Object.entries(settingsContent.content)
        .filter(([, value]) => value) // removes any null/undefined values
        .map(([key, value]) => ({
        ...value,
        id: key
    }))
        .sort((s1, s2) => s1.id.localeCompare(s2.id));
}
function isToolboxSettings(settings) {
    return 'content' in settings && 'updateToolsAutomatically' in settings.content;
}
const INITIAL_DISPLAYED_COUNT = 15;
function SettingsList(props) {
    const { settings, settingType } = props;
    const [displayedCount, setDisplayedCount] = useState(INITIAL_DISPLAYED_COUNT);
    const handleShowMore = (e) => {
        e.stopPropagation();
        setDisplayedCount(displayedCount + INITIAL_DISPLAYED_COUNT);
    };
    const hasMore = displayedCount < settings.length;
    if (settings.length === 0) {
        return (_jsx("div", { className: styles.boxSettingsListContainer, children: _jsx(Text, { className: styles.boxText, children: "No settings applied" }) }));
    }
    const visibleSettings = settings.slice(0, Math.min(displayedCount, settings.length));
    return (_jsxs("div", { className: styles.boxSettingsListContainer, children: [visibleSettings
                .flatMap(setting => getDescription(settingType, setting).flatMap(description => [{ setting, description }]))
                .flatMap(settingWithDescription => {
                const { setting, description } = settingWithDescription;
                switch (settingType) {
                    case 'ide':
                    case 'vmOptions':
                    case 'toolboxSetting': {
                        return (_jsx(Text, { className: styles.boxText, children: description }, description));
                    }
                    default: {
                        return _jsx(PluginDescription, { content: setting }, description);
                    }
                }
            }), hasMore && (_jsxs("div", { className: styles.showMoreBlock, children: [_jsx(Button, { text: true, onClick: handleShowMore, children: "Show more" }), _jsxs(Text, { info: true, children: [visibleSettings.length, " out of ", settings.length] })] }))] }));
}
