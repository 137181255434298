import { useCallback, useContext, useEffect, useRef } from 'react';
import { modalsControllerContext } from './context';
export function useModalsController() {
    const context = useContext(modalsControllerContext);
    if (!context) {
        throw new Error('useModalController must be used inside ModalContextProvider');
    }
    return context;
}
export function useOpenModal(ModalComponent, modalProps) {
    const modalsController = useModalsController();
    const openedModalRef = useRef(undefined);
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => { var _a; return (_a = openedModalRef.current) === null || _a === void 0 ? void 0 : _a.closeModal(); };
    }, []);
    useEffect(() => {
        var _a;
        (_a = openedModalRef.current) === null || _a === void 0 ? void 0 : _a.updateProps(modalProps);
    }, [modalProps]);
    const openModal = useCallback(async () => {
        const modal = modalsController.openModal(ModalComponent, modalProps);
        openedModalRef.current = modal;
        const modalResult = await modal.promise;
        openedModalRef.current = undefined;
        modal.closeModal();
        return modalResult;
    }, [ModalComponent, modalProps, modalsController]);
    return openModal;
}
