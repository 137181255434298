import { jsx as _jsx } from "react/jsx-runtime";
import { useController, useFormContext } from 'react-hook-form';
import { ToolboxSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/toolbox-setting-field';
export function UpdateToolsAutomaticallyToolboxSettingField() {
    const { control } = useFormContext();
    const { field: { value, onChange } } = useController({
        control,
        name: 'content.updateToolsAutomatically'
    });
    return (_jsx(ToolboxSettingField, { label: "Update tools automatically", setting: value, onSettingChange: newSetting => {
            onChange(newSetting);
        } }));
}
