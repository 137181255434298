// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentBody__df4 {
  display: block;
  border: 1px solid var(--ring-line-color);
  max-width: 100%;
  margin: calc(0.5 * var(--ring-unit)) 0;
  border-radius: var(--ring-border-radius);
}

.downloadButton__f9e {
  margin-bottom: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics/collected-data-reports-page.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,wCAAwC;EACxC,eAAe;EACf,sCAAsC;EACtC,wCAAwC;AAC1C;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".contentBody {\n  display: block;\n  border: 1px solid var(--ring-line-color);\n  max-width: 100%;\n  margin: calc(0.5 * var(--ring-unit)) 0;\n  border-radius: var(--ring-border-radius);\n}\n\n.downloadButton {\n  margin-bottom: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBody": `contentBody__df4`,
	"downloadButton": `downloadButton__f9e`
};
export default ___CSS_LOADER_EXPORT___;
