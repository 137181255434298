import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { CodeWithMeLogo } from '@jetbrains/logos/react';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { isNil } from 'lodash';
import { useCwmChangeSettingsMutation } from '@app/queries/code-with-me';
import { LimitAssigmentEditor } from '../../../shared/limit-assigment-editor/limit-assigment-editor';
import { ConstituentDialog } from '../../../shared/constituent-dialog/constituent-dialog';
export function CwmEditSettingsDialog(props) {
    const { onCloseAttempt, initialLimitUsage } = props;
    const { mutate: changeSettings, isLoading } = useCwmChangeSettingsMutation(onCloseAttempt);
    const [usage, setUsage] = useState(initialLimitUsage !== null && initialLimitUsage !== void 0 ? initialLimitUsage : 'unlimited');
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    const handleActivate = useCallback(() => {
        changeSettings({
            concurrentHostsLimit: usage === 'unlimited' ? undefined : usage
        });
    }, [changeSettings, usage]);
    const activateDisabled = isNil(usage) || (usage !== 'unlimited' && usage < 1);
    return (_jsxs(ConstituentDialog, { title: "Configure Code With Me Enterprise", titleLogo: _jsx(CodeWithMeLogo, {}), children: [_jsx(Content, { children: _jsx(LimitAssigmentEditor, { toggleText: "Unlimited concurrent sessions", limitNotice: _jsxs(_Fragment, { children: ["You can set a limit on the number of concurrent sessions,", _jsx("br", {}), "blocking users from creating new sessions when the limit is reached"] }), limit: usage, onLimitChange: setUsage }) }), _jsxs(Panel, { children: [_jsx(Button, { onClick: handleActivate, disabled: activateDisabled, loader: isLoading, primary: true, children: "Apply" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
