import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import Select from '@jetbrains/ring-ui/components/select/select';
import { isNil, uniqBy } from 'lodash';
import RegisteredSelect from '@components/hook-form/registered-select';
import { Required } from '@components/form/important-asterisk';
import { useAllFormattedPluginsSelectOptions } from '../hooks';
export function PluginsField({ pluginIndex }) {
    var _a;
    const [filter, setFilter] = useState('');
    const { control, setValue, watch } = useFormContext();
    const { data: pluginsData, isFetching: isLoading } = useAllFormattedPluginsSelectOptions(filter, false);
    const setFormValue = (selectedPlugin) => {
        if (isNil(selectedPlugin)) {
            return;
        }
        const pluginKey = typeof selectedPlugin.key === 'string' ? selectedPlugin.key : selectedPlugin.label;
        setValue(`content.${pluginIndex}.pluginId`, pluginKey, {
            shouldDirty: true
        });
    };
    const pluginId = watch(`content.${pluginIndex}.pluginId`);
    const selectedPlugin = {
        key: pluginId,
        label: ((_a = pluginsData.find(p => p.key === pluginId)) === null || _a === void 0 ? void 0 : _a.label) || pluginId
    };
    return (_jsx(RegisteredSelect, { selectedLabel: Required('Plugin ID'), inputPlaceholder: "Example: org.jetbrains.plugins.template", name: `content.${pluginIndex}.pluginId`, control: control, onFilter: query => setFilter(query), loading: isLoading, selected: selectedPlugin, onSelect: selection => setFormValue(selection), data: uniqBy(pluginsData, 'key'), filter: { fuzzy: true }, size: Size.L, type: Select.Type.INPUT, allowAny: true, hideArrow: true }));
}
