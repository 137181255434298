// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formContainer__d9e {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/user-edit-form-fields.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;AACvB","sourcesContent":[".formContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `formContainer__d9e`
};
export default ___CSS_LOADER_EXPORT___;
