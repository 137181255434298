// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.helpIcon__b43 {
  color: var(--ring-icon-color);
}

.helpIcon__b43:hover {
  color: var(--ring-icon-hover-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/util/help-icon.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".helpIcon {\n  color: var(--ring-icon-color);\n}\n\n.helpIcon:hover {\n  color: var(--ring-icon-hover-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpIcon": `helpIcon__b43`
};
export default ___CSS_LOADER_EXPORT___;
