import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
import Icon from '@jetbrains/ring-ui/components/icon';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import moreOptionsIcon from '@jetbrains/icons/more-options';
import Group from '@jetbrains/ring-ui/components/group/group';
import { noop } from 'lodash';
import { Navigate, useLoaderData } from 'react-router-dom';
import { PageTitle } from '../../../components/page-layout/page-title';
import { useDeletePluginMutation } from '../../../queries/plugins';
import ConfigureCustomChannelDialog from '../configure-custom-channel-dialog';
import { confirmPluginDeletion } from '../confirmations';
import UploadPlugin from '../upload-plugin/upload-plugin';
import PluginVersions from './plugin-versions';
import PluginInfo from './plugin-info/plugin-info';
import styles from './plugin-page.css';
export default function PluginPage() {
    const plugin = useLoaderData();
    const [isConfiguringChannel, setIsConfiguringChannel] = useState(false);
    const [showPluginUpload, setShowPluginUpload] = useState(false);
    const onClosePluginDialog = useCallback(() => setShowPluginUpload(false), []);
    const deleteMutation = useDeletePluginMutation();
    const enableFetching = !(deleteMutation.isLoading || deleteMutation.isSuccess);
    const moreActions = useMemo(() => [
        {
            key: 'remove-plugin',
            className: styles.danger,
            label: 'Remove plugin',
            onClick: () => confirmPluginDeletion(plugin)
                .then(() => deleteMutation.mutateAsync(plugin.id))
                .catch(noop)
        }
    ], [plugin, deleteMutation]);
    if (deleteMutation.isSuccess) {
        return _jsx(Navigate, { to: "/plugins" });
    }
    return (_jsxs("div", { children: [_jsx(PageTitle, { parts: [{ title: 'Plugins', href: '/plugins' }, { title: (plugin === null || plugin === void 0 ? void 0 : plugin.name) || '' }], rightPanel: _jsxs(_Fragment, { children: [_jsx(Button, { onClick: () => setIsConfiguringChannel(true), text: true, children: "Copy plugin repository URL" }), _jsxs(Group, { children: [_jsx(Button, { primary: true, "data-test": "upload-plugin", onClick: () => setShowPluginUpload(true), children: "Upload plugin version" }), _jsx(DropdownMenu, { data: moreActions, disabled: !enableFetching, anchor: _jsx(Button, { disabled: !enableFetching, short: true, children: _jsx(Icon, { glyph: moreOptionsIcon }) }) })] })] }) }), deleteMutation.isLoading && _jsx(Loader, {}), enableFetching && (_jsxs(_Fragment, { children: [_jsx(PluginInfo, { plugin: plugin }), _jsx(PluginVersions, { plugin: plugin }), isConfiguringChannel && (_jsx(ConfigureCustomChannelDialog, { pluginId: plugin.id, onClose: () => setIsConfiguringChannel(false) }))] })), showPluginUpload && (_jsx(UploadPlugin, { onClose: onClosePluginDialog, title: "Upload Plugin Version" }))] }));
}
