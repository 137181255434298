import { jsx as _jsx } from "react/jsx-runtime";
import TagsInput from '@jetbrains/ring-ui/components/tags-input/tags-input';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import RingField from '@components/form/ring-field';
import { architectureOptions } from '../filter-options';
function ArchField() {
    const { setValue, watch } = useFormContext();
    const arch = watch('arch');
    return (_jsx(RingField, { fieldId: "archField", label: "Architecture", children: _jsx(TagsInput, { id: "archField", dataSource: () => architectureOptions, tags: architectureOptions.filter(tag => arch === null || arch === void 0 ? void 0 : arch.includes(tag.key)), filter: true, onAddTag: ({ tag }) => setValue('arch', arch ? [...arch, tag.key] : [tag.key]), onRemoveTag: ({ tag }) => arch &&
                setValue('arch', arch.filter(item => item !== tag.key)), placeholder: isEmpty(arch) ? 'All' : '' }) }));
}
export default ArchField;
