import React, {useMemo, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';

import {useNavigate} from 'react-router-dom';
import {FullWidthSelectableTable} from '@components/tables/full-width-selectable-table';
import {getErrorsAnalytics} from '../../../api/collected-data-error';
import {RANGE_OPTIONS} from '../analytics-table';
import {Range} from '../range';
import {DEFAULT_TABLE_PAGE_SIZE} from '../../../api/paging-utils';
import {formatApiError} from '../../../api/errors';
import {PageTitle} from '../../../components/page-layout/page-title';
import NavigationLink from '../../../components/navigation/navigation-link';
import styles from './collected-data-errors.css';

export const ERRORS_PATH = '/analytics/collected-data/errors';

/** @typedef {{
 *   id: number,
 *   title: string,
 *   count: number,
 * }} ReportedErrorRow
 */

const IDENTIFIER_COLUMN = 'identifier';
const TITLE_COLUMN = 'title';
const COUNT_COLUMN = 'count';

/**
 *
 * @param {TReportedError} error
 * @return {ReportedErrorRow}
 */
function toRow(error) {
  return {
    ...error,
    identifier: `EA-${error.id}`
  };
}

function toApiSortColumn(column) {
  switch (column) {
    case IDENTIFIER_COLUMN:
      return 'ID';
    case TITLE_COLUMN:
      return 'TITLE';
    case COUNT_COLUMN:
      return 'REPORTS_NUMBER';
    default:
      return '';
  }
}

// noinspection RequiredAttributes
function CollectedDataErrorsTable({
  data,
  sortColumn,
  sortOrder,
  onSortOrderChange,
  onSortColumnChange,
  onItemEnter,
  isLoading,
  className,
  pagerProps
}) {
  const columns = [
    {
      id: IDENTIFIER_COLUMN,
      title: 'Identifier',
      sortable: true,
      className: styles.smallColumn,
      getValue(element) {
        return (
          <NavigationLink href={`${ERRORS_PATH}/${element.id}`}>
            {element.identifier}
          </NavigationLink>
        );
      }
    },
    {id: TITLE_COLUMN, title: 'Title', sortable: true},
    {
      id: COUNT_COLUMN,
      title: 'Reports',
      sortable: true,
      rightAlign: true,
      className: styles.smallColumn
    },
    {id: 'right-placeholder', title: ''}
  ];

  const rows = useMemo(() => (data || []).map(x => toRow(x)), [data]);

  return (
    <FullWidthSelectableTable
      data={rows}
      columns={columns}
      loading={isLoading}
      onSort={event => {
        onSortOrderChange(event.order);
        onSortColumnChange(event.column.id);
      }}
      sortKey={sortColumn}
      sortOrder={sortOrder}
      onItemEnter={onItemEnter}
      className={className}
      selectable={false}
      renderEmpty={() => 'No data available for selected period'}
      pagerProps={pagerProps}
    />
  );
}

export default function CollectedDataErrors() {
  // --- Options
  const [sortColumn, setSortColumn] = useState(COUNT_COLUMN);
  const [sortOrder, setSortOrder] = useState(false); // false == Descending
  const [page, setPage] = useState(1);
  const [range, setRange] = useState(RANGE_OPTIONS[1].key);

  /// -- Rest params
  const navigate = useNavigate();

  const {data: container, isFetching} = useQuery(
    ['reported-errors', range, page, sortColumn, sortOrder],
    () =>
      getErrorsAnalytics(
        range,
        page,
        DEFAULT_TABLE_PAGE_SIZE,
        undefined,
        toApiSortColumn(sortColumn),
        sortOrder ? 'ASC' : 'DESC'
      ),
    {
      placeholderData: {total: 0, items: []},
      staleTime: 60 * 1000, // 1 min
      retry: 1,
      onError: e => alert.error(formatApiError(e, 'Failed to load error reports'))
    }
  );

  const onItemEnter = element => {
    navigate(`${ERRORS_PATH}/${element.id}`);
  };

  return (
    <>
      <PageTitle parts={[{title: 'Analytics'}, {title: 'Error Reports'}]} />
      <Range range={range} rangeOptions={RANGE_OPTIONS} onRangeChange={setRange} />
      <CollectedDataErrorsTable
        data={container?.items}
        onSortColumnChange={setSortColumn}
        onSortOrderChange={setSortOrder}
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        onItemEnter={onItemEnter}
        isLoading={isFetching}
        className={styles.collectedDataErrorsTable}
        pagerProps={{
          total: container?.total,
          pageSize: DEFAULT_TABLE_PAGE_SIZE,
          currentPage: page,
          disablePageSizeSelector: true,
          onPageChange: setPage
        }}
      />
    </>
  );
}
