import { KEY_VALUE_LIST_KIND } from './settings-builders';
export function getSettingDescriptions(setting) {
    if (setting.kind === KEY_VALUE_LIST_KIND) {
        return getKeyValueListDescriptions(setting);
    }
    return [setting.description || '[Empty description]'];
}
export function getKeyValueListDescriptions(setting) {
    const commonDescription = setting.description;
    if (commonDescription) {
        return [commonDescription];
    }
    const entryDescriptions = getEntries(setting).map(parsedValueData => getEntryDescription(parsedValueData));
    if (entryDescriptions.length === 0) {
        return ['Setting of type "key-value list"'];
    }
    return entryDescriptions;
}
function getEntries(setting) {
    return Object.entries(setting.entries).map(([key, valueData]) => {
        if (valueData !== undefined && valueData instanceof Object && !(valueData instanceof Array)) {
            return { ...valueData, key };
        }
        return { key, value: valueData };
    });
}
function getEntryDescription(entry) {
    if (entry.description) {
        return entry.description;
    }
    const valueString = JSON.stringify(entry.value);
    switch (entry.operation) {
        case 'ADD_ELEMENTS': {
            return `${entry.key} += ${valueString}`;
        }
        case 'REMOVE_ELEMENTS': {
            return `${entry.key} -= ${valueString}`;
        }
        default: {
            return `${entry.key} = ${valueString}`;
        }
    }
}
