import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useQuery} from '@tanstack/react-query';

import {Tab, Tabs} from '@jetbrains/ring-ui/components/tabs/tabs';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';

import {FullWidthSelectableTable} from '@components/tables/full-width-selectable-table';
import {getOsAnalytics, getOsMachinesAmountAnalytics} from '../../api/operating-systems-analytics';
import {setPagingParameters, setSortingParameters} from '../../store/operating-systems-analytics';
import HelpIcon from '../../components/util/help-icon';
import {formatApiError} from '../../api/errors';
import {PageTitle} from '../../components/page-layout/page-title';
import AnalyticsTable, {RANGE_OPTIONS} from './analytics-table';
import styles from './operating-systems-analytics.css';

const osAnalyticsColumns = [
  {
    id: 'osName',
    title: 'Name',
    sortable: true,
    getValue(item) {
      const {osName} = item;
      return (
        <>
          {osName === 'Unknown' ? 'Other' : osName}
          <Tooltip
            className={styles.tooltip}
            delay={300}
            title="Some usage events don't contain information about the operating systems"
            hidden={osName !== 'Unknown'}
          >
            <HelpIcon />
          </Tooltip>
        </>
      );
    }
  },
  {
    id: 'osVersion',
    title: 'Version',
    sortable: true,
    rightAlign: true,
    getValue(item) {
      const {osVersion} = item;
      return osVersion === 'Unknown' ? '-' : osVersion;
    }
  },
  {id: 'osDistribution', title: 'Distribution', sortable: true},
  {
    id: 'osDistributionVersion',
    title: 'Distribution Version',
    sortable: true
  },
  {id: 'machinesCount', title: 'Devices', sortable: true, rightAlign: true},
  {id: 'right-placeholder', title: ''}
];

function OsAnalyticsTable({onRangeChange}) {
  const dispatch = useDispatch();

  const stateSelector = state => state.osAnalytics;
  const pageSize = useSelector(state => stateSelector(state).pageSize);

  const onSort = event => {
    dispatch(setSortingParameters(event));
  };

  const changeRange = newRange => {
    dispatch(d => getOsAnalytics(newRange, d));
    onRangeChange(newRange);
  };

  const onPageChange = nextPageIndex => {
    dispatch(setPagingParameters({nextPageIndex, pageSize}));
  };

  return (
    <AnalyticsTable
      title="Operating systems being used"
      columns={osAnalyticsColumns}
      onSort={onSort}
      onPageChange={onPageChange}
      onRangeChange={changeRange}
      stateSelector={stateSelector}
    />
  );
}

const fakeTotalOsName = 'Total';

const osOverviewColumns = [
  {
    id: 'osName',
    title: 'OS',
    getValue(item) {
      const {osName} = item;
      return osName === fakeTotalOsName ? (
        <b> {osName} </b>
      ) : (
        <>
          {osName === 'Unknown' ? 'Other' : osName}
          <Tooltip
            className={styles.tooltip}
            delay={300}
            title="Some usage events contain no information about the operating system"
            hidden={osName !== 'Unknown'}
          >
            <HelpIcon />
          </Tooltip>
        </>
      );
    }
  },
  {
    id: 'machinesCount',
    title: 'Devices',
    rightAlign: true,
    getValue(item) {
      const {osName, machinesCount} = item;
      return osName === fakeTotalOsName ? <b> {machinesCount} </b> : machinesCount;
    }
  },
  {id: 'right-placeholder', title: ''}
];

function OsOverview({range = undefined}) {
  const {data: analytics, isFetching} = useQuery(
    ['os-machines-analytics', range],
    () => getOsMachinesAmountAnalytics(range),
    {
      placeholderData: [],
      staleTime: 60 * 1000, // 1 min
      onError: e => alert.error(formatApiError(e, 'Failed to load OS machines analytics'))
    }
  );

  const data = [
    ...analytics.map(x => ({...x, key: x.osName})),
    // Last fake total column
    {
      osName: fakeTotalOsName,
      machinesCount: analytics.reduce((prev, cur) => prev + cur.machinesCount, 0),
      key: fakeTotalOsName
    }
  ];

  return (
    <FullWidthSelectableTable
      loading={isFetching}
      data={data}
      columns={osOverviewColumns}
      selectable={false}
      renderEmpty={() => 'No data available for selected period'}
    />
  );
}

export default function OperatingSystemsAnalytics() {
  const [selectedTab, setSelectedTab] = useState('analytics');
  const [range, setRange] = useState(RANGE_OPTIONS[1].key);

  return (
    <>
      <PageTitle parts={[{title: 'Analytics'}, {title: 'Operating Systems'}]} />
      <Tabs selected={selectedTab} onSelect={tab => setSelectedTab(tab)}>
        <Tab id="analytics" title="Analytics">
          <OsAnalyticsTable onRangeChange={x => setRange(x)} />
          <h3>Summary</h3>
          <OsOverview range={range} />
        </Tab>
        <Tab id="overview" title="Overview">
          <h3>All time overview</h3>
          <OsOverview />
        </Tab>
      </Tabs>
    </>
  );
}
