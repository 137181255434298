// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listContainer__d55 {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
}

.header__cc4 {
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
}

.collapseIcon__deb {
  color: #737577;
  margin-left: calc(var(--ring-unit) / 2);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/settings-list.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,uCAAuC;AACzC","sourcesContent":[".listContainer {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n}\n\n.header {\n  cursor: pointer;\n  letter-spacing: 2px;\n  text-transform: uppercase;\n  font-weight: 400;\n}\n\n.collapseIcon {\n  color: #737577;\n  margin-left: calc(var(--ring-unit) / 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": `listContainer__d55`,
	"header": `header__cc4`,
	"collapseIcon": `collapseIcon__deb`
};
export default ___CSS_LOADER_EXPORT___;
