import React from 'react';
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import HelpIcon from '../../components/util/help-icon';
import CollectedDataErrorAttachmentEntry from './collected-data-error-attachment-entry';

export default function CollectedDataErrorAttachments({attachments, totalSize}) {
  return (
    <>
      <h4>
        Errors with attachments:{' '}
        <Tooltip
          title="Total amount of reports can be different comparing to statistics above because it displays only reports that have attachments."
          popupProps={{directions: [Directions.RIGHT_CENTER]}}
        >
          <HelpIcon />
        </Tooltip>
      </h4>
      {totalSize > attachments.length && (
        <span>
          List of errors with attachments is too long, displaying {attachments.length} of{' '}
          {totalSize}
        </span>
      )}
      <ul>
        {attachments.map(e => (
          <li key={e.title}>
            <CollectedDataErrorAttachmentEntry title={e.title} url={e.url} />
          </li>
        ))}
      </ul>
    </>
  );
}
