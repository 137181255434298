import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown';
import Text from '@jetbrains/ring-ui/components/text/text';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import classnames from 'classnames';
import { LAST_7_DAYS } from '@app/pages/analytics/analytics-table';
import CopiedText from '@components/clipboard/copied-text/copied-text';
import HelpIcon from '@components/util/help-icon';
import twoColumnStyles from '@components/page-layout/two-column-form.css';
import auth from '@components/auth/auth';
import PluginDownloads from './plugin-downloads';
import styles from './plugin-info.css';
function PluginInfo({ plugin }) {
    const areAnalyticsEnabled = auth.getFeatures().analyticsEnabled === true;
    return (_jsxs("div", { className: classnames(twoColumnStyles.twoColumnForm, styles.gridContainer), children: [_jsx("span", { children: "Plugin ID" }), _jsx(CopiedText, { text: plugin.id }), _jsx("span", { children: "Vendor" }), _jsx(Text, { children: plugin.vendor }), areAnalyticsEnabled && (_jsxs(_Fragment, { children: [_jsxs("span", { children: [_jsx("span", { className: styles.downloadsLabel, children: "Downloads" }), _jsx(Tooltip, { delay: Dropdown.defaultProps.hoverShowTimeOut, title: "Plugin downloads for the last week", children: _jsx(HelpIcon, {}) })] }), _jsx(PluginDownloads, { pluginId: plugin.id, range: LAST_7_DAYS })] }))] }));
}
export default PluginInfo;
