// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateColumn__de6.dateColumn__de6 {
  min-width: fit-content;
}

.licenseKey__cd5 {
  color: var(--ring-link-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/license-history/license-history-body.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".dateColumn.dateColumn {\n  min-width: fit-content;\n}\n\n.licenseKey {\n  color: var(--ring-link-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateColumn": `dateColumn__de6`,
	"licenseKey": `licenseKey__cd5`
};
export default ___CSS_LOADER_EXPORT___;
