// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textContainer__eb1 {
  display: flex;
  align-items: center;
  width: 100%;
}

.textContainer__eb1 > span {
  padding-right: calc(var(--ring-unit) / 2);
}

.icon__a3c {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--ring-icon-color);
  width: var(--ring-unit);
  padding: 0 var(--ring-unit);
  height: 20px;
}

.navLink__ff3 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: var(--ring-unit);
}

.groupLink__ffc {
  color: var(--ring-secondary-color);
}

.directAssingnButton__c83 {
  display: flex;
  align-items: center;
  height: 20px;
  gap: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/profile-list-item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,6BAA6B;EAC7B,uBAAuB;EACvB,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".textContainer {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.textContainer > span {\n  padding-right: calc(var(--ring-unit) / 2);\n}\n\n.icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  color: var(--ring-icon-color);\n  width: var(--ring-unit);\n  padding: 0 var(--ring-unit);\n  height: 20px;\n}\n\n.navLink {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  padding-right: var(--ring-unit);\n}\n\n.groupLink {\n  color: var(--ring-secondary-color);\n}\n\n.directAssingnButton {\n  display: flex;\n  align-items: center;\n  height: 20px;\n  gap: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textContainer": `textContainer__eb1`,
	"icon": `icon__a3c`,
	"navLink": `navLink__ff3`,
	"groupLink": `groupLink__ffc`,
	"directAssingnButton": `directAssingnButton__c83`
};
export default ___CSS_LOADER_EXPORT___;
