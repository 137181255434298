import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Text from '@jetbrains/ring-ui/components/text/text';
import { H2 } from '@jetbrains/ring-ui/components/heading/heading';
import NavigationLink from '../navigation/navigation-link';
import styles from './breadcrumbs.css';
function BreadcrumbsPart({ title, href, className, ...restProps }) {
    return href ? (_jsx(NavigationLink, { href: href, className: className, enableIsActive: false, ...restProps, children: title })) : (_jsx(Text, { className: className, ...restProps, children: title }));
}
export function Breadcrumbs({ parts, className, typographyComponent = H2 }) {
    const Component = typographyComponent;
    return (_jsx(Component, { className: classNames(styles.breadcrumbContainer, className), children: parts.map(({ title, href, className: partClassName }, index) => (_jsxs("span", { className: styles.breadcrumb, children: [_jsx(BreadcrumbsPart, { title: title, href: href, "data-test": index < parts.length - 1 ? 'breadcrumb-part-nth' : 'breadcrumb-part-last', className: partClassName }), index !== parts.length - 1 && _jsx("span", { className: styles.separator, children: "/" })] }, `${title} ${href}`))) }));
}
