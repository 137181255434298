// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paragraph__e3f + .paragraph__e3f {
  margin-top: calc(var(--ring-unit) * 1.5);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/license-vault/dialogs/lv-enable-dialog/lv-enable-dialog.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C","sourcesContent":[".paragraph + .paragraph {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraph": `paragraph__e3f`
};
export default ___CSS_LOADER_EXPORT___;
