import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { format, isToday } from 'date-fns';
import Text from '@jetbrains/ring-ui/components/text/text';
import Icon from '@jetbrains/ring-ui/components/icon';
import codeWithMeLogo from '@jetbrains/logos/code-with-me/code-with-me.svg';
import classNames from 'classnames';
import { IconSize } from '@jetbrains/ring-ui/components/button/button';
import { THIS_MONTH_TIMEFRAME } from '@api/dashboard';
import { useConstituentCurrentUsage, useConstituentUsagePerDays } from '@app/queries/dashboard';
import { IntervalBarChart } from '@app/pages/dashboard/common/interval-bar-chart';
import widgetStyles from '@app/pages/dashboard/common/widget-layout.css';
import ChartTimeframeSelector from '@app/pages/dashboard/common/chart-timeframe-selector';
import { WidgetLayout } from '../../common/widget-layout';
const constituent = { id: 'cwm', title: 'Code with Me' };
export function CodeWithMeUsage() {
    var _a;
    const [timeframe, setTimeframe] = useState(THIS_MONTH_TIMEFRAME);
    const { data } = useConstituentUsagePerDays(constituent, timeframe);
    const { isLoading: isCurrentUsageLoading, isLoadingError: isCurrentUsageLoafingError } = useConstituentCurrentUsage(constituent);
    const [activeInterval, setActiveInterval] = useState();
    const formattedActiveDayDate = useMemo(() => activeInterval && format(activeInterval === null || activeInterval === void 0 ? void 0 : activeInterval.from, 'MMM d'), [activeInterval]);
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.days.length) {
            setActiveInterval(data.days[data.days.length - 1] || undefined);
        }
    }, [data]);
    return (_jsx(WidgetLayout, { loader: isCurrentUsageLoading, isLoadingError: isCurrentUsageLoafingError, title: "Code With Me", dataTest: `user-usage-${constituent.id}`, children: activeInterval && (data === null || data === void 0 ? void 0 : data.days) && (_jsxs(_Fragment, { children: [_jsx(IntervalBarChart, { intervals: data === null || data === void 0 ? void 0 : data.days, activeInterval: activeInterval, onActivateInterval: setActiveInterval }), _jsx("div", { children: _jsxs(Text, { children: ["Daily maximum of concurrent sessions,", ' ', _jsx(ChartTimeframeSelector, { onSelect: setTimeframe, timeframe: timeframe })] }) }), _jsxs("div", { className: widgetStyles.footer, children: [_jsxs("div", { children: [_jsxs("span", { className: widgetStyles.footerItem, children: [_jsx(Icon, { glyph: codeWithMeLogo, size: IconSize.Size16 }), _jsx(Text, { size: "l", children: (activeInterval === null || activeInterval === void 0 ? void 0 : activeInterval.total) || '0' })] }), _jsx(Text, { size: "s", info: true, className: classNames(widgetStyles.footerItemLabel, widgetStyles.footerItemLabelWithFixedWidth), children: isToday(activeInterval.from) ? (_jsx("span", { children: "Maximum sessions today" })) : (_jsxs("span", { children: ["Sessions", _jsx("br", {}), "on ", formattedActiveDayDate] })) })] }), data.maxUsageDay && (_jsxs("div", { children: [_jsxs("div", { className: classNames(widgetStyles.footerItem, widgetStyles.footerItemWithDate), children: [_jsx(Text, { size: "l", children: data.maxUsageDay.total || '0' }), _jsxs(Text, { size: "s", className: widgetStyles.footerItemDate, children: [' ', "on ", format((_a = data.maxUsageDay) === null || _a === void 0 ? void 0 : _a.from, 'MMM d')] })] }), _jsx(Text, { size: "s", info: true, className: widgetStyles.footerItemLabel, children: "Maximum sessions for period" })] }))] })] })) }));
}
