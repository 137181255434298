import { isEmpty } from 'lodash';
import { SessionExpiredError } from '../components/auth/auth-models';
/**
 * This is a legacy function. It takes an arbitrary request error and returns an error message
 * or validation errors. It'll be refactored in the scope of the TBE-2290
 * @param e
 */
export function getApiError(e) {
    if (e instanceof SessionExpiredError) {
        return {
            success: false,
            errorMessage: 'Session expired. Please log in again.'
        };
    }
    if (e.response != null) {
        if (e.response.status === 500) {
            return {
                success: false,
                errorMessage: e.response.data.message
                    ? `Server error: ${e.response.data.message}`
                    : `Unknown server error`
            };
        }
        if (e.response.status === 401) {
            return {
                success: false,
                errorMessage: 'Session expired. Please log in again.'
            };
        }
        if (e.response.status === 400 || e.response.status === 409 || e.response.status === 403) {
            const { data } = e.response;
            if (data != null) {
                if (data.errors != null) {
                    return { success: false, errors: data.errors };
                }
                return { success: false, errorMessage: data.message || e.message };
            }
        }
        if (e.response.status === 404) {
            return {
                success: false,
                errorMessage: 'No such record found',
                notFound: true
            };
        }
    }
    // eslint-disable-next-line no-console
    console.error('Unexpected error', e);
    return { success: false, errorMessage: 'Unexpected error' };
}
export function formatApiError(err, message = 'Failed to load data') {
    const data = getApiError(err);
    const errorsSuffix = isEmpty(data.errors)
        ? ''
        : ': request contains invalid fields, see the console for details';
    const messageSuffix = data.errorMessage ? `: ${data.errorMessage}` : '';
    return message + (errorsSuffix || messageSuffix);
}
