import React, {useMemo} from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import Button from '@jetbrains/ring-ui/components/button/button';
import addIcon from '@jetbrains/icons/add';
import closeIcon from '@jetbrains/icons/close';
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown';
import Popup from '@jetbrains/ring-ui/components/popup/popup';
import {startOfDay, startOfISOWeek, subDays, subWeeks} from 'date-fns';
import {sortBy, uniq} from 'lodash';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts';
import {useQuery} from '@tanstack/react-query';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import {formatApiError} from '../../api/errors';
import {formatProductCode, formatProductVersion} from '../../components/util/i18n';
import {getLanguages, getProductBuilds, getProductCodes} from '../../api/completion-performance';
import PRODUCT_CODES_KEY from './product-codes-key';
import {Range} from './range';
import {getSeriesColor} from './charts';
import styles from './completion-chart-options-panel.css';

/**
 * @typedef {{
 *   range: string,
 *   series: CompletionSeriesOptions[]
 * }} CompletionChartOptions
 *
 * @typedef {{
 *    productCode: ?string,
 *    productVersion: ?string,
 *    language: ?string
 * }} CompletionSeriesOptions
 */

/** @type {{key: string, label: string, toRange: function(Date): ApdexChartRange}[]} */
export const rangeOptions = [
  {
    key: '7_DAYS',
    label: 'Last 7 days',
    toRange: now => ({
      interval: 'DAY',
      minDate: subDays(startOfDay(now), 7),
      maxDate: startOfDay(now)
    })
  },
  {
    key: '28_DAYS',
    label: 'Last 28 days',
    toRange: now => ({
      interval: 'DAY',
      minDate: subDays(startOfDay(now), 28),
      maxDate: startOfDay(now)
    })
  },
  {
    key: '1_QUARTER',
    label: 'Last quarter',
    toRange: now => ({
      interval: 'WEEK',
      minDate: subWeeks(startOfISOWeek(now), 13),
      maxDate: startOfISOWeek(now)
    })
  },
  {
    key: '1_YEAR',
    label: 'Last year',
    toRange: now => ({
      interval: 'WEEK',
      minDate: subWeeks(startOfISOWeek(now), 52),
      maxDate: startOfISOWeek(now)
    })
  }
];

/**
 * @param {CompletionChartOptions} options
 * @param {function(CompletionChartOptions): void} onOptionsChange
 */
export default function CompletionChartOptionsPanel({options, onOptionsChange}) {
  return (
    <div className={styles.root}>
      <Range
        rangeOptions={rangeOptions}
        range={options.range}
        onRangeChange={range => onOptionsChange({...options, range})}
      />
      <SeriesList
        seriesList={options.series}
        onSeriesListChange={series => onOptionsChange({...options, series})}
      />
    </div>
  );
}

/**
 * @param {CompletionSeriesOptions[]} seriesList
 * @param {function(CompletionSeriesOptions[]): void} onSeriesListChange
 */
function SeriesList({seriesList, onSeriesListChange}) {
  const handleChange = (index, series) => {
    const newSeriesList = [...seriesList];
    newSeriesList[index] = series;
    onSeriesListChange(newSeriesList);
  };

  const handleRemove = index => {
    onSeriesListChange(seriesList.filter((_, i) => i !== index));
  };

  const handleAdd = () => {
    onSeriesListChange([...seriesList, seriesList[seriesList.length - 1]]);
  };

  const canRemove = seriesList.length > 1;

  return (
    <div className={styles.section}>
      <div className={styles.sectionLabel}>Data series</div>
      {seriesList.map((series, index) => (
        <Series
          key={index}
          index={index}
          series={series}
          onSeriesChange={newDataset => handleChange(index, newDataset)}
          removable={canRemove}
          onRemove={() => handleRemove(index)}
        />
      ))}
      <Button
        className={styles.addSeriesButton}
        icon={addIcon}
        text
        primary
        inline
        onClick={handleAdd}
      >
        Add data series
      </Button>
    </div>
  );
}

/** @param {CompletionSeriesOptions} series */
export function formatSeriesLabel(series) {
  return [
    series.productCode ? formatProductCode(series.productCode) : null,
    series.productVersion ? formatProductVersion(series.productVersion) : null,
    series.language
  ]
    .filter(Boolean)
    .join(' • ');
}

/**
 * @param {number} index
 * @param {CompletionSeriesOptions} series
 * @param {function(CompletionSeriesOptions): void} onSeriesChange
 * @param {boolean} removable
 * @param {function(): void} onRemove
 */
function Series({index, series, onSeriesChange, removable, onRemove}) {
  const label = useMemo(() => formatSeriesLabel(series), [series]);

  return (
    <div className={styles.series}>
      <div className={styles.seriesColor} style={{background: getSeriesColor(index)}} />
      <Dropdown
        className={styles.seriesDropdown}
        anchor={
          <Button className={styles.seriesDropdownButton} text inline dropdown>
            {label}
          </Button>
        }
        children={props => (
          <Popup {...props}>
            <SeriesEditor series={series} onSeriesChange={onSeriesChange} />
          </Popup>
        )}
      />
      {removable && (
        <Button
          icon={closeIcon}
          iconSize={13}
          iconSuppressSizeWarning
          text
          inline
          onClick={onRemove}
        />
      )}
    </div>
  );
}

function buildProductCodeOptions(productCodes) {
  if (productCodes != null) {
    return sortBy(productCodes, formatProductCode).map(product => ({
      key: product,
      label: formatProductCode(product)
    }));
  }
  return null;
}

function buildProductVersionOptions(productBuilds) {
  if (productBuilds != null) {
    const majorVersions = uniq(productBuilds.map(build => build.split('.')[0])).map(major => ({
      key: major,
      label: formatProductVersion(major)
    }));

    const patchVersions = productBuilds.map(build => ({
      key: build,
      label: formatProductVersion(build),
      level: 1
    }));

    return sortBy([...majorVersions, ...patchVersions], option => option.key);
  }
  return null;
}

function buildLanguageOptions(languages) {
  return languages?.map(language => ({key: language, label: language})) ?? null;
}

/**
 * @param {CompletionSeriesOptions} series
 * @param {function(CompletionSeriesOptions): void} onSeriesChange
 */
function SeriesEditor({series, onSeriesChange}) {
  const {data: productCodes} = useQuery(PRODUCT_CODES_KEY, getProductCodes, {
    staleTime: Infinity,
    retry: false,
    onError: e => alert.error(formatApiError(e, 'Failed to load product codes'))
  });

  const {data: builds} = useQuery(
    ['product-builds', series.productCode],
    () => getProductBuilds(series.productCode),
    {
      onError: e => alert.error(formatApiError(e, 'Failed to load product builds'))
    }
  );

  const {data: languages} = useQuery(
    ['languages', series.productCode],
    () => getLanguages(series.productCode),
    {
      onError: e => alert.error(formatApiError(e, 'Failed to load languages'))
    }
  );

  const productCodeOptions = useMemo(() => buildProductCodeOptions(productCodes), [productCodes]);
  const versionOptions = useMemo(() => buildProductVersionOptions(builds), [builds]);
  const languageOptions = useMemo(() => buildLanguageOptions(languages), [languages]);

  return (
    <div className={styles.seriesEditor}>
      <Select
        label="Select IDE"
        selectedLabel="IDE"
        filter
        compact
        loading={productCodeOptions == null}
        data={productCodeOptions ?? []}
        selected={productCodeOptions?.find(option => option.key === series.productCode)}
        onChange={option =>
          onSeriesChange({
            productCode: option.key,
            productVersion: null,
            language: null
          })
        }
      />
      <Tooltip
        title="Narrow down the results to a particular IDE version, or see weighted average of all versions."
        popupProps={{directions: [Directions.RIGHT_CENTER]}}
      >
        <Select
          label="All IDE versions"
          selectedLabel="IDE version"
          filter
          compact
          clear
          loading={versionOptions == null}
          data={versionOptions ?? []}
          selected={versionOptions?.find(option => option.key === series.productVersion)}
          onChange={option => onSeriesChange({...series, productVersion: option?.key ?? null})}
        />
      </Tooltip>
      <Tooltip
        title="Narrow down the results to a particular language, or see weighted average of all languages."
        popupProps={{directions: [Directions.RIGHT_CENTER]}}
      >
        <Select
          label="All languages"
          selectedLabel="Language"
          filter
          compact
          clear
          loading={languageOptions == null}
          data={languageOptions ?? []}
          selected={languageOptions?.find(option => option.key === series.language)}
          onChange={option => onSeriesChange({...series, language: option?.key ?? null})}
        />
      </Tooltip>
    </div>
  );
}
