import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getPlugins } from '@app/store/profiles/selectors';
import { AddSettingButton } from '../common/add-setting-button';
import { SettingBlock } from '../common/setting-block';
import { SettingPlaceholder } from '../common/settings-placeholder';
import { SettingsContext } from '../context/settings-context';
import { DisabledPlugins } from './disabled-plugins/disabled-plugins';
import AutoInstalledPlugins from './new-auto-installed-plugins/auto-installed-plugins';
import styles from './profile-plugins.css';
const AUTO_INSTALLED_PLUGINS_LABEL = 'Auto-installed plugin';
const DISABLED_PLUGINS_LABEL = 'Disabled plugin';
const DROPDOWN_OPTIONS = [AUTO_INSTALLED_PLUGINS_LABEL, DISABLED_PLUGINS_LABEL].map(label => ({
    label,
    key: label
}));
export function ProfilePlugins() {
    const { disabled, autoInstalled } = useSelector(getPlugins);
    const [sharedState, setSharedState] = useState();
    const sharedContext = useMemo(() => ({ formState: sharedState, setFormState: setSharedState }), [sharedState]);
    const showPlaceholder = sharedState === undefined && isEmpty(disabled) && isEmpty(autoInstalled);
    const onSelect = ({ label }) => {
        setSharedState({
            settingType: label === AUTO_INSTALLED_PLUGINS_LABEL ? 'autoInstalled' : 'disabled'
        });
    };
    return (_jsx(SettingBlock, { header: "Plugins", headerActionComponent: _jsx(DropdownMenu, { anchor: _jsx(AddSettingButton, {}), onSelect: onSelect, data: DROPDOWN_OPTIONS, "data-test": "plugins-block-dropdown", title: "Add plugins setting" }), children: _jsx("div", { className: styles.profilesContainer, children: showPlaceholder ? (_jsx(SettingPlaceholder, { title: "No plugins yet" })) : (_jsxs(SettingsContext.Provider, { value: sharedContext, children: [_jsx(AutoInstalledPlugins, {}), _jsx(DisabledPlugins, {})] })) }) }));
}
