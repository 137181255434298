import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { StringParam, createEnumParam } from 'serialize-query-params';
import { isEqual } from 'lodash';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import { apiProfileAuditTypeOptions } from '@api/profiles';
import { useProfileHistoryQuery } from '@app/queries/profiles';
import { useSyncedSearchArgs } from '@components/navigation/use-synced-search-args';
import { formatDateString } from '@components/dates/format';
import ProfileHistoryItem from './profile-history-item';
import { HistoryFilters } from './filters/history-filters';
import styles from './profile-history-page-body.css';
function transformHistoryData(history) {
    return history
        .filter(item => item.date !== null)
        .map(item => {
        var _a;
        return ({
            ...item,
            date: formatDateString((_a = item.date) !== null && _a !== void 0 ? _a : '', 'd MMM yyyy')
        });
    })
        .reduce((prev, curr) => {
        const historyObject = prev.find(item => isEqual(item.date, curr.date));
        if (historyObject === undefined) {
            return [...prev, { date: curr.date, items: [curr] }];
        }
        historyObject.items = [...historyObject.items, curr];
        return prev;
    }, [])
        .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());
}
const tsTypeToQuerySchema = {
    search: StringParam,
    type: createEnumParam(apiProfileAuditTypeOptions.map(item => item.key))
};
function validateProfileHistoryFilter(parsed) {
    var _a, _b;
    return {
        search: (_a = parsed.search) !== null && _a !== void 0 ? _a : undefined,
        type: (_b = parsed.type) !== null && _b !== void 0 ? _b : undefined
    };
}
function ProfileHistoryPageBody({ profileId }) {
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema, validateProfileHistoryFilter);
    const onFiltersChange = useCallback((newFilters) => {
        setUrlParams({
            ...urlParams,
            ...newFilters
        });
    }, [setUrlParams, urlParams]);
    const { data, isLoading } = useProfileHistoryQuery(profileId, urlParams);
    const tranformedData = useMemo(() => data && transformHistoryData(data), [data]);
    const hasNoParams = !urlParams.type && !urlParams.search;
    return (_jsxs("div", { className: styles.container, children: [_jsx(HistoryFilters, { filters: { ...urlParams }, onFiltersChange: onFiltersChange }), isLoading ? (_jsx(Loader, {})) : (_jsxs("div", { className: styles.eventsSeparator, children: [(!tranformedData || tranformedData.length === 0) && (_jsxs(Text, { children: ["No history data available", !hasNoParams && ' for selected criteria', "."] })), tranformedData === null || tranformedData === void 0 ? void 0 : tranformedData.map(group => (_jsxs("div", { className: styles.eventContainer, children: [_jsx("div", { className: styles.dateText, children: _jsx(Text, { children: group.date }) }), _jsx("div", { className: styles.eventsSeparator, children: group.items.map(item => (_jsx(ProfileHistoryItem, { item: item, profileId: profileId }, `audit-log-event-${item.id}`))) })] }, `group-${group.date}`)))] }))] }));
}
export default ProfileHistoryPageBody;
