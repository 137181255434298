import Button from '@jetbrains/ring-ui/components/button/button';
import downloadIcon from '@jetbrains/icons/download';
import {apdexFormat} from '../../components/util/i18n';

/**
 * @param {string} [className]
 * @param {ApdexChartData} data
 */
export default function ApdexChartExportAsTsvButton({className, data}) {
  function handleClick() {
    const tsv = convertChartDataToTsv(data);
    downloadFile('apdex.tsv', 'text/tab-separated-values', tsv);
  }

  return (
    <Button className={className} primary icon={downloadIcon} onClick={handleClick}>
      Export as TSV
    </Button>
  );
}

/**
 * @param {ApdexChartData} data
 * @returns {string}
 */
function convertChartDataToTsv(data) {
  const header = [
    'Data Series',
    {DAY: 'Day', WEEK: 'Week'}[data.range.interval],
    'APDEX',
    'APDEX Confidence Interval Lower Bound',
    'APDEX Confidence Interval Upper Bound',
    'Users'
  ];

  console.log(data.series);

  const records = data.series.flatMap(series =>
    series.data.map(dataPoint => [
      series.label,
      dataPoint.date,
      apdexFormat.format(dataPoint.score),
      dataPoint.scoreConfidenceInterval
        ? apdexFormat.format(dataPoint.scoreConfidenceInterval.start)
        : '',
      dataPoint.scoreConfidenceInterval
        ? apdexFormat.format(dataPoint.scoreConfidenceInterval.end)
        : '',
      String(dataPoint.deviceCount)
    ])
  );

  const table = [header, ...records];

  return table
    .map(row =>
      row
        .map(
          cell =>
            cell
              .replaceAll(/[^ -~]/g, '') // preserve only ASCII printable characters and spaces
              .replaceAll(/\\/g, '') // remove backslashes
        )
        .join('\t')
    )
    .join('\r\n');
}

function downloadFile(filename, contentType, content) {
  const blob = new Blob([content], {type: contentType});
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
  a.remove();
}
