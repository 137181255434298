// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[8].use[1]!../node_modules/@jetbrains/ring-ui/components/global/variables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[8].use[1]!../node_modules/@jetbrains/ring-ui/components/form/form.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[8].use[1]!../node_modules/@jetbrains/ring-ui/components/table/table.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: 100%;
}

body {
  height: 100%;

  font-family: var(--ring-font-family);
  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
  background: var(--ring-content-background-color);
  color: var(--ring-text-color);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

#root {
  height: 100%;
}

:root {
  --app-header-height: 64px;
  --ai-color: #834df0;
}

select {
  border-color: var(--ring-borders-color);
  outline-color: var(--ring-borders-color);
}

ul,
ol {
  list-style-position: inside;
}

ul {
  list-style-type: '— ';
}

li {
  margin: calc(0.5 * var(--ring-unit)) 0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAIA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;;EAEZ,oCAAoC;EACpC,gCAAgC;EAChC,oCAAoC;EACpC,gDAAgD;EAChD,6BAA6B;AAC/B;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EAmFE,SAAS;EACT,UAAU;EACV,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,uCAAuC;EACvC,wCAAwC;AAC1C;;AAEA;;EAEE,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":["@import '~@jetbrains/ring-ui/components/global/variables.css';\n@import '~@jetbrains/ring-ui/components/form/form.css';\n@import '~@jetbrains/ring-ui/components/table/table.css';\n\nhtml {\n  height: 100%;\n}\n\nbody {\n  height: 100%;\n\n  font-family: var(--ring-font-family);\n  font-size: var(--ring-font-size);\n  line-height: var(--ring-line-height);\n  background: var(--ring-content-background-color);\n  color: var(--ring-text-color);\n}\n\nhtml,\nbody,\ndiv,\nspan,\napplet,\nobject,\niframe,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np,\nblockquote,\npre,\na,\nabbr,\nacronym,\naddress,\nbig,\ncite,\ncode,\ndel,\ndfn,\nem,\nimg,\nins,\nkbd,\nq,\ns,\nsamp,\nsmall,\nstrike,\nstrong,\nsub,\nsup,\ntt,\nvar,\nb,\nu,\ni,\ncenter,\ndl,\ndt,\ndd,\nmenu,\nol,\nul,\nli,\nfieldset,\nform,\nlabel,\nlegend,\ntable,\ncaption,\ntbody,\ntfoot,\nthead,\ntr,\nth,\ntd,\narticle,\naside,\ncanvas,\ndetails,\nembed,\nfigure,\nfigcaption,\nfooter,\nheader,\nhgroup,\nmain,\nmenu,\nnav,\noutput,\nruby,\nsection,\nsummary,\ntime,\nmark,\naudio,\nvideo {\n  margin: 0;\n  padding: 0;\n  border: 0;\n}\n\n:global(#root) {\n  height: 100%;\n}\n\n:root {\n  --app-header-height: 64px;\n  --ai-color: #834df0;\n}\n\nselect {\n  border-color: var(--ring-borders-color);\n  outline-color: var(--ring-borders-color);\n}\n\nul,\nol {\n  list-style-position: inside;\n}\n\nul {\n  list-style-type: '— ';\n}\n\nli {\n  margin: calc(0.5 * var(--ring-unit)) 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
