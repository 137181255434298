import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import DatePicker from '@jetbrains/ring-ui/components/date-picker/date-picker';
import styles from './registered-date-picker.css';
function RegisteredDatePicker({ name, control, controllerProps, label, ...rest }) {
    const { field: { onChange, value }, fieldState: { error } } = useController({
        name,
        control,
        ...controllerProps
    });
    return (_jsxs("div", { className: styles.datePickerContainer, children: [_jsx("label", { className: styles.label, htmlFor: name, children: label }), _jsx(DatePicker, { date: value && new Date(value), onChange: onChange, ...rest }), (error === null || error === void 0 ? void 0 : error.message) && _jsx("p", { className: styles.error, children: error === null || error === void 0 ? void 0 : error.message })] }));
}
export default RegisteredDatePicker;
