import { useQuery } from '@tanstack/react-query';
import getToolboxInfo from '../api/toolbox';
import { formatApiError } from '../api/errors';
import { getQueryAlertMessages } from './formatters/alerts';
const keys = {
    all: ['toolbox']
};
export function useToolboxInfo() {
    return useQuery(keys.all, getToolboxInfo, {
        staleTime: Infinity,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load information about the required toolbox app version'))
    });
}
