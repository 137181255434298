import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { filterByFocusedApp, useProfilesParams } from '@app/pages/profiles/profile-details/profile-ide-provisioner/context/profiles-params-context';
import { SettingsList } from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/settings-list';
import { AutoinstalledToolsDialogForm } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/autoinstalled-tools/profiles-autoinstalled-tools-form';
import { showFilteredSettings, showHiddenSettingsPlaceholder, showNoSettingsPlaceholder } from '@app/pages/profiles/profile-details/profile-ide-provisioner/functions/settings';
import { deleteSetting, updateAutoinstalledTools } from '@app/store/profiles/actions';
import { getAutoinstalledToolsSettings } from '@app/store/profiles/selectors';
import { SettingPlaceholder } from '../../common/settings-placeholder';
import { useSettingsContext } from '../../context/settings-context';
import SettingsListEntry from '../../common/settings-list-entry';
export function ProfileAutoinstalledTools() {
    const dispatch = useDispatch();
    const autoinstalledTools = useSelector(getAutoinstalledToolsSettings);
    const { focusedApp } = useProfilesParams();
    const filteredAutoinstalledTools = filterByFocusedApp(autoinstalledTools, focusedApp);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { setFormState, showForm, resetFormState, settingId } = useSettingsContext('autoinstalledTools');
    const deleteEntry = (settingId) => {
        dispatch(deleteSetting({ settingType: 'autoinstalledTools', settingId }));
    };
    const submit = (values) => {
        dispatch(updateAutoinstalledTools(values));
        setIsCollapsed(false);
        resetFormState();
    };
    return (_jsxs(Tooltip, { title: "JetBrains IDE Services will pre-install these tools on developer machines", children: [_jsxs(SettingsList, { title: "auto-installed tools", isCollapsed: isCollapsed, onToggleCollapsed: () => setIsCollapsed(prev => !prev), children: [showNoSettingsPlaceholder(autoinstalledTools) && (_jsx(SettingPlaceholder, { title: "No auto-installed tools yet" })), showHiddenSettingsPlaceholder(autoinstalledTools, filteredAutoinstalledTools) && (_jsx(SettingPlaceholder, { title: autoinstalledTools.length === 1
                            ? 'One group of auto-installed tools is hidden'
                            : `${autoinstalledTools.length} auto-installed tools are hidden` })), showFilteredSettings(autoinstalledTools) &&
                        filteredAutoinstalledTools.map(entry => (_jsx(SettingsListEntry, { entry: entry, onEditClick: setFormState, onDeleteClick: deleteEntry, settingType: "autoinstalledTools" }, entry.id)))] }), showForm && (_jsx(AutoinstalledToolsDialogForm, { onReject: resetFormState, item: autoinstalledTools.find(({ id }) => id === settingId), onSubmit: submit }))] }));
}
