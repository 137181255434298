// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email__bc3 + .instruction__dbe {
  margin-top: calc(1.5 * var(--ring-unit));
}

.instruction__dbe + .joinMsg__f87 {
  margin-top: calc(1.5 * var(--ring-unit));
}

.email__bc3 + .joinMsg__f87 {
  margin-top: calc(1.5 * var(--ring-unit));
}

.actions__bc7 {
  margin-top: calc(1.5 * var(--ring-unit));
  display: flex;
  gap: var(--ring-unit);
}

.actions__bc7 + .table__d33 {
  margin-top: calc(3 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/pages/welcome-page/user-welcome-page/user-welcome-page.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;EACxC,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".email + .instruction {\n  margin-top: calc(1.5 * var(--ring-unit));\n}\n\n.instruction + .joinMsg {\n  margin-top: calc(1.5 * var(--ring-unit));\n}\n\n.email + .joinMsg {\n  margin-top: calc(1.5 * var(--ring-unit));\n}\n\n.actions {\n  margin-top: calc(1.5 * var(--ring-unit));\n  display: flex;\n  gap: var(--ring-unit);\n}\n\n.actions + .table {\n  margin-top: calc(3 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"email": `email__bc3`,
	"instruction": `instruction__dbe`,
	"joinMsg": `joinMsg__f87`,
	"actions": `actions__bc7`,
	"table": `table__d33`
};
export default ___CSS_LOADER_EXPORT___;
