import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import classNames from 'classnames';
import Code from '@jetbrains/ring-ui/components/code/code';
import copyIcon from '@jetbrains/icons/copy';
import Button, { IconSize } from '@jetbrains/ring-ui/components/button/button';
import { Size as CopyFieldSize } from '@jetbrains/ring-ui/components/input/input';
import { copyToClipboard } from '../copy-to-clipboard';
import styles from './copied-code.css';
export { CopyFieldSize };
export default function CopiedCode({ text, className, buttonClassName, inline = false, softWrap = false, size = CopyFieldSize.FULL, disabled = false }) {
    const classes = classNames(styles.container, {
        [styles.containerSizeFull]: size === CopyFieldSize.FULL,
        [styles.containerSizeAuto]: size === CopyFieldSize.AUTO,
        [styles.containerSizeS]: size === CopyFieldSize.S,
        [styles.containerSizeM]: size === CopyFieldSize.M,
        [styles.containerSizeL]: size === CopyFieldSize.L,
        [styles.disabled]: disabled
    }, className);
    const copy = useCallback(() => {
        if (!disabled) {
            copyToClipboard(text);
        }
    }, [text, disabled]);
    return (_jsxs("div", { role: "code", className: classes, onClick: copy, title: "Copy to clipboard", children: [_jsx(Code, { softWrap: softWrap, code: text, className: classNames(styles.text, { [styles.inlineText]: inline }) }), !disabled && (_jsx(Button, { title: "Copy to clipboard", className: buttonClassName, iconSize: IconSize.Size14, icon: copyIcon }))] }));
}
