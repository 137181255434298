// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listContainer__ded {
  line-height: calc(3 * var(--ring-unit));
}

.contentContainer__cfb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: calc(var(--ring-unit) / 2);
  flex-grow: 1;
}

.bottomContainer__ed6 {
  display: flex;
  gap: calc(var(--ring-unit) / 2);
}

.warningText__f5d.warningText__f5d,
.warningText__f5d svg {
  color: var(--ring-warning-color);
}

.warningText__f5d.warningText__f5d:hover,
.warningText__f5d:hover svg {
  color: var(--ring-link-hover-color);
}

.warningDetailsBlock__c33 {
  font-size: var(--ring-font-size-smaller);
  margin: -2px 0 4px 12px;
  max-width: 250px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/popular-profiles.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,+BAA+B;EAC/B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,+BAA+B;AACjC;;AAEA;;EAEE,gCAAgC;AAClC;;AAEA;;EAEE,mCAAmC;AACrC;;AAEA;EACE,wCAAwC;EACxC,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".listContainer {\n  line-height: calc(3 * var(--ring-unit));\n}\n\n.contentContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  gap: calc(var(--ring-unit) / 2);\n  flex-grow: 1;\n}\n\n.bottomContainer {\n  display: flex;\n  gap: calc(var(--ring-unit) / 2);\n}\n\n.warningText.warningText,\n.warningText svg {\n  color: var(--ring-warning-color);\n}\n\n.warningText.warningText:hover,\n.warningText:hover svg {\n  color: var(--ring-link-hover-color);\n}\n\n.warningDetailsBlock {\n  font-size: var(--ring-font-size-smaller);\n  margin: -2px 0 4px 12px;\n  max-width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": `listContainer__ded`,
	"contentContainer": `contentContainer__cfb`,
	"bottomContainer": `bottomContainer__ed6`,
	"warningText": `warningText__f5d`,
	"warningDetailsBlock": `warningDetailsBlock__c33`
};
export default ___CSS_LOADER_EXPORT___;
