import * as z from 'zod';
export const tAutomationTokenSchema = z.object({
    clientId: z
        .string()
        .min(1, 'Please enter Client ID')
        .refine(value => !/\s/.test(value), 'Field shouldn’t contain spaces'),
    expireDate: z.union([
        z.date({ invalid_type_error: 'Please select date' }).transform(date => date.toISOString()),
        z.string(),
        z.null()
    ])
});
