import { isNil, union } from 'lodash';
import { buildBuildOptions } from '../filters-editor/filter-build-options';
/**
 *Function that filters all available builds by first numbers of main branch builds
 *
 * @param {ApplicationsFormValues['include']} includedBuilds
 * @param {ApplicationsFormValues['exclude']} excludedBuilds
 * @param {string} buildOptionKey
 * @returns {build: string, version: string}
 */
function getMainBuilds(includedBuilds, excludedBuilds, buildOptionKey) {
    return union(includedBuilds, excludedBuilds).filter(build => build.build.startsWith(buildOptionKey.split('.')[0]));
}
/**
 *Function that generates data list for NestedDataList component.
 *
 * @param {ApplicationsFormValues} applicationData ApplicationsFormValues
 * @param {boolean} allSelected Flag indicating all builds are selected, used in AddApplicationForm
 * @param {string} editedNotificationId ID of notification which user is editing.
 * @param {BuildDataListOptionsType} buildDataListOptions
 */
export const buildDataListOptions = (applicationData, allSelected, editedNotificationId) => {
    // Build array of objects of all available builds for selected product
    const buildOptions = buildBuildOptions([
        {
            id: applicationData === null || applicationData === void 0 ? void 0 : applicationData.product,
            name: applicationData === null || applicationData === void 0 ? void 0 : applicationData.productName,
            builds: union(applicationData === null || applicationData === void 0 ? void 0 : applicationData.exclude, applicationData === null || applicationData === void 0 ? void 0 : applicationData.include).map(({ build, version }) => ({ id: build, name: version }))
        }
    ], [], []);
    // Get excluded items that has a notification
    const lockedItems = applicationData.notifications
        ? applicationData.notifications.flatMap(({ applicable }) => applicable)
        : [];
    // Get nodes that user is editing with NotificationEdit
    const editedNodes = !isNil(editedNotificationId) &&
        applicationData.notifications.find(({ id }) => editedNotificationId === id).applicable;
    // Build data tree for main branches (ex. 223.*) for NestedDataList
    const buildsData = buildOptions.map(item => {
        // Get all available builds for this main build
        const options = getMainBuilds(applicationData.include, applicationData.exclude, item.key);
        // assigning selected builds
        let selectedItems = [];
        // if `allSelected` flag is true, set `selected` to all available builds and main branch
        if (allSelected) {
            selectedItems = [...options.map(item => item.build), item.key];
        }
        else {
            selectedItems = applicationData.include
                .filter(included => options.some(item => item.build === included.build))
                .map(item => item.build);
        }
        const isEditingNotification = !!editedNodes;
        return {
            id: item.key,
            product: applicationData.product,
            label: item.label,
            selected: selectedItems,
            isEditingNotification,
            isEditingNode: editedNodes ? editedNodes.some(id => id === item.key) : false,
            isAutoinstalled: true,
            items: options.map(({ build, version }) => ({
                id: build,
                product: applicationData.product,
                label: `${build} ${version}`,
                notificationDate: lockedItems.some(id => id === build)
                    ? applicationData
                        .notifications.find(({ applicable }) => applicable.includes(build))
                        .dueDate.substring(0, 10) // trim ISO string date
                    : undefined,
                isAutoinstalled: build === applicationData.autoinstalled,
                isEditingNode: editedNodes ? editedNodes.some(id => id === build) : false
            })),
            lockedItems
        };
    });
    return buildsData;
};
