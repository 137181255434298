import { createReducer, current, original } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { deleteNotification, setNewProfiles, updateApplicationFilters, updateNotification } from './actions';
import { getProfileMetadata } from './utils';
/**
 * A reducer that is responsible for updating metadata. It delegates all the actions to the inner profiles' reducer.
 * Metadata is an information that is collected when handling Redux Actions. Initially this metadata is a workaround
 * to synchronize redux model with the server model using the metadata.isProfileStale flag.
 * It is not expected we create actions for this reducer! This is just a wrapper (or middleware) reducer that
 * takes an action and optionally updates metadata
 * @param innerReducer
 */
function withMetadata(innerReducer) {
    const initialInnerState = innerReducer(undefined, { type: undefined });
    const initialState = {
        inner: initialInnerState,
        metadata: getProfileMetadata()
    };
    return createReducer(initialState, builder => {
        builder
            .addCase(setNewProfiles, (state, action) => {
            state.inner = innerReducer(original(state.inner), action);
            state.metadata = getProfileMetadata();
        })
            .addCase(updateApplicationFilters, (state, action) => {
            state.inner = innerReducer(original(state.inner), action);
            state.metadata.isProfileStale = true;
        })
            .addCase(deleteNotification, (state, action) => {
            state.inner = innerReducer(original(state.inner), action);
            state.metadata.isProfileStale = true;
        })
            .addCase(updateNotification, (state, action) => {
            var _a;
            const foundRule = state.inner.applications.rules.find(notification => notification.id === action.payload.id);
            if (foundRule === undefined) {
                state.metadata.isProfileStale = true;
            }
            else {
                const currentState = current(foundRule);
                const isDueDateChanged = !isEqual((_a = currentState.notification) === null || _a === void 0 ? void 0 : _a.dueDate, action.payload.notification.dueDate);
                const areFiltersChanged = !isEqual(currentState.filters, action.payload.filters);
                if (areFiltersChanged || isDueDateChanged) {
                    state.metadata.isProfileStale = true;
                }
            }
            state.inner = innerReducer(original(state.inner), action);
        })
            .addDefaultCase((state, action) => {
            state.inner = innerReducer(original(state.inner), action);
        });
    });
}
export default withMetadata;
