// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo__ccd {
  margin-top: calc(var(--ring-unit) * 1.5);

  border-radius: var(--ring-border-radius);
  border: 1px solid var(--ring-line-color);
}

.wrapper__c96 .logoUploader__b52 {
  margin-top: calc(var(--ring-unit) * 1.5);

  width: calc(10 * var(--ring-unit));
  height: calc(10 * var(--ring-unit));
}

.actions__ba1 {
  margin-top: calc(var(--ring-unit) * 1.5);

  display: flex;
  gap: calc(var(--ring-unit) * 1.5);
}

.button__df7 {
  padding: 0;
  height: var(--ring-line-height-lowest);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
  color: var(--ring-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/client-machine-configuration/logo-editor/logo-editor.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;;EAExC,wCAAwC;EACxC,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;;EAExC,kCAAkC;EAClC,mCAAmC;AACrC;;AAEA;EACE,wCAAwC;;EAExC,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,UAAU;EACV,sCAAsC;;EAEtC,wCAAwC;EACxC,2CAA2C;EAC3C,kCAAkC;AACpC","sourcesContent":[".logo {\n  margin-top: calc(var(--ring-unit) * 1.5);\n\n  border-radius: var(--ring-border-radius);\n  border: 1px solid var(--ring-line-color);\n}\n\n.wrapper .logoUploader {\n  margin-top: calc(var(--ring-unit) * 1.5);\n\n  width: calc(10 * var(--ring-unit));\n  height: calc(10 * var(--ring-unit));\n}\n\n.actions {\n  margin-top: calc(var(--ring-unit) * 1.5);\n\n  display: flex;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.button {\n  padding: 0;\n  height: var(--ring-line-height-lowest);\n\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n  color: var(--ring-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `logo__ccd`,
	"wrapper": `wrapper__c96`,
	"logoUploader": `logoUploader__b52`,
	"actions": `actions__ba1`,
	"button": `button__df7`
};
export default ___CSS_LOADER_EXPORT___;
