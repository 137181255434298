import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Icon from '@jetbrains/ring-ui/components/icon';
import frownIcon from '@jetbrains/icons/frown';
import Text from '@jetbrains/ring-ui/components/text/text';
import { ParagraphLoader } from '@components/loaders/paragraph-loader';
import NavigationLink from '@components/navigation/navigation-link';
import styles from './widget-layout.css';
export function WidgetLayout({ children, title, titleHint, link, isLoadingError, dataTest, className, loader }) {
    const titleNode = (_jsx(H3, { "data-test": "widget-title", className: styles.headerText, children: title }));
    const headerTitle = loader ? undefined : titleHint;
    return (_jsxs("div", { className: styles.container, "data-test": `widget-${dataTest}`, children: [link ? (_jsx(NavigationLink, { secondary: true, href: link, className: styles.header, title: headerTitle, children: titleNode })) : (_jsx("div", { className: styles.header, title: headerTitle, children: titleNode })), _jsxs("div", { className: classnames(styles.contentContainer, className), children: [loader ? _jsx(ParagraphLoader, {}) : children, !loader && isLoadingError && (_jsxs(Text, { info: true, children: [_jsx(Icon, { glyph: frownIcon }), " Failed to load data"] }))] })] }));
}
