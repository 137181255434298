import { combineReducers } from '@reduxjs/toolkit';
import usageDataBatchesReducer from './usage-data-current-batch';
import usageUsageDataHistoryReducer from './usage-data-history';
import osAnalyticsReducer from './operating-systems-analytics';
import ideAnalyticsReducer from './ide-analytics';
import pluginsAnalyticsReducer from './plugins-analytics';
import freezesAnalyticsReducer from './freezes-analytics';
import pluginsAuditLogReducer from './plugins-audit-log-reducer';
import newProfileReducer from './profiles/index';
import immutableProfilesReducer from './profiles/immutableProfilesState';
import withMetadata from './profiles/with-metadata';
export const rootReducer = combineReducers({
    usageDataCurrentBatch: usageDataBatchesReducer,
    usageDataHistory: usageUsageDataHistoryReducer,
    newProfiles: withMetadata(newProfileReducer),
    immutableProfiles: immutableProfilesReducer,
    osAnalytics: osAnalyticsReducer,
    ideAnalytics: ideAnalyticsReducer,
    pluginsAnalytics: pluginsAnalyticsReducer,
    pluginsAuditLog: pluginsAuditLogReducer,
    freezesAnalytics: freezesAnalyticsReducer
});
