import { useQuery } from '@tanstack/react-query';
import { fetchBundledPlugins } from '../api/marketplace';
import { MARKETPLACE_SOURCE } from '../components/plugins/ide-plugins-types';
import { formatApiError } from '../api/errors';
import { getPlugin } from './plugins';
import { getQueryAlertMessages } from './formatters/alerts';
export function useBundledPlugins() {
    return useQuery({
        queryKey: ['bundled-plugins'],
        queryFn: () => fetchBundledPlugins().then(response => response.map(plugin => getPlugin(plugin.xmlId, MARKETPLACE_SOURCE, plugin.name))),
        placeholderData: [],
        staleTime: Infinity,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load bundled plugins'))
    });
}
