// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[8].use[1]!../../../node_modules/@jetbrains/ring-ui/components/global/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableWrapper__db3 {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
  padding-bottom: calc(var(--ring-unit) * 3);
}

/*
Apply negative margins to let table take the full width.
It's assumed that table is the first child of the tableWrapper.
Actually table is wrapped by div by default so we have to apply styles to the div element.
*/
.tableWrapper__db3 > div:nth-child(1) {
  margin: calc(var(--ring-unit) * 2) calc(-4 * var(--ring-unit)) 0 calc(-4 * var(--ring-unit));
}

.table__b86 .tableHoverAction__a97 {
  opacity: 0;
}

.table__b86 tr:hover .tableHoverAction__a97,
.table__b86 tr:focus .tableHoverAction__a97,
.table__b86 tr[data-test-focused='true'] .tableHoverAction__a97 {
  opacity: 1;
}

.table__b86 .tableActionContainer__a37 {
  margin: 0 calc(4 * var(--ring-unit));
}

@media (max-width: calc(${___CSS_LOADER_ICSS_IMPORT_0___.locals["breakpoint-middle"]} - 1px)) {
  .table__b86 .tableActionContainer__a37 {
    margin: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/full-width-table-view.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,0CAA0C;AAC5C;;AAEA;;;;CAIC;AACD;EACE,4FAA4F;AAC9F;;AAEA;EACE,UAAU;AACZ;;AAEA;;;EAGE,UAAU;AACZ;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":["@value breakpoint-middle from '~@jetbrains/ring-ui/components/global/global.css';\n\n.tableWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n  padding-bottom: calc(var(--ring-unit) * 3);\n}\n\n/*\nApply negative margins to let table take the full width.\nIt's assumed that table is the first child of the tableWrapper.\nActually table is wrapped by div by default so we have to apply styles to the div element.\n*/\n.tableWrapper > div:nth-child(1) {\n  margin: calc(var(--ring-unit) * 2) calc(-4 * var(--ring-unit)) 0 calc(-4 * var(--ring-unit));\n}\n\n.table .tableHoverAction {\n  opacity: 0;\n}\n\n.table tr:hover .tableHoverAction,\n.table tr:focus .tableHoverAction,\n.table tr[data-test-focused='true'] .tableHoverAction {\n  opacity: 1;\n}\n\n.table .tableActionContainer {\n  margin: 0 calc(4 * var(--ring-unit));\n}\n\n@media (max-width: calc(breakpoint-middle - 1px)) {\n  .table .tableActionContainer {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpoint-middle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["breakpoint-middle"]}`,
	"tableWrapper": `tableWrapper__db3`,
	"table": `table__b86`,
	"tableHoverAction": `tableHoverAction__a97`,
	"tableActionContainer": `tableActionContainer__a37`
};
export default ___CSS_LOADER_EXPORT___;
