import { useMutation, useQuery } from '@tanstack/react-query';
import { getAiState, changeAiSettings, disableAi, enableAi } from '@api/ai-enterprise';
import { formatApiError } from '@api/errors';
import queryClient from '@app/queries/query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from '@app/queries/formatters/alerts';
import auth from '@components/auth/auth';
export function useAiConstituent() {
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    return useQuery({
        queryKey: ['ai-enabled'],
        queryFn: getAiState,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch AI Enterprise status')),
        enabled: !offlineModeEnabled
    });
}
export function useAiEnableMutation(onSuccess) {
    return useMutation({
        mutationFn: enableAi,
        onSuccess: newAiEnabledState => {
            queryClient.setQueryData(['ai-enabled'], newAiEnabledState);
            queryClient.invalidateQueries(['users']);
            onSuccess(newAiEnabledState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to enable AI Enterprise'), () => 'AI Enterprise enabled')
    });
}
export function useAiChangeSettingsMutation(onSuccess) {
    return useMutation({
        mutationFn: changeAiSettings,
        onSuccess: newAiEnabledState => {
            queryClient.setQueryData(['ai-enabled'], newAiEnabledState);
            queryClient.invalidateQueries(['users']);
            onSuccess(newAiEnabledState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to change AI Enterprise settings'), () => 'AI Enterprise settings changed')
    });
}
export function useAiDisableMutation(onSuccess) {
    return useMutation({
        mutationFn: disableAi,
        onSuccess: newAiEnabledState => {
            queryClient.setQueryData(['ai-enabled'], newAiEnabledState);
            queryClient.invalidateQueries(['users']);
            onSuccess();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to disable AI Enterprise'), () => 'AI Enterprise disabled')
    });
}
export function fetchAiEnabled() {
    return queryClient.fetchQuery({
        queryKey: ['ai-enabled'],
        queryFn: getAiState,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch AI Enterprise status'))
    });
}
