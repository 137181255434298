import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useConstituentState } from '@app/queries/constituents-common';
export function ConstituentEnabledGuard(props) {
    const { children, notEnabledFallback, constituent, missingLicenseFallback } = props;
    const { data: state } = useConstituentState(constituent);
    switch (state) {
        case 'SERVER_ENABLED': {
            return _jsx(_Fragment, { children: children });
        }
        case 'SERVER_DISABLED': {
            return notEnabledFallback ? _jsx(_Fragment, { children: notEnabledFallback }) : null;
        }
        case 'LICENSE_DISABLED': {
            return missingLicenseFallback ? _jsx(_Fragment, { children: missingLicenseFallback }) : null;
        }
        default: {
            return null;
        }
    }
}
