import { getServiceClient } from '../components/auth/auth';
import { getUsageDataCurrentBatch } from './usage-data-current-batch';
export default function startNewBatch() {
    return async (dispatch) => {
        try {
            await getServiceClient().put('/api/usage-data/batch');
        }
        catch (e) {
            return;
        }
        dispatch(getUsageDataCurrentBatch());
    };
}
