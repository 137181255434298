import { jsx as _jsx } from "react/jsx-runtime";
import { useLvConstituentState } from '@app/queries/license-vault';
import { LicenseVaultEnabled } from '@app/pages/configuration-page/license/license-vault/license-vault-enabled/license-vault-enabled';
import { LicenseVaultLimits } from '@app/pages/configuration-page/license/license-vault/license-vault-limits';
import { useOpenModal } from '@components/modals/hooks';
import { LvEnableDialog } from './dialogs/lv-enable-dialog/lv-enable-dialog';
import { LvDisableDialog } from './dialogs/lv-disable-dialog/lv-disable-dialog';
import { LicenseVaultDisabled } from './license-vault-disabled/license-vault-disabled';
export function LicenseVaultConstituent(props) {
    const { className, licenseType } = props;
    const { data } = useLvConstituentState();
    const openDisableDialog = useOpenModal(LvDisableDialog, {});
    const openEnableDialog = useOpenModal(LvEnableDialog, {
        showPostpaidHint: licenseType === 'POSTPAID'
    });
    const openLicensesDialog = useOpenModal(LicenseVaultLimits, {});
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled') {
        return _jsx(LicenseVaultDisabled, { className: className, onEnabledClick: openEnableDialog });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'enabled') {
        return (_jsx(LicenseVaultEnabled, { className: className, onDisableClick: openDisableDialog, onOpenLicensesDetails: openLicensesDialog }));
    }
    return null;
}
