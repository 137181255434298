import React, {useState} from 'react';

import {Tab, Tabs} from '@jetbrains/ring-ui/components/tabs/tabs';

import {PageTitle} from '../../components/page-layout/page-title';
import CollectedDataCurrentBatch from './collected-data-current-batch';
import StartNewBatchButton from './collected-data-new-batch';
import CollectedDataHistory from './collected-data-history';
import styles from './collected-data.css';

export default function CollectedData() {
  const [selectedTab, setSelectedTab] = useState('current');

  return (
    <div>
      <PageTitle parts={[{title: 'Analytics'}, {title: 'Collected Data'}]} />

      <div className={styles.textContainer}>
        <p>
          JetBrains IDE Services collects the data on usage statistics, exceptions, and freezes from
          the connected developer machines. You can analyze the data directly from the S3-compatible
          storage.
        </p>

        <p>
          The data is grouped into batches. There can only be one batch that collects new reports.
          All other batches are final and are not modified by JetBrains IDE Services.
        </p>

        <Tabs selected={selectedTab} onSelect={selected => setSelectedTab(selected)}>
          <Tab id="current" title="Current">
            <CollectedDataCurrentBatch />
            <StartNewBatchButton />
          </Tab>

          <Tab id="history" title="History">
            <CollectedDataHistory />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
