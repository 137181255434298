import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import exceptionIcon from '@jetbrains/icons/exception';
import ButtonSet from '@jetbrains/ring-ui/components/button-set/button-set';
import Button from '@jetbrains/ring-ui/components/button/button';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useDispatch, useSelector } from 'react-redux';
import { TutorialAlertContent } from '@app/pages/profiles/profile-details/banner/tutorial/tutorial-alert-content';
import { useUpdateProfileMutation } from '@app/queries/profiles';
import { setNewProfiles } from '@app/store/profiles/actions';
import { getImmutableProfile } from '@app/store/profiles/immutableProfilesState/selectors';
import { getProfile, getProfileTitle, isStateChanged } from '@app/store/profiles/selectors';
import { InformationBanner } from '@components/information-banner/information-banner';
import { RouteLeavingGuard } from '@components/route-leaving-guard/route-leaving-guard';
import { useControlledNotification } from '@components/notifications/use-controlled-notification';
import { useIsProfileChangedOnServerAndLocally } from '../profile-ide-provisioner/hooks/use-profile-synchronization';
import styles from './profiles-banner.css';
export function ProfilesBanner({ profilesState, is404, allowedRoutes }) {
    var _a, _b;
    const dispatch = useDispatch();
    const profileTitle = useSelector(getProfileTitle);
    const originalProfile = useSelector(getImmutableProfile);
    const isChanged = useSelector(isStateChanged);
    const mutation = useUpdateProfileMutation();
    const profile = useSelector(getProfile);
    const cannotSync = useIsProfileChangedOnServerAndLocally(profilesState);
    const isUpdateConflict = ((_b = (_a = mutation.error) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.status) === 409;
    const handleDiscard = () => {
        dispatch(setNewProfiles(originalProfile));
    };
    const handleReset = () => {
        mutation.reset();
        handleDiscard();
    };
    const { showNotification } = useControlledNotification('profile-tutorial-alert', _jsx(TutorialAlertContent, {}));
    const handleSubmit = () => {
        mutation.mutate(profile, { onSuccess: showNotification });
    };
    if (is404) {
        return (_jsx(InformationBanner, { variant: "error", children: "Profile has been deleted by someone else. Please create a new profile" }));
    }
    if (!isChanged) {
        return null;
    }
    if (cannotSync || isUpdateConflict) {
        return (_jsx(InformationBanner, { variant: "error", buttons: _jsx(Button, { onClick: handleReset, children: "Reset & Reload" }), children: "Profile was changed by someone else. Please refresh the page." }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(InformationBanner, { buttons: _jsxs(ButtonSet, { children: [mutation.isError && _jsx(Icon, { glyph: exceptionIcon, className: styles.errorIcon }), _jsx(Button, { onClick: handleDiscard, loader: mutation.isLoading, children: "Discard" }), _jsx(Button, { "data-test": "save-changes-button", onClick: handleSubmit, primary: true, loader: mutation.isLoading, children: "Save changes" })] }), children: _jsxs(Text, { children: ["\"", profileTitle, "\" profile was updated. Make sure to save the changes once you've completed updating the profile."] }) }), _jsx(RouteLeavingGuard, { when: isChanged, allowedRoutesPatterns: allowedRoutes })] }));
}
