// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyButton__ebd {
  width: fit-content;
}

.copyButton__ebd:hover > * {
  color: var(--ring-icon-hover-color);
  cursor: pointer;
  transition: color var(--ring-ease), background-color var(--ring-ease), box-shadow var(--ring-ease);
}

.icon__fbd {
  padding-left: calc(var(--ring-unit) / 2);
  color: var(--ring-icon-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/clipboard/copied-text/copied-text.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;EACnC,eAAe;EACf,kGAAkG;AACpG;;AAEA;EACE,wCAAwC;EACxC,6BAA6B;AAC/B","sourcesContent":[".copyButton {\n  width: fit-content;\n}\n\n.copyButton:hover > * {\n  color: var(--ring-icon-hover-color);\n  cursor: pointer;\n  transition: color var(--ring-ease), background-color var(--ring-ease), box-shadow var(--ring-ease);\n}\n\n.icon {\n  padding-left: calc(var(--ring-unit) / 2);\n  color: var(--ring-icon-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyButton": `copyButton__ebd`,
	"icon": `icon__fbd`
};
export default ___CSS_LOADER_EXPORT___;
