import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import Text from '@jetbrains/ring-ui/components/text/text';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import helpIcon from '@jetbrains/icons/help';
import Button from '@jetbrains/ring-ui/components/button/button';
import RegisteredToggle from '../../../components/hook-form/registered-toggle';
import styles from './deactivate-user-field.css';
const DEACTIVATE_USER_TEXT = 'Deactivated users are not managed by JetBrains IDE Services';
export function DeactivateUserField() {
    const { watch, control } = useFormContext();
    const activateUser = watch('isActive');
    return (_jsx("div", { className: styles.toggleContainer, children: _jsxs(RegisteredToggle, { "aria-label": activateUser ? 'Is inactive' : 'Is active', name: "isActive", control: control, children: [_jsxs(Text, { children: [activateUser ? 'Active' : 'Deactivated', " "] }), _jsx(Tooltip, { title: DEACTIVATE_USER_TEXT, children: _jsx(Button, { icon: helpIcon }) })] }, activateUser ? 'Active' : 'Deactivated') }));
}
