import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tray } from '@jetbrains/ring-ui/components/header/header';
import Theme, { ThemeProvider } from '@jetbrains/ring-ui/components/global/theme';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Container } from '@webteam/layout';
import classNames from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';
import { IdeServicesLogo } from '@components/ide-services/ide-services-logo/ide-services-logo';
import auth from '../../auth/auth';
import styles from './app-header.css';
function UnauthorizedHeader() {
    const navigate = useNavigate();
    const doLogin = () => {
        auth.login().then(() => navigate('/'));
    };
    const isGuest = auth.isGuest();
    return (_jsx(ThemeProvider, { theme: Theme.DARK, children: _jsx("header", { className: classNames(styles.appHeader, styles.unauthorized), "data-test": "app-header", children: _jsx(Container, { className: styles.contentWrapper, children: isGuest && (_jsxs(_Fragment, { children: [_jsx(NavLink, { to: "/", children: _jsx(IdeServicesLogo, { collapsible: true }) }), _jsx(Tray, { className: styles.tray, children: _jsx(Button, { text: true, className: styles.dropdownButton, onClick: doLogin, "data-test": "unauthorized-header-login-button", children: "Log in to JetBrains IDE Services" }) })] })) }) }) }));
}
export default UnauthorizedHeader;
