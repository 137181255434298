import { useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { LayoutContext } from './context';
export function useMountTo(mountingPoint, children) {
    const context = useContext(LayoutContext);
    const mountingPointContextValue = context === null || context === void 0 ? void 0 : context[mountingPoint];
    useEffect(() => {
        if (mountingPointContextValue) {
            mountingPointContextValue.setMountingPointVisible(true);
        }
        return () => {
            if (mountingPointContextValue) {
                mountingPointContextValue.setMountingPointVisible(false);
            }
        };
    }, [mountingPointContextValue]);
    return (mountingPointContextValue === null || mountingPointContextValue === void 0 ? void 0 : mountingPointContextValue.mountingPointElementRef)
        ? createPortal(children, mountingPointContextValue.mountingPointElementRef)
        : null;
}
export function useMountingPoint(mountingPoint) {
    const [mountingPointVisible, setMountingPointVisible] = useState(false);
    const [mountingPointElementRef, setMountingPointElementRef] = useState(null);
    const contextPartValue = useMemo(() => {
        return {
            [mountingPoint]: { mountingPointElementRef, setMountingPointVisible }
        };
    }, [mountingPoint, mountingPointElementRef]);
    return [mountingPointVisible, setMountingPointElementRef, contextPartValue];
}
