// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.root__e42 {
  width: 240px;
  display: flex;
  flex-direction: column;
}

.section__c9b {
  display: flex;
  flex-direction: column;
  padding: calc(2 * var(--ring-unit)) 0;
  gap: var(--ring-unit);
  border-bottom: 1px solid var(--ring-line-color);
}

.section__c9b:first-child {
  padding-top: 0;
}

.sectionLabel__d43 {
  color: var(--ring-secondary-color);
  font-size: var(--ring-font-size-smaller);
}

.series__ac2 {
  display: flex;
  gap: calc(0.5 * var(--ring-unit));
}

.seriesColor__cc6 {
  width: 10px;
  height: 10px;
  margin-top: 8px;
  margin-left: 1px;
}

.seriesDropdown__ba8 {
  flex: 1 1 0;
}

.seriesDropdownButton__a51 {
  text-align: left;
  line-height: 1.15;
  height: auto;
  padding: calc(0.5 * var(--ring-unit));
  color: var(--ring-text-color);
}

.addSeriesButton__a99 {
  padding: 0;
  align-self: start;
}

.seriesEditor__ad2 {
  display: flex;
  flex-direction: column;
  gap: calc(3 * var(--ring-unit));
  padding: calc(4 * var(--ring-unit)) calc(3 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics/completion-chart-options-panel.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qCAAqC;EACrC,qBAAqB;EACrB,+CAA+C;AACjD;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kCAAkC;EAClC,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,qCAAqC;EACrC,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,8DAA8D;AAChE","sourcesContent":[".root {\n  width: 240px;\n  display: flex;\n  flex-direction: column;\n}\n\n.section {\n  display: flex;\n  flex-direction: column;\n  padding: calc(2 * var(--ring-unit)) 0;\n  gap: var(--ring-unit);\n  border-bottom: 1px solid var(--ring-line-color);\n}\n\n.section:first-child {\n  padding-top: 0;\n}\n\n.sectionLabel {\n  color: var(--ring-secondary-color);\n  font-size: var(--ring-font-size-smaller);\n}\n\n.series {\n  display: flex;\n  gap: calc(0.5 * var(--ring-unit));\n}\n\n.seriesColor {\n  width: 10px;\n  height: 10px;\n  margin-top: 8px;\n  margin-left: 1px;\n}\n\n.seriesDropdown {\n  flex: 1 1 0;\n}\n\n.seriesDropdownButton {\n  text-align: left;\n  line-height: 1.15;\n  height: auto;\n  padding: calc(0.5 * var(--ring-unit));\n  color: var(--ring-text-color);\n}\n\n.addSeriesButton {\n  padding: 0;\n  align-self: start;\n}\n\n.seriesEditor {\n  display: flex;\n  flex-direction: column;\n  gap: calc(3 * var(--ring-unit));\n  padding: calc(4 * var(--ring-unit)) calc(3 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `root__e42`,
	"section": `section__c9b`,
	"sectionLabel": `sectionLabel__d43`,
	"series": `series__ac2`,
	"seriesColor": `seriesColor__cc6`,
	"seriesDropdown": `seriesDropdown__ba8`,
	"seriesDropdownButton": `seriesDropdownButton__a51`,
	"addSeriesButton": `addSeriesButton__a99`,
	"seriesEditor": `seriesEditor__ad2`
};
export default ___CSS_LOADER_EXPORT___;
