import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import RegisteredInput from '../../components/hook-form/registered-input';
import { Required } from '../../components/form/important-asterisk';
import { RoleField } from './form-fields/role-field';
import styles from './user-edit-form-fields.css';
export function UserEditFormFields({ disabled }) {
    const { control } = useFormContext();
    return (_jsxs("div", { className: styles.formContainer, children: [_jsx(RegisteredInput, { label: Required('Name'), name: "username", "data-test": "form-username", autoComplete: "off", disabled: disabled, control: control }), _jsx(RegisteredInput, { label: "Email", name: "email", autoComplete: "off", disabled: disabled, control: control }), _jsx(RoleField, { disabled: disabled }), _jsx(RegisteredInput, { label: "OAuth subject", name: "subject", autoComplete: "off", control: control, disabled: true })] }));
}
