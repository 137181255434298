// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloadProgressBarWrapper__ca2 {
  width: calc(12 * var(--ring-unit));
  display: inline-block;
  text-align: right;
}

.noPaddingButton__f37 {
  padding: 0;
}

.tableRightToolbar__ee4 {
  flex-direction: row-reverse;
}

.danger__e5e {
  color: var(--ring-error-color);
}

.channelsText__b99 {
  margin-right: calc(var(--ring-unit) * 0.75);
}
`, "",{"version":3,"sources":["webpack://./src/pages/plugins/plugin-page/plugin-page.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".downloadProgressBarWrapper {\n  width: calc(12 * var(--ring-unit));\n  display: inline-block;\n  text-align: right;\n}\n\n.noPaddingButton {\n  padding: 0;\n}\n\n.tableRightToolbar {\n  flex-direction: row-reverse;\n}\n\n.danger {\n  color: var(--ring-error-color);\n}\n\n.channelsText {\n  margin-right: calc(var(--ring-unit) * 0.75);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadProgressBarWrapper": `downloadProgressBarWrapper__ca2`,
	"noPaddingButton": `noPaddingButton__f37`,
	"tableRightToolbar": `tableRightToolbar__ee4`,
	"danger": `danger__e5e`,
	"channelsText": `channelsText__b99`
};
export default ___CSS_LOADER_EXPORT___;
