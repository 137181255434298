import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
export function confirmTokenDeletion() {
    return confirm({
        text: 'Delete Automaton Token?',
        description: (_jsx(_Fragment, { children: "This action can\u2019t be undone. Applications won\u2019t be able to send requests to API with this token." })),
        confirmLabel: 'Delete',
        rejectLabel: 'Cancel',
        cancelIsDefault: true
    });
}
