import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {setPagingParameters, setSortingParameters} from '../../store/freezes-analytics';
import {getFreezesAnalytics} from '../../api/collected-data-freezes';
import {millisToMinutesAndSeconds} from '../../components/dates/format';
import {PageTitle} from '../../components/page-layout/page-title';
import NavigationLink from '../../components/navigation/navigation-link';
import AnalyticsTable from './analytics-table';
import styles from './collected-data-table.css';

export const FREEZES_PATH = '/analytics/collected-data/freezes';

export default function CollectedDataFreezes() {
  const columns = [
    {
      id: 'id',
      title: 'ID',
      sortable: false,
      className: styles.smallColumn,
      getValue(element) {
        return (
          <NavigationLink href={`${FREEZES_PATH}/${element.id}`}>F-{element.id}</NavigationLink>
        );
      }
    },
    {
      id: 'durationMs',
      title: 'Duration',
      sortable: true,
      rightAlign: true,
      className: styles.smallColumn,
      getValue(element) {
        return <>{millisToMinutesAndSeconds(element.durationMs)}</>;
      }
    },
    {id: 'right-placeholder', title: ''}
  ];

  const stateSelector = state => {
    return state.freezesAnalytics;
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {pageSize, sortOrder, currentPageIndex} = useSelector(state => stateSelector(state));

  const onItemEnter = element => {
    navigate(`${FREEZES_PATH}/${element.id}`);
  };

  const onSort = (event, range) => {
    dispatch(setSortingParameters(event));
    dispatch(dispatch => getFreezesAnalytics(range, dispatch, event.order, currentPageIndex));
  };

  const onRangeChange = newRange => {
    dispatch(dispatch => getFreezesAnalytics(newRange, dispatch, sortOrder, currentPageIndex));
  };

  const onPageChange = (nextPageIndex, range) => {
    dispatch(setPagingParameters({nextPageIndex, pageSize}));
    dispatch(dispatch => getFreezesAnalytics(range, dispatch, sortOrder, nextPageIndex));
  };

  return (
    <>
      <PageTitle parts={[{title: 'Analytics'}, {title: 'Freezes'}]} />
      <AnalyticsTable
        onRangeChange={onRangeChange}
        columns={columns}
        onPageChange={onPageChange}
        onSort={onSort}
        onItemEnter={onItemEnter}
        pageSize={pageSize}
        stateSelector={stateSelector}
      />
    </>
  );
}
