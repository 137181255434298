import { getServiceClient } from '../components/auth/auth';
import { setAuditLogs, setFetchError, setIsFetching } from '../store/plugins-audit-log-reducer';
import { formatDateAndTime } from '../components/util/i18n';
import { formatAuditId } from './audit-utils';
import { DEFAULT_TABLE_PAGE_SIZE, getSortOrder } from './paging-utils';
function getSortParam(sortKey) {
    switch (sortKey) {
        case 'createdBy':
            return 'CREATED_BY';
        case 'createdDate':
            return 'CREATED_DATE';
        default:
            return null;
    }
}
export function getAuditLogs(page, sortKey, sortOrder, filter) {
    const orderParam = getSortOrder(sortOrder);
    const sortParam = getSortParam(sortKey);
    let url = `/api/plugins-audit-logs?page=${page}&limit=${DEFAULT_TABLE_PAGE_SIZE}`;
    if (sortParam != null) {
        url += `&sort=${sortParam}&order=${orderParam}`;
    }
    if (filter) {
        url += `&pluginIdFilter=${filter}`;
    }
    return async (dispatch) => {
        try {
            dispatch(setIsFetching(true));
            const response = await getServiceClient().get(url);
            if (response.status) {
                const data = response.data.items.map(x => {
                    return {
                        ...x,
                        createdBy: formatAuditId(x.createdBy),
                        createdDate: formatDateAndTime(x.createdDate),
                        createdDateOrg: x.createdDate
                    };
                });
                const totalCount = response.data.total;
                dispatch(setAuditLogs(data, page, sortKey, sortOrder, totalCount));
            }
        }
        catch (e) {
            dispatch(setFetchError(true));
            dispatch(setIsFetching(false));
        }
    };
}
