import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
export function confirmPluginDeletion(plugin, withVerionsNote = false) {
    return confirm({
        text: 'Remove Plugin?',
        description: (_jsxs(_Fragment, { children: [withVerionsNote && (_jsxs("p", { children: ["After this action, no ", _jsx("strong", { children: plugin.name }), " versions will be available and the entire plugin will be removed."] })), _jsxs("p", { children: ["Are you sure you want to remove the ", _jsx("strong", { children: plugin.name }), " plugin? You can't undo this action."] })] })),
        confirmLabel: 'Remove',
        rejectLabel: 'Cancel',
        cancelIsDefault: true
    });
}
export function confirmPluginVersionsDeletion(plugin, pluginVersions) {
    const getConfirmMessage = () => {
        var _a, _b, _c, _d;
        if (pluginVersions.length > 2) {
            return (_jsxs(_Fragment, { children: ["Are you sure you want to remove ", _jsx("strong", { children: plugin.name }), " version", ' ', _jsx("strong", { children: (_a = pluginVersions[0]) === null || _a === void 0 ? void 0 : _a.version }), " and", ' ', _jsxs("strong", { children: [pluginVersions.length - 1, " more versions"] }), "? You can't undo this action."] }));
        }
        if (pluginVersions.length > 1) {
            return (_jsxs(_Fragment, { children: ["Are you sure you want to remove ", _jsx("strong", { children: plugin.name }), " versions", ' ', _jsx("strong", { children: (_b = pluginVersions[0]) === null || _b === void 0 ? void 0 : _b.version }), " and", ' ', _jsx("strong", { children: (_c = pluginVersions[1]) === null || _c === void 0 ? void 0 : _c.version }), "? You can't undo this action."] }));
        }
        return (_jsxs(_Fragment, { children: ["Are you sure you want to remove ", _jsx("strong", { children: plugin.name }), " version", ' ', _jsx("strong", { children: (_d = pluginVersions[0]) === null || _d === void 0 ? void 0 : _d.version }), "? You can't undo this action."] }));
    };
    return confirm({
        text: pluginVersions.length === 1 ? 'Remove Version?' : 'Remove Versions?',
        description: getConfirmMessage(),
        confirmLabel: 'Remove',
        rejectLabel: 'Cancel',
        cancelIsDefault: true
    });
}
