import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import HorizontalContainer from '@components/page-layout/horizontal-container';
import SearchFilter from '@components/search-filter/search-filter';
import styles from './groups-filter.css';
export function GroupsFilters({ filters, onFiltersChange }) {
    const handleSearchValueChange = useCallback(newValue => {
        if (typeof newValue === 'string') {
            onFiltersChange({
                ...filters,
                query: newValue.length > 0 ? newValue : null
            });
        }
    }, [onFiltersChange, filters]);
    return (_jsx(HorizontalContainer, { className: styles.container, children: _jsx(SearchFilter, { value: filters.query || '', onChange: handleSearchValueChange, placeholder: "Search by group" }) }));
}
