import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { getFilesAttachmentClassName } from '@components/drop-zone/files-attachment';
import { CompanyLogo, LogoSize } from '@components/company-logo/company-logo';
import { formatRejection, getDefaultImageDropzoneOptions } from '../../image-upload/image-upload';
import styles from './image-file-attachment.css';
function ImageFileAttachment({ imageUrl, onUploaded }) {
    const [errorMsg, setErrorMsg] = useState();
    const handleDropAccepted = (files) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                onUploaded(reader.result);
            }
        };
        reader.readAsDataURL(files[0]);
        setErrorMsg(undefined);
    };
    const handleDropRejected = (rejections) => {
        setErrorMsg(formatRejection(rejections[0]));
    };
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        ...getDefaultImageDropzoneOptions(),
        onDropAccepted: handleDropAccepted,
        onDropRejected: handleDropRejected
    });
    const divStyles = getFilesAttachmentClassName(false, isDragActive);
    return (_jsxs("div", { ...getRootProps(), className: classNames(styles.wrapper, divStyles, {
            [styles.withImage]: !!imageUrl
        }), children: [_jsx("input", { ...getInputProps(), "data-test": "company-logo-input" }), imageUrl && _jsx(CompanyLogo, { logoUrl: imageUrl, size: LogoSize.M }), _jsxs("div", { className: styles.textsContainer, "data-test": "company-logo-texts-container", children: [_jsxs(Text, { children: [_jsx(Button, { text: true, inline: true, onClick: open, children: "Click to browse" }), ' ', "or drag a file"] }), !errorMsg && _jsx(Text, { info: true, children: "Upload a JPG, SVG or PNG file. Maximum file size: 2MB." }), errorMsg && (_jsx(Text, { info: true, className: styles.errorMessage, children: errorMsg }))] })] }));
}
export default ImageFileAttachment;
