import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Select from '@jetbrains/ring-ui/components/select/select';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useProfileOptions } from '@app/queries/profiles';
import { useAddProfileToUsersMutation } from '@app/queries/users';
import NavigationLink from '@components/navigation/navigation-link';
import LoadingError from '@components/errors/loading-error';
import RegisteredSelect from '@components/hook-form/registered-select';
import styles from './assign-profile-dialog-form.css';
function ProfileField({ onSelect }) {
    const { control, formState: { isSubmitting } } = useFormContext();
    const { data: profileOptions, isFetching, isError } = useProfileOptions();
    const profileSelectItems = useMemo(() => {
        var _a;
        return (_a = profileOptions === null || profileOptions === void 0 ? void 0 : profileOptions.map(p => ({
            label: p.title,
            key: p.id,
            description: p.description
        }))) !== null && _a !== void 0 ? _a : [];
    }, [profileOptions]);
    if (isError) {
        return _jsx(LoadingError, {});
    }
    return (_jsx(RegisteredSelect, { name: "profileId", label: "Select profile", data: profileSelectItems, type: Select.Type.BUTTON, size: Size.L, loading: isFetching, disabled: isSubmitting, filter: true, clear: false, control: control, onSelect: selected => { var _a, _b; return onSelect((_b = (selected && ((_a = selected.label) === null || _a === void 0 ? void 0 : _a.toString()))) !== null && _b !== void 0 ? _b : ''); } }));
}
function AssignProfileDialogForm({ onProfileAssigned, onReject, selectedUsers }) {
    const [profileName, setProfileName] = useState('');
    const form = useForm();
    const { handleSubmit, watch, formState: { isDirty, isSubmitting } } = form;
    const selectedProfile = watch('profileId');
    const userNames = useMemo(() => selectedUsers.map(user => user.username), [selectedUsers]);
    const mutation = useAddProfileToUsersMutation(profileName, userNames);
    const dialogTitle = useMemo(() => {
        if (selectedUsers.length > 2) {
            return `Assign Profile to ${selectedUsers[0].username} and ${selectedUsers.length - 1} more users`;
        }
        if (selectedUsers.length > 1) {
            return `Assign Profile to ${selectedUsers[0].username} and ${selectedUsers[1].username}`;
        }
        if (selectedUsers.length === 1) {
            return `Assign Profile to ${selectedUsers[0].username}`;
        }
        return 'Assign Profile';
    }, [selectedUsers]);
    const onSubmit = async (values) => {
        // when onSubmit is called we actually get already validated values
        const validValues = values;
        await mutation.mutateAsync({
            users: selectedUsers,
            profileId: validValues.profileId
        }, {
            onSuccess: () => {
                onProfileAssigned();
            }
        });
    };
    return (_jsxs(Dialog, { show: true, showCloseButton: true, closeButtonInside: true, autoFocusFirst: true, label: dialogTitle, "data-test": "add-profile-for-users", onEscPress: onReject, onCloseAttempt: onReject, children: [_jsx(Header, { children: dialogTitle }), _jsxs(Content, { className: styles.contentContainer, children: [_jsx(FormProvider, { ...form, children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsx(ProfileField, { onSelect: setProfileName }) }) }), profileName === '' ? (_jsx(Text, { children: "You can assign the profile to a group of users on a dedicated profile page." })) : (_jsxs(Text, { children: ["You can assign the profile to a group of users on the", ' ', _jsx(NavigationLink, { href: `/profiles/${selectedProfile}/users`, children: profileName }), ' ', "page."] }))] }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, type: "submit", disabled: !isDirty, loader: isSubmitting, onClick: handleSubmit(onSubmit), children: "Assign" }), _jsx(Button, { loader: isSubmitting, onClick: onReject, children: "Cancel" })] })] }));
}
export default AssignProfileDialogForm;
