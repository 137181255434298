import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import ImageFileAttachment from '@app/pages/configuration-page/client-machine-configuration/logo-editor/upload-logo-dialog/image-file-attachment/image-file-attachment';
export function UploadLogoDialog(props) {
    const { currentImage, onCloseAttempt } = props;
    const [newImage, setNewImage] = useState(undefined);
    const handleOkClick = () => {
        if (newImage) {
            onCloseAttempt(newImage);
        }
    };
    const handleUpload = (img) => {
        setNewImage(img !== null && img !== void 0 ? img : undefined);
    };
    const handleCancel = () => {
        onCloseAttempt(undefined);
    };
    return (_jsxs(Dialog, { show: true, autoFocusFirst: true, onCloseAttempt: handleCancel, "data-test": "upload-logo-dialog", children: [_jsx(Header, { children: "Upload Company Logo" }), _jsx(Content, { children: _jsx(ImageFileAttachment, { onUploaded: handleUpload, imageUrl: newImage !== null && newImage !== void 0 ? newImage : currentImage }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, disabled: !newImage, onClick: handleOkClick, "data-test": "logo-upload-button", children: "Upload" }), _jsx(Button, { onClick: handleCancel, "data-test": "cancel-upload-button", children: "Cancel" })] })] }));
}
