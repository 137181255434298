import { getServiceClient } from '../components/auth/auth';
import memoize from '../components/memoize';
import { toLocalDateString } from '../components/util/i18n';
import { getApiError } from './errors';
export const getApdexTimeSeries = memoize(async (options) => {
    try {
        const params = {
            interval: '',
            'filter-min-date': '',
            'filter-max-date': '',
            'filter-product-code': null,
            'filter-product-build-prefix': null,
            'filter-language': null
        };
        params.interval = options.interval;
        params['filter-min-date'] = toLocalDateString(options.minDate);
        params['filter-max-date'] = toLocalDateString(options.maxDate);
        if (options.productCode != null) {
            params['filter-product-code'] = options.productCode;
        }
        if (options.productBuildPrefix != null) {
            params['filter-product-build-prefix'] = options.productBuildPrefix;
        }
        if (options.language != null) {
            params['filter-language'] = options.language;
        }
        const { data } = await getServiceClient().get('/api/usage-analytics/completion/apdex-time-series', { params });
        return { success: true, data };
    }
    catch (e) {
        return getApiError(e);
    }
});
export async function getProductCodes() {
    return getServiceClient()
        .get('/api/usage-analytics/completion/product-codes')
        .then(r => r.data);
}
export async function getProductBuilds(productCode) {
    return getServiceClient()
        .get('/api/usage-analytics/completion/product-builds', {
        params: { 'filter-product-code': productCode }
    })
        .then(r => r.data);
}
export async function getLanguages(productCode) {
    return getServiceClient()
        .get('/api/usage-analytics/completion/languages', {
        params: { 'filter-product-code': productCode }
    })
        .then(r => r.data);
}
