import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import getUID from '@jetbrains/ring-ui/components/global/get-uid';
import { modalsContext, modalsControllerContext } from './context';
import { ModalModel } from './modal-model';
export function ModalsContextProvider(props) {
    const { children } = props;
    const [modals, setModals] = useState([]);
    const handleModalClose = useCallback((modalId) => {
        setModals(prevModals => prevModals.filter(m => m.modalId !== modalId));
    }, []);
    const handleModalPropsUpdate = useCallback(() => {
        // just trigger rerender for the "modals-renderer" which uses "modalsContext"
        setModals(prevModals => prevModals.slice(0));
    }, []);
    const handleAddModal = useCallback((component, modalProps) => {
        const modal = new ModalModel(getUID('modal'), component, modalProps, handleModalClose, handleModalPropsUpdate);
        setModals(prevModals => [modal, ...prevModals]);
        return modal;
    }, [handleModalClose, handleModalPropsUpdate]);
    const modalsController = useMemo(() => ({
        openModal: handleAddModal
    }), [handleAddModal]);
    return (_jsx(modalsControllerContext.Provider, { value: modalsController, children: _jsx(modalsContext.Provider, { value: modals, children: children }) }));
}
