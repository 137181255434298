import {useMemo} from 'react';
import {useColorModeValue} from '../color-mode-context';

/**
 * It returns a dark or white theme that can be used by the Ace editor.
 * @return {'one_dark'|'github'}
 */
export default function useEditorTheme() {
  const name = useColorModeValue('github', 'one_dark');
  return useMemo(() => name, [name]);
}
