import { getServiceClient } from '@components/auth/auth';
export async function fetchAgreementJson() {
    const r = await getServiceClient().get('/api/transparency/agreement');
    return r.data;
}
export async function uploadNewAgreement(newFile) {
    const data = new FormData();
    data.append('file', newFile);
    const response = await getServiceClient().post('/api/transparency/agreement', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}
export async function resetAgreement() {
    const response = await getServiceClient().delete('/api/transparency/agreement');
    return response.data;
}
