// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actions__e78 {
  margin-top: calc(var(--ring-unit) * 1.5);

  display: flex;
  gap: var(--ring-unit);
}

.licenseData__e8a {
  margin-top: var(--ring-unit);
}

.licenseData__e8a + .addMore__f9f {
  margin-top: calc(var(--ring-unit) / 2);
}

.addMore__f9f {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.deniedRequests__d6e {
  margin-top: calc(var(--ring-unit) * 1.5);

  display: flex;
  align-items: center;
}

.deniedRequestsIcon__c40 {
  color: var(--ring-error-color);
}

.deniedRequestsMsg__dca {
  margin-left: calc(var(--ring-unit) / 2);
  color: var(--ring-error-color);
}

.deniedRequestsLink__f01 {
  margin-left: calc(var(--ring-unit) * 1.5);
}

.prepaid__b33 {
  margin-right: calc(var(--ring-unit) * 1.5);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/license-vault/license-vault-enabled/license-vault-enabled.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;;EAExC,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;;EAExC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,uCAAuC;EACvC,8BAA8B;AAChC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".actions {\n  margin-top: calc(var(--ring-unit) * 1.5);\n\n  display: flex;\n  gap: var(--ring-unit);\n}\n\n.licenseData {\n  margin-top: var(--ring-unit);\n}\n\n.licenseData + .addMore {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.addMore {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.deniedRequests {\n  margin-top: calc(var(--ring-unit) * 1.5);\n\n  display: flex;\n  align-items: center;\n}\n\n.deniedRequestsIcon {\n  color: var(--ring-error-color);\n}\n\n.deniedRequestsMsg {\n  margin-left: calc(var(--ring-unit) / 2);\n  color: var(--ring-error-color);\n}\n\n.deniedRequestsLink {\n  margin-left: calc(var(--ring-unit) * 1.5);\n}\n\n.prepaid {\n  margin-right: calc(var(--ring-unit) * 1.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `actions__e78`,
	"licenseData": `licenseData__e8a`,
	"addMore": `addMore__f9f`,
	"deniedRequests": `deniedRequests__d6e`,
	"deniedRequestsIcon": `deniedRequestsIcon__c40`,
	"deniedRequestsMsg": `deniedRequestsMsg__dca`,
	"deniedRequestsLink": `deniedRequestsLink__f01`,
	"prepaid": `prepaid__b33`
};
export default ___CSS_LOADER_EXPORT___;
