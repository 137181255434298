import { isNil, uniqBy, isEmpty, range, sortBy, uniq } from 'lodash';
function includeQualityLabel(qualityName) {
    return `${qualityName} or more stable`;
}
function excludeQualityLabel(qualityName) {
    return `Less stable than ${qualityName}`;
}
export function getQualityLabelByType(type, qualityName) {
    return type === 'include' ? includeQualityLabel(qualityName) : excludeQualityLabel(qualityName);
}
export function buildProductOptions(allProducts, selectedProducts) {
    let options = [
        ...allProducts.map(product => ({
            key: product.id,
            label: product.name
        })),
        ...selectedProducts.map(product => ({ key: product, label: product }))
    ];
    options = uniqBy(options, option => option.key);
    return options;
}
export function toQualityOption(quality, type) {
    const label = getQualityLabelByType(type, quality.name);
    return {
        key: quality.orderValue,
        label,
        qualityName: quality.name
    };
}
export function buildQualityOptions(allQualities, selectedQuality, type) {
    let options = [
        ...allQualities.map(quality => toQualityOption(quality, type)),
        ...(selectedQuality ? [toQualityOption(selectedQuality, type)] : [])
    ];
    options = uniqBy(options, option => option.key);
    return options;
}
export function withReplacedSuffix(item) {
    return { ...item, type: item.type.replace('-all', '') };
}
export function replaceProps(obj, props) {
    return props.reduce((accum, prop) => {
        accum[prop] = accum[prop] || [];
        return accum;
    }, obj);
}
export function replaceQuality(object) {
    if (isNil(object === null || object === void 0 ? void 0 : object.quality)) {
        return object;
    }
    return {
        quality: { orderValue: undefined },
        ...object
    };
}
export function buildBuildOptions(allProducts, selectedProducts, selectedBuilds, defaultBuildFilter = () => true) {
    if (isEmpty(selectedProducts)) {
        // If user didn't select any product, suggest major builds (e.g. 213) of all products
        const builds = allProducts.flatMap(product => product.builds).map(build => build.id);
        const patterns = builds
            .filter(defaultBuildFilter)
            .map(build => (build.includes('.') ? `${build.split('.')[0]}.*` : ''));
        return sortBy(uniq([...patterns, ...selectedBuilds])).map(i => ({
            key: i,
            label: i
        }));
    }
    // If user selected one or more products, suggest builds of selected products
    let builds = allProducts
        .filter(product => selectedProducts.includes(product.id))
        .flatMap(product => product.builds)
        .map(build => build.id);
    builds = sortBy(uniq(builds));
    // Patterns with wildcards, e.g. '211.*', '211.7628.*'
    const patterns = builds.flatMap(build => {
        const parts = build.split('.');
        return range(1, parts.length).map(i => `${parts.slice(0, i).join('.')}.*`);
    });
    return sortBy(uniq([...patterns, ...builds, ...selectedBuilds])).map(i => ({
        key: i,
        label: i
    }));
}
