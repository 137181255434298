// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorIcon__b5d {
  color: var(--ring-icon-error-color);
  margin-right: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/banner/tba-banner.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,8BAA8B;AAChC","sourcesContent":[".errorIcon {\n  color: var(--ring-icon-error-color);\n  margin-right: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorIcon": `errorIcon__b5d`
};
export default ___CSS_LOADER_EXPORT___;
