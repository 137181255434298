import {useEffect, useState} from 'react';

/**
 * Encapsulates internal component value that can change with high frequency.
 * Calls `onChange` only after the internal value didn't change for `timeout` milliseconds.
 *
 * @example
 *   function Example({value, onChange}) {
 *     const [rawValue, onRawChange] = useDebounceComponentValue(value, onChange);
 *     return <input value={rawValue} onChange={e => onRawChange(e.target.value)}/>
 *   }
 *
 * @template T
 * @param {T} value
 * @param {function(T): void} onChange
 * @param {number} [timeout]
 * @returns {[T, function(T): void]}
 */
export function useDebounceComponentValue(value, onChange, timeout = 500) {
  const [rawValue, setRawValue] = useState(value);

  useEffect(() => {
    setRawValue(value);
  }, [value]);

  useEffect(() => {
    const timeoutId = setTimeout(() => onChange(rawValue), timeout);
    return () => clearTimeout(timeoutId);
  }, [rawValue, timeout]);

  return [rawValue, setRawValue];
}

/**
 * see https://usehooks.com/useDebounce/ and https://codesandbox.io/s/ted8o?file=/src/useDebounce.js
 * @template T
 * @param {T} value
 * @param {number} delay
 * @returns {T}
 */
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => setDebouncedValue(value), delay);
      return () => clearTimeout(handler);
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}
