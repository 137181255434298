import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import NavigationLink from '../../../components/navigation/navigation-link';
import { getPluginsUploadUrl } from '../../../api/plugins';
import CopyCode from '../../../components/clipboard/copied-code/copied-code';
import auth from '../../../components/auth/auth';
import styles from './upload-plugin.css';
function PluginsUploadObtainToken() {
    return (_jsxs(Text, { className: styles.oneLineText, children: ["To automate plugin distribution, start by obtaining an", ' ', _jsx(NavigationLink, { href: "/configuration/automation-tokens", children: "access token" }), "."] }));
}
function PluginsUploadUsingCurl({ channels, expirationDate }) {
    const command = useMemo(() => {
        const url = getPluginsUploadUrl();
        const getChannelArg = () => {
            if (channels.length === 1) {
                return `     -F channel=${channels[0]} \\\n`;
            }
            if (channels.length > 1) {
                const channelsArrayPresentation = channels.map(channel => `"${channel}"`).join(', ');
                return `     -F 'channels=[${channelsArrayPresentation}];type=application/json' \\\n`;
            }
            return undefined;
        };
        const expirationDateArg = expirationDate
            ? `     -F expirationDate=${expirationDate === null || expirationDate === void 0 ? void 0 : expirationDate.toISOString()} \\\n`
            : undefined;
        return [
            `curl -i \\\n`,
            `     -H 'Authorization: Automation <permanentToken>' \\\n`,
            `     -F file=@<path to plugin .jar/.zip file> \\\n`,
            getChannelArg(),
            expirationDateArg,
            `     ${url}`
        ]
            .filter(it => !!it)
            .join('');
    }, [channels, expirationDate]);
    return (_jsxs("div", { children: [_jsx(Text, { children: "Then run the following cURL command:" }), _jsx(CopyCode, { className: styles.codeSnippet, text: command })] }));
}
function PluginsUploadUsingGradle({ channels }) {
    const url = auth.getBaseURL();
    const channelArg = channels.length
        ? `\n        channels.set(listOf(${channels.map(channel => `"${channel}"`).join(', ')}))`
        : '';
    const gradleConfig = `
plugins {
    // This functionality is available in JetBrains IDE Services version 1.5.0 and later
    id("org.jetbrains.intellij") version "1.16.0"
}

tasks {
    publishPlugin {
        toolboxEnterprise.set(true)
        host.set("${url}")
        token.set(<permanentToken>)${channelArg}
    }
}
`.substring(1); // to drop first line break
    return (_jsxs("div", { children: [_jsxs(Text, { children: ["Alternatively, you can configure the ", _jsx("code", { children: "publishPlugin" }), " task in", ' ', _jsx("code", { children: "build.gradle.kts" }), ". The task is available from the Gradle IntelliJ plugin."] }), _jsx(CopyCode, { text: gradleConfig, className: styles.codeSnippet })] }));
}
export function UploadPluginInfo({ channels, expirationDate }) {
    return (_jsxs("div", { className: styles.instructionsContainer, children: [_jsx(PluginsUploadObtainToken, {}), _jsx(PluginsUploadUsingCurl, { channels: channels, expirationDate: expirationDate }), _jsx(PluginsUploadUsingGradle, { channels: channels })] }));
}
