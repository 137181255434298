import { getServiceClient } from '../components/auth/auth';
import { setOsAnalyticsFetchError, setOsAnalyticsInfo, setOsAnalyticsIsFetching } from '../store/operating-systems-analytics';
export async function getOsAnalytics(period, dispatch) {
    try {
        dispatch(setOsAnalyticsIsFetching(true));
        const { data } = await getServiceClient().get(`/api/usage-analytics/os-by-period/${period}`);
        dispatch(setOsAnalyticsInfo(data));
    }
    catch (e) {
        dispatch(setOsAnalyticsFetchError(e));
    }
}
export async function getOsMachinesAmountAnalytics(period = undefined) {
    return getServiceClient()
        .get('/api/usage-analytics/os-machines-amount', {
        params: { period }
    })
        .then(r => r.data);
}
