// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo__f57 {
  height: inherit;
  width: inherit;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/code-with-me/code-with-me-logo/code-with-me-logo.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;;EAEd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB;IACE,YAAY;EACd;AACF","sourcesContent":[".logo {\n  height: inherit;\n  width: inherit;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  svg {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `logo__f57`
};
export default ___CSS_LOADER_EXPORT___;
