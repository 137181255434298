import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Header } from '@jetbrains/ring-ui/components/island/island';
import Content from '@jetbrains/ring-ui/components/island-legacy/content-legacy';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import CopiedCode from '@components/clipboard/copied-code/copied-code';
import { copyToClipboard } from '@components/clipboard/copy-to-clipboard';
import styles from './automation-token-popups.css';
export function AutomationTokenCopyPopup(props) {
    const { onCloseAttempt, token } = props;
    return (_jsxs(Dialog, { show: true, autoFocusFirst: true, label: `Automation Token '${token.clientId}' Created`, onCloseAttempt: onCloseAttempt, children: [_jsxs(Header, { children: ["Automation Token: ", token.clientId] }), _jsxs(Content, { className: styles.content, children: [_jsxs("p", { children: ["Before you close this dialog, copy the token and save it in a secure location", ' ', _jsx("b", { children: "This is the only time the token is visible." })] }), _jsxs("p", { children: ["Token name: ", _jsx("b", { children: token.clientId })] }), _jsx("p", { children: "Automation Token" }), _jsx(CopiedCode, { text: token.clientAuthorizationToken, className: styles.code })] }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: () => copyToClipboard(token.clientAuthorizationToken), children: "Copy" }), _jsx(Button, { onClick: onCloseAttempt, children: "Close" })] })] }));
}
