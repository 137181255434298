// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloadsLabel__ed3 {
  margin-right: calc(var(--ring-unit) / 2);
}

.privacyLabel__b9e {
  margin-right: calc(var(--ring-unit) / 2);
}

.privacyToggle__c46 {
  margin-right: var(--ring-unit);
}

.hiddenElement__f69 {
  visibility: hidden;
}

.gridContainer__ebc {
  grid-template-rows: repeat(4, 1fr);
}
`, "",{"version":3,"sources":["webpack://./src/pages/plugins/plugin-page/plugin-info/plugin-info.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".downloadsLabel {\n  margin-right: calc(var(--ring-unit) / 2);\n}\n\n.privacyLabel {\n  margin-right: calc(var(--ring-unit) / 2);\n}\n\n.privacyToggle {\n  margin-right: var(--ring-unit);\n}\n\n.hiddenElement {\n  visibility: hidden;\n}\n\n.gridContainer {\n  grid-template-rows: repeat(4, 1fr);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadsLabel": `downloadsLabel__ed3`,
	"privacyLabel": `privacyLabel__b9e`,
	"privacyToggle": `privacyToggle__c46`,
	"hiddenElement": `hiddenElement__f69`,
	"gridContainer": `gridContainer__ebc`
};
export default ___CSS_LOADER_EXPORT___;
