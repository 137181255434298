import { jsx as _jsx } from "react/jsx-runtime";
import { useCodeWithMeConstituent } from '@app/queries/code-with-me';
import { useOpenModal } from '@components/modals/hooks';
import { CodeWithMeDisabled } from './code-with-me-disabled/code-with-me-disabled';
import { CodeWithMeEnabled } from './code-with-me-enabled/code-with-me-enabled';
import { CodeWithMeMisconfigured } from './code-with-me-misconfigured/code-with-me-misconfigured';
import { CwmEnableDialog } from './dialogs/cwm-enable-dialog/cwm-enable-dialog';
import { CwmDisableDialog } from './dialogs/cwm-disable-dialog/cwm-disable-dialog';
import { CwmEditSettingsDialog } from './dialogs/cwm-edit-settings-dialog/cwm-edit-settings-dialog';
export function CodeWithMeConstituent(props) {
    const { className, licenseType } = props;
    const { data } = useCodeWithMeConstituent();
    const openEnableDialog = useOpenModal(CwmEnableDialog, {});
    const openDisableDialog = useOpenModal(CwmDisableDialog, {});
    const openEditDialog = useOpenModal(CwmEditSettingsDialog, {
        initialLimitUsage: (data === null || data === void 0 ? void 0 : data.status) === 'enabled' ? data.data.concurrentHostsLimit : undefined
    });
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled') {
        return _jsx(CodeWithMeDisabled, { onEnabledClick: openEnableDialog, className: className });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'configuration_error') {
        return _jsx(CodeWithMeMisconfigured, { className: className, data: data.data });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'enabled') {
        return (_jsx(CodeWithMeEnabled, { data: data.data, onEditClick: openEditDialog, onDisableClick: openDisableDialog, allowEditSettings: licenseType === 'POSTPAID', className: className }));
    }
    return null;
}
