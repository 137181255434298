import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo } from 'react';
import { Tab as RingTab, Tabs as RingTabs } from '@jetbrains/ring-ui/components/tabs/tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigurationChangedNotification } from '@app/pages/configuration-page/configuration-changed-notification/configuration-changed-notification';
import { PageTitle } from '@components/page-layout/page-title';
import { useControlledNotification } from '@components/notifications';
import { ServerConfigurationProvider } from '@components/machine-config';
import auth from '@components/auth/auth';
import { AutomationTokens } from './automation-tokens/automation-tokens';
import OfflineMode from './offline-mode/offline-mode';
import DataCollectionStatement from './data-collection/data-collection';
import { ClientMachineConfiguration } from './client-machine-configuration/client-machine-configuration';
import LicenseActivationPage from './license/license-activation-page';
var Tab;
(function (Tab) {
    Tab["CLIENT_MACHINE_CONFIGURATION"] = "client-machine-configuration";
    Tab["AUTOMATION_TOKENS"] = "automation-tokens";
    Tab["LICENSE_ACTIVATION"] = "license-activation";
    Tab["DATA_COLLECTION_STATEMENT"] = "data-collection-statement";
    Tab["OFFLINE_MODE"] = "offline-mode";
})(Tab || (Tab = {}));
function useConfigurationPageTabs() {
    const { slug } = useParams();
    const navigate = useNavigate();
    const tab = useMemo(() => {
        return Object.values(Tab).includes(slug) ? slug : undefined;
    }, [slug]);
    useEffect(() => {
        if (!tab) {
            navigate(`/configuration/${Tab.CLIENT_MACHINE_CONFIGURATION}`, {
                replace: true
            });
        }
    }, [tab, navigate]);
    const setTab = useCallback((tab) => {
        if (Object.values(Tab).includes(tab)) {
            navigate(`/configuration/${tab}`);
        }
    }, [navigate]);
    return [tab !== null && tab !== void 0 ? tab : Tab.CLIENT_MACHINE_CONFIGURATION, setTab];
}
export function ConfigurationPage() {
    const [currentTab, setTab] = useConfigurationPageTabs();
    const { showNotification } = useControlledNotification('server-configuration-changed', _jsx(ConfigurationChangedNotification, {}));
    const hasAnalyticsEnabled = !!auth.getFeatures().analyticsEnabled;
    const handleConfigChanged = useCallback(() => showNotification(), [showNotification]);
    return (_jsxs(ServerConfigurationProvider, { onConfigChanged: handleConfigChanged, children: [_jsx(PageTitle, { title: "Configuration" }), _jsxs(RingTabs, { selected: currentTab, onSelect: setTab, "data-test": "configuration-page-tabs", children: [_jsx(RingTab, { id: Tab.CLIENT_MACHINE_CONFIGURATION, title: "Client Machine Configuration", titleProps: { 'data-test': 'client-machine-configuration-tab' }, children: _jsx(ClientMachineConfiguration, {}) }), _jsx(RingTab, { id: Tab.AUTOMATION_TOKENS, title: "Automation Tokens", titleProps: { 'data-test': 'automation-tokens-tab' }, children: _jsx(AutomationTokens, {}) }), _jsx(RingTab, { id: Tab.LICENSE_ACTIVATION, title: "License & Activation", titleProps: { 'data-test': 'license-and-activation-tab' }, children: _jsx(LicenseActivationPage, {}) }), hasAnalyticsEnabled && (_jsx(RingTab, { id: Tab.DATA_COLLECTION_STATEMENT, title: "Data Collection Statement", titleProps: { 'data-test': 'data-collection-statement-tab' }, children: _jsx(DataCollectionStatement, {}) })), _jsx(RingTab, { id: Tab.OFFLINE_MODE, title: "Offline Mode", titleProps: { 'data-test': 'offline-mode-tab' }, children: _jsx(OfflineMode, {}) }, "offline-mode")] })] }));
}
