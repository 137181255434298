import { jsx as _jsx } from "react/jsx-runtime";
import { Header } from '@jetbrains/ring-ui/components/island/island';
import styles from './dialog-header.css';
/**
 * Utility component that can be used instead of a Header component from the Ring UI.
 * It is primarily made to make it possible to put header and buttons into a flexbox
 */
function DialogHeader({ children }) {
    return _jsx(Header, { className: styles.content, children: children });
}
export default DialogHeader;
