import { useSelector } from 'react-redux';
import { useTbePlugins } from '@app/queries/plugins';
import { getImmutablePluginIds } from '@app/store/profiles/immutableProfilesState/selectors';
import { useMarketplacePlugins } from '@components/plugins/marketplace-query-wrappers';
/**
 * Hook which is primarily made to find plugin names. It takes Redux immutable profile plugins
 * and converts them to the EditorPlugin.
 */
function useImmutablePlugins(enabled = true) {
    const ids = useSelector(getImmutablePluginIds);
    const { data: tPlugins, isFetching: isFetchingTPlugins } = useTbePlugins(enabled);
    const tIds = new Set((tPlugins === null || tPlugins === void 0 ? void 0 : tPlugins.map(x => x.key)) || []);
    const mIds = (ids === null || ids === void 0 ? void 0 : ids.filter(x => !tIds.has(x))) || [];
    const { data: mPlugins, isFetching: isFetchingMPlugins } = useMarketplacePlugins(mIds, enabled && mIds.length > 0 && !isFetchingTPlugins);
    return {
        data: tPlugins ? tPlugins.concat(mPlugins || []) : [],
        isFetching: isFetchingTPlugins || isFetchingMPlugins
    };
}
export function useEditorPlugin(id) {
    const ids = useSelector(getImmutablePluginIds);
    const searchMarketplace = !ids.includes(id);
    const { data: immutablePlugins, isFetching: isFetchingImmutablePlugins } = useImmutablePlugins(!searchMarketplace);
    const immutablePlugin = immutablePlugins === null || immutablePlugins === void 0 ? void 0 : immutablePlugins.find(x => 'key' in x && x.key === id);
    const { data, isFetching: isFetchingPlugin } = useMarketplacePlugins([id], searchMarketplace);
    const marketplacePlugin = (data === null || data === void 0 ? void 0 : data.length) === 1 ? data[0] : undefined;
    return {
        data: searchMarketplace ? marketplacePlugin : immutablePlugin,
        isFetching: searchMarketplace ? isFetchingPlugin : isFetchingImmutablePlugins
    };
}
