import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import { NavigationLinkOutsideRouter } from '@components/navigation/navigation-link-outside-router';
export function confirmGroupRemoval(group, profileName) {
    return confirm({
        text: `Unassign ${profileName} from ${group.name}`,
        description: (_jsxs(_Fragment, { children: ["Do you really want to unassign ", _jsx("b", { children: profileName }), " from", _jsx(NavigationLinkOutsideRouter, { href: `/users?groupId=${group.id}`, children: group.name }), "?"] })),
        confirmLabel: 'Unassign',
        rejectLabel: 'Cancel',
        cancelIsDefault: true
    });
}
