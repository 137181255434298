import { isEqual } from 'lodash';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { AlertTimeouts } from '../constants/constants';
const FIREFOX_DOM_EXCEPTION_ERROR = 'Clipboard write is not allowed.';
export const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
        alert.successMessage('Copied to clipboard', AlertTimeouts.SHORT);
    }
    catch (err) {
        if (navigator.userAgent.indexOf('Firefox') !== -1 &&
            err instanceof DOMException &&
            isEqual(err.message, FIREFOX_DOM_EXCEPTION_ERROR)) {
            return;
        }
        alert.error('Error copying text to clipboard');
    }
};
