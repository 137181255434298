import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Size } from '@jetbrains/ring-ui/components/input/input';
import { useFormContext } from 'react-hook-form';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import RingFieldsList from '@components/form/ring-fields-list';
import { Required } from '@components/form/important-asterisk';
import RegisteredInput from '@components/hook-form/registered-input';
import RegisteredCheckbox from '@components/hook-form/registered-checkbox';
import HelpIcon from '@components/util/help-icon';
import styles from './settings/ide-settings/form/common/forced-field.css';
export default function ProfileEditFields() {
    const { control } = useFormContext();
    return (_jsxs(RingFieldsList, { children: [_jsx(RegisteredInput, { name: "title", label: Required('Title'), size: Size.L, autoComplete: "off", control: control }), _jsx(RegisteredInput, { name: "description", label: "Description", size: Size.L, autoComplete: "off", control: control }), _jsxs("div", { children: [_jsx(RegisteredCheckbox, { name: "isDefault", label: "Default", control: control, labelClassName: styles.forcedCheckbox }), _jsx(Tooltip, { title: "Default profiles are automatically assigned to new users", children: _jsx(HelpIcon, {}) })] })] }));
}
