import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import closeIcon from '@jetbrains/icons/close';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import NavigationLink from '@components/navigation/navigation-link';
import { EnumerableTextElements } from '@components/text-formattings/enumerable-text-elements';
import styles from './profile-list-item.css';
export function ProfileListItem({ onRemove = () => { }, profile, disabled }) {
    const { title, id, groups } = profile;
    const onDelete = useCallback(() => onRemove(id), [onRemove, id]);
    const hasGroups = !!groups && groups.length > 0;
    return (_jsxs("div", { className: styles.textContainer, "data-test": "profile-list-item", children: [_jsx(NavigationLink, { href: `/profiles/${id}`, className: styles.navLink, "data-test": "profile-name", children: title }, id), hasGroups ? (_jsx(EnumerableTextElements, { getKey: group => `user-group-${group.id}`, getValue: group => (_jsx(NavigationLink, { className: styles.groupLink, href: `/users?groupId=${group.id}`, children: group.name })), items: groups.sort((a, b) => a.name.localeCompare(b.name)), maxVisibleElems: 2 })) : (_jsxs(Text, { info: true, className: styles.directAssingnButton, children: ["Assigned directly", _jsx(Button, { disabled: disabled, className: styles.icon, icon: closeIcon, onClick: onDelete, "data-test": "profile-remove", title: "Unassign profile" })] }))] }));
}
