// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloadWinButton__a29 {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.generateDownloadLinkButton__af0 {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.downloadCommand__e2e {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.validMsg__c85 {
  margin-top: calc(var(--ring-unit) * 2);
}

.warning__beb {
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/code-with-me/session-page/manual-download-content/manual-download-content.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".downloadWinButton {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.generateDownloadLinkButton {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.downloadCommand {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.validMsg {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n\n.warning {\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadWinButton": `downloadWinButton__a29`,
	"generateDownloadLinkButton": `generateDownloadLinkButton__af0`,
	"downloadCommand": `downloadCommand__e2e`,
	"validMsg": `validMsg__c85`,
	"warning": `warning__beb`
};
export default ___CSS_LOADER_EXPORT___;
