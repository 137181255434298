export function toTool(apps) {
    return {
        product: apps.product,
        productName: apps.productName
    };
}
export function findGreaterEquals(elements, target) {
    return elements.find(x => {
        return (x.substring(0, target.length).localeCompare(target, undefined, {
            numeric: true,
            sensitivity: 'base'
        }) >= 0);
    });
}
