import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { H1, H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Icon, { Size } from '@jetbrains/ring-ui/components/icon';
import Button from '@jetbrains/ring-ui/components/button/button';
import codeWithMeLogo from '@jetbrains/logos/code-with-me/code-with-me.svg';
import { useLoaderData } from 'react-router-dom';
import classNames from 'classnames';
import auth from '../../../components/auth/auth';
import { DownloadCwmInTbeToolboxApp } from '../../../components/machine-config';
import { Collapsible } from '../../../components/collapsible/collapsible';
import { ExternalLinks } from '../../../components/constants/constants';
import { BackgroundGradientsWrapper } from './gradient-wrapper/background-gradients-wrapper';
import { ManualDownloadContent } from './manual-download-content';
import styles from './session-page.css';
export function CodeWithMeSessionPage() {
    const codeWithMeDataEntity = useLoaderData();
    const sessionHeaderMessage = useMemo(() => {
        if (!codeWithMeDataEntity) {
            return 'Session data is undefined';
        }
        return `${codeWithMeDataEntity.userMoniker} has invited you to join their project ${codeWithMeDataEntity.projectName} in ${codeWithMeDataEntity.idePresentableName}.`;
    }, [codeWithMeDataEntity]);
    const [joinSessionClickedOnce, setJoinSessionClickedOnce] = useState(false);
    const openInToolboxUrl = useMemo(() => {
        const encoded = encodeURIComponent(auth.getBaseURL());
        return `jetbrains://client/code-with-me/join?v=t1&sessionId=${codeWithMeDataEntity === null || codeWithMeDataEntity === void 0 ? void 0 : codeWithMeDataEntity.id}&baseUrl=${encoded}`;
    }, [codeWithMeDataEntity]);
    return (_jsx(BackgroundGradientsWrapper, { className: styles.wrapper, children: _jsxs("div", { className: styles.wrapperInner, children: [_jsx(Icon, { glyph: codeWithMeLogo, size: Size.Size128 }), _jsx(H1, { children: sessionHeaderMessage }), _jsx("p", { className: classNames(styles.text, { [styles.retryBlock]: joinSessionClickedOnce }), children: joinSessionClickedOnce ? (_jsxs(_Fragment, { children: [_jsx(H3, { children: "Did not work?" }), "Ensure Toolbox App is installed before joining a session and", ' ', _jsx(Button, { href: openInToolboxUrl, inline: true, delayed: true, primary: true, text: true, children: "retry" })] })) : ('Ensure Toolbox App is installed before joining a session') }), _jsx("p", { className: styles.text, children: _jsx(DownloadCwmInTbeToolboxApp, { className: classNames(styles.downloadToolbox, {
                            [styles.downloadToolboxRetry]: joinSessionClickedOnce
                        }), sessionID: (codeWithMeDataEntity === null || codeWithMeDataEntity === void 0 ? void 0 : codeWithMeDataEntity.id) || '' }) }), !joinSessionClickedOnce && (_jsx(Button, { className: styles.joinButton, href: openInToolboxUrl, onClick: () => setJoinSessionClickedOnce(true), primary: true, children: "Join Session" })), _jsx("p", { className: styles.aboutParagraph, children: _jsx(Button, { href: ExternalLinks.JB_CWM, text: true, className: styles.aboutLink, target: "_blank", children: "About Code With Me" }) }), codeWithMeDataEntity.serveOldLaunchers && (_jsx(Collapsible, { className: styles.manualDownloadButton, title: "Other ways to join", children: _jsx(ManualDownloadContent, { codeWithMeDataEntity: codeWithMeDataEntity, className: styles.manualDownloadContent }) }))] }) }));
}
