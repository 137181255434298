import { createContext, useCallback, useContext, useMemo } from 'react';
import { noop } from 'lodash';
export const SettingsContext = createContext({
    formState: { settingType: 'ide' },
    setFormState: noop
});
export function useSettingsContext(settingType) {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettingFormContext must be used within SharedFormContext');
    }
    const { formState, setFormState } = context;
    const innerSetFormState = useCallback((settingId) => {
        setFormState({ settingId, settingType });
    }, [settingType, setFormState]);
    const resetFormState = useCallback(() => {
        setFormState(undefined);
    }, [setFormState]);
    const showForm = useMemo(() => (formState === null || formState === void 0 ? void 0 : formState.settingType) === settingType, [settingType, formState]);
    const settingId = useMemo(() => ((formState === null || formState === void 0 ? void 0 : formState.settingType) === settingType ? formState.settingId : undefined), [settingType, formState]);
    return {
        settingId,
        showForm,
        setFormState: innerSetFormState,
        resetFormState
    };
}
