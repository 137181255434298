import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Code from '@jetbrains/ring-ui/components/code/code';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { useLoaderData } from 'react-router-dom';
import kvStyles from '../../components/util/key-value-row.css';
import { getStacktracesZip } from '../../api/collected-data-error';
import KeyValueRow from '../../components/util/key-value-row';
import { downloadBlob } from '../../components/util/blob';
import { PageTitle } from '../../components/page-layout/page-title';
import CollectedDataErrorAttachments from './collected-data-error-attachments';
import styles from './collected-data-reports-page.css';
export default function CollectedDataErrorPage() {
    const collectedError = useLoaderData();
    const [isDownloading, setIsDownloading] = useState(false);
    const handleOnClick = () => {
        setIsDownloading(true);
        getStacktracesZip(`${collectedError.id}`).then(response => {
            if (response.status === 200) {
                downloadBlob(new Blob([response.data]), `stacktrace-${collectedError.id}.zip`);
            }
            else {
                alert.error(`Couldn't download stacktraces: ${response.statusText}`);
            }
            setIsDownloading(false);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { parts: [
                    { title: 'Analytics' },
                    { title: 'Error Reports', href: '/analytics/collected-data/errors' },
                    { title: `EA-${collectedError.id}` }
                ], rightPanel: collectedError.stacktrace ? (_jsx(Button, { onClick: handleOnClick, loader: isDownloading, primary: true, children: "Download stacktraces" })) : null }), _jsx(KeyValueRow, { lhs: "Title:", rhs: collectedError.title }), _jsx(KeyValueRow, { lhs: "Similar occurred:", rhs: collectedError.count }), collectedError.stacktrace && (_jsxs("div", { className: kvStyles.row, children: [_jsx("div", { className: kvStyles.key, children: "Example stacktrace:" }), _jsx(Code, { className: styles.contentBody, code: collectedError.stacktrace })] })), collectedError.attachments && collectedError.attachments.total > 0 && (_jsx(CollectedDataErrorAttachments, { attachments: collectedError.attachments.items, totalSize: collectedError.attachments.total }))] }));
}
