class ConstituentExceededNotificationStorage {
    constructor() {
        this.dismissedNotifications = new Map();
    }
    saveDismissedNotification(notificationData) {
        const { id, usage, overLimit } = notificationData;
        this.dismissedNotifications.set(id, { usage, overLimit });
    }
    isNotificationDismissed(notificationData) {
        const { id, usage, overLimit } = notificationData;
        const dismissedForConstituent = this.dismissedNotifications.get(id);
        return ((dismissedForConstituent === null || dismissedForConstituent === void 0 ? void 0 : dismissedForConstituent.usage) === usage && (dismissedForConstituent === null || dismissedForConstituent === void 0 ? void 0 : dismissedForConstituent.overLimit) === overLimit);
    }
}
export const constituentExceedNotificationStorage = new ConstituentExceededNotificationStorage();
