import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import RegisteredToggle from '@components/hook-form/registered-toggle';
import PartiallyHiddenText from '@components/text-formattings/partially-hidden-text';
function ForcedField() {
    const { control, watch } = useFormContext();
    const watchPluginId = watch('pluginId');
    const disabled = !watchPluginId;
    return (_jsxs(_Fragment, { children: [_jsx(RegisteredToggle, { control: control, name: "forced", disabled: disabled, children: "Force auto-install" }), _jsx("div", { children: _jsx(PartiallyHiddenText, { info: true, size: "s", hiddenText: "If a developer decides to disable or uninstall the plugin that was forcefully installed, they will be prompted to restart the IDE or to reinstall the plugin.", children: "Developers will have to restart their IDEs after receiving this configuration on their machines." }) })] }));
}
export default ForcedField;
