import { jsx as _jsx } from "react/jsx-runtime";
import TagsInput from '@jetbrains/ring-ui/components/tags-input/tags-input';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import RingField from '@components/form/ring-field';
import { operatingSystemOptions } from '../filter-options';
function OsField() {
    const { setValue, watch } = useFormContext();
    const os = watch('os');
    return (_jsx(RingField, { fieldId: "osField", label: "OS", "data-test": "application-filter-os", children: _jsx(TagsInput, { id: "osField", placeholder: isEmpty(os) ? 'All' : '', dataSource: () => operatingSystemOptions, tags: operatingSystemOptions.filter(tag => os === null || os === void 0 ? void 0 : os.includes(tag.key)), filter: true, onAddTag: ({ tag }) => setValue('os', os ? [...os, tag.key] : [tag.key]), onRemoveTag: ({ tag }) => os &&
                setValue('os', os.filter(item => item !== tag.key)) }) }));
}
export default OsField;
