import { getServiceClient } from '../components/auth/auth';
import { withAuditFieldTexts } from './audit-utils';
export async function getAutomationTokens() {
    const response = await getServiceClient().get('/api/automation-tokens');
    return response.data.map(x => withAuditFieldTexts(x));
}
export async function createToken(tokenData) {
    const response = await getServiceClient().post('/api/automation-tokens', tokenData);
    return response.data;
}
export async function updateToken(tokenId, tokenData) {
    const response = await getServiceClient().patch(`/api/automation-tokens/${tokenId}`, tokenData);
    return withAuditFieldTexts(response.data);
}
export async function deleteToken(tokenId) {
    await getServiceClient().delete(`/api/automation-tokens/${tokenId}`);
}
