// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyingContainer__a5f {
  padding-bottom: calc(1.5 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/profile-edit-dialog.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;AAC9C","sourcesContent":[".copyingContainer {\n  padding-bottom: calc(1.5 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyingContainer": `copyingContainer__a5f`
};
export default ___CSS_LOADER_EXPORT___;
