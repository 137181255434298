import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useAiConstituent } from '@app/queries/ai-enterprise';
import { useOpenModal } from '@components/modals/hooks';
import { AiDisabledJba } from './ai-disabled-jba/ai-disabled-jba';
import { AiDisabled } from './ai-disabled/ai-disabled';
import { AiEnabled } from './ai-enabled/ai-enabled';
import { AiDisableDialog } from './dialogs/ai-disable-dialog/ai-disable-dialog';
import { AiEnableDialog } from './dialogs/ai-settings-dialog/ai-enable-dialog';
import { AiSetupDialog } from './dialogs/ai-settings-dialog/ai-setup-dialog';
import { AiChangeSettingsDialog } from './dialogs/ai-settings-dialog/ai-change-settings-dialog';
export function AiEnterpriseConstituent(props) {
    const { licenseType, className } = props;
    const { data } = useAiConstituent();
    const openSetupAiModal = useOpenModal(AiSetupDialog, {});
    const openEnableAiModal = useOpenModal(AiEnableDialog, {});
    const openDisableAiModal = useOpenModal(AiDisableDialog, {});
    const openChangeSettingsModal = useOpenModal(AiChangeSettingsDialog, {
        initialValues: (data === null || data === void 0 ? void 0 : data.status) === 'enabled' ? data.data : undefined
    });
    const handleSetupClick = useCallback(() => {
        if (licenseType === 'POSTPAID') {
            openSetupAiModal();
        }
        else {
            openEnableAiModal();
        }
    }, [licenseType, openEnableAiModal, openSetupAiModal]);
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled_jba') {
        return _jsx(AiDisabledJba, { className: className });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'disabled') {
        return _jsx(AiDisabled, { onSetupClick: handleSetupClick, className: className });
    }
    if ((data === null || data === void 0 ? void 0 : data.status) === 'enabled') {
        return (_jsx(AiEnabled, { aiPlatformData: data.data, onDisableClick: openDisableAiModal, onEditClick: openChangeSettingsModal, allowEditSettings: licenseType === 'POSTPAID', className: className }));
    }
    return null;
}
