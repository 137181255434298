import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import {getUsageDataCurrentBatch} from '../../api/usage-data-current-batch';
import LoadingError from '../../components/errors/loading-error';
import KeyValueRow from '../../components/util/key-value-row';

export default function CollectedDataCurrentBatch() {
  const dispatch = useDispatch();

  const info = useSelector(state => state.usageDataCurrentBatch.items);
  const isFetching = useSelector(state => state.usageDataCurrentBatch.isFetching);
  const isFetchError = useSelector(state => state.usageDataCurrentBatch.isFetchError);

  useEffect(() => {
    dispatch(getUsageDataCurrentBatch());
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (isFetchError) {
    return <LoadingError />;
  }

  return (
    <>
      <h2>Current Batch Statistics</h2>
      <KeyValueRow lhs="Batch name:" rhs={info.batchName} />
      <KeyValueRow lhs="Event logs:" rhs={info.totalEvents} />
      <KeyValueRow lhs="Errors:" rhs={info.totalErrors} />
      <KeyValueRow lhs="Freezes:" rhs={info.totalFreezes} />
    </>
  );
}
