// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__c95 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.header__f63 {
  margin-top: calc(var(--ring-unit) * 1.5);
  align-self: start;
  display: flex;
  align-items: center;
  gap: calc(var(--ring-unit) * 1.5);

  > h3 {
    margin-top: 0;
  }
}

.companyLogo__d9c {
  margin-top: calc(var(--ring-unit) * 15);
}

.companyName__fcc {
  margin-top: calc(var(--ring-unit) * 4);
  text-align: center;
}

.info__e3d {
  margin-top: calc(var(--ring-unit) * 2);
}

.loginBtn__c65 {
  margin-top: calc(var(--ring-unit) * 3);
}

.link__fae {
  padding: 0;
}

.aboutLink__e68 {
  margin-top: calc(var(--ring-unit) * 11);
}
`, "",{"version":3,"sources":["webpack://./src/pages/code-with-me/unauthorized-page/session-page-unauthorized.css"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;EACxC,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,iCAAiC;;EAEjC;IACE,aAAa;EACf;AACF;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".wrapper {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n\n.header {\n  margin-top: calc(var(--ring-unit) * 1.5);\n  align-self: start;\n  display: flex;\n  align-items: center;\n  gap: calc(var(--ring-unit) * 1.5);\n\n  > h3 {\n    margin-top: 0;\n  }\n}\n\n.companyLogo {\n  margin-top: calc(var(--ring-unit) * 15);\n}\n\n.companyName {\n  margin-top: calc(var(--ring-unit) * 4);\n  text-align: center;\n}\n\n.info {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n\n.loginBtn {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.link {\n  padding: 0;\n}\n\n.aboutLink {\n  margin-top: calc(var(--ring-unit) * 11);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__c95`,
	"header": `header__f63`,
	"companyLogo": `companyLogo__d9c`,
	"companyName": `companyName__fcc`,
	"info": `info__e3d`,
	"loginBtn": `loginBtn__c65`,
	"link": `link__fae`,
	"aboutLink": `aboutLink__e68`
};
export default ___CSS_LOADER_EXPORT___;
