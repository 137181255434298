import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
// eslint-disable-next-line no-restricted-imports
import Link from '@jetbrains/ring-ui/components/link/link';
import styles from './partially-hidden-text.css';
function PartiallyHiddenText({ children, hiddenText, ...textProps }) {
    const [isHidden, setIsHidden] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(Text, { ...textProps, children: ["Developers will have to restart their IDEs after receiving this configuration on their machines.", ' ', !isHidden && (
                    // fixme: replace with button
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    _jsx(Link, { pseudo: true, onClick: () => setIsHidden(true), children: "More" }))] }), ' ', isHidden && (
            // fixme: replace with button
            _jsx("div", { className: styles.hiddenPart, children: _jsxs(Text, { ...textProps, children: [hiddenText, " ", _jsx(Link, { pseudo: true, onClick: () => setIsHidden(false), children: "Less" })] }) }))] }));
}
export default PartiallyHiddenText;
