import auth from './auth';
export function authLoaderGuard(loadCb) {
    const isAuthenticated = auth.isAuthenticated();
    return (...args) => {
        if (isAuthenticated) {
            return loadCb(...args);
        }
        return Promise.resolve(null);
    };
}
