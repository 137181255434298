import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from '@jetbrains/ring-ui/components/select/select';
import Toggle from '@jetbrains/ring-ui/components/toggle/toggle';
import Text from '@jetbrains/ring-ui/components/text/text';
import checkmarkIcon from '@jetbrains/icons/checkmark';
import styles from './toolbox-setting-field.css';
const SETTING_OPTIONS = [
    {
        key: 'default',
        label: 'Not specified',
        selectedLabel: 'Not specified',
        forced: null
    },
    {
        key: 'not-forced',
        label: 'User can edit setting',
        selectedLabel: 'Editable',
        forced: false
    },
    {
        key: 'forced',
        label: 'User can only view setting',
        selectedLabel: 'Read only',
        forced: true
    }
];
export function ToolboxSettingField({ label, setting, onSettingChange }) {
    var _a;
    const selectedSettingOption = (_a = SETTING_OPTIONS.find(option => { var _a; return ((_a = setting === null || setting === void 0 ? void 0 : setting.forced) !== null && _a !== void 0 ? _a : null) === option.forced; })) !== null && _a !== void 0 ? _a : SETTING_OPTIONS[0];
    const settingOptions = SETTING_OPTIONS.map(option => option.key === selectedSettingOption.key
        ? {
            ...option,
            rightGlyph: checkmarkIcon
        }
        : option);
    return (_jsxs("div", { className: styles.settingControls, children: [_jsx("div", { className: styles.toggle, children: !setting ? (_jsx(Text, { children: label })) : (_jsx(Toggle, { checked: setting.value, onChange: e => onSettingChange === null || onSettingChange === void 0 ? void 0 : onSettingChange({
                        ...setting,
                        value: e.target.checked
                    }), children: label })) }), _jsx(Select, { minWidth: 270, className: styles.grayAnchor, type: Select.Type.INLINE, selectedLabel: selectedSettingOption.selectedLabel, onSelect: newSettingOption => {
                    var _a;
                    if (!newSettingOption) {
                        return;
                    }
                    const newSetting = newSettingOption.forced == null
                        ? null
                        : {
                            forced: newSettingOption.forced,
                            value: (_a = setting === null || setting === void 0 ? void 0 : setting.value) !== null && _a !== void 0 ? _a : true
                        };
                    onSettingChange === null || onSettingChange === void 0 ? void 0 : onSettingChange(newSetting);
                }, selected: selectedSettingOption, data: settingOptions })] }));
}
