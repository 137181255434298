import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSetting, updateDisabledPlugin } from '@app/store/profiles/actions';
import { getDisabledPlugins } from '@app/store/profiles/selectors';
import { ControlledModal } from '@components/modals';
import { SettingsList } from '../../common/settings-list';
import SettingsListEntry from '../../common/settings-list-entry';
import { SettingPlaceholder } from '../../common/settings-placeholder';
import { useSettingsContext } from '../../context/settings-context';
import { showFilteredSettings, showHiddenSettingsPlaceholder, showNoSettingsPlaceholder } from '../../functions/settings';
import { filterByFocusedApp, useProfilesParams } from '../../context/profiles-params-context';
import { DisabledPluginsDialogForm } from './disabled-plugins-dialog-form';
export function DisabledPlugins() {
    const dispatch = useDispatch();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const plugins = useSelector(getDisabledPlugins);
    const { focusedApp } = useProfilesParams();
    const filteredPlugins = filterByFocusedApp(plugins, focusedApp);
    const { setFormState, showForm, resetFormState, settingId } = useSettingsContext('disabled');
    const handleDeleteClick = (settingId) => {
        dispatch(deleteSetting({ settingType: 'disabled', settingId }));
    };
    const submitContentForm = useCallback((values) => {
        dispatch(updateDisabledPlugin(values));
        setIsCollapsed(false);
        resetFormState();
    }, [resetFormState, dispatch, setIsCollapsed]);
    const handleOnCloseDalogAttempt = useCallback((values) => {
        if (values) {
            submitContentForm(values);
        }
        else {
            resetFormState();
        }
    }, [submitContentForm, resetFormState]);
    return (_jsxs(_Fragment, { children: [_jsxs(SettingsList, { title: "disabled plugins", isCollapsed: isCollapsed, onToggleCollapsed: () => setIsCollapsed(prev => !prev), children: [showNoSettingsPlaceholder(plugins) && (_jsx(SettingPlaceholder, { title: "No disabled plugins yet" })), showHiddenSettingsPlaceholder(plugins, filteredPlugins) && (_jsx(SettingPlaceholder, { title: plugins.length === 1 ? 'One plugin is hidden' : `${plugins.length} plugins are hidden` })), showFilteredSettings(filteredPlugins) &&
                        filteredPlugins.map(entry => (_jsx(SettingsListEntry, { entry: entry, onEditClick: setFormState, onDeleteClick: handleDeleteClick, settingType: "disabled" }, entry.id)))] }), showForm && (_jsx(ControlledModal, { component: DisabledPluginsDialogForm, dialogProps: { item: plugins.find(({ id }) => id === settingId) }, onCloseAttempt: handleOnCloseDalogAttempt }))] }));
}
