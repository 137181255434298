import { jsx as _jsx } from "react/jsx-runtime";
import TagsInput from '@jetbrains/ring-ui/components/tags-input/tags-input';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import RingField from '@components/form/ring-field';
import { buildBuildOptions } from '../filter-build-options';
function BuildField({ allProducts }) {
    const { setValue, watch } = useFormContext();
    const products = watch('products');
    const builds = watch('builds');
    const buildOptions = buildBuildOptions(allProducts, products !== null && products !== void 0 ? products : [], builds !== null && builds !== void 0 ? builds : [], build => !build.match(/^\d{3}\./));
    return (_jsx(RingField, { fieldId: "buildsField", label: "Build", "data-test": "application-filter-build", children: _jsx(TagsInput, { id: "buildsField", placeholder: isEmpty(builds) ? 'All' : '', dataSource: () => buildOptions, tags: buildOptions === null || buildOptions === void 0 ? void 0 : buildOptions.filter(option => builds === null || builds === void 0 ? void 0 : builds.includes(option.key)), filter: true, allowAddNewTags: true, onAddTag: ({ tag }) => setValue('builds', builds ? [...builds, tag.key] : [tag.key]), onRemoveTag: ({ tag }) => builds &&
                setValue('builds', builds.filter(item => item !== tag.key)) }) }));
}
export default BuildField;
