import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import classNames from 'classnames';
import { OperatingSystems } from './operating-systems';
export function GenerateLinkButton(props) {
    const { operatingSystem, onRefreshTokenClick, className, isFirstGenerate } = props;
    const generateText = useMemo(() => {
        switch (operatingSystem) {
            case OperatingSystems.WINDOWS:
                return 'Create JetBrains Client Link';
            default:
                return isFirstGenerate ? 'Generate Download Command' : 'Refresh Command';
        }
    }, [isFirstGenerate, operatingSystem]);
    return (_jsx(Button, { className: classNames(className), onClick: onRefreshTokenClick, children: generateText }));
}
