import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useQuery } from '@tanstack/react-query';
import infoIcon from '@jetbrains/icons/info';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { format } from 'date-fns';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Size as IconSize } from '@jetbrains/ring-ui/components/icon/icon__constants';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { licenseHistory } from '@api/tbe-license';
import { formatApiError } from '@api/errors';
import { getQueryAlertMessages } from '@app/queries/formatters/alerts';
import { FullWidthSelectableTable } from '@components/tables/full-width-selectable-table';
import NavigationLink from '@components/navigation/navigation-link';
import CopiedText from '@components/clipboard/copied-text/copied-text';
import styles from './license-history-body.css';
const currentYear = new Date().getFullYear();
export function useGetLicenseEvents(params) {
    return useQuery({
        queryFn: () => licenseHistory(params),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load License events data'))
    });
}
function LicenseHistoryBody({ params, onParamChange }) {
    var _a;
    const { data, isLoading, isFetching } = useGetLicenseEvents({
        ...params
    });
    const events = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.items) !== null && _a !== void 0 ? _a : []; }, [data]);
    const total = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0; }, [data]);
    const columns = useMemo(() => [
        {
            id: 'timestamp',
            title: 'Date',
            className: styles.dateColumn,
            getValue: ({ timestamp }) => {
                const date = new Date(timestamp);
                return format(date, date.getFullYear() === currentYear ? 'd MMM HH:mm' : 'd MMM yyyy HH:mm');
            }
        },
        {
            id: 'event',
            title: 'Event',
            getValue: ({ action, licenseId, message, author }) => {
                const isSystemAuthor = author.id.split('-').length < 5;
                return (_jsxs("div", { children: ["License ", _jsx("strong", { children: licenseId }), " was ", action.toLowerCase(), " by", ' ', isSystemAuthor ? (_jsx("strong", { children: author.username })) : (_jsx(NavigationLink, { href: `/users/${author.id}`, children: author.username })), ' ', message && (_jsx(Tooltip, { title: `Reason: ${message}`, children: _jsx(Button, { icon: infoIcon, iconSize: IconSize.Size14 }) }))] }));
            }
        },
        {
            id: 'checksumInfo',
            title: 'Checksum',
            getValue: ({ checksumAlgorithm, checksum }) => {
                return (_jsxs("div", { children: [_jsx(Text, { info: true, size: "s", children: checksumAlgorithm }), ' ', _jsx(CopiedText, { text: checksum })] }));
            }
        }
    ], []);
    return (_jsx(FullWidthSelectableTable, { data: events, columns: columns, selectable: false, loading: isLoading, renderEmpty: () => (isFetching ? 'Loading...' : 'No events found'), pagerProps: {
            total,
            currentPage: (_a = params.page) !== null && _a !== void 0 ? _a : 1,
            pageSize: DEFAULT_TABLE_PAGE_SIZE,
            onPageChange: newPage => {
                onParamChange({ ...params, page: newPage });
            },
            disablePageSizeSelector: true
        } }));
}
export default LicenseHistoryBody;
