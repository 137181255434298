import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@jetbrains/ring-ui/components/button/button';
import { noop } from 'lodash';
import auth from '@components/auth/auth';
import { usePatchUserMutation, useRevokeUserToken, useUser } from '../../queries/users';
import { UserApplications } from './applications-tab/user-applications';
import { toUserFormValues, toUserPatch } from './utils';
import { UserEditForm } from './user-edit-form';
import styles from './user-edit-page.css';
function UserEditPage() {
    const { id } = useParams();
    const { data: user } = useUser(id);
    const hasSamlEnabled = !!auth.getFeatures().enableSaml;
    const mutation = usePatchUserMutation();
    const { mutateAsync: revokeTokenMutation, isLoading: isRevoking } = useRevokeUserToken(id !== null && id !== void 0 ? id : '');
    const onSubmit = async (values) => {
        const patch = toUserPatch(user, values);
        const userId = user === null || user === void 0 ? void 0 : user.id;
        return mutation.mutateAsync({ user: patch, userId });
    };
    const handleRevokeUserClick = useCallback(() => {
        revokeTokenMutation().catch(noop);
    }, [revokeTokenMutation]);
    return (_jsx("div", { className: styles.pageContainer, children: user && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.leftContainer, children: _jsx(UserEditForm, { userData: toUserFormValues(user), machineInfo: user.machineInfo, onSubmit: onSubmit, disabled: false }) }), hasSamlEnabled && (_jsx("div", { className: styles.revokeButton, children: _jsx(Button, { danger: true, onClick: handleRevokeUserClick, loader: isRevoking, children: "Revoke user tokens" }) })), _jsx(UserApplications, { id: id })] })) }));
}
export default UserEditPage;
