// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorPage__ad0 {
  line-height: var(--ring-line-height);
}
`, "",{"version":3,"sources":["webpack://./src/components/errors/error-page.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC","sourcesContent":[".errorPage {\n  line-height: var(--ring-line-height);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorPage": `errorPage__ad0`
};
export default ___CSS_LOADER_EXPORT___;
