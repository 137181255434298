import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import Text from '@jetbrains/ring-ui/components/text/text';
import { formatNumberShortening } from '../../../../components/dates/format';
import NavigationLink from '../../../../components/navigation/navigation-link';
import { makePluginDownloadPath } from '../../../../components/plugins/plugins-paths';
import { useDebounce } from '../../../../hooks/use-debounce-component-value';
import { usePluginDownloads } from '../../../../queries/plugins';
import { countTotalDownloads } from '../../../analytics/plugin-downloads/downloads-for-single-plugin';
import styles from './plugin-downloads-info.css';
export default function PluginDownloads({ pluginId, range }) {
    const dPluginId = useDebounce(pluginId, 500);
    const { data, isFetching } = usePluginDownloads(dPluginId, range);
    const total = formatNumberShortening(countTotalDownloads(data));
    const encodedPluginId = encodeURIComponent(pluginId);
    return (_jsxs("div", { children: [isFetching && _jsx(InlineLoader, {}), !isFetching && (_jsxs("div", { className: styles.downloadsContainer, children: [_jsx(Text, { children: total }), _jsx(NavigationLink, { href: makePluginDownloadPath(encodedPluginId), children: "Details" })] }))] }));
}
