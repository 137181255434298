import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { deleteNotification, updateNotification } from '@app/store/profiles/actions';
import { getNotifications } from '@app/store/profiles/selectors';
import { SettingPlaceholder } from '../common/settings-placeholder';
import { useNotificationsContext } from '../context/notifications-context';
import { filterByFocusedApp, useProfilesParams } from '../context/profiles-params-context';
import { NotificationListEntry } from './notification-list-entry';
import { NotificationForm } from './notification-dialog-form';
export function NotificationsList() {
    const notifications = useSelector(getNotifications);
    const { focusedApp } = useProfilesParams();
    const filteredNotifications = filterByFocusedApp(notifications, focusedApp);
    const dispatch = useDispatch();
    const { setContentFormState, showContentForm, resetFormState, ruleId } = useNotificationsContext();
    const deleteEntry = (id) => {
        dispatch(deleteNotification(id));
    };
    const submit = (values) => {
        dispatch(updateNotification(values));
        resetFormState();
    };
    return (_jsxs(_Fragment, { children: [isEmpty(notifications) && _jsx(SettingPlaceholder, { title: "No restrictions yet" }), !isEmpty(notifications) && isEmpty(filteredNotifications) && (_jsx(SettingPlaceholder, { title: notifications.length === 1
                    ? 'One restriction is hidden'
                    : `${notifications.length} restrictions are hidden` })), !isEmpty(filteredNotifications) &&
                filteredNotifications.map(entry => (_jsx(NotificationListEntry, { entry: entry, onEditClick: setContentFormState, onDeleteClick: deleteEntry }, entry.id))), showContentForm && (_jsx(NotificationForm, { onReject: resetFormState, id: ruleId, onSubmit: submit }))] }));
}
