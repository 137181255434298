import { isNil } from 'lodash';
import * as z from 'zod';
import { MARKETPLACE_SOURCE, TBE_SOURCE } from '@components/plugins/ide-plugins-types';
import { tSettingOptionSchema } from './setting-option-schema';
export const tSingleAutoInstalledPluginSchema = (isRestricted, restrictedPlugins) => {
    return z.object({
        pluginId: isRestricted
            ? z
                .string()
                .min(1, 'Please set plugin ID')
                .refine(val => restrictedPlugins === null || restrictedPlugins === void 0 ? void 0 : restrictedPlugins.includes(val), {
                message: 'This plugin is not allowed in Restricted Mode'
            })
            : z.string().min(1, 'Please set plugin ID'),
        description: z
            .string()
            .transform(x => x !== null && x !== void 0 ? x : '')
            .optional()
            .nullable(),
        forced: z.boolean(),
        source: z.union([z.literal(TBE_SOURCE), z.literal(MARKETPLACE_SOURCE)]),
        version: z
            .string()
            .optional()
            .nullable()
            .transform(x => x || null),
        channel: z
            .object({
            id: z.string().optional().nullable(),
            name: z.string().optional().nullable()
        })
            .optional()
            .nullable()
            .transform(x => { var _a; return (isNil(x === null || x === void 0 ? void 0 : x.id) || ((_a = x === null || x === void 0 ? void 0 : x.name) === null || _a === void 0 ? void 0 : _a.trim().length) === 0 ? null : x); })
    });
};
export const tAutoInstalledPluginsSchema = (isRestricted, restrictedPlugins) => tSettingOptionSchema.extend({
    content: z
        .array(tSingleAutoInstalledPluginSchema(isRestricted, restrictedPlugins))
        .min(1, { message: 'Please add Plugin' })
});
