import sniffer from '@jetbrains/ring-ui/components/global/sniffer';
export var OperatingSystems;
(function (OperatingSystems) {
    OperatingSystems["WINDOWS"] = "windows";
    OperatingSystems["MACOS"] = "macos";
    OperatingSystems["OTHER"] = "other";
})(OperatingSystems || (OperatingSystems = {}));
export function getCurrentOs() {
    const osName = sniffer.os.name;
    if (osName.indexOf(OperatingSystems.WINDOWS) > -1) {
        return OperatingSystems.WINDOWS;
    }
    if (osName === OperatingSystems.MACOS) {
        return OperatingSystems.MACOS;
    }
    return OperatingSystems.OTHER;
}
