import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import RegisteredInput from '@components/hook-form/registered-input';
import { Required } from '@components/form/important-asterisk';
function SetAttributeFields({ settingIndex, specsIndex }) {
    const { control } = useFormContext();
    return (_jsxs(_Fragment, { children: [_jsx(RegisteredInput, { label: Required('Name'), size: Size.FULL, name: `content.${settingIndex}.specs.${specsIndex}.name`, control: control, autoComplete: "off" }), _jsx(RegisteredInput, { label: "Value", size: Size.FULL, name: `content.${settingIndex}.specs.${specsIndex}.value`, control: control, autoComplete: "off" })] }));
}
export default SetAttributeFields;
