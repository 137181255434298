// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row__f8d {
  padding-bottom: var(--ring-unit);
}

.key__bd8 {
  display: inline-block;
  width: calc(var(--ring-unit) * 20);
  vertical-align: top;
  font-weight: bold;
}

.value__f74 {
  display: inline-block;
  clear: both;
}
`, "",{"version":3,"sources":["webpack://./src/components/util/key-value-row.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;EACrB,kCAAkC;EAClC,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".row {\n  padding-bottom: var(--ring-unit);\n}\n\n.key {\n  display: inline-block;\n  width: calc(var(--ring-unit) * 20);\n  vertical-align: top;\n  font-weight: bold;\n}\n\n.value {\n  display: inline-block;\n  clear: both;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `row__f8d`,
	"key": `key__bd8`,
	"value": `value__f74`
};
export default ___CSS_LOADER_EXPORT___;
