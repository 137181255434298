import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { NumberParam } from 'serialize-query-params';
import LicenseHistoryBody from '@app/pages/configuration-page/license/license-history/license-history-body';
import { useSyncedSearchArgs } from '@components/navigation/use-synced-search-args';
import { PageTitle } from '@components/page-layout/page-title';
const tsTypeToQuerySchema = {
    page: NumberParam
};
function validateSessionsFilter(parsed) {
    return {
        page: parsed.page && parsed.page > 0 ? parsed.page : 1
    };
}
export function LicenseHistoryPage() {
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema, validateSessionsFilter);
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { parts: [
                    { title: 'Configuration' },
                    { title: 'License & Activation', href: '/configuration/license-activation' },
                    { title: 'License History' }
                ] }), _jsx(LicenseHistoryBody, { params: { ...urlParams }, onParamChange: setUrlParams })] }));
}
