export function toUserFormValues(user) {
    var _a, _b, _c;
    return {
        subject: (_a = user.subject) !== null && _a !== void 0 ? _a : '',
        username: user.username,
        email: (_b = user.email) !== null && _b !== void 0 ? _b : '',
        role: user.role,
        isActive: user.isActive,
        profiles: (_c = user.profiles) !== null && _c !== void 0 ? _c : []
    };
}
export function toUserPatch(user, formValues) {
    return {
        version: user.version,
        username: formValues.username.trim(),
        email: formValues.email === undefined ? undefined : formValues.email.trim(),
        role: formValues.role,
        isActive: formValues.isActive,
        profiles: formValues.profiles
    };
}
