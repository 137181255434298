import { useMutation, useQuery } from '@tanstack/react-query';
import { createToken, deleteToken, getAutomationTokens, updateToken } from '../api/automation-tokens';
import { formatApiError } from '../api/errors';
import queryClient, { DEFAULT_STALE_TIME } from './query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from './formatters/alerts';
const keys = {
    all: ['automation']
};
export const useUpdateTokenMutation = () => {
    return useMutation({
        meta: getMutationAlertMessages((err, vars) => {
            return formatApiError(err, `Failed to update token '${vars.tokenData.clientId}'`);
        }, () => `Token updated`),
        mutationFn: ({ tokenId, tokenData }) => updateToken(tokenId, tokenData),
        onSuccess: data => {
            const prev = queryClient.getQueryData(keys.all);
            queryClient.setQueryData(keys.all, prev === null || prev === void 0 ? void 0 : prev.map(x => (x.id === data.id ? data : x)));
        }
    });
};
export const useCreateTokenMutation = () => {
    return useMutation({
        meta: getMutationAlertMessages((error, vars) => formatApiError(error, `Failed to create token with an id '${vars.clientId}'`), () => `Token created`),
        mutationFn: (tokenData) => createToken(tokenData),
        onSuccess: async () => {
            await queryClient.invalidateQueries(keys.all);
        }
    });
};
export const useDeleteTokenMutation = () => {
    return useMutation({
        meta: getMutationAlertMessages((error, vars) => {
            return formatApiError(error, `Failed to remove automation token '${vars.clientId}'`);
        }, () => {
            return `Token deleted`;
        }),
        mutationFn: (vars) => deleteToken(vars.tokenId),
        onSuccess: (_, vars) => {
            var _a;
            const prev = queryClient.getQueryData(keys.all);
            queryClient.setQueryData(keys.all, (_a = prev === null || prev === void 0 ? void 0 : prev.filter(x => x.id !== vars.tokenId)) !== null && _a !== void 0 ? _a : []);
        }
    });
};
export const useAutomationTokens = () => {
    return useQuery({
        queryKey: keys.all,
        queryFn: getAutomationTokens,
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load automation token'))
    });
};
