import React, {useState} from 'react';
import ErrorMessage from '@jetbrains/ring-ui/components/error-message/error-message';
import frownIcon from '@jetbrains/icons/frown';
import Button from '@jetbrains/ring-ui/components/button/button';
import warningIcon from '@jetbrains/icons/warning';
import errorStyles from '@jetbrains/ring-ui/components/error-message/error-message.css';
import styles from './browser-precondition.css';

export default function BrowserPrecondition({children}) {
  const [isIgnoreError, setIsIgnoreError] = useState(false);

  if (
    isIgnoreError === false &&
    (window.location.hash.indexOf('assume-insecure') >= 0 || window.isSecureContext !== true)
  ) {
    const secureContextDoc = 'https://w3c.github.io/webappsec-secure-contexts';

    const message =
      'The browser assumes the current context as non-secure, ' +
      'JetBrains IDE Services may not work properly and safely.\n' +
      'Please contact your IT department for details';

    console.warn(
      `` +
        `schema: ${window.location.protocol}\n` +
        `window.isSecureContext !== true\n` +
        `\n\n\n${message}\n\n` +
        `Try the following steps to fix the problem:\n` +
        ` - make sure you are using HTTPS protocol\n` +
        ` - check the browser documentation for security-context at ${secureContextDoc}\n\n\n`
    );

    const setIgnore = () => {
      setIsIgnoreError(true);
    };

    return (
      <div className={styles.container}>
        <ErrorMessage icon={frownIcon} message="Insecure connection detected">
          <div className={errorStyles.description}>
            {message.split('\n').map((t, v) => (
              <p key={v}>{t}</p>
            ))}
            <Button icon={warningIcon} danger={true} onClick={setIgnore}>
              Ignore Error and Continue
            </Button>
          </div>
        </ErrorMessage>
      </div>
    );
  }

  return <>{children}</>;
}
