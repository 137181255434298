// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.twoColumnForm__ff5 {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: calc(var(--ring-unit) * 2.5);
}

.twoColumnForm__ff5 > :nth-child(odd) {
  white-space: nowrap;
  color: var(--ring-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/page-layout/two-column-form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sCAAsC;EACtC,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,mBAAmB;EACnB,kCAAkC;AACpC","sourcesContent":[".twoColumnForm {\n  display: grid;\n  grid-template-columns: min-content 1fr;\n  align-items: center;\n  gap: calc(var(--ring-unit) * 2.5);\n}\n\n.twoColumnForm > :nth-child(odd) {\n  white-space: nowrap;\n  color: var(--ring-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"twoColumnForm": `twoColumnForm__ff5`
};
export default ___CSS_LOADER_EXPORT___;
