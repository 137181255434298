import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import ButtonSet from '@jetbrains/ring-ui/components/button-set/button-set';
import Button from '@jetbrains/ring-ui/components/button/button';
import { InformationBanner } from '../../components/information-banner/information-banner';
export function UserEditPageBanner({ userName, show, isSubmitting, onSubmit, onDiscard }) {
    if (!show) {
        return null;
    }
    return (_jsx(InformationBanner, { buttons: _jsxs(ButtonSet, { children: [_jsx(Button, { onClick: onDiscard, loader: isSubmitting, children: "Discard" }), _jsx(Button, { onClick: onSubmit, loader: isSubmitting, primary: true, children: "Save changes" })] }), children: _jsxs(Text, { children: ["User \"", userName, "\" was updated. Make sure to save the changes once you've completed updating the user details."] }) }));
}
