import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import auth from '../../auth/auth';
import AuthorizedHeader from './authorized-header';
import UnauthorizedHeader from './unauthorized-header';
import styles from './app-header.css';
export function AppHeader({ loading }) {
    // hack to force re-render on location change
    // need to refactor to a listening of auth state change
    // eslint-disable-next-line no-void
    void useLocation();
    const isUserAuthenticated = auth.isAuthenticated();
    return (_jsxs("div", { className: styles.appHeaderWrapper, children: [isUserAuthenticated ? _jsx(AuthorizedHeader, {}) : _jsx(UnauthorizedHeader, {}), _jsx("div", { className: styles.headerLoader, children: loading && _jsx("div", { className: styles.headerLoaderInner, "data-test": "header-loader" }) })] }));
}
