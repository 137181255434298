// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button__ac3 {
  margin-top: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/license-vault/license-vault-disabled/license-vault-disabled.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B","sourcesContent":[".button {\n  margin-top: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button__ac3`
};
export default ___CSS_LOADER_EXPORT___;
