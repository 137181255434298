// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.licenseBlock__fcf + .licenseBlock__fcf {
  margin-top: calc(var(--ring-unit) * 3);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/license-activation-page.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC","sourcesContent":[".licenseBlock + .licenseBlock {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"licenseBlock": `licenseBlock__fcf`
};
export default ___CSS_LOADER_EXPORT___;
