import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@components/page-layout/page-title';
import ProfileCreatePopup from './profile-create-popup';
import { ProfilesAdminBody } from './profiles-admin-body';
export default function ProfilesAdminPage() {
    const navigate = useNavigate();
    const [createProfilePopupVisible, setCreateProfilePopupVisible] = useState(false);
    const handleConfirm = (profileId) => {
        setCreateProfilePopupVisible(false);
        navigate(`/profiles/${profileId}`);
    };
    return (_jsxs("div", { children: [_jsx(PageTitle, { parts: [{ title: 'Profiles' }], rightPanel: _jsx(Button, { "data-test": "create-profile", onClick: () => setCreateProfilePopupVisible(true), children: "Create profile" }) }), _jsx(ProfilesAdminBody, {}), createProfilePopupVisible && (_jsx(ProfileCreatePopup, { onConfirm: handleConfirm, onReject: () => setCreateProfilePopupVisible(false) }))] }));
}
