import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDialogCancelConfirmation } from '@app/hooks/use-dialog-cancel-confirmation';
import { tAutoinstalledToolsSchema } from '../../schemas/autoinstalled-tools-schema';
import { getEmptyAutoinstalledTool } from '../../schemas/ctors';
import SettingFiltersField from '../../common/setting-filters-field';
import styles from './profiles-autoinstalled-tools-form.css';
export function AutoinstalledToolsDialogForm({ item, onReject, onSubmit }) {
    const form = useForm({
        defaultValues: item !== null && item !== void 0 ? item : getEmptyAutoinstalledTool(),
        resolver: zodResolver(tAutoinstalledToolsSchema)
    });
    const { formState: { isDirty, isSubmitting }, handleSubmit } = form;
    const [isFetchingTools, setIsFetchingTools] = useState(false);
    const canSubmit = isDirty && !isFetchingTools;
    const submit = async (formData) => {
        onSubmit(formData);
    };
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onReject, isDirty);
    return (_jsxs(Dialog, { show: true, autoFocusFirst: false, onCloseAttempt: onCloseAttempt, className: styles.dialogContainer, children: [_jsx(Header, { children: "Configure Auto-Installed Tools" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsx("form", { onSubmit: handleSubmit(submit), className: styles.formContainer, children: _jsx(SettingFiltersField, { onIsFetchingChange: setIsFetchingTools }) }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(submit), type: "submit", loader: isSubmitting, disabled: !canSubmit, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: onReject, children: "Cancel" })] })] }));
}
