import { getServiceClient } from '../components/auth/auth';
import { setIdeAnalyticsFetchError, setIdeAnalyticsInfo, setIdeAnalyticsIsFetching } from '../store/ide-analytics';
export async function getIdeAnalytics(period, dispatch) {
    try {
        dispatch(setIdeAnalyticsIsFetching(true));
        const { data } = await getServiceClient().get(`/api/usage-analytics/ide-by-period/${period}`);
        dispatch(setIdeAnalyticsInfo(data));
    }
    catch (e) {
        dispatch(setIdeAnalyticsFetchError(e));
    }
}
