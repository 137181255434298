import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import SearchFilter from '@components/search-filter/search-filter';
import { GroupsAndUsersList } from './groups-and-users-list';
import styles from './assign-user-dialog.css';
function AssignUserDialog({ profileName, profileId, userIds, onReject }) {
    const [query, setQuery] = useState('');
    return (_jsxs(Dialog, { show: true, showCloseButton: true, closeButtonInside: true, autoFocusFirst: false, onCloseAttempt: onReject, contentClassName: styles.dialogContainer, children: [_jsxs(Header, { title: `Assign ${profileName}`, children: ["Assign ", profileName] }), _jsxs(Content, { children: [_jsx(SearchFilter, { autoFocus: true, value: query !== null && query !== void 0 ? query : '', onChange: setQuery, size: Size.M, placeholder: "Search by user or group name" }), _jsx(GroupsAndUsersList, { query: query, profileId: profileId, profileName: profileName, userIds: userIds }, userIds.length)] })] }));
}
export default AssignUserDialog;
