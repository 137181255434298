// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eventContainer__c35 {
  display: flex;
  gap: var(--ring-unit);
}

.dateText__e0c {
  width: calc(var(--ring-unit) * 11);
  min-width: calc(var(--ring-unit) * 11);
  margin-top: 4px;
}

.eventsSeparator__ef5 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
}

.container__ddf {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 3);
  margin-bottom: calc(var(--ring-unit) * 3);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-history/profile-history-page-body.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,kCAAkC;EAClC,sCAAsC;EACtC,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,yCAAyC;AAC3C","sourcesContent":[".eventContainer {\n  display: flex;\n  gap: var(--ring-unit);\n}\n\n.dateText {\n  width: calc(var(--ring-unit) * 11);\n  min-width: calc(var(--ring-unit) * 11);\n  margin-top: 4px;\n}\n\n.eventsSeparator {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 3);\n  margin-bottom: calc(var(--ring-unit) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventContainer": `eventContainer__c35`,
	"dateText": `dateText__e0c`,
	"eventsSeparator": `eventsSeparator__ef5`,
	"container": `container__ddf`
};
export default ___CSS_LOADER_EXPORT___;
