// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggleContainer__c27 {
  display: flex;
  gap: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/form-fields/deactivate-user-field.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".toggleContainer {\n  display: flex;\n  gap: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleContainer": `toggleContainer__c27`
};
export default ___CSS_LOADER_EXPORT___;
