import format from 'xml-formatter';
import { CODESTYLE_XML_KIND, CodeStyleSetting, InspectionProfileSetting, Metadata, PERSISTENT_STATE_XPATH_KIND, PersistentStateSettingXpathKind, PropertiesComponentSetting, RegistrySetting, KeyValueListSetting, CustomKindSetting } from './settings-builders';
export function formatXml(xml) {
    let newXml;
    try {
        newXml = format(xml, {
            lineSeparator: '\n'
        });
    }
    catch (e) {
        newXml = xml;
    }
    return newXml;
}
export function getSettingsTemplates() {
    return [
        PersistentStateSettingXpathKind(),
        RegistrySetting(),
        PropertiesComponentSetting(),
        CodeStyleSetting(),
        InspectionProfileSetting(),
        KeyValueListSetting(),
        CustomKindSetting(),
        PersistentStateSettingXpathKind('com.intellij.openapi.externalSystem.autoimport.ExternalSystemProjectTrackerSettings', [
            {
                action: 'set-attribute',
                xpath: "./option[@name='autoReloadType']",
                name: 'value',
                value: 'NONE'
            }
        ], undefined, Metadata(PERSISTENT_STATE_XPATH_KIND, 'PROJECT', false, 'Disable auto import')),
        PersistentStateSettingXpathKind('com.intellij.ide.plugins.org.PluginManagerConfigurableForOrgConfig', [
            {
                action: 'replace-child',
                xpath: '.',
                'child-xml': '<option name="allowInstallFromDisk" value="true"/>',
                'child-rel-xpath': "./option[@name='allowInstallFromDisk']",
                'keep-attributes': true,
                'keep-name': true,
                'keep-content': true
            },
            {
                action: 'set-attribute',
                xpath: "./option[@name='allowInstallFromDisk']",
                name: 'value',
                value: 'false'
            }
        ], undefined, Metadata(PERSISTENT_STATE_XPATH_KIND, 'APPLICATION', true, 'Disable installing plugins from disk')),
        PersistentStateSettingXpathKind('com.intellij.ide.GeneralSettings', [
            {
                action: 'set-attribute',
                xpath: "./option[@name='confirmExit']",
                name: 'value',
                value: 'false'
            }
        ], undefined, Metadata(PERSISTENT_STATE_XPATH_KIND, 'APPLICATION', true, 'Do not confirm IDE exit')),
        CodeStyleSetting('<code_scheme name="IDE Services" version="173">\n    <codeStyleSettings language="Scala">\n        <indentOptions>\n            <option name="INDENT_SIZE" value="4"/>\n        </indentOptions>\n        <option name="METHOD_PARAMETERS_LPAREN_ON_NEXT_LINE" value="true"/>\n    </codeStyleSettings>\n    <ScalaCodeStyleSettings>\n        <option name="KEEP_ONE_LINE_LAMBDAS_IN_ARG_LIST" value="true"/>\n        <option name="MULTILINE_STRING_CLOSING_QUOTES_ON_NEW_LINE" value="true"/>\n    </ScalaCodeStyleSettings>\n</code_scheme>', Metadata(CODESTYLE_XML_KIND, 'PROJECT', false, 'Example code styles for Scala')),
        PersistentStateSettingXpathKind('com.intellij.ide.GeneralSettings', [
            {
                action: 'set-attribute',
                xpath: "./option[@name='reopenLastProject']",
                name: 'value',
                value: 'false'
            }
        ], undefined, Metadata(PERSISTENT_STATE_XPATH_KIND, 'APPLICATION', true, 'Do not open last project as default')),
        PersistentStateSettingXpathKind('com.intellij.openapi.vcs.VcsConfiguration', [
            {
                action: 'set-attribute',
                xpath: "./option[@name='REFORMAT_BEFORE_PROJECT_COMMIT']",
                name: 'value',
                value: 'true'
            }
        ], undefined, Metadata(PERSISTENT_STATE_XPATH_KIND, 'PROJECT', true, 'Reformat code before commit')),
        // https://github.com/bazelbuild/intellij/blob/master/base/src/com/google/idea/blaze/base/settings/BlazeUserSettings.java
        PersistentStateSettingXpathKind('com.google.idea.blaze.base.settings.BlazeUserSettings', [
            {
                action: 'set-attribute',
                xpath: "./option[@name='showPerformanceWarnings']",
                name: 'value',
                value: 'true'
            }
        ], 'com.google.idea.bazel.ijwb', Metadata(PERSISTENT_STATE_XPATH_KIND, 'APPLICATION', false, 'Show performance warnings in Bazel plugin')),
        // https://github.com/bazelbuild/intellij/blob/master/java/src/com/google/idea/blaze/java/settings/BlazeJavaUserSettings.java
        // TODO: Can't see this on UI and can't test. Probably because it is hidden on Mac: https://github.com/bazelbuild/intellij/blob/d07856ca1ed16b34419e0c76055e46a973a91d8d/java/src/com/google/idea/blaze/java/settings/BlazeJavaUserSettingsConfigurable.java#L5
        PersistentStateSettingXpathKind('com.google.idea.blaze.java.settings.BlazeJavaUserSettings', [
            {
                action: 'set-attribute',
                xpath: "./option[@name='useJarCache']",
                name: 'value',
                value: 'true'
            }
        ], 'com.google.idea.bazel.ijwb', Metadata(PERSISTENT_STATE_XPATH_KIND, 'APPLICATION', false, 'Use local jar cache in Bazel plugin')),
        PersistentStateSettingXpathKind('com.google.idea.blaze.base.settings.BlazeUserSettings', [
            {
                action: 'set-attribute',
                xpath: "./option[@name='bazelBinaryPath']",
                name: 'value',
                value: ''
            }
        ], 'com.google.idea.bazel.ijwb', Metadata(PERSISTENT_STATE_XPATH_KIND, 'APPLICATION', false, 'Set Bazel executable path')),
        PersistentStateSettingXpathKind('com.intellij.ide.impl.TrustedPaths', [
            {
                action: 'replace-child',
                xpath: "./option[@name='TRUSTED_PROJECT_PATHS']",
                'child-xml': '<map></map>',
                'child-rel-xpath': './map',
                'keep-attributes': true,
                'keep-name': true,
                'keep-content': true
            },
            {
                action: 'replace-child',
                xpath: "./option[@name='TRUSTED_PROJECT_PATHS']/map",
                'child-xml': '<entry key="$USER_HOME$/relative/path/to/project" value="true" />',
                'keep-attributes': true,
                'keep-name': true,
                'keep-content': true
            }
        ], undefined, Metadata(PERSISTENT_STATE_XPATH_KIND, 'APPLICATION', false, 'Add project to trusted paths'))
    ].map((x) => ('xmlPayload' in x ? { ...x, xmlPayload: formatXml(x.xmlPayload) } : x));
}
