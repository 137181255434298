export const DEFAULT_TABLE_PAGE_SIZE = 50;
export const MIN_LIST_SIZE = 10;
export function getSortOrder(sortOrder) {
    return sortOrder ? 'ASC' : 'DESC';
}
export function sliceToPage(records, pageSize, pageIndex) {
    const rangeStart = pageSize * (pageIndex - 1);
    const rangeEnd = pageSize * pageIndex;
    return records.slice(rangeStart, rangeEnd);
}
export const pagingSortOrder = ['ASC', 'DESC'];
export const booleanSortOrder = ['true', 'false'];
