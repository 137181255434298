import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { evaluateAppFilters, evaluateProfileFilters, evaluateSettingFilters } from '../api/profiles';
import { getApplications, getProfile } from '../store/profiles/selectors';
import { formatApiError } from '../api/errors';
import { DEFAULT_STALE_TIME } from './query-client';
import { getQueryAlertMessages } from './formatters/alerts';
const keys = {
    all: ['redux-profile-evaluations'],
    evaluatedFilters: (profile) => ['redux-profile-evaluations', 'filters', profile],
    evaluatedSettingFilters: (applicationRules, filters) => ['redux-profile-evaluations', 'setting-filters', applicationRules, filters],
    evaluatedAppFilters: (filters) => [
        'redux-profile-evaluations',
        'app-filters',
        filters
    ]
};
export function useEvaluatedReduxProfileFilters(enabled = true) {
    const profile = useSelector(getProfile);
    return useQuery({
        queryKey: keys.evaluatedFilters(profile),
        queryFn: () => evaluateProfileFilters(profile),
        staleTime: DEFAULT_STALE_TIME,
        enabled,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to evaluate profile filters'))
    });
}
export function useEvaluatedSettingFilters(filters) {
    const applications = useSelector(getApplications);
    const applicationRules = applications.rules;
    return useQuery({
        queryKey: keys.evaluatedSettingFilters(applicationRules, filters),
        queryFn: () => evaluateSettingFilters(applicationRules, filters),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to evaluate setting filters'))
    });
}
export function useEvaluatedAppFilters(filters) {
    return useQuery({
        queryKey: keys.evaluatedAppFilters(filters),
        queryFn: () => evaluateAppFilters(filters),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to evaluate app filters'))
    });
}
