import { getServiceClient } from '@components/auth/auth';
import { DEFAULT_TABLE_PAGE_SIZE } from './paging-utils';
function getSortParam(sortKey) {
    switch (sortKey) {
        case 'name':
            return 'NAME';
        case 'createdBy':
            return 'CREATED_BY';
        case 'createdDate':
            return 'CREATED_DATE';
        case 'lastModifiedBy':
            return 'LAST_MODIFIED_BY';
        case 'lastModifiedDate':
            return 'LAST_MODIFIED_DATE';
        default:
            return null;
    }
}
function buildRequestParams(params) {
    const { page, sortKey, sortOrder, limit, query } = params;
    const res = {};
    res.page = page;
    if (query) {
        res['filter-query'] = query;
    }
    res.limit = limit ? String(limit) : String(DEFAULT_TABLE_PAGE_SIZE);
    if (sortKey) {
        res.sort = getSortParam(sortKey);
        res.order = sortOrder;
    }
    return res;
}
export async function getGroups(params) {
    const r = await getServiceClient().get(`/api/groups`, {
        params: params ? buildRequestParams(params) : null
    });
    return r.data;
}
export async function getGroupDetails(groupId) {
    const r = await getServiceClient().get(`/api/groups/${groupId}`);
    return r.data;
}
export async function getUsersForGroups(groupId, params) {
    const r = await getServiceClient().get(`/api/groups/${groupId}/users`, {
        params: params ? buildRequestParams(params) : null
    });
    return r.data;
}
export async function assignProfileToGroup(profileId, groupId) {
    const r = await getServiceClient().put(`/api/groups/${groupId}/profiles/${profileId}`);
    return r.data;
}
export async function getGroupsForProfile(profileId, params) {
    const r = await getServiceClient().get(`/api/profiles/${profileId}/groups`, {
        params: params ? buildRequestParams(params) : null
    });
    return r.data;
}
export async function removeGroupFromProfile(groupId, profileId) {
    await getServiceClient().delete(`/api/groups/${groupId}/profiles/${profileId}`);
}
export async function syncIdpGroups(limit = 10) {
    const r = await getServiceClient().post('api/idp/sync-status', {
        params: { limit }
    });
    return r.data;
}
