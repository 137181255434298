import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Text from '@jetbrains/ring-ui/components/text/text';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import ChannelField from '@app/pages/profiles/profile-details/profile-ide-provisioner/ide-plugins/new-auto-installed-plugins/fields/channel-field';
import { tSingleAutoInstalledPluginSchema } from '../../../schemas/auto-installed-plugins-schema';
import { formatPluginSourceName } from '../../formatters';
import { useUserPluginRestrictedMode } from '../../../hooks/user-restricted-modes';
import VersionField from './version-field';
import ForcedField from './forced-field';
import DescriptionField from './description-field';
import styles from './content-field.css';
export default function EditPluginDialog({ initialValue, onSave, onCancel }) {
    const { isRestricted, restrictedPlugins } = useUserPluginRestrictedMode();
    const form = useForm({
        mode: 'onChange',
        defaultValues: initialValue,
        resolver: zodResolver(tSingleAutoInstalledPluginSchema(isRestricted, restrictedPlugins))
    });
    const onSubmit = form.handleSubmit(onSave);
    const doCancel = useCallback(() => onCancel(), [onCancel]);
    useEffect(() => {
        form.trigger();
    }, [form]);
    return (_jsxs(Dialog, { onEscPress: doCancel, show: true, children: [_jsxs(Header, { children: ["Configure Plugin ", initialValue.pluginId, _jsx(Text, { className: styles.contentFieldDialogSubheader, info: true, children: formatPluginSourceName(initialValue.source) })] }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("div", { className: styles.settingFields, children: [_jsx(VersionField, { isSmartMode: true }), _jsx(DescriptionField, {}), _jsx(ForcedField, {}), _jsx(ChannelField, {})] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { onClick: onSubmit, primary: true, children: "Save" }), _jsx(Button, { onClick: doCancel, children: "Cancel" })] })] }));
}
