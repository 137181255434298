import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { createTextCn } from '@rescui/typography';
import { Button as MarketingButton } from '@rescui/button';
import Logo from '@jetbrains/ring-ui/components/header/logo';
import { isNil } from 'lodash';
import { useCompanyName } from '@app/hooks/use-company-name';
import { useMachineConfig } from '@app/queries/machine-config';
import { AppFooter } from '@components/app-layout';
import auth from '@components/auth/auth';
import { DownloadTbeToolboxApp, ToolboxAppLink, useMachineConfigDownloadButtonProps } from '@components/machine-config';
import { useColorMode, useColorModeValue } from '@components/color-mode-context';
import toolboxAppBannerLight from '../../../assets/images/toolbox-app-banner-light.png';
import toolboxAppBannerDark from '../../../assets/images/toolbox-app-banner-dark.png';
import jbDecorationLogo from '../../../assets/jb-styled-decoration.svg';
import styles from './unauthorized-welcome-page.css';
export function UnauthorizedWelcomePage() {
    const tbaBanner = useColorModeValue(toolboxAppBannerLight, toolboxAppBannerDark);
    const { colorMode: theme } = useColorMode();
    const [startFirstColumnAnimation, setStartFirstColumnAnimation] = useState(false);
    const { data } = useMachineConfig();
    const image = data === null || data === void 0 ? void 0 : data.companyLogoUrl;
    const { companyName, isLoading: companyNameIsLoading } = useCompanyName();
    const rescuiCN = createTextCn(theme);
    const rescuiTextClassNames = rescuiCN('rs-text-2', {
        hardness: 'hard'
    });
    useEffect(() => {
        let subscribed = true;
        setTimeout(() => {
            if (subscribed) {
                setStartFirstColumnAnimation(true);
            }
        }, 100);
        return () => {
            subscribed = false;
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.welcomeBox, children: [_jsxs("div", { className: styles.welcomeBody, children: [_jsx("div", { className: classNames(styles.welcomeBodyColumn, {
                                    [styles.welcomeBodyColumnVisible]: startFirstColumnAnimation
                                }), children: _jsx("div", { children: _jsx("img", { className: classNames(styles.toolboxAppImg, {
                                            [styles.toolboxAppImgDarkTheme]: theme === 'dark'
                                        }), src: tbaBanner, alt: "Toolbox App" }) }) }), _jsxs("div", { className: classNames(styles.welcomeBodyColumn, {
                                    [styles.welcomeBodyColumnVisible]: !companyNameIsLoading
                                }), children: [!isNil(image) && (_jsx("div", { children: _jsx("img", { className: styles.companyNameIcon, src: image, alt: "Company Name", "data-test": "company-logo" }) })), _jsxs("header", { "data-test": "unauthorized-header", className: rescuiCN('rs-h2'), children: ["Join ", companyName] }), _jsxs("div", { className: classNames(styles.textContainer, rescuiTextClassNames), children: ["Join to gain access to tools and settings approved by ", companyName, ".", auth.getFeatures().offlineModeEnabled && (_jsx(DownloadTbeToolboxApp, { className: styles.downloadToolboxApp }))] }), _jsxs("div", { className: rescuiTextClassNames, children: ["Make sure the ", _jsx(ToolboxAppLink, { tooltip: false, className: styles.tbaLink }), " is installed on your computer.", ' ', _jsx(DownloadTbeToolboxApp, { className: classNames(styles.tbaLink, rescuiTextClassNames), icon: false })] }), _jsx("div", { children: _jsx(MarketingButton, { theme: theme, size: "l", "data-test": "join-organization-button", ...useMachineConfigDownloadButtonProps(`Join ${companyName}`) }) })] })] }), _jsx(Logo, { glyph: jbDecorationLogo, className: styles.headerLogo, size: 400 })] }), _jsx(AppFooter, {})] }));
}
