import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown';
import Popup from '@jetbrains/ring-ui/components/popup/popup';
import styles from './enumerable-text-elements.css';
export function EnumerableTextElements({ getValue, getKey, items, maxVisibleElems = 3 }) {
    if (!items.length) {
        return _jsx("span", {});
    }
    if (items.length === 1) {
        return _jsx("span", { children: getValue(items[0]) });
    }
    if (items.length <= maxVisibleElems) {
        return (_jsx(_Fragment, { children: items.map((item, idx) => (_jsxs("span", { children: [getValue(item), idx !== items.length - 1 && ', '] }, getKey(item)))) }));
    }
    const anchor = (_jsx("span", { className: styles.anchor, children: `${items.length - maxVisibleElems + 1} more` }));
    return (_jsxs("span", { children: [items.slice(0, maxVisibleElems - 1).map((item, idx) => (_jsxs("span", { children: [getValue(item), idx !== maxVisibleElems - 2 ? ', ' : ' and '] }, getKey(item)))), _jsx(Dropdown, { anchor: anchor, hoverMode: true, clickMode: false, children: _jsx(Popup, { attached: false, children: _jsx("div", { className: styles.popupContent, children: items.slice(maxVisibleElems - 1).map((item, idx) => {
                            return (_jsxs("span", { children: [getValue(item), idx + maxVisibleElems !== items.length && ', '] }, getKey(item)));
                        }) }) }) })] }));
}
