import axios, { AxiosHeaders } from 'axios';
import jetBrainsLogo from '@jetbrains/logos/jetbrains/apple-touch-icon-120x120.png';
import showAuthDialog from '@jetbrains/ring-ui/components/auth-dialog-service/auth-dialog-service';
import { localStorageService } from '@components/storage';
import { AppGlobal } from '../constants/constants';
import { getHashParams, removeHashParamsFromUrl } from './url-hash';
import withLock from './lock';
import { doRefreshTokens, exchangeCodeForTokens, getUserProfile, redirectToLogin } from './auth-requests';
import { AuthError, LicenseInactiveError, SessionExpiredError, Tokens, UnexpectedServerError } from './auth-models';
import tabStateListener from './tab-state-listener';
const AUTH_LOCK = 'AUTH_LOCK';
const TOKENS_KEY = 'TOKENS_V2';
const LAST_USER_AUTH_ACTION = 'LAST_USER_AUTH_ACTION';
const PAYMENT_REQUIRED_ERROR_KEY = 'PAYMENT_REQUIRED_ERROR';
class Auth {
    constructor() {
        this.baseURL = AppGlobal.BASE_URL || `${window.location.protocol}//${window.location.host}`;
        this.isAuthImplicit = false;
        this.sessionExpired = false;
        this.isInitialized = false;
        this.hadPaymentRequiredError = this.checkPaymentRequiredErrorFlag();
        this.client = this.createClient();
    }
    getBaseURL() {
        return this.baseURL;
    }
    isAuthenticationImplicit() {
        return this.isAuthImplicit;
    }
    async login() {
        if (!this.baseURL) {
            return;
        }
        await redirectToLogin(this.baseURL).then(targetUrl => {
            window.location.href = targetUrl;
        });
    }
    async logout() {
        await withLock(AUTH_LOCK, () => this.doLogout());
        await withLock(AUTH_LOCK, () => {
            localStorageService.set(LAST_USER_AUTH_ACTION, 'logout');
            return Promise.resolve();
        });
    }
    getClient() {
        return this.client;
    }
    hasLicense() {
        var _a;
        return !!((_a = this.userProfile) === null || _a === void 0 ? void 0 : _a.serverActivated) && !this.hadPaymentRequiredError;
    }
    isAuthenticated() {
        return !!this.userProfile;
    }
    isGuest() {
        return this.isInitialized && !this.isAuthenticated();
    }
    getUserProfile() {
        return this.userProfile;
    }
    updateProfile(userProp) {
        if (this.userProfile) {
            this.userProfile = { ...this.userProfile, ...userProp };
        }
    }
    getFeatures() {
        if (this.userProfile == null) {
            return {};
        }
        return this.userProfile.features || {};
    }
    hasRoles(roles = []) {
        return this.userProfile ? roles.includes(this.userProfile.role) : false;
    }
    /** @throws {UnexpectedServerError} */
    async init() {
        await withLock(AUTH_LOCK, async () => {
            try {
                this.isAuthImplicit = await this.tryAuthenticateImplicitly();
                if (!this.isAuthImplicit) {
                    const authCode = getHashParams().code;
                    if (authCode) {
                        removeHashParamsFromUrl();
                        await this.continueLoginFlow(authCode);
                        const tokens = await this.getTokens();
                        if (tokens) {
                            // tokens presented, means manual login was successful
                            localStorageService.set(LAST_USER_AUTH_ACTION, 'login');
                        }
                        else {
                            localStorageService.remove(LAST_USER_AUTH_ACTION);
                        }
                    }
                    await this.loadUserProfile();
                }
                else {
                    console.log('Using implicit authentication mode');
                }
            }
            catch (error) {
                if (error instanceof SessionExpiredError) {
                    await this.markSessionAsExpired();
                    this.userProfile = undefined;
                    await this.doLogout(); // Gracefully log out user.
                }
                else {
                    throw error;
                }
            }
            finally {
                this.isInitialized = true;
            }
        });
        this.setUpStorageListener();
    }
    async tryAuthenticateImplicitly() {
        try {
            await this.makeRequestForUserProfile();
            return true;
        }
        catch (e) {
            if (e instanceof SessionExpiredError) {
                return false;
            }
            throw e;
        }
    }
    getAuthRequestInterceptor() {
        return async (requestConfig) => {
            const tokens = await withLock(AUTH_LOCK, () => this.getTokens());
            const headers = AxiosHeaders.from(requestConfig.headers || {});
            if (tokens) {
                headers.set('Authorization', `${tokens.tokenType} ${tokens.accessToken}`);
            }
            return {
                ...requestConfig,
                headers
            };
        };
    }
    getErrorResponseInterceptor(sessionExpiredErrorCodes = [401], licenceInactiveErrorCodes = [402]) {
        return async (error) => {
            const { response } = error;
            if (sessionExpiredErrorCodes.some(code => code === (response === null || response === void 0 ? void 0 : response.status))) {
                await this.markSessionAsExpired(response === null || response === void 0 ? void 0 : response.statusText);
                return Promise.reject(new SessionExpiredError());
            }
            if (licenceInactiveErrorCodes.some(code => code === (response === null || response === void 0 ? void 0 : response.status))) {
                this.reloadPageInPaymentRequiredMode();
                return Promise.reject(new LicenseInactiveError());
            }
            return Promise.reject(error);
        };
    }
    /** @returns {AxiosInstance} */
    createClient() {
        const client = axios.create({
            baseURL: this.baseURL,
            params: {}
        });
        client.interceptors.request.use(this.getAuthRequestInterceptor());
        client.interceptors.response.use(response => response, this.getErrorResponseInterceptor());
        return client;
    }
    async getTokens() {
        if (this.sessionExpired) {
            throw new SessionExpiredError();
        }
        if (this.isAuthImplicit) {
            return undefined;
        }
        let tokens = this.loadTokensFromStorage();
        if (tokens && tokens.shouldBeRefreshed()) {
            tokens = await this.refreshTokens(tokens);
            this.saveTokensToStorage(tokens);
        }
        return tokens;
    }
    /** Continues login flow - exchanges authorization code from URL for tokens and saves tokens to storage. */
    async continueLoginFlow(authCode) {
        try {
            const response = await exchangeCodeForTokens(this.baseURL, authCode);
            const tokens = Tokens.fromOAuthResponse(response);
            console.log('Auth: Exchanged authorization code for tokens');
            this.saveTokensToStorage(tokens);
        }
        catch (error) {
            if (error instanceof AuthError) {
                console.error('Auth: Failed to exchange code for tokens', error);
                this.showAuthPopover(`Error: ${error.message}`);
            }
            this.saveTokensToStorage(undefined);
        }
    }
    /**
     * @param {Tokens} tokens
     * @returns {Promise<Tokens>}
     * @throws {SessionExpiredError}
     * @throws {UnexpectedServerError}
     */
    async refreshTokens(tokens) {
        try {
            const response = await doRefreshTokens(this.baseURL, tokens.refreshToken);
            const refreshedTokens = Tokens.fromOAuthResponse(response, tokens.refreshToken);
            console.log('Auth: Refreshed tokens');
            return refreshedTokens;
        }
        catch (error) {
            if (error instanceof AuthError) {
                if (error.status === 401 || error.status === 403 || error.status === 400) {
                    console.warn('Auth: Could not refresh tokens - session expired');
                    await this.markSessionAsExpired('Could not refresh tokens - session expired');
                    throw new SessionExpiredError();
                }
                else {
                    console.error('Auth: Failed to refresh tokens', error);
                    this.showAuthPopover(`Failed to refresh tokens: ${error.message}`);
                    throw new UnexpectedServerError(error);
                }
            }
            return undefined;
        }
    }
    /**
     * @throws {SessionExpiredError}
     * @throws {UnexpectedServerError}
     */
    async loadUserProfile() {
        const tokens = await this.getTokens();
        if (tokens != null) {
            await this.makeRequestForUserProfile(tokens.accessToken);
        }
        else {
            this.userProfile = undefined;
        }
    }
    async makeRequestForUserProfile(accessToken) {
        try {
            this.userProfile = await getUserProfile(this.baseURL, accessToken);
            console.log('Auth: Loaded user profile');
        }
        catch (error) {
            if (error instanceof AuthError && (error.status === 401 || error.status === 403)) {
                console.warn('Auth: Could not load user profile - session expired');
                throw new SessionExpiredError();
            }
            else if (error instanceof Error) {
                console.error('Auth: Failed to load user profile', error);
                this.showAuthPopover(`Error: ${error.message}`);
                throw new UnexpectedServerError(error);
            }
        }
    }
    async doLogout() {
        this.saveTokensToStorage();
        this.userProfile = undefined;
        this.sessionExpired = false;
    }
    saveTokensToStorage(tokens) {
        if (tokens == null) {
            localStorageService.remove(TOKENS_KEY);
            console.log('Auth: Removed tokens from local storage');
        }
        else {
            localStorageService.set(TOKENS_KEY, tokens);
            console.log('Auth: Saved tokens to local storage');
        }
    }
    loadTokensFromStorage() {
        const tokensConfig = localStorageService.get(TOKENS_KEY);
        if (tokensConfig) {
            try {
                const tokens = new Tokens(tokensConfig);
                console.debug('Auth: Loaded tokens from local storage');
                return tokens;
            }
            catch {
                console.warn('Auth: Could not load tokens from local storage - broken state');
                localStorageService.remove(TOKENS_KEY);
                return undefined;
            }
        }
        else {
            console.debug('Auth: No tokens found in local storage');
            return undefined;
        }
    }
    /** Starts listening to changes in local storage made by other browser tabs */
    setUpStorageListener() {
        this.unsubscribeOtherTabSessionListener = localStorageService.subscribeFromOtherTabChange(LAST_USER_AUTH_ACTION, async (value) => {
            if (value === 'logout' && this.isAuthenticated()) {
                this.reloadPage();
            }
        });
        this.unsubscribeActivateTabListener = tabStateListener.on(isTabVisible => {
            if (isTabVisible && !this.isAuthenticated()) {
                const lastOperation = localStorageService.get(LAST_USER_AUTH_ACTION);
                if (lastOperation === 'login') {
                    this.reloadPage();
                }
            }
        });
    }
    clearListeners() {
        if (this.unsubscribeOtherTabSessionListener) {
            this.unsubscribeOtherTabSessionListener();
            this.unsubscribeOtherTabSessionListener = undefined;
        }
        if (this.unsubscribeActivateTabListener) {
            this.unsubscribeActivateTabListener();
            this.unsubscribeActivateTabListener = undefined;
        }
    }
    async markSessionAsExpired(expiredSessionMessage) {
        await this.saveTokensToStorage();
        await this.handleExpiredSession(expiredSessionMessage);
    }
    async handleExpiredSession(expiredSessionMessage = 'Session expired') {
        // Tokens expired or were revoked or user logged out in a different tab.
        // We display alert but don't reload the UI because the user may have some unsaved changes.
        console.warn(`Auth: ${expiredSessionMessage}`);
        this.sessionExpired = true;
        this.showAuthPopover(expiredSessionMessage);
    }
    showAuthPopover(errorMessage) {
        showAuthDialog({
            serviceName: 'JetBrains IDE Services',
            serviceImage: jetBrainsLogo,
            errorMessage,
            onConfirm: () => this.login(),
            onCancel: async () => {
                await this.logout();
                window.location.reload();
            }
        });
    }
    checkPaymentRequiredErrorFlag() {
        const flag = localStorageService.get(PAYMENT_REQUIRED_ERROR_KEY);
        localStorageService.remove(PAYMENT_REQUIRED_ERROR_KEY);
        return flag;
    }
    reloadPage() {
        this.clearListeners();
        window.location.reload();
    }
    reloadPageInPaymentRequiredMode() {
        if (!this.hadPaymentRequiredError) {
            localStorageService.set(PAYMENT_REQUIRED_ERROR_KEY, true);
            this.reloadPage();
        }
    }
}
const auth = new Auth();
export default auth;
export function getServiceClient() {
    return auth.getClient();
}
