import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import ErrorBubble from '@jetbrains/ring-ui/components/error-bubble/error-bubble';
import { useDropzone } from 'react-dropzone';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import { formatFileRejections, readFileAsText } from '@components/drop-zone/files-attachment';
import styles from './xml-file-attachment.css';
function FileAttachment({ onFilesAttached = () => { }, dropzoneOptions = {}, disabled = false }) {
    const onDrop = useCallback(async (files, fileRejections) => onFilesAttached(files, fileRejections), [onFilesAttached]);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        disabled,
        ...dropzoneOptions
    });
    return (_jsxs("div", { ...getRootProps(), children: [_jsx("input", { ...getInputProps() }), _jsx(Button, { children: "Choose file" })] }));
}
function XmlFileAttachment({ onFileRead }) {
    const [filesAttachmentError, setFilesAttachmentError] = useState('');
    const [filename, setFilename] = useState('');
    const onFilesAttached = async (files, fileRejections) => {
        if (fileRejections.length > 0) {
            setFilesAttachmentError(formatFileRejections(fileRejections));
        }
        else {
            setFilesAttachmentError('');
            setFilename(files[0].name);
            const content = await readFileAsText(files[0]);
            onFileRead(content);
        }
    };
    return (_jsxs("div", { className: styles.container, children: [_jsx(ErrorBubble, { error: filesAttachmentError, children: _jsx(FileAttachment, { dropzoneOptions: { accept: 'text/xml' }, onFilesAttached: onFilesAttached }) }), _jsx(Text, { info: true, children: filename || 'No file chosen' })] }));
}
export default XmlFileAttachment;
