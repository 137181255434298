import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { noop } from 'lodash';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { FullWidthSelectableTable } from '@components/tables/full-width-selectable-table';
import LoadingError from '../../components/errors/loading-error';
import { useAnalyticsForPlugin } from '../../queries/analytics';
import styles from './plugin-analytics-popup.css';
export default function PluginAnalyticsPopup({ onReject, pluginId, filteringParameters }) {
    const [isAscending, setIsAscending] = useState(false);
    const { data: ascendingAnalyticsEntries, isFetching, isError } = useAnalyticsForPlugin(pluginId, filteringParameters);
    const columns = [
        { id: 'version', title: 'Version', sortable: true },
        { id: 'machinesCount', title: 'Devices', sortable: true }
    ];
    const tableData = useMemo(() => (isAscending ? ascendingAnalyticsEntries : ascendingAnalyticsEntries.slice().reverse()).map(entry => ({
        ...entry,
        id: entry.version
    })), [ascendingAnalyticsEntries, isAscending]);
    return (_jsxs(Dialog, { label: pluginId, show: true, showCloseButton: true, closeButtonInside: true, autoFocusFirst: true, onEscPress: onReject, onCloseAttempt: isFetching ? noop : onReject, contentClassName: styles.dialogContent, children: [_jsx(Header, { children: pluginId }), _jsxs(Content, { fade: false, scrollableWrapperClassName: styles.scrollableWrapper, children: [isError && _jsx(LoadingError, {}), _jsx(FullWidthSelectableTable, { data: tableData, loading: isFetching, columns: columns, selectable: false, onSort: event => setIsAscending(event.order), sortKey: "version", sortOrder: isAscending, renderEmpty: () => 'No data available for selected period' })] })] }));
}
