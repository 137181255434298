// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fieldWrapper__b64 {
  display: inline-flex;
  align-items: center;
}

.pencilBtn__ba5 {
  height: 20px;
  display: flex;
  align-items: center;
}

.inputWrapper__bab {
  display: inline;
}

.inputWrapperInner__a00 {
  margin-left: calc(var(--ring-unit) * -1);
  margin-top: calc(var(--ring-unit) * -1);
  margin-bottom: calc(var(--ring-unit) * -1);

  display: inline-flex;
  align-items: center;

  gap: var(--ring-unit);
}

.input__f99 {
  /*compensate borders*/
  --ring-input-padding-inline: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/components/inline-editable-field/inline-editable-field.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,wCAAwC;EACxC,uCAAuC;EACvC,0CAA0C;;EAE1C,oBAAoB;EACpB,mBAAmB;;EAEnB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,gCAAgC;AAClC","sourcesContent":[".fieldWrapper {\n  display: inline-flex;\n  align-items: center;\n}\n\n.pencilBtn {\n  height: 20px;\n  display: flex;\n  align-items: center;\n}\n\n.inputWrapper {\n  display: inline;\n}\n\n.inputWrapperInner {\n  margin-left: calc(var(--ring-unit) * -1);\n  margin-top: calc(var(--ring-unit) * -1);\n  margin-bottom: calc(var(--ring-unit) * -1);\n\n  display: inline-flex;\n  align-items: center;\n\n  gap: var(--ring-unit);\n}\n\n.input {\n  /*compensate borders*/\n  --ring-input-padding-inline: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldWrapper": `fieldWrapper__b64`,
	"pencilBtn": `pencilBtn__ba5`,
	"inputWrapper": `inputWrapper__bab`,
	"inputWrapperInner": `inputWrapperInner__a00`,
	"input": `input__f99`
};
export default ___CSS_LOADER_EXPORT___;
