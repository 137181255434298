import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Theme, { applyTheme } from '@jetbrains/ring-ui/components/global/theme';
import { localStorageService } from '@components/storage';
const darkMatcher = window.matchMedia('(prefers-color-scheme: dark)');
const STORAGE_ITEM_NAME = 'colorMode';
export const ColorModeContext = createContext({
    colorMode: darkMatcher.matches ? Theme.DARK : Theme.LIGHT,
    setColorMode: () => undefined,
    toggleColorMode: () => undefined
});
/**
 * React hook that reads from `ColorModeProvider` context
 * Returns the color mode and function to toggle it
 */
export function useColorMode() {
    const context = useContext(ColorModeContext);
    if (context === undefined) {
        throw new Error('useColorMode must be used within a ColorModeProvider');
    }
    return context;
}
/**
 * Change value based on color mode.
 *
 * @param light the light mode value
 * @param dark the dark mode value
 *
 * @example
 *
 * ```js
 * const Icon = useColorModeValue(MoonIcon, SunIcon)
 * ```
 */
export function useColorModeValue(light, dark) {
    const { colorMode } = useColorMode();
    return colorMode === 'dark' ? dark : light;
}
export function ColorModeProvider({ children }) {
    const defaultColorMode = darkMatcher.matches ? 'dark' : 'light';
    const [colorMode, rawSetColorMode] = useState(localStorageService.get(STORAGE_ITEM_NAME) || defaultColorMode);
    const setColorMode = useCallback((value) => {
        rawSetColorMode(value);
        localStorageService.set(STORAGE_ITEM_NAME, value);
    }, []);
    const toggleColorMode = useCallback(() => setColorMode(colorMode === 'dark' ? 'light' : 'dark'), [colorMode, setColorMode]);
    const context = useMemo(() => ({ colorMode, toggleColorMode, setColorMode }), [colorMode, toggleColorMode, setColorMode]);
    // noinspection JSCheckFunctionSignatures
    useEffect(() => applyTheme(colorMode === 'dark' ? Theme.DARK : Theme.LIGHT, document.body), [colorMode]);
    return _jsx(ColorModeContext.Provider, { value: context, children: children });
}
