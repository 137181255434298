// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip__dc9 {
  margin-left: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics/operating-systems-analytics.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B","sourcesContent":[".tooltip {\n  margin-left: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `tooltip__dc9`
};
export default ___CSS_LOADER_EXPORT___;
