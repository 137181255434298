import { getServiceClient } from '../components/auth/auth';
import { setPluginsAnalyticsFetchError, setPluginsAnalyticsInfo, setPluginsAnalyticsIsFetching } from '../store/plugins-analytics';
export async function getAnalyticsForAllPlugins(filteringParameters, dispatch) {
    try {
        dispatch(setPluginsAnalyticsIsFetching(true));
        const { data } = await getServiceClient().get(`/api/usage-analytics/plugins/${filteringParameters.pluginType}`, { params: { period: filteringParameters.period } });
        dispatch(setPluginsAnalyticsInfo(data));
    }
    catch (e) {
        dispatch(setPluginsAnalyticsFetchError(e));
    }
}
export async function getAnalyticsForPlugin(pluginId, filteringParameters) {
    const { data } = await getServiceClient().get(`/api/usage-analytics/plugins/${filteringParameters.pluginType}/${encodeURIComponent(pluginId)}`, { params: { period: filteringParameters.period } });
    return (data || []).map(it => ({ id: it.version, ...it }));
}
export async function fetchPluginDownloads(pluginId, period) {
    return getServiceClient()
        .get(`api/usage-analytics/plugins/${encodeURIComponent(pluginId)}/downloads?period=${period}`)
        .then(r => r.data);
}
export async function fetchPluginDownloadsAnalytics(period) {
    return getServiceClient()
        .get(`api/usage-analytics/plugins/downloads?period=${period}`)
        .then(r => ({ entries: r.data }));
}
