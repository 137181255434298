import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../initial-state';
import { resetImmutableProfilesState, setImmutableProfilesState } from './actions';
const immutableProfiles = createSlice({
    initialState,
    name: 'immutableProfiles',
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(setImmutableProfilesState, (state, action) => {
            return action.payload;
        })
            .addCase(resetImmutableProfilesState, () => {
            return initialState;
        });
    }
});
export default immutableProfiles.reducer;
