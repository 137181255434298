import React from 'react';
import {Bar} from 'react-chartjs-2';
import styles from './chart.css';

/**
 * @param {ApiPluginDownloadsAnalytics} analytics
 * @return {JSX.Element}
 * @constructor
 */
function PluginDownloadsAnalyticsChart({analytics}) {
  return (
    <Bar
      className={styles.chartContainer}
      options={{
        scales: {y: {ticks: {stepSize: 1}}}
      }}
      data={{
        labels: analytics.entries.map(x => x.pluginName),
        datasets: [
          {
            data: analytics.entries.map(x => x.downloads),
            backgroundColor: 'rgba(52,166,253,0.38)'
          }
        ]
      }}
    />
  );
}

export default PluginDownloadsAnalyticsChart;
