import { useEffect, useRef } from 'react';
import { useModalsController } from './hooks';
export function ControlledModal(props) {
    const { component, open = true, dialogProps, onCloseAttempt } = props;
    const modalController = useModalsController();
    // just to make sure we will not call stale callback in case if it won't be memoized on upper level
    const lastKnownOnCloseCallback = useRef(onCloseAttempt);
    lastKnownOnCloseCallback.current = onCloseAttempt;
    const openedModal = useRef();
    useEffect(() => {
        var _a, _b;
        if (open && !openedModal.current) {
            openedModal.current = modalController.openModal(component, dialogProps);
            (_a = openedModal.current) === null || _a === void 0 ? void 0 : _a.promise.then(res => {
                if (lastKnownOnCloseCallback.current) {
                    lastKnownOnCloseCallback.current(res);
                }
            });
        }
        else if (open && openedModal.current) {
            openedModal.current.updateProps(dialogProps);
        }
        else {
            (_b = openedModal.current) === null || _b === void 0 ? void 0 : _b.closeModal();
            openedModal.current = undefined;
        }
    }, [open, modalController, component, dialogProps, onCloseAttempt]);
    useEffect(() => {
        return () => { var _a; return (_a = openedModal.current) === null || _a === void 0 ? void 0 : _a.closeModal(); };
    }, []);
    return null;
}
