import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useSelector } from 'react-redux';
import Tag from '@jetbrains/ring-ui/components/tag/tag';
import classNames from 'classnames';
import { getApplications } from '@app/store/profiles/selectors';
import { useProfilesParams } from '../../context/profiles-params-context';
import styles from './filters-panel.css';
function FiltersPanel({ className }) {
    const { focusedApp, clearParams } = useProfilesParams();
    const apps = useSelector(getApplications);
    const focusedAppName = useMemo(() => { var _a; return (_a = apps.content.find(app => app.product === focusedApp.id)) === null || _a === void 0 ? void 0 : _a.productName; }, [apps.content, focusedApp]);
    return (_jsx("div", { className: classNames(styles.wrapper, className), children: focusedAppName ? (_jsxs(_Fragment, { children: [_jsx(Text, { className: styles.filterText, info: true, children: "Selected" }), _jsx("div", { className: styles.tagWrapper, children: _jsx(Tag, { onRemove: clearParams, children: focusedAppName }) })] })) : (_jsx(Text, { className: styles.placeholderText, info: true, children: "Select application to see its settings" })) }));
}
export default FiltersPanel;
