import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Group from '@jetbrains/ring-ui/components/group/group';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import Text from '@jetbrains/ring-ui/components/text/text';
import { noop } from 'lodash';
import { UsersProfileButton } from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/users-profile-button';
import { getProfileSettings } from '@app/store/profiles/selectors';
import { useUnAssignUsersMutation } from '@app/queries/users';
import confirmProfileDeletion from '@app/pages/profiles/profile-details/profile-ide-provisioner/confirm-profile-deletion';
import { useDeleteProfileMutation, useProfileDetails, useUsersByProfileId } from '@app/queries/profiles';
import ProfileEditDialog from '@app/pages/profiles/profile-details/profile-ide-provisioner/profile-edit-dialog';
import { useSynchronizedProfilesState } from '@app/pages/profiles/profile-details/profile-ide-provisioner/hooks/use-profile-synchronization';
import { ProfilesBanner } from '@app/pages/profiles/profile-details/banner/profiles-banner';
import NewlyIncludedAppsBanner from '@app/pages/profiles/profile-details/banner/newly-included-apps-banner';
import TbaBanner from '@app/pages/profiles/profile-details/banner/tba-banner';
import OfflineModeBanner from '@app/pages/profiles/profile-details/banner/offline-mode-banner';
import { useCodeWithMeConstituent } from '@app/queries/code-with-me';
import { useIdeProvisionerConstituent } from '@app/queries/ide-provisioner';
import { useAiConstituent } from '@app/queries/ai-enterprise';
import { getImmutableProfileConstituents } from '@app/store/profiles/immutableProfilesState/selectors';
import { updateAiConstituent, updateCwmConstituent, updateIDEPConstituent } from '@app/store/profiles/actions';
import { PageTitle } from '@components/page-layout/page-title';
import { RouteTab, RouteTabs } from '@components/route-tabs';
import auth from '@components/auth/auth';
import { ProfileDropdownMenu } from './profile-dropdown-menu/profile-dropdown-menu';
import styles from './profile-details-page.css';
export var ProfileTabPath;
(function (ProfileTabPath) {
    ProfileTabPath["IDE_PROVISIONER"] = "provisioner";
    ProfileTabPath["CODE_WITH_ME"] = "cwm";
    ProfileTabPath["AI"] = "ai";
})(ProfileTabPath || (ProfileTabPath = {}));
export function ProfileDetailsPage() {
    var _a, _b;
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    const { title: profileTitle, description } = useSelector(getProfileSettings);
    const constituents = useSelector(getImmutableProfileConstituents);
    const { id } = useParams();
    const unAssignMutation = useUnAssignUsersMutation();
    const deleteMutation = useDeleteProfileMutation();
    const { data: cwmResponse, isLoading: isCwmLoading } = useCodeWithMeConstituent();
    const { data: ideProvisionerResponse, isLoading: isIdePELoading } = useIdeProvisionerConstituent();
    const { data: aiResponse, isLoading: isAiLoading } = useAiConstituent();
    const idePInactive = isIdePELoading ||
        !((ideProvisionerResponse === null || ideProvisionerResponse === void 0 ? void 0 : ideProvisionerResponse.status) === 'enabled' && constituents.idep.isEnabled);
    const cwmInactive = isCwmLoading || !((cwmResponse === null || cwmResponse === void 0 ? void 0 : cwmResponse.status) === 'enabled' && constituents.cwm.isEnabled);
    const aiInactive = isAiLoading || !((aiResponse === null || aiResponse === void 0 ? void 0 : aiResponse.status) === 'enabled' && constituents.ai.isEnabled);
    const enableFetching = !(unAssignMutation.isLoading ||
        unAssignMutation.isSuccess ||
        deleteMutation.isLoading ||
        deleteMutation.isSuccess);
    const { data, isLoading: profileDetailsLoading, error } = useProfileDetails(id, enableFetching);
    const useUsersFetchResponse = useUsersByProfileId(id, undefined, enableFetching);
    const [showProfileDialog, setShowProfileDialog] = useState(false);
    const usersInProfileCount = useMemo(() => { var _a, _b; return (_b = (_a = useUsersFetchResponse.data) === null || _a === void 0 ? void 0 : _a.items.length) !== null && _b !== void 0 ? _b : 0; }, [useUsersFetchResponse]);
    useSynchronizedProfilesState(data);
    const handleDelete = useCallback(async () => {
        var _a;
        if (!usersInProfileCount) {
            return deleteMutation.mutateAsync({ profileId: id, profileTitle }).catch(noop);
        }
        const usersList = ((_a = useUsersFetchResponse.data) === null || _a === void 0 ? void 0 : _a.items) || [];
        return unAssignMutation
            .mutateAsync({ profileId: id, usersList })
            .then(() => deleteMutation.mutateAsync({ profileId: id, profileTitle }))
            .catch(noop);
    }, [
        deleteMutation,
        id,
        profileTitle,
        unAssignMutation,
        (_a = useUsersFetchResponse.data) === null || _a === void 0 ? void 0 : _a.items,
        usersInProfileCount
    ]);
    const handleDeleteProfile = useCallback(() => {
        return confirmProfileDeletion(profileTitle, usersInProfileCount)
            .then(handleDelete)
            .catch(hideConfirm);
    }, [profileTitle, usersInProfileCount, handleDelete]);
    const handleEditProfile = useCallback(() => setShowProfileDialog(true), []);
    const dispatch = useDispatch();
    const handleCwmActivationChange = useCallback((enabled) => dispatch(updateCwmConstituent({ isEnabled: enabled })), [dispatch]);
    const handleIdeProvisionerActivationChange = useCallback((enabled) => dispatch(updateIDEPConstituent({ isEnabled: enabled })), [dispatch]);
    const handleAiActivationChange = useCallback((enabled) => dispatch(updateAiConstituent({ isEnabled: enabled })), [dispatch]);
    if (deleteMutation.isSuccess) {
        return _jsx(Navigate, { to: "/profiles" });
    }
    if (profileDetailsLoading) {
        return _jsx(Loader, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { parts: [{ title: 'Profiles', href: '/profiles' }, { title: profileTitle }], className: styles.pageTitle, rightPanel: _jsxs(Group, { children: [_jsx(UsersProfileButton, { useUsersFetch: useUsersFetchResponse, enabled: enableFetching }), _jsx(ProfileDropdownMenu, { profileId: id, editProfile: handleEditProfile, deleteProfile: handleDeleteProfile, onCwmActivationChange: handleCwmActivationChange, onIdeProvisionerActivationChange: handleIdeProvisionerActivationChange, onAiActivationChange: handleAiActivationChange })] }) }), description && _jsx("div", { className: styles.description, children: description }), _jsxs("div", { className: styles.banners, children: [_jsx(ProfilesBanner, { is404: ((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status) === 404, profilesState: data, allowedRoutes: [
                            '/profiles/:id',
                            `/profiles/:id/${ProfileTabPath.IDE_PROVISIONER}`,
                            `/profiles/:id/${ProfileTabPath.CODE_WITH_ME}`,
                            `/profiles/:id/${ProfileTabPath.AI}`
                        ] }), _jsx(NewlyIncludedAppsBanner, {}), _jsx(TbaBanner, {}), _jsx(OfflineModeBanner, {})] }), _jsxs(RouteTabs, { className: styles.tabs, children: [_jsx(RouteTab, { path: ProfileTabPath.IDE_PROVISIONER, title: _jsx(TabName, { name: "IDE Provisioner", inactive: idePInactive }) }), _jsx(RouteTab, { path: ProfileTabPath.CODE_WITH_ME, title: _jsx(TabName, { name: "Code With Me", inactive: cwmInactive }) }), !offlineModeEnabled && (_jsx(RouteTab, { path: ProfileTabPath.AI, title: _jsx(TabName, { name: "AI Enterprise", inactive: aiInactive }) }))] }), enableFetching && _jsx(Outlet, {}), (deleteMutation.isLoading || unAssignMutation.isLoading) && _jsx(Loader, {}), showProfileDialog && _jsx(ProfileEditDialog, { onReject: () => setShowProfileDialog(false) })] }));
}
function TabName(props) {
    const { name, inactive } = props;
    return (_jsxs(_Fragment, { children: [name, inactive && (_jsx(Text, { info: true, className: styles.inactiveLabel, children: "Disabled" }))] }));
}
