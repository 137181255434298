import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTbeLicense } from '@app/queries/tbe-license';
import auth from '@components/auth/auth';
import { AiEnterpriseConstituent } from './ai-enterprise/ai-enterprise-constituent';
import { CodeWithMeConstituent } from './code-with-me/code-with-me-constituent';
import LicenseActivation from './license-activation/license-activation';
import { IdeProvisionerConstituent } from './ide-provisioner/ide-provisioner-constituent';
import { LicenseVaultConstituent } from './license-vault/license-vault-constituent';
import styles from './license-activation-page.css';
function LicenseActivationPage() {
    const { data } = useTbeLicense();
    const licenseType = data === null || data === void 0 ? void 0 : data.plan;
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    return (_jsxs(_Fragment, { children: [_jsx(LicenseActivation, { className: styles.licenseBlock }), licenseType && (_jsxs(_Fragment, { children: [_jsx(LicenseVaultConstituent, { licenseType: licenseType, className: styles.licenseBlock }), _jsx(IdeProvisionerConstituent, { licenseType: licenseType, className: styles.licenseBlock }), _jsx(CodeWithMeConstituent, { licenseType: licenseType, className: styles.licenseBlock }), !offlineModeEnabled && (_jsx(AiEnterpriseConstituent, { licenseType: licenseType, className: styles.licenseBlock }))] }))] }));
}
export default LicenseActivationPage;
