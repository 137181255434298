import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import Select from '@jetbrains/ring-ui/components/select/select';
import { noop } from 'lodash';
import { Required } from '@components/form/important-asterisk';
const actionOptions = [
    { key: 'replace-text', label: 'Replace text' },
    { key: 'set-attribute', label: 'Set attribute' },
    { key: 'replace-child', label: 'Replace child' },
    { key: 'remove', label: 'Remove' }
];
function ActionField({ settingIndex, specsIndex, onActionUpdate = noop }) {
    const { field: { onBlur, onChange, value } } = useController({
        name: `content.${settingIndex}.specs.${specsIndex}.action`,
        defaultValue: ''
    });
    const selected = actionOptions.find(x => x.key === value);
    return (_jsx(Select, { filter: true, onBlur: onBlur, selectedLabel: Required('Action'), data: actionOptions, selected: selected, size: Select.Size.FULL, onChange: (option) => {
            if (option === null || option === void 0 ? void 0 : option.key) {
                onActionUpdate(option === null || option === void 0 ? void 0 : option.key);
            }
            onChange(option === null || option === void 0 ? void 0 : option.key);
        } }));
}
export default ActionField;
