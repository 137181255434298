import { useMemo } from 'react';
import { isEmpty, isNil } from 'lodash';
import { useTbePlugins } from '@app/queries/plugins';
import { useBundledPlugins } from '@app/queries/bundled-plugins';
import { useDebounce } from '@app/hooks/use-debounce-component-value';
import { useFilteredMarketplacePlugins } from '@components/plugins/marketplace-query-wrappers';
import { useUserPluginRestrictedMode } from '../hooks/user-restricted-modes';
import { toPluginSelectItem } from './formatters';
export function useAllFormattedPluginsSelectOptions(filter, renderSource = true) {
    const debouncedFilter = useDebounce(filter, 500);
    const { isRestricted, restrictedPlugins } = useUserPluginRestrictedMode();
    const { data: tPlugins, isFetching: isFetchingTPlugins, isSuccess: isSuccessTPlugins } = useTbePlugins();
    const { data: bPlugins, isFetching: isFetchingBPlugins, isSuccess: isSuccessBPlugins } = useBundledPlugins();
    const { data: mPlugins, isFetching: isFetchingMPlugins, isSuccess: isSuccessMPlugins } = useFilteredMarketplacePlugins(debouncedFilter, true);
    const isFetching = isFetchingTPlugins || isFetchingBPlugins || isFetchingMPlugins;
    const isSuccess = isSuccessTPlugins || isSuccessBPlugins || isSuccessMPlugins;
    const pluginsData = useMemo(() => {
        var _a;
        // Filter duplicated bundled plugins which exist in marketplace Plugins
        const keys = new Set((_a = mPlugins === null || mPlugins === void 0 ? void 0 : mPlugins.map(x => x.key)) !== null && _a !== void 0 ? _a : []);
        const filteredBPlugins = !isEmpty(mPlugins) && !isEmpty(bPlugins) ? bPlugins.filter(x => !keys.has(x.key)) : bPlugins;
        const plugins = !isNil(tPlugins) && isSuccess
            ? tPlugins
                .concat(filteredBPlugins !== null && filteredBPlugins !== void 0 ? filteredBPlugins : [])
                .concat(mPlugins !== null && mPlugins !== void 0 ? mPlugins : [])
                .map(plugin => toPluginSelectItem(plugin, renderSource))
            : [];
        if (isRestricted) {
            return plugins.filter(({ key }) => restrictedPlugins.includes(key));
        }
        return plugins;
    }, [mPlugins, bPlugins, tPlugins, isSuccess, isRestricted, renderSource, restrictedPlugins]);
    return {
        isFetching,
        isSuccess,
        data: pluginsData
    };
}
