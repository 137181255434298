// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbContainer__a9b {
  display: flex;
  white-space: nowrap;
  font-weight: bold;
}

.container__c06 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: calc(2 * var(--ring-unit));
}

.separator__bb4 {
  display: inline-block;
  padding: 0 var(--ring-unit);
  color: var(--ring-icon-color);
}

/* Copy-pasted from the YouTrack repo: https://jetbrains.team/p/yt/repositories/youtrack/files/981674d6398085021cd49c03da295c92e0ab3c8e/frontend/simplified/src/components/article-breadcrumbs/breadcrumbs.css?tab=source */
.breadcrumb__b9a {
  display: inline-block;
  flex-shrink: 1;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/components/page-layout/breadcrumbs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;EAC3B,6BAA6B;AAC/B;;AAEA,2NAA2N;AAC3N;EACE,qBAAqB;EACrB,cAAc;EACd,uBAAuB;AACzB","sourcesContent":[".breadcrumbContainer {\n  display: flex;\n  white-space: nowrap;\n  font-weight: bold;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-right: calc(2 * var(--ring-unit));\n}\n\n.separator {\n  display: inline-block;\n  padding: 0 var(--ring-unit);\n  color: var(--ring-icon-color);\n}\n\n/* Copy-pasted from the YouTrack repo: https://jetbrains.team/p/yt/repositories/youtrack/files/981674d6398085021cd49c03da295c92e0ab3c8e/frontend/simplified/src/components/article-breadcrumbs/breadcrumbs.css?tab=source */\n.breadcrumb {\n  display: inline-block;\n  flex-shrink: 1;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbContainer": `breadcrumbContainer__a9b`,
	"container": `container__c06`,
	"separator": `separator__bb4`,
	"breadcrumb": `breadcrumb__b9a`
};
export default ___CSS_LOADER_EXPORT___;
