import { jsx as _jsx } from "react/jsx-runtime";
import Checkbox from '@jetbrains/ring-ui/components/checkbox/checkbox';
import { useController } from 'react-hook-form';
function RegisteredCheckbox({ control, name, controllerProps, ...rest }) {
    const { field: { value, onChange, onBlur, name: fieldName, ref } } = useController({
        control,
        name,
        defaultValue: false,
        ...controllerProps
    });
    return (_jsx(Checkbox, { checked: value, onChange: e => onChange(e.target.checked), onBlur: onBlur, id: fieldName, name: fieldName, inputRef: ref, ...rest }));
}
export default RegisteredCheckbox;
