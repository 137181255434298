import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import RegisteredInput from '@components/hook-form/registered-input';
import RegisteredDatePicker from '@components/hook-form/registered-date-picker';
import { Required } from '@components/form/important-asterisk';
import { localDateString } from '@components/dates/format';
export default function AutomationTokenEditFields({ disabled }) {
    const { control } = useFormContext();
    const today = useMemo(localDateString, []);
    return (_jsxs(_Fragment, { children: [_jsx(RegisteredInput, { control: control, disabled: disabled, name: "clientId", label: Required('Token name'), autoComplete: "off" }), _jsx(RegisteredDatePicker, { control: control, name: "expireDate", label: "Expiry date", disabled: disabled, minDate: today })] }));
}
