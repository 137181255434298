import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import Button from '@jetbrains/ring-ui/components/button/button';
import chevronRight from '@jetbrains/icons/chevron-right';
import styles from './collapsible.css';
export function Collapsible({ title, children, className, titleClassName }) {
    const [collapsed, setCollapsed] = useState(true);
    const [expandingAnimation, setStartExpandingAnimation] = useState(!collapsed);
    const toggleCollapse = useCallback(() => {
        if (collapsed) {
            setCollapsed(!collapsed);
            setStartExpandingAnimation(true);
        }
        else {
            setStartExpandingAnimation(false);
            setTimeout(() => setCollapsed(!collapsed), 100);
        }
    }, [collapsed]);
    return (_jsxs("div", { className: className, children: [_jsx(Button, { inline: true, icon: chevronRight, primary: true, onClick: toggleCollapse, className: classNames(styles.title, titleClassName, {
                    [styles.titleExpanded]: expandingAnimation
                }), children: title }), _jsx("div", { className: styles.collapsibleBody, children: _jsx("div", { className: classNames(styles.collapsibleContent, {
                        [styles.collapsibleContentExpanded]: expandingAnimation
                    }), children: !collapsed && children }) })] }));
}
