import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import HorizontalContainer from '@components/page-layout/horizontal-container';
import SearchFilter from '@components/search-filter/search-filter';
import auth from '@components/auth/auth';
import RoleFilter from './role-filter';
import ProfileFilter from './profile-filter';
import ActiveFilter from './active-filter';
import GroupFilter from './group-filter';
import ConstituentFilter from './constituent-filter';
export function UsersFilters({ filters, onFiltersChange }) {
    var _a;
    const handleSearchValueChange = useCallback(newValue => {
        onFiltersChange({ ...filters, query: newValue });
    }, [onFiltersChange, filters]);
    const { idpImportEnabled } = auth.getFeatures();
    return (_jsxs(HorizontalContainer, { children: [_jsx(SearchFilter, { value: filters.query || '', onChange: handleSearchValueChange, placeholder: "Search by name or email" }), _jsx(RoleFilter, { value: filters.role || '', onChange: value => onFiltersChange({ ...filters, role: value !== null && value !== void 0 ? value : null }) }), _jsx(ProfileFilter, { profileId: filters.profileId || '', onChange: value => {
                    onFiltersChange({ ...filters, profileId: value !== null && value !== void 0 ? value : null });
                } }), idpImportEnabled && (_jsx(GroupFilter, { groupId: filters.groupId || '', onChange: value => {
                    onFiltersChange({ ...filters, groupId: value !== null && value !== void 0 ? value : null });
                } })), _jsx(ConstituentFilter, { constituentId: filters.constituentId || '', onChange: value => {
                    onFiltersChange({ ...filters, constituentId: value !== null && value !== void 0 ? value : null });
                } }), _jsx(ActiveFilter, { isActive: (_a = filters.isActive) !== null && _a !== void 0 ? _a : undefined, onChange: value => onFiltersChange({ ...filters, isActive: value !== null && value !== void 0 ? value : null }) })] }));
}
