const SET_PLUGINS_AUDIT_LOG = 'SET_PLUGINS_AUDIT_LOG';
const SET_PLUGINS_AUDIT_LOG_IS_FETCHING = 'SET_PLUGINS_AUDIT_LOG_IS_FETCHING';
const SET_PLUGINS_AUDIT_LOG_FETCH_ERROR = 'SET_PLUGINS_AUDIT_LOG_FETCH_ERROR';
const SET_PLUGINS_AUDIT_LOG_PAGE = 'SET_PLUGINS_AUDIT_LOG_PAGE';
const SET_PLUGINS_AUDIT_LOG_SORT = 'SET_PLUGINS_AUDIT_LOG_SORT';

const defaultState = {
  items: [],
  sortKey: 'createdDate',
  sortOrder: false,
  page: 1,
  totalCount: 0,
  isFetching: true,
  isFetchError: false
};

export default function pluginsAuditLogReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PLUGINS_AUDIT_LOG:
      return {
        ...state,
        items: action.payload,
        page: action.page,
        totalCount: action.totalCount,
        sortKey: action.sortKey,
        sortOrder: action.sortOrder,
        isFetching: false
      };
    case SET_PLUGINS_AUDIT_LOG_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case SET_PLUGINS_AUDIT_LOG_SORT:
      return {
        ...state,
        page: 1,
        sortKey: action.payload,
        sortOrder: action.sortOrder
      };
    case SET_PLUGINS_AUDIT_LOG_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_PLUGINS_AUDIT_LOG_FETCH_ERROR:
      return {
        ...state,
        isFetchError: action.payload
      };
    default:
      return state;
  }
}

export function setAuditLogs(auditLogs, page, sortKey, sortOrder, totalCount) {
  return {
    type: SET_PLUGINS_AUDIT_LOG,
    payload: auditLogs,
    page,
    sortKey,
    sortOrder,
    totalCount
  };
}

export function setIsFetching(bool) {
  return {type: SET_PLUGINS_AUDIT_LOG_IS_FETCHING, payload: bool};
}

export function setFetchError(bool) {
  return {type: SET_PLUGINS_AUDIT_LOG_FETCH_ERROR, payload: bool};
}

export function setCurrentPage(page) {
  return {type: SET_PLUGINS_AUDIT_LOG_PAGE, payload: page};
}

export function setSortKey(sortKey, sortOrder) {
  return {type: SET_PLUGINS_AUDIT_LOG_SORT, payload: sortKey, sortOrder};
}
