import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { isEqual } from 'lodash';
import Text from '@jetbrains/ring-ui/components/text/text';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import Button from '@jetbrains/ring-ui/components/button/button';
import Input from '@jetbrains/ring-ui/components/input/input';
import classNames from 'classnames';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import alertService from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { useDropzone } from 'react-dropzone';
import { useGetAgreementJson, useResetAgreementMutation, useUploadAgreementMutation } from '@app/queries/agreement';
import confirmResetAgreement from '@app/pages/configuration-page/data-collection/confirm-reset-agreement';
import Markdown from '@components/markdown/markdown';
import { RouteLeavingGuard } from '@components/route-leaving-guard/route-leaving-guard';
import { ExternalLinks } from '@components/constants/constants';
import NavigationLink from '@components/navigation/navigation-link';
import styles from './data-collection.css';
export default function AgreementText() {
    const [isEditing, setIsEditing] = useState(false);
    const [editingAgreementText, setEditingAgreementText] = useState();
    const { data: agreement, isLoading: isAgreementLoading } = useGetAgreementJson();
    const uploadAgreementMutation = useUploadAgreementMutation();
    const resetAgreementMutation = useResetAgreementMutation();
    const handleEdit = useCallback(() => {
        if (!agreement) {
            return;
        }
        setIsEditing(true);
        setEditingAgreementText(agreement.text);
    }, [agreement]);
    const handleReset = async () => {
        try {
            await confirmResetAgreement();
            setIsEditing(false);
            const response = await resetAgreementMutation.mutateAsync();
            setEditingAgreementText(response.text);
        }
        catch (e) {
            hideConfirm();
        }
    };
    const handleAgreementChange = useCallback((e) => {
        setEditingAgreementText(e.target.value.trim());
    }, []);
    const handleSave = async () => {
        await uploadAgreementMutation.mutateAsync(new File([editingAgreementText !== null && editingAgreementText !== void 0 ? editingAgreementText : ''], 'data-collection-statement', {
            type: 'text/plain'
        }));
        setIsEditing(false);
    };
    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditingAgreementText(undefined);
    };
    const handleFileAccepted = async (acceptedFiles) => {
        await uploadAgreementMutation.mutateAsync(acceptedFiles[0]);
    };
    const handleFileRejected = () => {
        alertService.error('Only TXT and MD files are supported');
    };
    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        onDropAccepted: handleFileAccepted,
        onDropRejected: handleFileRejected,
        multiple: false,
        noClick: true,
        accept: ['text/plain', '.md']
    });
    if (isAgreementLoading || !agreement) {
        return _jsx(Loader, {});
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.container, children: [_jsxs("p", { children: ["The", ' ', _jsx(NavigationLink, { className: styles.transparencyLink, href: "/transparency/ide_details", children: "JetBrains IDE Services standard data collection statement" }), ' ', "is provided below. Developers will see a data collection statement in the Toolbox App. You can use the default data collection statement provided below, edit it online, or upload a custom TXT or MD file."] }), !isEditing ? (_jsxs("div", { className: styles.actionsContainer, children: [_jsxs("p", { children: [_jsx(Button, { onClick: open, text: true, inline: true, children: "Click to browse" }), ' ', "or drag a file here."] }), _jsxs("div", { className: styles.actionButtons, children: [_jsx(Button, { onClick: open, children: "Upload file..." }), _jsx(Button, { onClick: handleEdit, children: "Edit text..." }), _jsx(Button, { onClick: handleReset, disabled: agreement.isDefault, children: "Reset to default" })] })] })) : (_jsxs("div", { className: styles.editContainer, children: [_jsxs("div", { children: [_jsx(Input, { multiline: true, autoFocus: true, placeholder: "Enter text", className: styles.textEditor, defaultValue: editingAgreementText, onChange: handleAgreementChange }), _jsxs(Text, { info: true, children: ["Use", ' ', _jsx(NavigationLink, { href: ExternalLinks.MARKDOWN_SPEC, target: "_blank", children: "Markdown" }), ' ', "for formatting."] })] }), _jsxs("div", { className: styles.actionButtons, children: [_jsx(Button, { onClick: handleSave, primary: true, disabled: !editingAgreementText || isEqual(editingAgreementText, agreement.text), children: "Save changes" }), _jsx(Button, { onClick: handleCancelEdit, children: "Cancel" })] })] })), (agreement.text || isEditing) && (_jsxs("div", { className: classNames(styles.agreementTextWrapper, {
                            [styles.editing]: isEditing
                        }), children: [isEditing && _jsx("p", { className: styles.previewTitle, children: "Preview" }), _jsxs("div", { className: styles.agreementText, ...getRootProps(), children: [isDragActive && (_jsx("div", { className: styles.dragOverlay, children: _jsx(Text, { children: "Drop here to upload" }) })), _jsx(Markdown, { text: isEditing ? editingAgreementText !== null && editingAgreementText !== void 0 ? editingAgreementText : '' : agreement.text })] })] }))] }), _jsx("input", { ...getInputProps() }), _jsx(RouteLeavingGuard, { when: isEditing && !isEqual(editingAgreementText, agreement.text) })] }));
}
