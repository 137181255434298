import { format, parseISO } from 'date-fns';
/**
 * e.x. for given 12635 it returns 1,6k
 * @param {number} num
 * @return {string}
 */
export function formatNumberShortening(num) {
    const res = Math.abs(num) > 999
        ? `${(Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1)}k`
        : `${Math.sign(num) * Math.abs(num)}`;
    return res.replace('.', ',');
}
export function localDateString(date = new Date()) {
    return date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
}
/**
 * Function that changes date string to given format.
 * Used library: date-fns
 * @param {string} date
 * @param {string} dateFormat
 * @return {string}
 */
export function formatDateString(date, dateFormat) {
    const formatedDate = format(parseISO(date), dateFormat);
    return formatedDate;
}
export function millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    const seconds = (millis % 60000) / 1000;
    return `${(minutes > 0 ? `${minutes} min ` : '') + (seconds < 10 ? '0' : '') + seconds.toFixed(0)} s`;
}
