import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo } from 'react';
import { noop } from 'lodash';
/**
 * The initial goal of this context is to store a flag saying whether the machine config was mutated in the
 * React query layer
 */
export const ServerConfigurationContext = createContext({
    onConfigChanged: noop
});
export function useServerConfigurationContext() {
    const context = useContext(ServerConfigurationContext);
    if (context === undefined) {
        throw new Error('useServerConfigurationContext must be used within a ServerConfigurationProvider provider');
    }
    return context;
}
export function ServerConfigurationProvider({ children, onConfigChanged }) {
    const contextValue = useMemo(() => ({ onConfigChanged }), [onConfigChanged]);
    return (_jsx(ServerConfigurationContext.Provider, { value: contextValue, children: children }));
}
