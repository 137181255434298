import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import trashIcon from '@jetbrains/icons/trash';
import { isSettingChanged } from '@app/store/profiles/selectors';
import { SettingLayout } from './setting-layout';
import { SettingsPreview } from './settings-preview';
function SettingsListEntry({ entry, onEditClick, onDeleteClick, settingType }) {
    const { id } = entry;
    const isChanged = useSelector(state => isSettingChanged(state, entry.id, settingType));
    return (_jsx(SettingLayout, { isChanged: isChanged, icons: [
            {
                glyph: trashIcon,
                onClick: () => onDeleteClick(id),
                title: 'Remove setting'
            }
        ], onTileClick: () => onEditClick(id), children: _jsx(SettingsPreview, { settingType: settingType, settings: entry }) }, id));
}
export default SettingsListEntry;
