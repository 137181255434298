import { isNil } from 'lodash';
/**
 * Returns a new array with element added or replaced. elementToAdd is searched by the "id" prop
 * @param elements
 * @param elementToAdd
 */
export function addOrReplace(elements, elementToAdd) {
    const shouldUpdate = elements.map(x => x.id).includes(elementToAdd.id);
    return shouldUpdate
        ? elements.map(x => (x.id === elementToAdd.id ? elementToAdd : x))
        : [...elements, elementToAdd];
}
export function findSetting(state, id, settingType) {
    let arr;
    if (settingType === 'autoInstalled') {
        arr = state.plugins.autoInstalled;
    }
    else if (settingType === 'disabled') {
        arr = state.plugins.disabled;
    }
    else if (settingType === 'ide') {
        arr = state.settings.ide;
    }
    else if (settingType === 'vmOptions') {
        arr = state.settings.vmOptions;
    }
    else if (settingType === 'autoinstalledTools') {
        arr = state.settings.autoinstalledTools;
    }
    else {
        arr = [];
    }
    return arr.find(x => x.id === id);
}
export function findApplicationRule(state, id) {
    return state.applications.rules.find(x => x.id === id);
}
export function getProfileMetadata() {
    return { isProfileStale: false };
}
/**
 * By convention, we split rules into two parts: main rule + rest rules. Main rule contains filters. Rest rules
 * contain notifications
 * @param rules
 */
export function findMainApplicationsRule(rules) {
    return rules.find(x => isNil(x.notification));
}
/**
 * Returns a new profile with filtered profile.applications.rules
 */
export function withoutAppRule(profile, appRule) {
    return {
        ...profile,
        applications: {
            ...profile.applications,
            rules: profile.applications.rules.filter(x => x.id !== appRule)
        }
    };
}
/**
 * Algorithm that iterates over applications and finds elements which were excluded in the
 * "appsWithExcludeBuilds" and became included in the "appsWithIncludeBuilds".
 * @param appsWithExcludeBuilds
 * @param appsWithIncludeBuilds
 */
export function findNewlyIncludedAppRules(appsWithExcludeBuilds, appsWithIncludeBuilds) {
    const key = (product, build) => `${product}${build.build}${build.version}`;
    const keys = new Set(appsWithIncludeBuilds.flatMap(x => x.include.map(y => key(x.product, y))));
    return appsWithExcludeBuilds.reduce((out, excludedApp) => {
        // Calculate keys intersection
        const builds = excludedApp.exclude.filter(x => keys.has(key(excludedApp.product, x)));
        if (builds.length) {
            out.set(excludedApp.product, builds);
        }
        return out;
    }, new Map());
}
