import { getSchemaByKind } from '../../../schemas/ctors';
import { ALL_KINDS, KEY_VALUE_LIST_KIND } from '../settings-builders';
function parseOrNull(value) {
    let parsed;
    try {
        parsed = JSON.parse(value);
    }
    catch (e) {
        parsed = null;
    }
    return parsed;
}
/**
 * Check if there is a 'kind' property in the given object. Also check if this kind property is equal to any of the
 * existing setting kinds
 * @param obj
 */
const hasKind = (obj) => obj.kind;
function hasKindIn(obj, kinds) {
    return 'kind' in obj && kinds.some(y => y === obj.kind);
}
/**
 * Parse raw setting using Zod's schema. It finds schema by setting kind to return correct
 * list of errors in case setting is invalid
 * @param rawSetting
 */
export function parseIdeSetting(rawSetting) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsed = parseOrNull(rawSetting);
    const warnings = [];
    if (parsed === null) {
        return { success: false, errors: ['Invalid json'] };
    }
    if (Array.isArray(parsed)) {
        return { success: false, errors: ['Expected a single object, actual: array'] };
    }
    if (!hasKind(parsed)) {
        return { success: false, errors: ['Kind should be specified'] };
    }
    if (!hasKindIn(parsed, ALL_KINDS)) {
        warnings.push('Settings of custom kind are not provisioned by default');
    }
    if (hasKindIn(parsed, [KEY_VALUE_LIST_KIND])) {
        warnings.push('KEY_VALUE_LIST_KIND works only for IDE versions 2024.1 and later');
    }
    const res = getSchemaByKind(parsed.kind).safeParse(parsed);
    if (res.success) {
        return { success: true, warnings, data: res.data };
    }
    return {
        success: false,
        errors: res.error.errors.map(y => `${y.path}: ${y.message}`)
    };
}
export function tryParseIdeSettingArray(rawSetting) {
    const res = new Array();
    const parsedArray = parseOrNull(rawSetting);
    if (parsedArray !== undefined && parsedArray instanceof Array) {
        parsedArray.forEach((item) => {
            const parsed = parseIdeSetting(JSON.stringify(item));
            if (parsed.success) {
                res.push(parsed.data);
            }
        });
    }
    else {
        const parsed = parseIdeSetting(rawSetting);
        if (parsed.success) {
            res.push(parsed.data);
        }
    }
    return res;
}
