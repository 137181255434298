import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import Text from '@jetbrains/ring-ui/components/text/text';
import chevronDown from '@jetbrains/icons/chevron-down';
import chevronUp from '@jetbrains/icons/chevron-up';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import bell from '@jetbrains/icons/bell';
import download from '@jetbrains/icons/download';
import classnames from 'classnames';
import { autoinstalledMessage, RESTRICT_MESSAGE } from './texts';
import styles from './nested-data-list.css';
export function CollapsedDataItem({ item, excludedItemComponent }) {
    const [isExpanded, setIsExpanded] = useState(true);
    const selectedItems = item.selected;
    const deselectedItemsCount = item.items.filter(({ id }) => !selectedItems.includes(id)).length;
    const isParentSelected = selectedItems.some(value => value === item.id) || !deselectedItemsCount;
    const hasNotification = item.items.some(({ notificationDate }) => notificationDate);
    const autoinstalledDataItem = item.items.filter(({ isAutoinstalled }) => isAutoinstalled);
    return (_jsxs("div", { children: [_jsx(Text, { className: !isParentSelected ? styles.crossed : '', info: !isParentSelected, children: item.label }), _jsx(Icon, { className: styles.cursor, glyph: isExpanded ? chevronDown : chevronUp, onClick: () => setIsExpanded(prev => !prev) }), !isExpanded && hasNotification && (_jsx(Tooltip, { title: RESTRICT_MESSAGE, children: _jsx(Icon, { glyph: bell, className: styles.notificationIcon }) })), !isExpanded && autoinstalledDataItem.length > 0 && (_jsx(Tooltip, { title: autoinstalledMessage(item.product, autoinstalledDataItem[0].id.toString()), children: _jsx(Icon, { glyph: download }) })), !isParentSelected && !selectedItems.some(value => value) && excludedItemComponent, isExpanded &&
                item.items.map(({ id, notificationDate, isAutoinstalled }) => {
                    const isExcluded = !selectedItems.some(value => value === id);
                    return (_jsx("div", { className: styles.nestedItemContainer, children: _jsxs(Text, { className: classnames(styles.textContainer, isExcluded ? styles.crossed : ''), info: isExcluded, children: [id, notificationDate && (_jsx(Tooltip, { title: `This build version is restricted and will become unavailable on ${notificationDate}`, children: _jsx(Icon, { glyph: bell, className: styles.notificationIcon }) })), isAutoinstalled && (_jsx(Tooltip, { title: autoinstalledMessage(item.product, 'This build version'), children: _jsx(Icon, { glyph: download }) }))] }) }, id));
                })] }));
}
