import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import CopiedCode, { CopyFieldSize } from '../../../../components/clipboard/copied-code/copied-code';
import { OperatingSystems } from './operating-systems';
export function DownloadCode(props) {
    const { operatingSystem, sessionBaseUrl, linkExpired, className } = props;
    const shellCode = useMemo(() => {
        switch (operatingSystem) {
            case OperatingSystems.MACOS:
                return `/bin/bash -c "$(curl -fsSL '${sessionBaseUrl}/cwm-client-launcher-mac.sh'"?arch_type=$(uname -m)")"`;
            case OperatingSystems.OTHER:
                return `/bin/bash -c "$(curl -fsSL '${sessionBaseUrl}/cwm-client-launcher-linux.sh'"?arch_type=$(uname -m)")"`;
            default:
                throw new Error('Unreachable');
        }
    }, [sessionBaseUrl, operatingSystem]);
    return (_jsx(CopiedCode, { className: classNames(className), text: shellCode, size: CopyFieldSize.FULL, disabled: linkExpired, softWrap: true }));
}
