import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import Text from '@jetbrains/ring-ui/components/text/text';
import { AlertTimeouts } from '../components/constants/constants';
export function useDialogCancelConfirmation(onCancel, isConfirmationNeeded) {
    const cancelButtonRef = useRef(null);
    const [alertKey, setAlertKey] = useState();
    const [isConfirmationMode, setConfirmationMode] = useState(false);
    const onCloseAttempt = useCallback(() => {
        var _a, _b;
        const needConfirmation = typeof isConfirmationNeeded === 'function' ? isConfirmationNeeded() : isConfirmationNeeded;
        if (needConfirmation && !isConfirmationMode) {
            setConfirmationMode(true);
            const message = (_jsxs("div", { children: [_jsx("div", { children: _jsx(Text, { children: "Confirm Exit?" }) }), _jsx("div", { children: _jsxs(Text, { info: true, children: [_jsx("p", { children: "All unsaved changes will be discarded." }), _jsxs("p", { children: ["Click anywhere outside the dialog once again to", ' ', _jsx(Button, { onClick: onCancel, text: true, inline: true, children: "confirm exit" }), "."] })] }) })] }));
            setAlertKey(alert.message(message, AlertTimeouts.SHORT));
            (_b = (_a = cancelButtonRef.current) === null || _a === void 0 ? void 0 : _a.buttonRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            setTimeout(() => {
                setConfirmationMode(false);
            }, AlertTimeouts.SHORT);
        }
        else {
            onCancel();
            if (alertKey !== undefined) {
                alert.remove(alertKey);
                setAlertKey(undefined);
            }
        }
    }, [isConfirmationMode, isConfirmationNeeded, onCancel, alertKey]);
    return {
        onCloseAttempt,
        onEscPress: onCloseAttempt,
        isConfirmationMode,
        cancelButtonRef
    };
}
