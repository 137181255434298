import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import Input from '@jetbrains/ring-ui/components/input/input';
import { noop } from 'lodash';
function RegisteredInput({ name, control, controllerProps, changeListener = noop, ...rest }) {
    var _a;
    const { field: { ref, name: fieldName, onChange: fieldOnChange, value, onBlur }, fieldState } = useController({
        name,
        defaultValue: '',
        control,
        ...controllerProps
    });
    return (_jsx(Input, { value: value || '', onChange: e => {
            changeListener(e);
            fieldOnChange(e);
        }, inputRef: ref, onBlur: onBlur, name: fieldName, error: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, id: name, ...rest }));
}
export default RegisteredInput;
