export function validateChannel(tag) {
    if (!tag.label) {
        return {
            errorMessage: `Invalid channel. Channel key is undefined or null`
        };
    }
    if (tag.label.toString().length > 20) {
        return {
            errorMessage: `Invalid channel: ${tag.label}. Channel should have a maximum of 20 characters`
        };
    }
    if (`${tag.label}`.replace(/[^\w-_]+/gi, '') !== tag.label) {
        return {
            errorMessage: `Invalid channel: ${tag.label}. Channel can contain only latin letters, digits, '-', '_'`
        };
    }
    return {
        errorMessage: undefined
    };
}
