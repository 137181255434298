import React, {useEffect} from 'react';
import {Bar} from 'react-chartjs-2';
import {set, sub} from 'date-fns';
import {isNil} from 'lodash';
import {LAST_1_DAY, LAST_1_YEAR, LAST_28_DAYS, LAST_7_DAYS} from '../analytics-table';
import {useDebounce} from '../../../hooks/use-debounce-component-value';
import {formatDate, shortDateFormat} from '../../../components/util/i18n';
import {usePluginDownloads} from '../../../queries/plugins';
import styles from './chart.css';

// region Chart utils

/**
 * Required for the TBE-813 e.x. 2022-09-28T00:00:00 -> 2022-09-28
 * We remove the timezone information because otherwise chart will not render label for the first entry
 * @param {string} isoDate
 * @return {string}
 */
function withoutTimeSuffix(isoDate) {
  return isoDate.substring(0, 10);
}

/**
 * string value which is used for chart options
 * @param range
 * @return {string}
 */
function getUnit(range) {
  if (range === LAST_1_YEAR) {
    return 'month';
  }
  return 'day'; // LAST_1_DAY, LAST_7_DAYS, LAST_28_DAYS
}

/**
 * Returns the starting date for the given range. E.x. for the range = LAST_28_DAYS we get today's date minus 28 days
 * @param range
 * @return {Date}
 */
function getStartingDateOfRange(range) {
  if (range === LAST_1_DAY) {
    return new Date();
  }
  if (range === LAST_7_DAYS) {
    return sub(new Date(), {days: 7});
  }
  if (range === LAST_28_DAYS) {
    return sub(new Date(), {days: 28});
  }
  return set(sub(new Date(), {years: 1}), {date: 1}); // case: LAST_1_YEAR
}

/**
 * Utility function which is primarily made for the TBE-821. It returns given entries with one optional extra entry.
 * It is a way to tell the chart to render all the points for the given range instead of only given points
 * @param {ApiPluginDownloadsEntry[]} entries
 * @param range
 * @return {ApiPluginDownloadsEntry[]}
 */
function withFirstEntryIfNecessary(entries, range) {
  const compare = (lhs, rhs) => withoutTimeSuffix(lhs) === withoutTimeSuffix(rhs);

  const startingDate = getStartingDateOfRange(range).toISOString();
  const existsStartingEntry = entries.some(x => compare(x.date, startingDate));

  return existsStartingEntry || entries.length === 0
    ? entries
    : entries.concat([{date: startingDate, downloads: 0}]);
}

/**
 * Converter
 * @param {ApiPluginDownloadsEntry} entry
 * @return {{x: string, y: number}}
 */
function toChartPoint(entry) {
  return {x: entry.date, y: entry.downloads};
}

// endregion

/**
 *
 * @param {ApiPluginDownloads|null|undefined} data
 * @return {number}
 */
export function countTotalDownloads(data) {
  return isNil(data) ? 0 : data.entries.reduce((x, y) => x + y.downloads, 0);
}

/**
 *
 * @param {string} pluginId
 * @param {string} range
 * @param {function(boolean): void} onLoadingChange
 * @return {JSX.Element}
 * @constructor
 */
function DownloadsForSinglePlugin({pluginId, range, onLoadingChange}) {
  const dPluginId = useDebounce(pluginId, 500);
  const {data, isError, isFetching} = usePluginDownloads(dPluginId, range);

  const chartData = withFirstEntryIfNecessary(data?.entries ?? [], range).map(x =>
    toChartPoint({...x, date: withoutTimeSuffix(x.date)})
  );

  useEffect(() => {
    onLoadingChange(pluginId !== dPluginId || isFetching);
  }, [pluginId, dPluginId, isFetching, onLoadingChange]);

  if (isError || chartData.length < 1) {
    return null;
  }
  return (
    <Bar
      className={styles.chartContainer}
      options={{
        isoWeekday: true,
        scales: {
          x: {type: 'time', time: {unit: getUnit(range)}},
          y: {ticks: {stepSize: 1}}
        },
        backgroundColor: 'rgba(52,166,253,0.38)',
        displayFormats: {
          day: shortDateFormat,
          month: shortDateFormat
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: context => formatDate(context[0]?.raw?.x)
            }
          }
        }
      }}
      data={{datasets: [{data: chartData}]}}
    />
  );
}

export default DownloadsForSinglePlugin;
