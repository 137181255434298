// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.versionAlert__a6d {
  position: fixed;
  top: calc(var(--app-header-height) + 8px);
}

.versionAlertContainer__ba2 {
  display: flex;
  gap: calc(var(--ring-unit) * 1.5);
}

.versionAlertText__cac {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/components/server-config-context/render-version-alert.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".versionAlert {\n  position: fixed;\n  top: calc(var(--app-header-height) + 8px);\n}\n\n.versionAlertContainer {\n  display: flex;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.versionAlertText {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"versionAlert": `versionAlert__a6d`,
	"versionAlertContainer": `versionAlertContainer__ba2`,
	"versionAlertText": `versionAlertText__cac`
};
export default ___CSS_LOADER_EXPORT___;
