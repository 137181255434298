// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolsPreview__dac {
  margin-bottom: var(--ring-unit);
}
.errorMessage__c9a {
  color: var(--ring-error-color);
}
.filterField__af4 {
  background-color: var(--ring-sidebar-background-color);
  border: 1px solid var(--ring-border-disabled-color);
  border-radius: var(--ring-border-radius);
  padding: var(--ring-unit) var(--ring-unit) 0 var(--ring-unit);
  margin: 0 calc(var(--ring-unit) * -1);
  width: 100%;
}

.disabledModeText__f81 {
  padding: var(--ring-unit) 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/filters-field.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;AACA;EACE,8BAA8B;AAChC;AACA;EACE,sDAAsD;EACtD,mDAAmD;EACnD,wCAAwC;EACxC,6DAA6D;EAC7D,qCAAqC;EACrC,WAAW;AACb;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".toolsPreview {\n  margin-bottom: var(--ring-unit);\n}\n.errorMessage {\n  color: var(--ring-error-color);\n}\n.filterField {\n  background-color: var(--ring-sidebar-background-color);\n  border: 1px solid var(--ring-border-disabled-color);\n  border-radius: var(--ring-border-radius);\n  padding: var(--ring-unit) var(--ring-unit) 0 var(--ring-unit);\n  margin: 0 calc(var(--ring-unit) * -1);\n  width: 100%;\n}\n\n.disabledModeText {\n  padding: var(--ring-unit) 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolsPreview": `toolsPreview__dac`,
	"errorMessage": `errorMessage__c9a`,
	"filterField": `filterField__af4`,
	"disabledModeText": `disabledModeText__f81`
};
export default ___CSS_LOADER_EXPORT___;
