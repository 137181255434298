import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useDisableIdePMutation } from '@app/queries/ide-provisioner';
import { ConstituentDialog } from '../../../shared/constituent-dialog/constituent-dialog';
export function IdePDisableDialog(props) {
    const { onCloseAttempt } = props;
    const { mutate: disableIdeP, isLoading } = useDisableIdePMutation(() => onCloseAttempt(undefined));
    const handleDisable = useCallback(() => disableIdeP(), [disableIdeP]);
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    return (_jsxs(ConstituentDialog, { title: "Disable IDE Provisioner?", children: [_jsx(Content, { children: _jsxs("p", { children: ["After disabling IDE Provisioner, profile users will lose access to the local plugin", _jsx("br", {}), "repository and their tools will no longer be managed by the organization"] }) }), _jsxs(Panel, { children: [_jsx(Button, { onClick: handleDisable, loader: isLoading, danger: true, children: "Disable" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
