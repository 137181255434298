import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import RegisteredInput from '../../components/hook-form/registered-input';
import { Required } from '../../components/form/important-asterisk';
import { RoleField } from './form-fields/role-field';
import { ProfilesTagsField } from './form-fields/profiles-tags-field';
import { DeactivateUserField } from './form-fields/deactivate-user-field';
export function UserAddFormFields({ disabled, profileOptions }) {
    const { control } = useFormContext();
    return (_jsxs(_Fragment, { children: [_jsx(RegisteredInput, { label: "OAuth subject", name: "subject", autoComplete: "off", control: control, disabled: disabled }), _jsx(RegisteredInput, { label: Required('Name'), name: "username", autoComplete: "off", disabled: disabled, control: control }), _jsx(RegisteredInput, { label: "Email", name: "email", autoComplete: "off", disabled: disabled, control: control }), _jsx(RoleField, { disabled: disabled }), _jsx(ProfilesTagsField, { profileOptions: profileOptions, disabled: disabled }), _jsx(DeactivateUserField, {})] }));
}
