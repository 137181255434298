import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Text from '@jetbrains/ring-ui/components/text/text';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import warning from '@jetbrains/icons/warning';
import { zodResolver } from '@hookform/resolvers/zod';
import { validateVmOption } from '@api/ide-vm-options';
import { useDialogCancelConfirmation } from '@app/hooks/use-dialog-cancel-confirmation';
import { ExternalLinks } from '@components/constants/constants';
import NavigationLink from '@components/navigation/navigation-link';
import { tVmOptionsSchema } from '../../schemas/vm-options-schema';
import { getEmptyVmOption } from '../../schemas/ctors';
import SettingFiltersField from '../../common/setting-filters-field';
import { VmOptionsFieldArray } from './vm-options-field-array';
import styles from './profiles-vm-options-form.css';
export function VmOptionsDialogForm({ item, onReject, onSubmit }) {
    const form = useForm({
        defaultValues: item !== null && item !== void 0 ? item : getEmptyVmOption(),
        resolver: zodResolver(tVmOptionsSchema)
    });
    const { formState: { isDirty, isSubmitting }, handleSubmit, setError } = form;
    const [isFetchingTools, setIsFetchingTools] = useState(false);
    const canSubmit = isDirty && !isFetchingTools;
    const validate = async (vmOption, id) => {
        await validateVmOption(vmOption).catch(({ response }) => {
            setError(`content.${id}.value`, response.data);
            return Promise.reject(response);
        });
    };
    const submit = async (formData) => {
        return Promise.allSettled(formData.content.map(({ value }, id) => validate(value, id))).then(results => {
            if (results.every(({ status }) => status === 'fulfilled')) {
                onSubmit(formData);
            }
        });
    };
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onReject, isDirty);
    return (_jsxs(Dialog, { show: true, autoFocusFirst: false, onCloseAttempt: onCloseAttempt, className: styles.dialogContainer, children: [_jsx(Header, { children: "Configure Virtual Machine Options" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(submit), className: styles.formContainer, children: [_jsxs(Text, { className: styles.warningText, children: [_jsx(Icon, { glyph: warning, className: styles.warningIcon }), "Configure VM options carefully and test them in an IDE first. Incorrect configuration may influence IDE performance or even prevent it from starting."] }), _jsx(SettingFiltersField, { onIsFetchingChange: setIsFetchingTools }), _jsx(VmOptionsFieldArray, {}), _jsxs(Text, { info: true, className: styles.infoText, children: ["JetBrains IDE Services adds the provided VM options to the", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_CONFIGURE_JVM, target: "_blank", children: "vmoptions" }), ' ', "file"] })] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(submit), type: "submit", loader: isSubmitting, disabled: !canSubmit, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: onReject, children: "Cancel" })] })] }));
}
