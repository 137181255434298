// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.synchronizationText__f16 {
  margin-right: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/plugins/plugins-page.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC","sourcesContent":[".synchronizationText {\n  margin-right: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"synchronizationText": `synchronizationText__f16`
};
export default ___CSS_LOADER_EXPORT___;
