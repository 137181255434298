import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import { apiProfileAuditTypeOptions } from '@api/profiles';
import { Collapsible } from '@components/collapsible/collapsible';
import NavigationLink from '@components/navigation/navigation-link';
import { ProfileHistoryItemDetails } from './profile-history-item-details';
function getTypeLabel(type) {
    var _a, _b;
    return (_b = (_a = apiProfileAuditTypeOptions.find(item => item.key === type)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : 'Settings';
}
function ProfileHistoryItem({ item, profileId, ...rest }) {
    return (_jsx(Collapsible, { ...rest, title: _jsxs(Text, { children: [`${getTypeLabel(item.type)} updated `, item.author && (_jsxs(_Fragment, { children: [_jsx(Text, { children: "by " }), item.author.role === 'AUTOMATION' && (_jsxs(_Fragment, { children: ["automation token ", _jsx("strong", { children: item.author.id }), ' ', _jsxs(Text, { info: true, children: ["(see the list of", ' ', _jsx(NavigationLink, { href: "/configuration/automation-tokens", children: "automation tokens" }), ")"] })] })), item.author.role !== 'AUTOMATION' && (_jsx(NavigationLink, { href: `/users/${item.author.id}`, children: item.author.username }))] }))] }), children: _jsx(ProfileHistoryItemDetails, { itemId: item.id, profileId: profileId }) }));
}
export default ProfileHistoryItem;
