import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFieldArray, useFormContext } from 'react-hook-form';
import XpathFields from '../xpath/xpath-fields';
import { getXpathSpecByAction } from '../../../../schemas/ctors';
import RuleEditorsLayout from './rule-editor/rule-editors-layout';
import AddRuleButton from './rule-editor/add-rule-button';
import RuleEditor from './rule-editor/rule-editor';
import styles from './setting-form-tab.css';
function XpathSpecWithReplaceTextAction() {
    return {
        xpath: '',
        action: 'replace-text',
        text: '',
        cdata: false
    };
}
function SpecsField({ settingIndex }) {
    const { control } = useFormContext();
    const { fields, append, remove, update } = useFieldArray({
        name: `content.${settingIndex}.specs`,
        control
    });
    return (_jsxs(RuleEditorsLayout, { children: [fields.map((field, index) => (_jsx(RuleEditor, { onRemove: () => remove(index), isEditable: false, infoText: `SPECIFICATION ${index + 1}`, className: styles.xpathEditor, contentClassName: styles.xpathEditorContent, children: _jsx(XpathFields, { settingIndex: settingIndex, specsIndex: index, onActionUpdate: action => {
                        update(index, getXpathSpecByAction(action));
                    } }) }, field.id))), _jsx(AddRuleButton, { onAddRule: () => append(XpathSpecWithReplaceTextAction()), text: "Add specification" })] }));
}
export default SpecsField;
