import { getSettingDescriptions } from '../settings/ide-settings/ide-settings-description';
export function getDescription(settingType, content) {
    if (settingType === 'ide') {
        return getSettingDescriptions(content);
    }
    if (settingType === 'vmOptions') {
        return [content.value];
    }
    if (settingType === 'toolboxSetting') {
        const { value, id } = content;
        const toolboxSettingDescriptions = {
            updateToolsAutomatically: value
                ? 'Update tools automatically enabled'
                : 'Update tools automatically disabled',
            updateToolboxAutomatically: value
                ? 'Update Toolbox App automatically enabled'
                : 'Update Toolbox App automatically disabled',
            sendAnonymousUserStatistics: value
                ? 'Send anonymous user statistics enabled'
                : 'Send anonymous user statistics disabled',
            settingsUpdateIntervalSeconds: `Update frequency modified to ${value / 60} minutes`
        };
        const description = toolboxSettingDescriptions[id];
        if (description) {
            return [description];
        }
    }
    if (settingType === 'autoInstalled') {
        return [content.pluginId];
    }
    if (settingType === 'disabled') {
        return [content.pluginId];
    }
    return [''];
}
