// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageTitle__e81 {
  white-space: nowrap;
  margin-bottom: calc(2.5 * var(--ring-unit));
}

.pageTitleBreadcrumb__b43 {
  display: inline-flex;
}

.pageTitleRightPanel__e2a {
  float: right;
  padding: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/page-layout/page-title.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".pageTitle {\n  white-space: nowrap;\n  margin-bottom: calc(2.5 * var(--ring-unit));\n}\n\n.pageTitleBreadcrumb {\n  display: inline-flex;\n}\n\n.pageTitleRightPanel {\n  float: right;\n  padding: 4px 0;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageTitle": `pageTitle__e81`,
	"pageTitleBreadcrumb": `pageTitleBreadcrumb__b43`,
	"pageTitleRightPanel": `pageTitleRightPanel__e2a`
};
export default ___CSS_LOADER_EXPORT___;
