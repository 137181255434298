import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useProfileHistoryDetailsQuery } from '@app/queries/profiles';
import { ParagraphLoader } from '@components/loaders/paragraph-loader';
import { TextDiff } from './text-diff';
import styles from './profile-history-item-details.css';
function serializeValue(value) {
    if (typeof value === 'number' || typeof value === 'boolean') {
        return `${value}`;
    }
    if (value && typeof value === 'object') {
        return JSON.stringify(value, null, 3);
    }
    return !value ? '' : `${value}`;
}
function HistoryItemProperty({ name, value, prevValue }) {
    const text = serializeValue(value);
    const oldText = serializeValue(prevValue);
    return (_jsxs("div", { className: styles.property, children: [_jsxs("span", { className: styles.propertyName, children: [name, ":"] }), ' ', _jsx("code", { children: _jsx(TextDiff, { newText: text, oldText: oldText }) })] }, name));
}
function extractProperties(data, prevData) {
    return Object.keys(data)
        .filter(key => key !== 'id')
        .map(key => {
        const content = data[key];
        if (Array.isArray(content)) {
            const prevContent = (prevData && prevData[key] && prevData[key]) || [];
            if (content.length || prevContent.length) {
                const getId = ({ id }, idx) => (!id ? `${idx}` : id);
                const newContent = [...content];
                const removedContent = prevContent.filter((it, idx) => {
                    const id = getId(it, idx);
                    return !newContent.some((newIt, i) => getId(newIt, i) === id);
                });
                return (_jsxs(Fragment, { children: [newContent.map((it, idx) => {
                            const id = getId(it, idx);
                            return (_jsx(HistoryItemProperty, { name: `${key}[${id}]`, value: it, prevValue: prevContent.find((prevIt, i) => getId(prevIt, i) === id) }));
                        }), removedContent.map((it, idx) => {
                            return (_jsx(HistoryItemProperty, { name: `${key}[${getId(it, idx)}]`, value: "", prevValue: it }));
                        })] }, key));
            }
            return undefined;
        }
        return _jsx(HistoryItemProperty, { name: key, value: content, prevValue: prevData[key] });
    })
        .filter(it => !!it);
}
export function ProfileHistoryItemDetails({ itemId, profileId }) {
    var _a;
    const { data, isLoading } = useProfileHistoryDetailsQuery(profileId, itemId);
    if (isLoading) {
        return (_jsx("div", { className: styles.detailsContainer, children: _jsx(ParagraphLoader, {}) }));
    }
    return (_jsx(Text, { info: true, className: styles.detailsContainer, children: data && extractProperties(data === null || data === void 0 ? void 0 : data.snapshot, (_a = data === null || data === void 0 ? void 0 : data.prevSnapshot) !== null && _a !== void 0 ? _a : {}) }));
}
