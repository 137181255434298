import { setFetchError, setIsFetching, setCurrentBatchInfo } from '../store/usage-data-current-batch';
import { getServiceClient } from '../components/auth/auth';
export function getUsageDataCurrentBatch() {
    return async (dispatch) => {
        try {
            dispatch(setIsFetching(true));
            const response = await getServiceClient().get('/api/usage-data/info');
            const { data } = response;
            dispatch(setCurrentBatchInfo(data));
        }
        catch (e) {
            dispatch(setFetchError(true));
        }
    };
}
