import { useQuery } from '@tanstack/react-query';
import { getFeedFilterProducts, getQualities } from '../api/ide-feed-filters';
import { formatApiError } from '../api/errors';
import { getQueryAlertMessages } from './formatters/alerts';
const keys = {
    all: ['ide-feed-filters'],
    qualities: () => ['ide-feed-filters', 'qualities'],
    products: () => ['ide-feed-filters', 'products']
};
export function useQualities() {
    return useQuery({
        queryKey: keys.qualities(),
        queryFn: getQualities,
        placeholderData: [],
        staleTime: Infinity,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load feed filter qualities'))
    });
}
export function useFeedFilterProducts(profileId) {
    return useQuery({
        queryKey: keys.products(),
        queryFn: () => getFeedFilterProducts(profileId),
        placeholderData: [],
        // TODO: we may not even cache that. No point
        staleTime: 0,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load feed filter products'))
    });
}
