// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer__f47 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--ring-unit) * 1.5);
}

.dialogContainer__a67 {
  width: 560px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/applications/application-versions-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iCAAiC;AACnC;;AAEA;EACE,YAAY;AACd","sourcesContent":[".mainContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.dialogContainer {\n  width: 560px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `mainContainer__f47`,
	"dialogContainer": `dialogContainer__a67`
};
export default ___CSS_LOADER_EXPORT___;
