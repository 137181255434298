// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entry__e90 button[disabled],
.entry__e90 button[disabled]:hover {
  cursor: initial;
  color: initial;
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics/collected-data-error-attachments.css"],"names":[],"mappings":"AAAA;;EAEE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".entry button[disabled],\n.entry button[disabled]:hover {\n  cursor: initial;\n  color: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"entry": `entry__e90`
};
export default ___CSS_LOADER_EXPORT___;
