import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { isNil } from 'lodash';
import { IdeProvisionerLogo } from '@components/ide-provisioner/ide-provisioner-logo/ide-provisioner-logo';
import { ConstituentDialog } from '../../../shared/constituent-dialog/constituent-dialog';
import { LimitAssigmentEditor } from '../../../shared/limit-assigment-editor/limit-assigment-editor';
import styles from './idep-settings-dialog.css';
export function IdePSettingsDialogBase(props) {
    const { onCloseAttempt, title, isLoading, onActivate, initialLimitUsage, limitsLocked } = props;
    const [usage, setUsage] = useState(initialLimitUsage !== null && initialLimitUsage !== void 0 ? initialLimitUsage : 'unlimited');
    const handleActivate = useCallback(() => {
        onActivate({
            assignedUsersLimit: usage === 'unlimited' ? undefined : usage
        });
    }, [onActivate, usage]);
    const activateDisabled = isNil(usage) || (usage !== 'unlimited' && usage < 1);
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    return (_jsxs(ConstituentDialog, { title: title, titleLogo: _jsx(IdeProvisionerLogo, {}), children: [_jsx(Content, { children: _jsxs("div", { className: styles.contentWrapper, children: [_jsxs("p", { className: styles.info, children: ["Once you enable IDE Provisioner, you can manage which tools, plugins,", _jsx("br", {}), "and settings are available to profile users."] }), _jsx(LimitAssigmentEditor, { toggleText: "Unlimited usage", limitNotice: _jsxs(_Fragment, { children: ["You can control your spending by setting a limit on the number of assigned users.", _jsx("br", {}), "Users who exceed this limit will have restricted access."] }), limit: usage, readonly: limitsLocked, onLimitChange: setUsage, className: styles.limitEditor })] }) }), _jsxs(Panel, { children: [_jsx(Button, { disabled: activateDisabled, loader: isLoading, onClick: handleActivate, primary: true, children: "Apply" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
