import { useEffect, useRef, useState } from 'react';
import { isPast } from 'date-fns';
export function useIsTimeExpired(dateTime, refreshIntervalMs = 1000) {
    const [expired, setExpired] = useState(isPast(dateTime));
    const interval = useRef(undefined);
    useEffect(() => {
        setExpired(isPast(dateTime));
        interval.current = window.setInterval(() => {
            if (isPast(dateTime)) {
                setExpired(true);
                window.clearInterval(interval.current);
            }
        }, refreshIntervalMs);
        return () => window.clearInterval(interval.current);
    }, [dateTime, refreshIntervalMs]);
    return expired;
}
