import {format, formatISO, isSameYear, isToday, isYesterday, parseISO} from 'date-fns';

/** e.g. "1 Jan 2000" */
export const dateFormatter = new Intl.DateTimeFormat('en-GB', {
  dateStyle: 'medium'
});
/** e.g. "1 Jan 2000, 13:00:00" */
export const dateAndTimeFormatter = new Intl.DateTimeFormat('en-GB', {
  dateStyle: 'medium',
  timeStyle: 'medium'
});

/** e.g. "1 Jan" */
export const shortDateFormat = 'd MMM';
/** e.g. "Mon 1 Jan 2000" */
export const longDateFormat = 'EEE d MMM yyyy';
/** e.g. "week beginning on 1 Jan 2000" */
export const startOfWeekFormat = "'week beginning on' d MMM yyyy";

/** e.g. "0.50" */
export const apdexFormat = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

/**
 * Converts argument to an instance of Date object.
 * Supported formats:
 * — ISO 8601 string — e.g. "2000-12-31T13:00:00+01:00" (JDK OffsetDateTime) or "2000-12-31" (JDK LocalDate)
 * — numeric date value — e.g. 1639062622162
 * — instance of Date object — returned unchanged
 * @param {null|Date|number|string} date a Date object, numeric date value, or ISO string
 * @returns {null|Date}
 */
export function toDate(date) {
  if (!date) {
    return null;
  }
  if (date instanceof Date) {
    return date;
  }
  if (typeof date === 'string') {
    return parseISO(date);
  }
  if (typeof date === 'number') {
    return new Date(date);
  }
  throw new Error(`Invalid date format: ${date}`);
}

/**
 * Converts argument to ISO date string (e.g. "2000-12-31") used in REST API,
 * compatible with JDK LocalDate and Spring DateTimeFormat.ISO.DATE.
 * @param {null|Date|number|string} date a Date object, numeric date value, or ISO string
 * @returns {null|string}
 */
export function toLocalDateString(date) {
  if (!date) {
    return null;
  }
  return formatISO(toDate(date), {representation: 'date'});
}

/**
 * Converts argument to ISO date and time string (e.g. "2000-12-31T13:00:00.000+01:00") used in REST API,
 * compatible with JDK OffsetDateTime, JDK Instant, and Spring DateTimeFormat.ISO.DATE_TIME.
 * @param {null|Date|number|string} date a Date object, numeric date value, or ISO string
 * @returns {null|string}
 */
export function toOffsetDateTimeString(date) {
  if (!date) {
    return null;
  }
  return formatISO(toDate(date), {representation: 'complete'});
}

/**
 * @param {null|undefined|Date|number|string} date a Date object, numeric date value, or ISO string
 * @returns {null|string} a localized date and time
 */
export function formatDateAndTime(date) {
  if (!date) {
    return null;
  }
  return dateAndTimeFormatter.format(toDate(date));
}

/**
 * @param {null|Date|number|string} date a Date object, numeric date value, or ISO string
 * @returns {null|string} a localized date and time
 */
export function formatDate(date) {
  if (!date) {
    return null;
  }
  return dateFormatter.format(toDate(date));
}

/**
 * @param {number} count
 * @returns {string} e.g. "1", "12", "123", "1.2k", "12k".
 */
export function formatAbbreviatedCount(count) {
  if (count <= 999) {
    return `${count}`;
  }
  const thousands = (count / 1_000).toLocaleString('en-GB', {
    minimumSignificantDigits: 2,
    maximumSignificantDigits: 2
  });
  return `${thousands}k`;
}

const productLabels = {
  AI: 'Android Studio',
  CL: 'CLion',
  CWMG: 'IntelliJ Guest for Code With Me',
  DB: 'DataGrip',
  DC: 'dotCover',
  DM: 'dotMemory',
  DP: 'dotTrace',
  DPK: 'dotPeek',
  DS: 'DataSpell',
  GO: 'GoLand',
  GW: 'JetBrains Gateway',
  IC: 'IntelliJ IDEA Community',
  IE: 'IntelliJ IDEA Edu',
  IU: 'IntelliJ IDEA Ultimate',
  JBC: 'JetBrains Client',
  MPS: 'MPS',
  OC: 'AppCode',
  PC: 'PyCharm Community',
  PCA: 'PyCharm Community for Anaconda',
  PE: 'PyCharm Edu',
  PS: 'PhpStorm',
  PY: 'PyCharm Professional',
  PYA: 'PyCharm Professional for Anaconda',
  RD: 'Rider',
  RDCPPP: 'Rider for Unreal Engine',
  RM: 'RubyMine',
  WS: 'WebStorm'
};

/**
 * @param {string} productCode
 * @returns {string}
 */
export function formatProductCode(productCode) {
  return productLabels[productCode] ?? productCode;
}

/**
 * @param {string} version A major version ("213") or a build number ("213.5744.223")
 * @returns {string}
 */
export function formatProductVersion(version) {
  if (/^\d{3}$/.test(version)) {
    // e.g. "213" --> "2021.3"
    return `20${version.substr(0, 2)}.${version.substr(2, 1)}`;
  }
  return version;
}

/**
 *
 * @param {Date | number} date
 * @returns {string}
 */
export function formatDateTimeWithRelative(date) {
  if (!isSameYear(date, new Date())) {
    return format(date, 'dd MMM yyyy, hh:mm');
  }

  if (isToday(date)) {
    return `Today, ${format(date, 'hh:mm')}`;
  }
  if (isYesterday(date)) {
    return `Yesterday, ${format(date, 'hh:mm')}`;
  }
  return format(date, 'dd MMM, hh:mm');
}

/**
 *
 * @param {Date | number} date
 * @returns {string}
 */
export function formatDateTimeFull(date) {
  return format(date, 'dd MMM yyyy, hh:mm');
}
