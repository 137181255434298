// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.applicationsContainer__a05 {
  display: flex;
  flex-direction: column;
  gap: calc(1.5 * var(--ring-unit));
  padding: calc(3 * var(--ring-unit)) calc(3 * var(--ring-unit)) calc(2.5 * var(--ring-unit));
  border-left: 1px solid var(--ring-line-color);
  width: 360px;
  margin-left: calc(var(--ring-unit) * 4);
}

.header__eba {
  display: flex;
  align-items: center;
  gap: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/applications-tab/user-applications.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;EACjC,2FAA2F;EAC3F,6CAA6C;EAC7C,YAAY;EACZ,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB","sourcesContent":[".applicationsContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(1.5 * var(--ring-unit));\n  padding: calc(3 * var(--ring-unit)) calc(3 * var(--ring-unit)) calc(2.5 * var(--ring-unit));\n  border-left: 1px solid var(--ring-line-color);\n  width: 360px;\n  margin-left: calc(var(--ring-unit) * 4);\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  gap: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"applicationsContainer": `applicationsContainer__a05`,
	"header": `header__eba`
};
export default ___CSS_LOADER_EXPORT___;
