import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import exceptionIcon from '@jetbrains/icons/exception';
import Icon from '@jetbrains/ring-ui/components/icon';
import Text from '@jetbrains/ring-ui/components/text/text';
import { Size } from '@jetbrains/ring-ui/components/icon/icon__constants';
import { useToolboxInfo } from '@app/queries/toolbox';
import { InformationBanner } from '@components/information-banner/information-banner';
import auth from '@components/auth/auth';
import { findGreaterEquals } from './formatters';
import { useIncludedTbaBuilds } from './hooks';
import styles from './tba-banner.css';
/**
 * Error banner which is shown when there is no included TBA version above the minimal required
 * @constructor
 */
function TbaBanner() {
    const builds = useIncludedTbaBuilds();
    const { data: toolboxData, isFetching, isError } = useToolboxInfo();
    const { offlineModeEnabled } = auth.getFeatures();
    if (!toolboxData || isFetching || isError) {
        return null;
    }
    const { minSupportedVersion } = toolboxData;
    const greaterEqVersion = findGreaterEquals(builds, minSupportedVersion);
    if (greaterEqVersion || offlineModeEnabled) {
        return null;
    }
    return (_jsx(InformationBanner, { variant: "error", children: _jsxs("span", { children: [_jsx(Icon, { glyph: exceptionIcon, className: styles.errorIcon, size: Size.Size18 }), _jsxs(Text, { children: ["For JetBrains IDE Services to work correctly, enable JetBrains Toolbox version", ' ', minSupportedVersion, " or higher in this profile"] })] }) }));
}
export default TbaBanner;
