// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout__ec8 {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}

.layoutRight__e35 {
  flex-basis: 700px;
}

.exportButton__d78 {
  margin: calc(2 * var(--ring-unit)) 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics/completion-analytics.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".layout {\n  display: flex;\n  gap: 32px;\n  flex-wrap: wrap;\n}\n\n.layoutRight {\n  flex-basis: 700px;\n}\n\n.exportButton {\n  margin: calc(2 * var(--ring-unit)) 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `layout__ec8`,
	"layoutRight": `layoutRight__e35`,
	"exportButton": `exportButton__d78`
};
export default ___CSS_LOADER_EXPORT___;
