import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Tray from '@jetbrains/ring-ui/components/header/tray';
import Theme, { ThemeProvider } from '@jetbrains/ring-ui/components/global/theme';
import auth from '@components/auth/auth';
import { IdeServicesLogo } from '@components/ide-services/ide-services-logo/ide-services-logo';
import NavigationLink from '@components/navigation/navigation-link';
import ProtectedHeaderLink from './protected-header-link';
import HeaderDropdown from './authorized-header-dropdown';
import { OfflineModeBanner } from './offline-mode-banner';
import styles from './app-header.css';
function AuthorizedHeader() {
    const isAnalyticsHeaderEnabled = auth.getFeatures().analyticsEnabled === true;
    return (_jsx(ThemeProvider, { theme: Theme.DARK, children: _jsxs("header", { className: styles.appHeader, "data-test": "app-header", children: [_jsxs("div", { className: styles.innerContainer, children: [_jsx(NavigationLink, { href: "/", children: _jsx(IdeServicesLogo, { collapsible: true }) }), _jsxs("div", { className: styles.linksWrapper, children: [_jsx(ProtectedHeaderLink, { roles: ['ADMIN'], href: "/configuration", "data-test": "header-configuration-link", children: "Configuration" }), _jsx(ProtectedHeaderLink, { roles: ['ADMIN'], href: "/users", "data-test": "header-users-link", children: "Users" }), _jsx(ProtectedHeaderLink, { roles: ['ADMIN'], href: "/profiles", "data-test": "header-profiles-link", children: "Profiles" }), _jsx(ProtectedHeaderLink, { roles: ['ADMIN'], href: "/plugins", "data-test": "header-plugins-link", children: "Plugins" }), _jsx(ProtectedHeaderLink, { roles: ['ADMIN'], "data-test": "header-analytics-link", href: "/analytics", isHeaderPermitted: isAnalyticsHeaderEnabled, children: "Analytics" }), _jsx(ProtectedHeaderLink, { href: "/license-vault", roles: ['ADMIN'], "data-test": "header-license-vault-link", children: "Licenses" })] })] }), _jsxs(Tray, { className: styles.tray, children: [auth.getFeatures().offlineModeEnabled && auth.hasRoles(['ADMIN']) && (_jsx(OfflineModeBanner, {})), _jsx(HeaderDropdown, {})] })] }) }));
}
export default AuthorizedHeader;
