import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
export class DefaultStorageService {
    constructor(storage) {
        this.storage = storage;
        this.subscribers = new Map();
        this.onStorageEvent = (e) => {
            if (e.storageArea === this.storage && e.key && this.subscribers.has(e.key)) {
                const subscribers = this.subscribers.get(e.key);
                if (!subscribers) {
                    return;
                }
                const newValue = e.newValue ? this.deserialize(e.newValue) : null;
                const oldValue = e.oldValue ? this.deserialize(e.newValue) : null;
                subscribers.forEach(subscriber => subscriber(newValue, oldValue));
            }
        };
    }
    get(name) {
        try {
            const item = this.storage.getItem(name);
            return this.deserialize(item);
        }
        catch (e) {
            if (this.isCorruptedFsError(e)) {
                alert.error(DefaultStorageService.CORRUPTED_FS_MESSAGE);
            }
            return null;
        }
    }
    set(name, value) {
        const serialized = this.serialize(value);
        if (serialized !== null) {
            try {
                this.storage.setItem(name, serialized);
            }
            catch (e) {
                if (this.isCorruptedFsError(e)) {
                    alert.error(DefaultStorageService.CORRUPTED_FS_MESSAGE);
                }
            }
        }
    }
    remove(name) {
        try {
            this.storage.removeItem(name);
        }
        catch (e) {
            if (this.isCorruptedFsError(e)) {
                alert.error(DefaultStorageService.CORRUPTED_FS_MESSAGE);
            }
        }
    }
    subscribeFromOtherTabChange(name, callback) {
        return this.addAndSubscribe(name, callback);
    }
    addAndSubscribe(name, callback) {
        let subscribers = this.subscribers.get(name);
        if (!subscribers) {
            subscribers = [];
            this.subscribers.set(name, subscribers);
        }
        subscribers.push(callback);
        if (!this.disposeListener) {
            window.addEventListener('storage', this.onStorageEvent);
            this.disposeListener = () => {
                window.removeEventListener('storage', this.onStorageEvent);
            };
        }
        return () => this.disposeSubscription(name, callback);
    }
    disposeSubscription(name, callback) {
        const subscribers = this.subscribers.get(name);
        if (!subscribers) {
            return;
        }
        const index = subscribers.indexOf(callback);
        if (index === -1) {
            return;
        }
        subscribers.splice(index, 1);
        if (subscribers.length === 0) {
            this.subscribers.delete(name);
        }
        if (this.subscribers.size === 0 && this.disposeListener) {
            this.disposeListener();
            this.disposeListener = undefined;
        }
    }
    deserialize(value) {
        if (value === null) {
            return null;
        }
        try {
            return JSON.parse(value);
        }
        catch (e) {
            return null;
        }
    }
    serialize(value) {
        if (!value === null) {
            return null;
        }
        try {
            return JSON.stringify(value);
        }
        catch {
            return null;
        }
    }
    isCorruptedFsError(e) {
        return e instanceof Error && e.name === 'NS_ERROR_FILE_CORRUPTED';
    }
}
DefaultStorageService.CORRUPTED_FS_MESSAGE = 'Sorry, it looks like your browser storage is corrupted. ' +
    'Please clear your storage by going to Tools -> Clear Recent History -> Cookies' +
    ' and setting time range to "Everything". This will remove the corrupted browser storage across all sites.';
