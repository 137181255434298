import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from '@jetbrains/ring-ui/components/select/select';
import { useController } from 'react-hook-form';
import { noop } from 'lodash';
import classNames from 'classnames';
import styles from './registered-select.css';
function RegisteredSelect({ control, name, controllerProps, className, changeListener = noop, ...rest }) {
    const { field: { name: fieldName, onBlur, onChange }, fieldState: { error } } = useController({
        control,
        name,
        defaultValue: '',
        ...controllerProps
    });
    return (_jsxs("div", { className: styles.selectContainer, children: [_jsx(Select, { id: fieldName, onChange: (option) => {
                    changeListener(option);
                    onChange(typeof (option === null || option === void 0 ? void 0 : option.key) === 'string' ? option === null || option === void 0 ? void 0 : option.key : option === null || option === void 0 ? void 0 : option.label);
                }, onBlur: onBlur, onReset: () => onChange(''), className: classNames(className, (error === null || error === void 0 ? void 0 : error.message) && styles.errorBorder), ...rest }), (error === null || error === void 0 ? void 0 : error.message) && _jsx("p", { className: styles.error, children: error === null || error === void 0 ? void 0 : error.message })] }));
}
export default RegisteredSelect;
