import React, {useState} from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import {useDispatch} from 'react-redux';
import Confirm from '@jetbrains/ring-ui/components/confirm/confirm';
import ButtonToolbar from '@jetbrains/ring-ui/components/button-toolbar/button-toolbar';
import startNewBatch from '../../api/usage-data-new-batch';
import styles from './collected-data-new-batch.css';

export default function StartNewBatchButton() {
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <ButtonToolbar className={styles.batchActions}>
      <Button danger onClick={() => setShowConfirmation(true)}>
        Start new batch
      </Button>

      <Confirm
        show={showConfirmation}
        text="Do you really want to start collecting data to a new batch?"
        description="This action cannot be undone."
        confirmLabel="OK"
        rejectLabel="Cancel"
        onConfirm={() => {
          dispatch(startNewBatch());
          setShowConfirmation(false);
        }}
        onReject={() => setShowConfirmation(false)}
      />
    </ButtonToolbar>
  );
}
