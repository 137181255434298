import * as z from 'zod';
import { isEmpty } from 'lodash';
import { tFiltersSchema } from './filters-schema';
export const tSettingOptionSchema = z.object({
    id: z.string().transform(val => (isEmpty(val) ? crypto.randomUUID() : val)),
    filters: tFiltersSchema,
    tools: z.array(z.object({
        product: z.string(),
        productName: z.string()
    }))
});
