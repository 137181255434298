// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapsibleBody__dd4 {
  overflow: hidden;
}

.collapsibleContent__fb5 {
  transform: translateY(-100%);
  overflow: hidden;
  transition: transform 0.15s;
}

.collapsibleContentExpanded__f96 {
  transform: translateY(0%);
  transition: transform 0.15s;
}

.title__ecb {
  padding: 0;
}

.title__ecb svg {
  transition: transform 0.7s;
  margin: 0 -4px;
}

.titleExpanded__ec7 svg {
  transform: rotate(90deg);
  transition: transform 0.4s;
}
`, "",{"version":3,"sources":["webpack://./src/components/collapsible/collapsible.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,wBAAwB;EACxB,0BAA0B;AAC5B","sourcesContent":[".collapsibleBody {\n  overflow: hidden;\n}\n\n.collapsibleContent {\n  transform: translateY(-100%);\n  overflow: hidden;\n  transition: transform 0.15s;\n}\n\n.collapsibleContentExpanded {\n  transform: translateY(0%);\n  transition: transform 0.15s;\n}\n\n.title {\n  padding: 0;\n}\n\n.title svg {\n  transition: transform 0.7s;\n  margin: 0 -4px;\n}\n\n.titleExpanded svg {\n  transform: rotate(90deg);\n  transition: transform 0.4s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapsibleBody": `collapsibleBody__dd4`,
	"collapsibleContent": `collapsibleContent__fb5`,
	"collapsibleContentExpanded": `collapsibleContentExpanded__f96`,
	"title": `title__ecb`,
	"titleExpanded": `titleExpanded__ec7`
};
export default ___CSS_LOADER_EXPORT___;
