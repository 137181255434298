import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useCompanyName } from '@app/hooks/use-company-name';
import { DownloadMachineConfigurationButton, useMachineConfigDownloadButtonProps } from '@components/machine-config';
import { ExternalLinks } from '@components/constants/constants';
import NavigationLink from '@components/navigation/navigation-link';
import { CompanyNameEditor } from './company-name-editor';
import { ServerUrl } from './server-url/server-url';
import { LogoEditor } from './logo-editor/logo-editor';
import styles from './client-machine-configuration.css';
export function ClientMachineConfiguration(props) {
    const { className } = props;
    const { companyName, isLoading: companyNameIsLoading } = useCompanyName();
    const machineConfigProps = useMachineConfigDownloadButtonProps(`Join ${companyName}`);
    return (_jsxs("section", { className: className, children: [_jsx("div", { children: _jsxs("p", { children: ["Server URL: ", _jsx(ServerUrl, {})] }) }), _jsxs("div", { className: styles.companyName, children: ["Company name:", _jsx(CompanyNameEditor, { className: styles.companyNameEditor })] }), _jsx(LogoEditor, { className: styles.logo }), _jsxs("div", { className: styles.connectionBlock, children: [_jsx(H3, { children: "Connect to Server" }), _jsxs("div", { className: styles.infoText, children: ["Distribute the configuration file to developer machines within your organization to connect them to the server via the Toolbox App. For more information,", _jsx("br", {}), _jsx(NavigationLink, { target: "_blank", href: ExternalLinks.JB_TBE_DOCS_DISTRIBUTE_CONFIGURATION, children: "refer to the documentation" }), "."] })] }), _jsxs("div", { className: styles.actions, children: [!companyNameIsLoading && (_jsx(Button, { "data-test": "join-organization-button", ...machineConfigProps })), _jsx(DownloadMachineConfigurationButton, { children: "Download client machine configuration" })] })] }));
}
