// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paragraph__cef {
  margin: var(--ring-unit) 0;
  width: 100%;
  height: 12px;
  background-color: RGBA(var(--ring-icon-disabled-components), 0.4);
  border-radius: var(--ring-border-radius);
  transition: width 0.6s ease;
  position: relative;
  z-index: 1;
}

.paragraph__cef:after {
  animation: wave-animation__dff 2000ms linear infinite;
  opacity: 0.7;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    var(--ring-content-background-color),
    rgba(255, 255, 255, 0)
  );
  background-repeat: no-repeat;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.paragraphShort__a15 {
  width: 45%;
}

@keyframes wave-animation__dff {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/loaders/paragraph-loader.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,WAAW;EACX,YAAY;EACZ,iEAAiE;EACjE,wCAAwC;EACxC,2BAA2B;EAC3B,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,qDAAgD;EAChD,YAAY;EACZ;;;;;GAKC;EACD,4BAA4B;EAC5B,SAAS;EACT,WAAW;EACX,OAAO;EACP,kBAAkB;EAClB,QAAQ;EACR,MAAM;AACR;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".paragraph {\n  margin: var(--ring-unit) 0;\n  width: 100%;\n  height: 12px;\n  background-color: RGBA(var(--ring-icon-disabled-components), 0.4);\n  border-radius: var(--ring-border-radius);\n  transition: width 0.6s ease;\n  position: relative;\n  z-index: 1;\n}\n\n.paragraph:after {\n  animation: wave-animation 2000ms linear infinite;\n  opacity: 0.7;\n  background-image: linear-gradient(\n    to right,\n    rgba(255, 255, 255, 0),\n    var(--ring-content-background-color),\n    rgba(255, 255, 255, 0)\n  );\n  background-repeat: no-repeat;\n  bottom: 0;\n  content: '';\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n}\n\n.paragraphShort {\n  width: 45%;\n}\n\n@keyframes wave-animation {\n  from {\n    transform: translateX(-100%);\n  }\n  to {\n    transform: translateX(100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraph": `paragraph__cef`,
	"wave-animation": `wave-animation__dff`,
	"paragraphShort": `paragraphShort__a15`
};
export default ___CSS_LOADER_EXPORT___;
