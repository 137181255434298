// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeholderButton__d10 {
  color: var(--ring-disabled-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/ide-plugins/new-auto-installed-plugins/fields/plugin-id-field.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC","sourcesContent":[".placeholderButton {\n  color: var(--ring-disabled-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholderButton": `placeholderButton__d10`
};
export default ___CSS_LOADER_EXPORT___;
