import { getServiceClient } from '../components/auth/auth';
import { setUsageDataHistoryFetchError, setUsageDataHistoryInfo, setUsageDataHistoryIsFetching, setUsageDataHistoryRefreshError } from '../store/usage-data-history';
export function getUsageDataHistory() {
    return async (dispatch) => {
        try {
            dispatch(setUsageDataHistoryIsFetching(true));
            const response = await getServiceClient().get('/api/usage-data/history');
            const { data } = response;
            dispatch(setUsageDataHistoryInfo(data));
        }
        catch (e) {
            dispatch(setUsageDataHistoryFetchError(e));
        }
    };
}
export function rescanUsageDataHistory() {
    return async (dispatch) => {
        try {
            dispatch(setUsageDataHistoryIsFetching(true));
            const response = await getServiceClient().post('/api/usage-data/history/rescan');
            const { data } = response;
            dispatch(setUsageDataHistoryInfo(data));
        }
        catch (e) {
            dispatch(setUsageDataHistoryRefreshError(e));
        }
    };
}
