import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { noop } from 'lodash';
import Button from '@jetbrains/ring-ui/components/button/button';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import Text from '@jetbrains/ring-ui/components/text/text';
import checkMarkIcon from '@jetbrains/icons/checkmark';
// eslint-disable-next-line import/no-cycle
import { useTbeLicense, useTbeLicenseMutation } from '@app/queries/tbe-license';
import auth from '@components/auth/auth';
import { LicenseActivationContent } from '@components/license/license-activation-content';
import { PageTitle } from '@components/page-layout/page-title';
import NavigationLink from '@components/navigation/navigation-link';
import { useOpenModal } from '@components/modals/hooks';
import { LicenseGenerateNewPublicKeyDialog } from '@components/license/license-generate-new-public-key-dialog';
import styles from './license-activation-page.css';
import dialogStyles from './license-activation-dialog.css';
export function LicenseActivationPage() {
    const [licenseKeyDraft, setLicenseKeyDraft] = useState('');
    const { mutateAsync: licenseKeyMutation, isLoading: isMutating, isSuccess } = useTbeLicenseMutation();
    const { data } = useTbeLicense();
    const isValidating = useMemo(() => (data === null || data === void 0 ? void 0 : data.status) === 'VALIDATING', [data]);
    const isLicenseEnabled = useMemo(() => (data === null || data === void 0 ? void 0 : data.status) === 'ACTIVE', [data]);
    const licenceValidated = isSuccess && isLicenseEnabled;
    const handleActivation = async () => {
        if (licenseKeyDraft) {
            await licenseKeyMutation(licenseKeyDraft)
                .then(() => {
                window.location.reload();
            })
                .catch(noop);
        }
    };
    const generateNewPublicKey = useOpenModal(LicenseGenerateNewPublicKeyDialog, {});
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: "Activate JetBrains IDE Services" }), auth.hasRoles(['ADMIN']) ? (_jsxs("div", { className: styles.licenceActivationForm, children: [_jsx(LicenseActivationContent, { inputValue: licenseKeyDraft, inputValueOnChange: setLicenseKeyDraft }), isValidating && (_jsx("div", { children: _jsx(LoaderInline, { children: "JetBrains IDE Services is currently updating your license details." }) })), licenceValidated && (_jsxs("div", { className: dialogStyles.successMessageContainer, children: [_jsx(Icon, { glyph: checkMarkIcon, className: dialogStyles.successIcon }), _jsx(Text, { children: "JetBrains IDE Services license is now valid. Please wait for application to reload." })] })), _jsxs("div", { children: [_jsxs("div", { className: styles.licenceActivationPrimaryActions, children: [_jsx(Button, { onClick: handleActivation, loader: isMutating, disabled: !licenseKeyDraft.trim() || isMutating || isValidating, "data-test": "license-activation-button", primary: true, children: "Activate" }), _jsx(NavigationLink, { href: "/configuration/license-activation/history", children: "View license history" })] }), _jsx(Button, { onClick: generateNewPublicKey, loader: isMutating, danger: true, className: styles.generateNewKeyButton, children: "Generate new public key" })] })] })) : (_jsx(Text, { children: "The JetBrains IDE Services instance for your organization is not activated yet. Please contact your administrator." }))] }));
}
