import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useLoaderData } from 'react-router-dom';
import ButtonGroup from '@jetbrains/ring-ui/components/button-group/button-group';
import { createEnumParam, NumberParam, StringParam } from 'serialize-query-params';
import { noop } from 'lodash';
import { booleanSortOrder, DEFAULT_TABLE_PAGE_SIZE, pagingSortOrder } from '@api/paging-utils';
import { ensureProfileDetailsQueryData, useUsersByProfileId } from '@app/queries/profiles';
import { useSyncIdpGroups } from '@app/queries/groups';
import { PageTitle } from '@components/page-layout/page-title';
import { transformPseudoBooleanToBoolean, useSyncedSearchArgs } from '@components/navigation/use-synced-search-args';
import { useModalsController } from '@components/modals';
import auth from '@components/auth/auth';
import UsersListBody, { usersSortableColumns } from './users-list-body';
import GroupsListBody, { groupsSortableColumns } from './groups-list-body';
import AssignUserDialog from './assign-dialog/assign-user-dialog';
import styles from './profile-users-page.css';
var ListedItems;
(function (ListedItems) {
    ListedItems["USERS"] = "users";
    ListedItems["GROUPS"] = "groups";
})(ListedItems || (ListedItems = {}));
export function profileUsersPageResolver(profileId) {
    return ensureProfileDetailsQueryData(profileId);
}
function getDefaultFilters() {
    return {
        role: null,
        isActive: null,
        query: null
    };
}
export function ProfileUsersPage() {
    var _a, _b, _c, _d;
    const profile = useLoaderData();
    const { data } = useUsersByProfileId(profile === null || profile === void 0 ? void 0 : profile.id, getDefaultFilters(), !!(profile === null || profile === void 0 ? void 0 : profile.id));
    const { mutateAsync: syncMutation, isLoading } = useSyncIdpGroups();
    const users = useMemo(() => data === null || data === void 0 ? void 0 : data.items, [data]);
    const total = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0; }, [data]);
    const modalsController = useModalsController();
    const { idpImportEnabled } = auth.getFeatures();
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema, validateProfileUsersFilter);
    const userIds = useMemo(() => { var _a; return (_a = users === null || users === void 0 ? void 0 : users.map(user => user.id)) !== null && _a !== void 0 ? _a : []; }, [users]);
    const titleParts = useMemo(() => {
        return [
            { title: 'Profiles', href: '/profiles' },
            { title: profile === null || profile === void 0 ? void 0 : profile.title, href: `/profiles/${profile === null || profile === void 0 ? void 0 : profile.id}` },
            { title: `${total} Users` }
        ];
    }, [profile === null || profile === void 0 ? void 0 : profile.id, profile === null || profile === void 0 ? void 0 : profile.title, total]);
    const handlePageChangeOnUnassign = () => {
        if (urlParams.page >= total / DEFAULT_TABLE_PAGE_SIZE + 1) {
            setUrlParams({
                ...urlParams,
                page: 1
            });
        }
    };
    const handleAssign = async () => {
        const assignUserDialog = modalsController.openModal(AssignUserDialog, {
            userIds,
            profileName: profile === null || profile === void 0 ? void 0 : profile.title,
            profileId: profile === null || profile === void 0 ? void 0 : profile.id,
            onReject() {
                handlePageChangeOnUnassign();
                assignUserDialog.closeModal();
            }
        });
    };
    const handleListChange = (type) => {
        if (type === urlParams.type) {
            return;
        }
        if (type === ListedItems.USERS) {
            setUrlParams({
                ...urlParams,
                isActive: true,
                query: '',
                sortKey: usersSortableColumns.username,
                sortOrder: 'DESC',
                type
            });
        }
        if (type === ListedItems.GROUPS) {
            setUrlParams({
                ...urlParams,
                isActive: null,
                role: null,
                query: '',
                sortKey: groupsSortableColumns.name,
                sortOrder: 'DESC',
                type
            });
        }
    };
    const handleSyncClick = async () => {
        await syncMutation().catch(noop);
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { parts: titleParts, rightPanel: _jsxs("div", { className: styles.assignmentButtons, "data-test": "profile-users-action-buttons", children: [idpImportEnabled && (_jsx(Button, { text: true, onClick: handleSyncClick, disabled: isLoading, children: "Synchronize users" })), _jsx(Button, { onClick: handleAssign, primary: true, children: "Assign..." })] }) }), _jsxs(ButtonGroup, { children: [_jsx(Button, { active: urlParams.type === ListedItems.USERS, onClick: () => handleListChange(ListedItems.USERS), children: "Users list" }), _jsx(Button, { active: urlParams.type === ListedItems.GROUPS, onClick: () => handleListChange(ListedItems.GROUPS), children: "Groups list" })] }), urlParams.type === ListedItems.USERS && (_jsx(UsersListBody, { urlParams: urlParams, setUrlParams: params => setUrlParams({ ...params, type: ListedItems.USERS }), profileId: (_a = profile === null || profile === void 0 ? void 0 : profile.id) !== null && _a !== void 0 ? _a : '', profileTitle: (_b = profile.title) !== null && _b !== void 0 ? _b : '' })), urlParams.type === ListedItems.GROUPS && (_jsx(GroupsListBody, { urlParams: urlParams, setUrlParams: params => setUrlParams({
                    ...params,
                    type: ListedItems.GROUPS,
                    role: null,
                    isActive: null
                }), profileId: (_c = profile === null || profile === void 0 ? void 0 : profile.id) !== null && _c !== void 0 ? _c : '', profileTitle: (_d = profile.title) !== null && _d !== void 0 ? _d : '' }))] }));
}
const tsTypeToQuerySchema = {
    role: StringParam,
    isActive: createEnumParam(booleanSortOrder),
    query: StringParam,
    page: NumberParam,
    sortKey: createEnumParam(Object.values({ ...usersSortableColumns, ...groupsSortableColumns })),
    sortOrder: createEnumParam(pagingSortOrder),
    type: createEnumParam([ListedItems.GROUPS, ListedItems.USERS])
};
function validateProfileUsersFilter(parsed) {
    var _a, _b, _c, _d, _e, _f;
    if (parsed.type === ListedItems.GROUPS) {
        return {
            type: parsed.type,
            role: null,
            isActive: null,
            query: (_a = parsed.query) !== null && _a !== void 0 ? _a : '',
            page: parsed.page && parsed.page > 0 ? parsed.page : 1,
            sortKey: parsed.sortKey && Object.values(groupsSortableColumns).includes(parsed.sortKey)
                ? parsed.sortKey
                : groupsSortableColumns.name,
            sortOrder: (_b = parsed.sortOrder) !== null && _b !== void 0 ? _b : 'ASC'
        };
    }
    const isActive = transformPseudoBooleanToBoolean(parsed.isActive);
    return {
        type: (_c = parsed.type) !== null && _c !== void 0 ? _c : ListedItems.USERS,
        role: (_d = parsed.role) !== null && _d !== void 0 ? _d : null,
        isActive,
        query: (_e = parsed.query) !== null && _e !== void 0 ? _e : '',
        page: parsed.page && parsed.page > 0 ? parsed.page : 1,
        sortKey: parsed.sortKey && Object.values(usersSortableColumns).includes(parsed.sortKey)
            ? parsed.sortKey
            : usersSortableColumns.username,
        sortOrder: (_f = parsed.sortOrder) !== null && _f !== void 0 ? _f : 'ASC'
    };
}
