import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import Input from '@jetbrains/ring-ui/components/input/input';
import Button from '@jetbrains/ring-ui/components/button/button';
import classNames from 'classnames';
import pencilIcon from '@jetbrains/icons/pencil';
import { ControlsHeight } from '@jetbrains/ring-ui/components/global/controls-height';
import styles from './inline-editable-field.css';
export function InlineEditableField(props) {
    const { currentValue, className, onNewValue, ...restInputProps } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(currentValue);
    const [pendingNewValue, setPendingNewValue] = useState(false);
    useEffect(() => {
        if (currentValue !== value) {
            setValue(currentValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue]);
    const handleInputChange = useCallback((e) => {
        setValue(e.target.value);
    }, [setValue]);
    const handlePencilClick = useCallback(() => {
        setIsEditing(true);
        setValue(currentValue);
    }, [currentValue]);
    const handleSave = useCallback(async () => {
        if (value === currentValue) {
            setIsEditing(false);
        }
        if (!!value && value !== currentValue) {
            setPendingNewValue(true);
            await onNewValue(value);
            setIsEditing(false);
            setPendingNewValue(false);
        }
    }, [currentValue, onNewValue, value]);
    const handleCancel = useCallback(() => {
        setValue(currentValue);
        setIsEditing(false);
    }, [currentValue]);
    if (isEditing) {
        return (_jsx("div", { className: classNames(styles.inputWrapper, className), children: _jsxs("div", { className: styles.inputWrapperInner, children: [_jsx(Input, { autoFocus: true, value: value, onChange: handleInputChange, className: styles.input, ...restInputProps }), _jsx(Button, { primary: true, onClick: handleSave, disabled: !(value === null || value === void 0 ? void 0 : value.trim()) || pendingNewValue, loader: pendingNewValue, children: "Save" }), _jsx(Button, { onClick: handleCancel, loader: pendingNewValue, children: "Cancel" })] }) }));
    }
    return (_jsxs("span", { className: classNames(styles.fieldWrapper, className), children: [currentValue, _jsx(Button, { icon: pencilIcon, className: styles.pencilBtn, height: ControlsHeight.S, onClick: handlePencilClick, title: "Edit company name" })] }));
}
