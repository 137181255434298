import React from 'react';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import {
  apdexFormat,
  formatAbbreviatedCount,
  longDateFormat,
  shortDateFormat,
  startOfWeekFormat
} from '../../components/util/i18n';
import {Chart, getSeriesColor, getTransparentColor} from './charts';
import styles from './apdex-chart.css';

const unreliableBorderDash = [1, 6];
const unreliableBackgroundColor = '#ffffff';

const satisfactions = [
  {name: 'Excellent', min: 0.94, mid: 0.97, max: 1.0},
  {name: 'Good', min: 0.85, mid: 0.895, max: 0.94},
  {name: 'Fair', min: 0.7, mid: 0.775, max: 0.85},
  {name: 'Poor', min: 0.5, mid: 0.6, max: 0.7},
  {name: 'Unacceptable', min: 0.0, mid: 0.25, max: 0.5}
];

/**
 * @typedef {{
 *   range: ApdexChartRange,
 *   series: ApdexChartSeries[]
 * }} ApdexChartData
 *
 * @typedef {{
 *   minDate: Date,
 *   maxDate: Date,
 *   interval: 'DAY'|'WEEK'
 * }} ApdexChartRange
 *
 * @typedef {{
 *   label: string,
 *   data: ApdexChartPoint[]
 * }} ApdexChartSeries
 *
 * @typedef {{
 *   date: string,
 *   score: number,
 *   scoreConfidenceInterval: ?{start: number, end: number},
 *   deviceCount: number
 * }} ApdexChartPoint
 */

/**
 * @param {boolean} loading
 * @param {ApdexChartData} data
 */
export default function ApdexChart({loading, data}) {
  return (
    <div className={styles.root}>
      <Chart
        width={700}
        height={400}
        type="line"
        data={{
          datasets: data.series.flatMap((series, index) => [
            {
              label: series.label,
              data: series.data,
              parsing: {
                xAxisKey: 'date',
                yAxisKey: 'score'
              },
              pointRadius: 2,
              borderCapStyle: 'round',
              borderColor: getSeriesColor(index),
              backgroundColor: getSeriesColor(index),
              pointBackgroundColor: ctx =>
                ctx.element?.raw?.scoreConfidenceInterval != null
                  ? getSeriesColor(index)
                  : unreliableBackgroundColor,
              segment: {
                borderDash: ctx =>
                  ctx.p0.raw?.scoreConfidenceInterval != null &&
                  ctx.p1.raw?.scoreConfidenceInterval != null
                    ? undefined
                    : unreliableBorderDash
              }
            },
            {
              data: series.data,
              parsing: {
                xAxisKey: 'date',
                yAxisKey: 'scoreConfidenceInterval.start'
              },
              showLine: false,
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: getTransparentColor(getSeriesColor(index))
            },
            {
              data: series.data,
              parsing: {
                xAxisKey: 'date',
                yAxisKey: 'scoreConfidenceInterval.end'
              },
              showLine: false,
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: getTransparentColor(getSeriesColor(index)),
              fill: '-1'
            }
          ])
        }}
        options={{
          scales: {
            x: {
              type: 'time',
              min: data.range.minDate,
              max: data.range.maxDate,
              time: {
                unit: {
                  DAY: 'day',
                  WEEK: 'week'
                }[data.range.interval],
                isoWeekday: true,
                displayFormats: {
                  day: shortDateFormat,
                  week: shortDateFormat
                },
                tooltipFormat: {
                  DAY: longDateFormat,
                  WEEK: startOfWeekFormat
                }[data.range.interval]
              },
              grid: {
                display: false
              }
            },
            y: {
              type: 'linear',
              title: {
                display: true,
                text: 'APDEX score'
              },
              min: 0,
              max: 1,
              ticks: {
                stepSize: 0.2,
                format: apdexFormat
              },
              grid: {
                display: false
              }
            },
            satisfactionLabels: {
              position: 'right',
              grid: {
                display: false
              },
              ticks: {
                font: {
                  size: 11
                }
              },
              afterTickToLabelConversion: axis => {
                axis.ticks = satisfactions.map(satisfaction => ({
                  value: satisfaction.mid,
                  label: satisfaction.name
                }));
              }
            },
            satisfactionLines: {
              ticks: {
                display: false
              },
              grid: {
                drawBorder: false,
                drawTicks: false,
                borderDash: [3, 3]
              },
              afterTickToLabelConversion: axis => {
                axis.ticks = satisfactions.map(satisfaction => ({
                  value: satisfaction.max
                }));
              }
            }
          },
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
          },
          animation: {
            duration: 0
          },
          plugins: {
            tooltip: {
              filter: tooltipItem => tooltipItem.dataset.label != null,
              callbacks: {
                label: tooltipItem => formatTooltip(tooltipItem.dataset.label, tooltipItem.raw)
              }
            }
          }
        }}
      />
      {loading && <Loader />}
    </div>
  );
}

/**
 * @param {string} seriesLabel
 * @param {ApdexChartPoint} point
 * @returns {string}
 */
function formatTooltip(seriesLabel, point) {
  const score = apdexFormat.format(point.score);
  const deviceCount = formatAbbreviatedCount(point.deviceCount);
  if (point.scoreConfidenceInterval != null) {
    const ciStart = apdexFormat.format(point.scoreConfidenceInterval.start);
    const ciEnd = apdexFormat.format(point.scoreConfidenceInterval.end);
    return `${score} [${ciStart}, ${ciEnd}] — ${seriesLabel} (${deviceCount} users)`;
  }
  return `${score} (approx.) — ${seriesLabel} (${deviceCount} users)`;
}
