import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import List from '@jetbrains/ring-ui/components/list/list';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './sidebar-menu.css';
class SidebarList extends List {
}
function highlightActiveEntry(menuItems, activeIndex) {
    return menuItems.map((it, idx) => {
        if (it.rgItemType === List.ListProps.Type.TITLE) {
            return {
                ...it,
                className: classNames(styles.listItem, styles.listItemTitle)
            };
        }
        if (idx === activeIndex) {
            return {
                className: classNames(styles.listItem, styles.listItemSelected),
                ...it
            };
        }
        return {
            className: styles.listItem,
            hoverClassName: styles.listItemHovered,
            ...it
        };
    });
}
export function SidebarMenu(props) {
    const { menuItems } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const activeIndex = menuItems.findIndex(item => !!item.page && location.pathname.startsWith(item.page));
    const itemsWithHighlightActiveEntry = useMemo(() => highlightActiveEntry(menuItems, activeIndex), [activeIndex, menuItems]);
    const handleSelect = useCallback((item) => {
        if (item.page) {
            navigate(item.page);
        }
    }, [navigate]);
    return (_jsx(SidebarList, { data: itemsWithHighlightActiveEntry, activateSingleItem: true, activeIndex: activeIndex, onSelect: handleSelect, renderOptimization: false }));
}
