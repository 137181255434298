import React from 'react';
import styles from './key-value-row.css';

export default function KeyValueRow({
  lhs, // cant use key-value here since key property in react is internal and not forwarded to component
  rhs
}) {
  return (
    <div className={styles.row}>
      <div className={styles.key}>{lhs}</div>
      <div className={styles.value}>{rhs}</div>
    </div>
  );
}
