import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSetting, updateVMOptions } from '@app/store/profiles/actions';
import { getVmOptionsSettings } from '@app/store/profiles/selectors';
import { SettingsList } from '../../common/settings-list';
import SettingsListEntry from '../../common/settings-list-entry';
import { SettingPlaceholder } from '../../common/settings-placeholder';
import { useSettingsContext } from '../../context/settings-context';
import { showFilteredSettings, showHiddenSettingsPlaceholder, showNoSettingsPlaceholder } from '../../functions/settings';
import { filterByFocusedApp, useProfilesParams } from '../../context/profiles-params-context';
import { VmOptionsDialogForm } from './profiles-vm-options-form';
export function ProfileVMOptions() {
    const dispatch = useDispatch();
    const vmOptions = useSelector(getVmOptionsSettings);
    const { focusedApp } = useProfilesParams();
    const filteredVmOptions = filterByFocusedApp(vmOptions, focusedApp);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { setFormState, showForm, resetFormState, settingId } = useSettingsContext('vmOptions');
    const deleteEntry = (settingId) => {
        dispatch(deleteSetting({ settingType: 'vmOptions', settingId }));
    };
    const submit = (values) => {
        dispatch(updateVMOptions(values));
        setIsCollapsed(false);
        resetFormState();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(SettingsList, { title: "vm options", isCollapsed: isCollapsed, onToggleCollapsed: () => setIsCollapsed(prev => !prev), children: [showNoSettingsPlaceholder(vmOptions) && _jsx(SettingPlaceholder, { title: "No VM options yet" }), showHiddenSettingsPlaceholder(vmOptions, filteredVmOptions) && (_jsx(SettingPlaceholder, { title: vmOptions.length === 1
                            ? 'One VM option is hidden'
                            : `${vmOptions.length} VM options are hidden` })), showFilteredSettings(filteredVmOptions) &&
                        filteredVmOptions.map(entry => (_jsx(SettingsListEntry, { entry: entry, onEditClick: setFormState, onDeleteClick: deleteEntry, settingType: "vmOptions" }, entry.id)))] }), showForm && (_jsx(VmOptionsDialogForm, { onReject: resetFormState, item: vmOptions.find(({ id }) => id === settingId), onSubmit: submit }))] }));
}
