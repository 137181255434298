import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import ToolbarItem from '@components/toolbar-item/toolbar-item';
const isActiveOptions = [
    { key: 1, label: 'Active' },
    { key: 0, label: 'Deactivated' }
];
function ActiveFilter({ isActive, onChange }) {
    const handleChange = useCallback((selected) => {
        onChange(selected === null ? undefined : !!selected.key);
    }, [onChange]);
    return (_jsx(ToolbarItem, { label: "Status", children: _jsx(Select, { clear: true, label: "All", data: isActiveOptions, size: Select.Size.S, selected: isActive === undefined
                ? undefined
                : isActiveOptions.find(option => !!option.key === isActive), onChange: handleChange }) }));
}
export default ActiveFilter;
