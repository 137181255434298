const SET_CURRENT_BATCH = 'SET_CURRENT_BATCH';
const SET_CURRENT_BATCH_IS_FETCHING = 'SET_CURRENT_BATCH_IS_FETCHING';
const SET_CURRENT_BATCH_FETCH_ERROR = 'SET_CURRENT_BATCH_FETCH_ERROR';

const defaultState = {
  items: [],
  isFetching: true,
  isFetchError: false
};

export default function usageDataBatchesReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_CURRENT_BATCH:
      return {
        ...state,
        items: action.payload,
        isFetching: false
      };
    case SET_CURRENT_BATCH_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case SET_CURRENT_BATCH_FETCH_ERROR:
      return {
        ...state,
        isFetchError: action.payload,
        isFetching: false
      };
    default:
      return state;
  }
}

export function setCurrentBatchInfo(transfers) {
  return {type: SET_CURRENT_BATCH, payload: transfers};
}

export function setIsFetching(bool) {
  return {type: SET_CURRENT_BATCH_IS_FETCHING, payload: bool};
}

export function setFetchError(bool) {
  return {type: SET_CURRENT_BATCH_FETCH_ERROR, payload: bool};
}
