// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__dc9 {
  height: calc(var(--ring-unit) * 6.5);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-users/filters/groups-filter.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC","sourcesContent":[".container {\n  height: calc(var(--ring-unit) * 6.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__dc9`
};
export default ___CSS_LOADER_EXPORT___;
