import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Group from '@jetbrains/ring-ui/components/group/group';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
import Icon from '@jetbrains/ring-ui/components/icon';
import chevronDown from '@jetbrains/icons/chevron-down';
import { noop } from 'lodash';
import { useSyncIdpGroups } from '@app/queries/groups';
import { PageTitle } from '@components/page-layout/page-title';
import auth from '@components/auth/auth';
import UsersPageBody from './users-page-body';
import UsersPagePopups, { PopupType } from './users-page-popups';
const DROPDOWN_OPTIONS = [
    PopupType.INVITATION_LINK,
    PopupType.CONFIGURATION_FILE,
    PopupType.MANUALL
].map(label => ({
    label,
    key: label
}));
export default function UsersAdminPage() {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const canAssignProfile = selectedUsers.length !== 0;
    const [currentPopup, setCurrentPopup] = useState();
    const { mutateAsync: syncMutation, isLoading } = useSyncIdpGroups();
    const { idpImportEnabled } = auth.getFeatures();
    const handleSyncClick = async () => {
        await syncMutation().catch(noop);
    };
    return (_jsxs("div", { children: [_jsx(PageTitle, { title: "Users", rightPanel: _jsxs(Group, { children: [idpImportEnabled && (_jsx(Button, { text: true, onClick: handleSyncClick, disabled: isLoading, "data-test": "synchronize-button", children: "Synchronize users" })), canAssignProfile && (_jsx(Button, { primary: true, onClick: () => setCurrentPopup(PopupType.ADD_PROFILE), children: "Assign profile" })), _jsx(DropdownMenu, { anchor: _jsxs(Button, { children: ["Add users ", _jsx(Icon, { glyph: chevronDown })] }), onSelect: ({ label }) => setCurrentPopup(label), data: DROPDOWN_OPTIONS })] }) }), _jsx(UsersPageBody, { onSelectUsers: setSelectedUsers }), _jsx(UsersPagePopups, { selectedUsers: selectedUsers, currentPopup: currentPopup, setCurrentPopup: setCurrentPopup })] }));
}
