export const assignTestId = (...testIds) => {
    const id = testIdsToString(...testIds);
    return id ? { 'data-test': id } : {};
};
const testIdsToString = (...testIds) => {
    return testIds.map(mapTestId).filter(Boolean).join(' ');
};
function mapTestId(testId) {
    if (!testId) {
        return undefined;
    }
    if (typeof testId === 'object') {
        if (isComputedTestId(testId)) {
            return testId['data-test'];
        }
        return Object.keys(testId)
            .map(key => (testId[key] ? key : undefined))
            .filter(Boolean)
            .join(' ');
    }
    return testId.toString();
}
function isComputedTestId(testId) {
    if (!testId) {
        return false;
    }
    return typeof testId === 'object' && 'data-test' in testId;
}
