import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import { matchPath, useBlocker, useParams } from 'react-router-dom';
import { ConfirmationDialog } from './confirmation-dialog';
const useBeforeunload = (handler) => {
    const eventListenerRef = useRef(handler);
    useEffect(() => {
        eventListenerRef.current = handler;
    }, [handler]);
    useEffect(() => {
        const eventListener = (event) => {
            eventListenerRef.current(event);
        };
        window.addEventListener('beforeunload', eventListener);
        return () => {
            window.removeEventListener('beforeunload', eventListener);
        };
    }, []);
};
/**
 * Caution! This component for some unknown for me reason is working badly in nested forms.
 * Keeping the component only in the root form is fine.
 */
export function RouteLeavingGuard(props) {
    const { when, allowedRoutesPatterns } = props;
    const currentParams = useParams();
    const blocker = useBlocker(({ nextLocation }) => {
        if (when && (allowedRoutesPatterns === null || allowedRoutesPatterns === void 0 ? void 0 : allowedRoutesPatterns.length)) {
            return !allowedRoutesPatterns.some(allowedRoute => {
                const nextMatchPath = matchPath(allowedRoute, nextLocation.pathname);
                return !!nextMatchPath && isCurrentParamsExistsInNext(currentParams, nextMatchPath.params);
            });
        }
        return when;
    });
    const closeModal = useCallback(() => {
        if (blocker.state === 'blocked') {
            blocker.reset();
        }
    }, [blocker]);
    const handleConfirmNavigationClick = useCallback(() => {
        if (blocker.state === 'blocked') {
            blocker.proceed();
        }
    }, [blocker]);
    useBeforeunload(() => when);
    useEffect(() => {
        window.onbeforeunload = when ? () => true : null;
        return () => {
            window.onbeforeunload = null;
        };
    }, [when]);
    return (_jsx(ConfirmationDialog, { modalVisible: blocker.state === 'blocked', handleConfirmNavigationClick: handleConfirmNavigationClick, closeModal: closeModal }));
}
function isCurrentParamsExistsInNext(currentParams, nextParams) {
    return Object.keys(currentParams).every(key => currentParams[key] === nextParams[key]);
}
