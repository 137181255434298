import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import trash from '@jetbrains/icons/trash';
import { isApplicationRuleChanged } from '@app/store/profiles/selectors';
import { SettingLayout } from '../common/setting-layout';
import { RestrictionPreview } from '../common/restriction-preview';
export function NotificationListEntry({ entry, onEditClick, onDeleteClick }) {
    const { id } = entry;
    const isChanged = useSelector(state => isApplicationRuleChanged(state, id));
    return (_jsx(SettingLayout, { isChanged: isChanged, icons: [
            {
                glyph: trash,
                onClick: () => onDeleteClick(id),
                title: 'Remove restriction'
            }
        ], onTileClick: () => onEditClick(id), children: _jsx(RestrictionPreview, { restrictionRule: entry }) }, id));
}
