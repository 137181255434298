import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import ErrorMessage from '@jetbrains/ring-ui/components/error-message/error-message';
import permissionIcon from '@jetbrains/icons/permission';
import Button from '@jetbrains/ring-ui/components/button/button';
import auth from '../auth/auth';
import styles from './error-page.css';
const changeUser = () => auth.logout().then(() => auth.login());
const login = () => auth.login();
export default function ForbiddenPage(props) {
    var _a;
    const isGuest = useMemo(() => !auth.isAuthenticated(), []);
    const userName = (_a = auth.getUserProfile()) === null || _a === void 0 ? void 0 : _a.username;
    const description = isGuest
        ? "Unfortunately, you are not allowed to access the page you've requested. It seems you don't have sufficient permissions."
        : `Unfortunately, you are not allowed to access the page you've requested. You are logged in as ${userName}. Please change your account or contact the administrator.`;
    return (_jsxs(ErrorMessage, { icon: permissionIcon, className: styles.errorPage, code: "403", message: "Woah, you can't touch this!", description: description, ...props, children: [userName && (_jsx(Button, { "data-test": "forbidden-page-change-user-button", onClick: changeUser, children: "Change user" })), isGuest && (_jsx(Button, { primary: true, onClick: login, "data-test": "forbidden-page-login-button", children: "Login..." }))] }));
}
