import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createEnumParam } from 'serialize-query-params';
import { getApplications } from '@app/store/profiles/selectors';
import { useUserPluginRestrictedMode } from '@app/pages/profiles/profile-details/profile-ide-provisioner/hooks/user-restricted-modes';
import { useSyncedSearchArgs } from '@components/navigation/use-synced-search-args';
const tsTypeToQuerySchema = (availableApps) => {
    return {
        id: createEnumParam(availableApps)
    };
};
function validateProfilesFilter(parsed) {
    var _a;
    return {
        id: (_a = parsed.id) !== null && _a !== void 0 ? _a : undefined
    };
}
export const ProfilesParamsContext = createContext({
    focusedApp: { id: undefined },
    clearParams: () => undefined,
    setFocusedApp: () => undefined
});
export function useProfilesParams() {
    const context = useContext(ProfilesParamsContext);
    if (context === undefined) {
        throw new Error('useProfilesParams must be used within a ProfilesParamsProvider');
    }
    return context;
}
export function ProfilesParamsProvider({ children }) {
    const apps = useSelector(getApplications);
    const { isRestricted } = useUserPluginRestrictedMode();
    const availableApplications = apps.content.map(application => application.product);
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema(availableApplications), validateProfilesFilter);
    const clearParams = useCallback(() => {
        return setUrlParams({ id: undefined });
    }, [setUrlParams]);
    useEffect(() => {
        if (isRestricted) {
            clearParams();
        }
    }, [isRestricted, clearParams]);
    const context = useMemo(() => ({ focusedApp: urlParams, setFocusedApp: setUrlParams, clearParams }), [clearParams, setUrlParams, urlParams]);
    return (_jsx(ProfilesParamsContext.Provider, { value: context, children: children }));
}
export function filterByFocusedApp(container, focusedApp) {
    if (!focusedApp.id) {
        return container;
    }
    return container.filter(setting => setting.tools.some(tool => tool.product === (focusedApp === null || focusedApp === void 0 ? void 0 : focusedApp.id)));
}
