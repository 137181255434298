import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { uniq } from 'lodash';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import close from '@jetbrains/icons/close';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import exceptionIcon from '@jetbrains/icons/exception';
import warningIcon from '@jetbrains/icons/warning';
import { useFieldArray, useFormContext } from 'react-hook-form';
import RingFieldsList from '@components/form/ring-fields-list';
import { getSettingDescriptions } from '../ide-settings-description';
import TemplateSelector from './template-selector';
import commonStyles from './common/common.css';
import styles from './add-settings-fields.css';
function AddSettingsFields({ onSettingSelect, ...restProps }) {
    const { formState: { errors } } = useFormContext();
    const { remove, append, fields } = useFieldArray({
        name: 'content'
    });
    const hasDuplicatedDescriptions = uniq(fields.map(x => getSettingDescriptions(x).join('\n'))).length !== fields.length;
    const handleSettingAdded = (setting) => append(setting);
    return (_jsxs(RingFieldsList, { ...restProps, children: [hasDuplicatedDescriptions && (_jsxs("div", { children: [_jsx(Icon, { glyph: warningIcon, className: styles.duplicatedDescriptionsIcon }), _jsx(Text, { className: styles.duplicatedDescriptionsText, children: "You have duplicated descriptions" })] })), !fields.length && _jsx(Text, { info: true, children: "Select and configure IDE settings to propagate" }), fields.map((field, ind) => {
                const descriptions = getSettingDescriptions(field);
                return (_jsxs("div", { children: [_jsxs("div", { className: styles.boxHorizontal, children: [errors.content !== undefined && errors.content[ind] && (_jsx(Icon, { glyph: exceptionIcon, className: styles.incorrectSettingIcon })), _jsx("div", { className: styles.boxVertical, children: descriptions.map(line => (_jsx(Text, { className: styles.boxText, children: line }))) }), _jsx(Button, { icon: close, onClick: () => remove(ind) })] }), _jsx(Button, { text: true, inline: true, className: commonStyles.fitButton, onClick: () => onSettingSelect(ind), children: "Configure" })] }, field.id));
            }), _jsx(TemplateSelector, { onChange: handleSettingAdded })] }));
}
export default AddSettingsFields;
