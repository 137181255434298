import { getServiceClient } from '../components/auth/auth';
export async function getMachineConfig() {
    const res = await getServiceClient().request({
        method: 'GET',
        url: '/api/machine-config',
        headers: { Accept: 'application/json' }
    });
    return res.data;
}
export async function patchMachineConfig(patch) {
    const res = await getServiceClient().patch('/api/machine-config', patch);
    return res.data;
}
