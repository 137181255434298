import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Type } from '@jetbrains/ring-ui/components/list/consts';
import Text from '@jetbrains/ring-ui/components/text/text';
import { AccessLevel, getMainMenuSchema, licenseVaultApi, ModeContext, routes } from '@jetbrains-license-vault/client/lib';
import '@jetbrains-license-vault/client/lib/index.css';
import { Outlet } from 'react-router-dom';
import frownIcon from '@jetbrains/icons/frown';
import ErrorMessage from '@jetbrains/ring-ui/components/error-message/error-message';
import LoaderScreen from '@jetbrains/ring-ui/components/loader-screen/loader-screen';
import Theme, { ThemeProvider } from '@jetbrains/ring-ui/components/global/theme';
import { Sidebar, SidebarFooter, SidebarMenu } from '@components/app-layout';
import auth from '../../components/auth/auth';
import styles from './license-vault-page.css';
export default function LicenseVaultPage() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const userRole = (auth.hasRoles(['ADMIN']) ? 'ADMIN' : 'USER');
    const isAdmin = userRole === AccessLevel.ADMIN;
    const serverMode = licenseVaultApi.server.useServerMode(true);
    const serverInfo = licenseVaultApi.server.useInfo(isAdmin);
    const lvLoadingState = getLVLoadingState(serverMode, serverInfo);
    const menuItems = useMemo(() => {
        if (!isAdmin) {
            return [];
        }
        const navigation = getMainMenuSchema()
            .map(({ link, title }) => ({
            key: link.replace(/^w/g, '-'),
            rgItemType: Type.ITEM,
            page: link,
            label: title
        }))
            .concat([
            {
                key: 'my-licenses',
                rgItemType: Type.ITEM,
                page: routes().admin.currentLicenses.path,
                label: 'My licenses'
            }
        ]);
        return [
            {
                rgItemType: Type.TITLE,
                label: 'License vault'
            },
            ...navigation
        ];
    }, [isAdmin]);
    const errorMessage = (_d = (_b = (_a = serverMode.error) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : (_c = serverInfo.error) === null || _c === void 0 ? void 0 : _c.title) !== null && _d !== void 0 ? _d : 'Invalid data';
    const errorDetails = ((_f = (_e = serverMode.error) === null || _e === void 0 ? void 0 : _e.status) !== null && _f !== void 0 ? _f : (_g = serverInfo.error) === null || _g === void 0 ? void 0 : _g.status) === 503 ? (_jsx(_Fragment, { children: "There seems to be a problem with your License Vault server configuration. Please consult server logs for more details" })) : (_jsx(_Fragment, { children: "Ohh... Something went wrong with License Vault server." }));
    const licenseVaultGeneralClientInfo = serverInfo.data && userRole === AccessLevel.ADMIN && (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsxs(Text, { children: [(_h = serverInfo.data) === null || _h === void 0 ? void 0 : _h.projectName, ", ", (_j = serverInfo.data) === null || _j === void 0 ? void 0 : _j.serverUid] }) }), _jsxs("div", { className: styles.licenseVaultPageSidebarConnectedInfo, children: [_jsx("div", { children: _jsx(Text, { info: true, children: "Last connected to JetBrains" }) }), _jsx("div", { children: _jsxs(Text, { info: true, children: [dayjs((_k = serverInfo.data) === null || _k === void 0 ? void 0 : _k.lastConnection).format('MMMM D, YYYY H:mm'), " "] }) })] })] }));
    return (_jsxs(_Fragment, { children: [isAdmin && (_jsxs(Sidebar, { children: [_jsx(SidebarMenu, { menuItems: menuItems }), _jsx(SidebarFooter, { children: licenseVaultGeneralClientInfo })] })), lvLoadingState === 'success' && (_jsx(ModeContext.Provider, { value: serverMode.data, children: _jsx(ThemeProvider, { className: styles.lvWrapper, theme: Theme.LIGHT, children: _jsx("div", { className: styles.lvWrapperInner, children: _jsx(Outlet, {}) }) }) })), lvLoadingState === 'loading' && _jsx(LoaderScreen, {}), lvLoadingState === 'error' && (_jsx(ErrorMessage, { icon: frownIcon, message: errorMessage, children: errorDetails }))] }));
}
function getLVLoadingState(serverMode, serverInfo) {
    if (serverMode.error || serverInfo.error) {
        return 'error';
    }
    if (serverMode.data && serverInfo.data) {
        return 'success';
    }
    return 'loading';
}
