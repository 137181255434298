import { useMachineConfig } from '../queries/machine-config';
const DEFAULT_BACKEND_COMPANY_NAME = 'Company Name is not set';
export function useCompanyName() {
    const { data, ...restOptions } = useMachineConfig();
    const companyName = (data === null || data === void 0 ? void 0 : data.companyName) && data.companyName !== DEFAULT_BACKEND_COMPANY_NAME
        ? data.companyName
        : 'your organization';
    return {
        companyName: restOptions.isLoading ? '' : companyName,
        ...restOptions
    };
}
