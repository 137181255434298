import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import ErrorBubble from '@jetbrains/ring-ui/components/error-bubble/error-bubble';
import DatePicker from '@jetbrains/ring-ui/components/date-picker/date-picker';
import { localDateString } from '../../../components/dates/format';
import styles from './expiration-date-editor.css';
export default function ExpirationDateEditor({ initialExpirationDate, errorMessage, isSubmitting, onSubmit, onCancel }) {
    const [expirationDate, setExpirationDate] = useState(initialExpirationDate);
    const renderedErrorMessage = useMemo(() => (expirationDate === initialExpirationDate ? errorMessage : ''), [expirationDate, initialExpirationDate, errorMessage]);
    const today = useMemo(localDateString, []);
    return (_jsxs(Dialog, { label: "Dialog", show: true, onCloseAttempt: onCancel, trapFocus: true, autoFocusFirst: false, children: [_jsx(Header, { children: "Set Expiration Date" }), _jsx(Content, { children: _jsx(ErrorBubble, { error: renderedErrorMessage, children: _jsx(DatePicker, { date: expirationDate, onChange: (date) => setExpirationDate(date || null), withTime: true, minDate: today, clear: true }) }) }), _jsxs(Panel, { className: styles.panel, children: [_jsxs("div", { children: [_jsx(Button, { primary: true, loader: isSubmitting, onClick: () => onSubmit(expirationDate), className: styles.okButton, children: "OK" }), _jsx(Button, { onClick: onCancel, children: "Cancel" })] }), _jsx(Button, { danger: true, loader: isSubmitting, onClick: () => onSubmit(null), children: "Reset expiration date" })] })] }));
}
