import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import Button from '@jetbrains/ring-ui/components/button/button';
import ButtonGroup from '@jetbrains/ring-ui/components/button-group/button-group';
import styles from './scope-field.css';
function formatScope(scope) {
    if (scope === 'APPLICATION') {
        return 'Application';
    }
    if (scope === 'PROJECT') {
        return 'Project';
    }
    return scope;
}
const ALL_SCOPES = ['APPLICATION', 'PROJECT'];
const SCOPE_OPTIONS = ALL_SCOPES.map(x => ({
    key: x,
    label: formatScope(x)
}));
function ScopeField({ settingIndex, hidden = false }) {
    const { watch, register, setValue } = useFormContext();
    const watchScope = watch(`content.${settingIndex}.scope`);
    const onClick = (scope) => {
        setValue(`content.${settingIndex}.scope`, scope);
    };
    // ButtonGroup's hidden property doesn't have any effect that's why we render a hidden input instead
    return hidden ? (_jsx("input", { type: "text", hidden: hidden, ...register(`content.${settingIndex}.scope`) })) : (_jsxs("div", { className: styles.fieldContainer, children: [_jsx("span", { className: styles.scopeGroupLabel, children: "Scope" }), _jsx(ButtonGroup, { className: styles.scopeGroup, children: SCOPE_OPTIONS.map(x => (_jsx(Button, { active: watchScope === x.key, onClick: () => onClick(x.key), children: x.label }, x.key))) })] }));
}
export default ScopeField;
