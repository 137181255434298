import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { AlertType } from '@jetbrains/ring-ui/components/alert/alert';
import Button from '@jetbrains/ring-ui/components/button/button';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import giftIcon from '@jetbrains/icons/gift';
import Text from '@jetbrains/ring-ui/components/text/text';
import { AlertTimeouts } from '../constants/constants';
import styles from './render-version-alert.css';
export const renderVersionAlert = (docsLink) => alert.addAlert(_jsxs("div", { className: styles.versionAlertContainer, children: [_jsx(Icon, { glyph: giftIcon }), _jsxs("div", { className: styles.versionAlertText, children: [_jsx(Text, { children: "New Server Version Available" }), _jsx(Button, { type: "button", text: true, inline: true, onClick: () => window.open(docsLink), children: "Go to documentation" })] })] }), AlertType.MESSAGE, AlertTimeouts.LONG, {
    className: styles.versionAlert
});
