import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import chevronDown from '@jetbrains/icons/chevron-down';
import chevronUp from '@jetbrains/icons/chevron-up';
import Icon from '@jetbrains/ring-ui/components/icon';
import { H4 } from '@jetbrains/ring-ui/components/heading/heading';
import styles from './settings-list.css';
export function SettingsList({ children, title, isCollapsed, onToggleCollapsed, headless }) {
    return (_jsxs("div", { className: styles.listContainer, children: [!headless && (_jsxs(H4, { className: styles.header, onClick: () => onToggleCollapsed(), children: [_jsx(Text, { info: true, children: title }), _jsx(Icon, { className: styles.collapseIcon, glyph: isCollapsed ? chevronDown : chevronUp })] })), (!isCollapsed || headless) && children] }));
}
