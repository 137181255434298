// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* workaround for missing labels in RingUI Select */
.label__eb9 {
  display: block;
  margin-bottom: calc(0.5 * var(--ring-unit));
  color: var(--ring-secondary-color);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.fieldsList__dee {
  display: flex;
  flex-direction: column;
  gap: calc(1.5 * var(--ring-unit));
}

.importantAsterisk__b99 {
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/form/form.css"],"names":[],"mappings":"AAAA,mDAAmD;AACnD;EACE,cAAc;EACd,2CAA2C;EAC3C,kCAAkC;EAClC,wCAAwC;EACxC,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":["/* workaround for missing labels in RingUI Select */\n.label {\n  display: block;\n  margin-bottom: calc(0.5 * var(--ring-unit));\n  color: var(--ring-secondary-color);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n}\n\n.fieldsList {\n  display: flex;\n  flex-direction: column;\n  gap: calc(1.5 * var(--ring-unit));\n}\n\n.importantAsterisk {\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `label__eb9`,
	"fieldsList": `fieldsList__dee`,
	"importantAsterisk": `importantAsterisk__b99`
};
export default ___CSS_LOADER_EXPORT___;
