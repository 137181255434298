import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getApplications } from '@app/store/profiles/selectors';
import { findMainApplicationsRule } from '@app/store/profiles/utils';
import { tFiltersFormSchema } from '../schemas/filters-schema';
import AppFiltersField from './app-filters-field';
import styles from './application-filters-dialog.css';
function ApplicationFiltersDialog({ onReject, onSubmit }) {
    var _a, _b;
    const { rules } = useSelector(getApplications);
    const form = useForm({
        defaultValues: { filters: (_b = (_a = findMainApplicationsRule(rules)) === null || _a === void 0 ? void 0 : _a.filters) !== null && _b !== void 0 ? _b : [] },
        resolver: zodResolver(tFiltersFormSchema)
    });
    const { formState: { isDirty, isSubmitting }, handleSubmit } = form;
    const submit = async (formData) => {
        onSubmit(formData.filters);
    };
    return (_jsxs(Dialog, { autoFocusFirst: false, show: true, contentClassName: styles.dialogContainer, onCloseAttempt: onReject, children: [_jsx(Header, { children: "Configure Available Applications" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsx("form", { onSubmit: handleSubmit(submit), children: _jsx(AppFiltersField, {}) }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(submit), type: "submit", loader: isSubmitting, disabled: !isDirty, children: "Save" }), _jsx(Button, { onClick: onReject, children: "Close" })] })] }));
}
export default ApplicationFiltersDialog;
