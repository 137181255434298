import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Outlet, useNavigation } from 'react-router-dom';
import { Container } from '@webteam/layout';
import classnames from 'classnames';
import auth from '@components/auth/auth';
import { ConstituentExceedNotificationController } from '@components/constituent-exceed-notification-controller/constituent-exceed-notification-controller';
import { ModalsRenderer } from '@components/modals';
import { AppHeader } from './app-header/app-header';
import { LayoutContext } from './context';
import { useMountingPoint } from './hooks';
import styles from './app-layout.css';
export function AppLayout(props) {
    const { showLoader, showContent } = props;
    const navigation = useNavigation();
    const isAuthorized = auth.isAuthenticated();
    const [sidebarVisible, sidebarRef, sidebarMountingPointRecord] = useMountingPoint('sidebar');
    const [footerVisible, footerRef, footerMountingPointRecord] = useMountingPoint('footer');
    const layoutContextValue = useMemo(() => ({ ...sidebarMountingPointRecord, ...footerMountingPointRecord }), [sidebarMountingPointRecord, footerMountingPointRecord]);
    return (_jsxs(LayoutContext.Provider, { value: layoutContextValue, children: [_jsxs("div", { className: classnames(styles.app, { [styles.authorized]: isAuthorized }), children: [_jsx(AppHeader, { loading: showLoader || navigation.state === 'loading' }), _jsxs("div", { className: styles.innerWrapper, "data-test": "app", children: [sidebarVisible && _jsx("aside", { ref: sidebarRef, className: styles.sidebar }), _jsx("main", { className: styles.content, children: showContent && _jsx(Outlet, {}) })] }), footerVisible && (_jsx(Container, { className: styles.footerContainer, children: _jsx("footer", { ref: footerRef, className: styles.footer }) }))] }), auth.hasLicense() && auth.hasRoles(['ADMIN']) && _jsx(ConstituentExceedNotificationController, {}), _jsx(ModalsRenderer, {})] }));
}
