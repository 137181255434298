import { jsx as _jsx } from "react/jsx-runtime";
import { Children, isValidElement, useCallback } from 'react';
import { resolvePath, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';
import { Tab, Tabs } from '@jetbrains/ring-ui/components/tabs/tabs';
import { RouteTab } from './route-tab';
export function RouteTabs(props) {
    const { children, ...tabsProps } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const resolvedTabsPath = useResolvedPath('.');
    const onTabChange = useCallback((tabPath) => {
        const newPath = resolvePath(tabPath, resolvedTabsPath.pathname);
        navigate(newPath);
    }, [navigate, resolvedTabsPath]);
    const mappedChildren = Children.toArray(children).map(child => {
        if (isComponentType(RouteTab, child)) {
            const { path, ...childProps } = child.props;
            // converting provided path to absolute path, to proper matching with route and easy to compare with location
            // this will allow us to pass absolute or relative paths to RouteTab
            // if we need to match it with pattern like /profiles/:profileId
            // it can be doable with some rework using matchPath validation
            const childAbsolutePath = resolvePath(path, resolvedTabsPath.pathname);
            return (_jsx(Tab, { id: childAbsolutePath.pathname, ...childProps }, childAbsolutePath.pathname));
        }
        throw new Error('Invalid child type. Only RouteTab is allowed.');
    });
    return (_jsx(Tabs, { ...tabsProps, selected: location.pathname, onSelect: onTabChange, children: mappedChildren }));
}
// can be extracted to shared utils or something
function isComponentType(componentType, reactNode) {
    return isValidElement(reactNode) && reactNode.type === componentType;
}
