import { useMutation, useQuery } from '@tanstack/react-query';
import { changeIdeProvisionerSettings, disableIdeProvisioner, enableIdeProvisioner, getIdePState } from '@api/ide-provisioner';
import { formatApiError } from '@api/errors';
import { getUsersAndTotal } from '@api/users';
import queryClient from '@app/queries/query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from '@app/queries/formatters/alerts';
export function useIdeProvisionerConstituent() {
    return useQuery({
        queryKey: ['idep-enabled'],
        queryFn: getIdePState,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch IDE Provisioner status'))
    });
}
export function useIdeProvisionerConstituentUsageCount() {
    return useQuery({
        queryKey: ['idep-usage-count'],
        queryFn: async () => getUsersAndTotal({ constituentId: 'idep', page: 1, limit: 1 }, undefined),
        select: data => data.total,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch IDE Provisioner usage count'))
    });
}
export function useEnableIdePMutation(onSuccess) {
    return useMutation({
        mutationFn: enableIdeProvisioner,
        onSuccess: newIdePState => {
            queryClient.setQueryData(['idep-enabled'], newIdePState);
            queryClient.invalidateQueries(['users']);
            onSuccess(newIdePState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to enable IDE Provisioner'), () => 'IDE Provisioner enabled')
    });
}
export function useChangeIDEPSettingsMutation(onSuccess) {
    return useMutation({
        mutationFn: changeIdeProvisionerSettings,
        onSuccess: newIdePState => {
            queryClient.setQueryData(['idep-enabled'], newIdePState);
            queryClient.invalidateQueries(['users']);
            onSuccess(newIdePState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to change IDE Provisioner settings'), () => 'IDE Provisioner settings changed')
    });
}
export function useDisableIdePMutation(onSuccess) {
    return useMutation({
        mutationFn: disableIdeProvisioner,
        onSuccess: newIdePState => {
            queryClient.setQueryData(['idep-enabled'], newIdePState);
            queryClient.invalidateQueries(['users']);
            onSuccess();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to disable IDE Provisioner'), () => 'IDE Provisioner disabled')
    });
}
