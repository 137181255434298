import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import Button from '@jetbrains/ring-ui/components/button/button';
import closeIcon from '@jetbrains/icons/close';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import Icon from '@jetbrains/ring-ui/components/icon';
import cancelIcon from '@jetbrains/icons/cancel';
import checkmarkIcon from '@jetbrains/icons/checkmark';
import styles from './upload-plugin.css';
function formatFileSize(size) {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    const value = (size / 1024 ** i).toFixed(2);
    return `${value}  ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}
function UploadingFilePreview({ file, uploadProgress = -1, onRemove }) {
    const isDone = uploadProgress === 1;
    const isLoading = uploadProgress >= 0 && !isDone;
    return (_jsxs("div", { className: styles.filePreview, children: [_jsx(Text, { children: _jsx("span", { className: styles.fileName, children: file.file.name }) }), _jsxs("div", { className: styles.filePreviewRight, children: [_jsx(Text, { className: styles.fileSize, info: true, children: uploadProgress > 0 && uploadProgress < 1
                            ? `${(uploadProgress * 100).toFixed(0)}%`
                            : formatFileSize(file.file.size) }), uploadProgress === -1 && !isDone && !!onRemove && (_jsx(Button, { inline: true, className: styles.filePreviewIcon, title: `Remove file ${file.file.name}`, icon: closeIcon, onClick: () => onRemove(file) })), isLoading && (_jsx("span", { className: styles.filePreviewIcon, children: _jsx(LoaderInline, {}) })), isDone && file.error && (_jsx(Tooltip, { title: file.error, children: _jsx(Icon, { className: styles.filePreviewIcon, glyph: cancelIcon, color: Icon.Color.RED }) })), isDone && !file.error && (_jsx(Icon, { className: styles.filePreviewIcon, glyph: checkmarkIcon, color: Icon.Color.GREEN, title: "File was successfully uploaded" }))] })] }, `file-${file.id}`));
}
export function UploadingFilePreviews({ files, onRemove }) {
    if (files.length) {
        return (_jsx("div", { className: styles.filePreviews, children: files.map(file => (_jsx(UploadingFilePreview, { file: file, onRemove: onRemove, uploadProgress: file.progress }, `file-preview-${file.id}`))) }));
    }
    return _jsx("div", {});
}
