import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  BarElement
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import {Chart} from 'react-chartjs-2';

ChartJS.register(
  LineController,
  LinearScale,
  CategoryScale,
  TimeScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Filler,
  BarElement
);
ChartJS.defaults.borderColor = '#dfe5eb'; // --ring-borders-color
ChartJS.defaults.color = '#737577'; // --ring-secondary-color

export {Chart};

const colors = ['#36a2eb', '#ff6384', '#4bc0c0', '#9966ff', '#ff9f40'];

export function getSeriesColor(index) {
  return colors[index % colors.length];
}

export function getTransparentColor(baseColor) {
  return `${baseColor}30`;
}
