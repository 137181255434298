import { jsx as _jsx } from "react/jsx-runtime";
import ErrorBubble from '@jetbrains/ring-ui/components/error-bubble/error-bubble';
import { useFormContext } from 'react-hook-form';
import Select from '@jetbrains/ring-ui/components/select/select';
import RegisteredSelect from '../../../components/hook-form/registered-select';
import { ROLE_OPTION_ITEMS } from '../../../api/users';
export function RoleField({ disabled }) {
    var _a;
    const { watch, control, formState } = useFormContext();
    const role = watch('role');
    const selectedOption = ROLE_OPTION_ITEMS.find(r => r.key === role);
    return (_jsx(ErrorBubble, { error: (_a = formState.errors.role) === null || _a === void 0 ? void 0 : _a.message, children: _jsx(RegisteredSelect, { selectedLabel: "Role", name: "role", disabled: disabled, control: control, data: ROLE_OPTION_ITEMS, selected: selectedOption, size: Select.Size.M, filter: false }) }));
}
