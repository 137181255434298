import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import Button from '@jetbrains/ring-ui/components/button/button';
import { DownloadTbeToolboxApp, useMachineConfigDownloadButtonProps } from '../../components/machine-config';
import { useCompanyName } from '../../hooks/use-company-name';
import { WidgetLayout } from './common/widget-layout';
import styles from './toolbox-app.css';
export function ToolboxApp() {
    const { companyName, isFetching } = useCompanyName();
    return (_jsxs(WidgetLayout, { title: "Toolbox App", dataTest: "toolbox-app", className: styles.container, loader: isFetching, children: [_jsxs(Text, { className: styles.textContainer, children: ["Join to gain access to tools and settings approved by ", companyName] }), _jsx(Button, { className: styles.button, loader: !companyName, "data-test": "join-organization-button", autoFocus: true, ...useMachineConfigDownloadButtonProps(`Join ${companyName}`) }), _jsx(DownloadTbeToolboxApp, {})] }));
}
