// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings__d16 {
  margin-bottom: var(--ring-unit);
}

.range__bd2 {
  width: calc(15 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics/plugin-downloads/plugin-downloads.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".settings {\n  margin-bottom: var(--ring-unit);\n}\n\n.range {\n  width: calc(15 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": `settings__d16`,
	"range": `range__bd2`
};
export default ___CSS_LOADER_EXPORT___;
