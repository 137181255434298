import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { AddSettingButton } from '../common/add-setting-button';
import { SettingBlock } from '../common/setting-block';
import { NotificationsContext } from '../context/notifications-context';
import { NotificationsList } from './notifications-list';
import styles from './notifications.css';
export function Notifications() {
    const [formState, setFormState] = useState();
    const context = useMemo(() => ({ formState, setFormState }), [formState]);
    const handleOnClick = () => {
        setFormState({ formType: 'content' });
    };
    return (_jsx(SettingBlock, { header: "Restrictions", headerActionComponent: _jsx(AddSettingButton, { "data-test": "restrictions-add-button", onClick: handleOnClick, title: "Add restriction" }), children: _jsx("div", { className: styles.notificationsContainer, children: _jsx(NotificationsContext.Provider, { value: context, children: _jsx(NotificationsList, {}) }) }) }));
}
