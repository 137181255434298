import * as z from 'zod';
import { tSettingOptionSchema } from './setting-option-schema';
export const tVmOptionsSchema = tSettingOptionSchema.extend({
    content: z
        .array(z.object({
        value: z.string().min(1, { message: 'Please enter VM Option' }),
        forced: z.boolean()
    }))
        .min(1, { message: 'Please add VM Option' })
});
