import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import classNames from 'classnames';
import { ErrorCode, useDropzone } from 'react-dropzone';
import styles from './files-attachment.css';
export function getFilesAttachmentClassName(disabled, isDragActive, className) {
    return classNames(styles.dropZone, disabled ? styles.dropZoneDisabled : null, isDragActive ? styles.dropZoneHovered : null, className);
}
export async function readFileAsText(file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            resolve(reader.result);
        }, false);
        reader.readAsText(file);
    });
}
export function formatFileRejections(fileRejections, maxFilesAmount) {
    // When multiple uploading is disabled, all the errors will be of type "TooManyFiles"
    // So we assume if we see any "TooManyFiles" error that we have encountered this situation
    const isTooManyFilesError = fileRejections.some(fr => { var _a; return ((_a = fr.errors[0]) === null || _a === void 0 ? void 0 : _a.code) === ErrorCode.TooManyFiles; });
    if (isTooManyFilesError) {
        if (maxFilesAmount !== undefined) {
            return `Too many files were attached. Max amount of files: ${maxFilesAmount}`;
        }
        return 'Multiple uploads are not supported. Please upload one at a time.';
    }
    return fileRejections
        .map(({ file, errors }) => errors.map(e => `${file.name}: ${e.message}`).join(', '))
        .join('\n');
}
export default function FilesAttachment({ className = '', onFilesAttached = () => { }, disabled = false, dropzoneOptions = {}, dropzoneText = 'Drag a file here or browse for one' }) {
    const onDrop = useCallback((files, fileRejections) => onFilesAttached(files, fileRejections), [onFilesAttached]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        disabled,
        ...dropzoneOptions
    });
    const divStyles = getFilesAttachmentClassName(disabled, isDragActive, className);
    return (_jsxs("div", { ...getRootProps(), className: divStyles, children: [_jsx("input", { ...getInputProps() }), _jsx(Text, { info: true, className: styles.dropZoneText, children: isDragActive ? 'Drop file here ...' : dropzoneText })] }));
}
