export function getUserFormValues(profileIds = []) {
    return {
        subject: '',
        username: '',
        email: '',
        isActive: true,
        role: 'USER',
        profiles: profileIds.map(value => {
            return { id: value, title: value };
        })
    };
}
