import {baseAnalyticsTableReducer} from './base-analytics-table';

const SET_PLUGINS_ANALYTICS = 'SET_PLUGINS_ANALYTICS';
const SET_PLUGINS_ANALYTICS_IS_FETCHING = 'SET_PLUGINS_ANALYTICS_IS_FETCHING';
const SET_PLUGINS_ANALYTICS_FETCH_ERROR = 'SET_PLUGINS_ANALYTICS_FETCH_ERROR';
const SET_PLUGINS_ANALYTICS_PAGE_PARAMS = 'SET_PLUGINS_ANALYTICS_PAGE_PARAMS';
const SET_PLUGINS_ANALYTICS_SORT_PARAMS = 'SET_PLUGINS_ANALYTICS_SORT_PARAMS';
const SET_PLUGINS_ANALYTICS_TABLE_TYPE = 'SET_PLUGINS_ANALYTICS_TABLE_TYPE';

const defaultState = {
  items: [],
  isFetching: true,
  isFetchError: false,
  isRefreshError: false,
  sortKey: 'machinesCount',
  currentPageIndex: 1,
  currentPageData: [],
  sortOrder: false,
  pageSize: 25,
  tableType: 'DISABLED_BUNDLED',
  totalItems: 0
};

export default function pluginsAnalyticsReducer(state = defaultState, action) {
  const onUnknownEventType = (action, state) => {
    if (action.type === SET_PLUGINS_ANALYTICS_TABLE_TYPE) {
      // Here we should only change the tableType.
      // If table type is changed then the component should handle it changing and possibly invoke another action to fetch data
      return {
        ...state,
        tableType: action.payload
      };
    }
    return state;
  };

  const eventTypes = {
    SET_ITEMS: SET_PLUGINS_ANALYTICS,
    IS_FETCHING: SET_PLUGINS_ANALYTICS_IS_FETCHING,
    FETCHING_ERROR: SET_PLUGINS_ANALYTICS_FETCH_ERROR,
    PAGE_PARAMS: SET_PLUGINS_ANALYTICS_PAGE_PARAMS,
    SORT_PARAMS: SET_PLUGINS_ANALYTICS_SORT_PARAMS
  };
  return baseAnalyticsTableReducer(eventTypes, onUnknownEventType, state, action);
}

export function setPluginsAnalyticsInfo(data) {
  return {type: SET_PLUGINS_ANALYTICS, payload: data};
}

export function setPluginsAnalyticsIsFetching(isFetching) {
  return {type: SET_PLUGINS_ANALYTICS_IS_FETCHING, payload: isFetching};
}

export function setPluginsAnalyticsFetchError(error) {
  return {type: SET_PLUGINS_ANALYTICS_FETCH_ERROR, payload: error};
}

export function setPagingParameters(pageParams) {
  return {type: SET_PLUGINS_ANALYTICS_PAGE_PARAMS, payload: pageParams};
}

export function setSortingParameters(params) {
  return {type: SET_PLUGINS_ANALYTICS_SORT_PARAMS, payload: params};
}

export function setPluginsAnalyticsTableType(params) {
  return {type: SET_PLUGINS_ANALYTICS_TABLE_TYPE, payload: params};
}
