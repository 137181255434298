import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useMachineConfig, useMachineConfigMutation } from '@app/queries/machine-config';
import { InlineEditableField } from '@components/inline-editable-field';
export function CompanyNameEditor(props) {
    const { className } = props;
    const { data, isError, isLoading } = useMachineConfig();
    const mutation = useMachineConfigMutation();
    const handleNewValue = useCallback(async (newCompanyName) => {
        if ((data === null || data === void 0 ? void 0 : data.companyName.trim()) !== newCompanyName.trim()) {
            await mutation.mutateAsync({ companyName: newCompanyName.trim() });
        }
    }, [mutation, data]);
    if (isLoading) {
        return _jsx(Text, { children: "..." });
    }
    if (isError) {
        return _jsx(Text, { children: "-" });
    }
    return (_jsx(InlineEditableField, { className: className, currentValue: data === null || data === void 0 ? void 0 : data.companyName, onNewValue: handleNewValue, name: "companyName", autoComplete: "off" }));
}
