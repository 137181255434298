import React from 'react';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import {Header, Content} from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';

export function ConfirmationDialog({modalVisible, handleConfirmNavigationClick, closeModal}) {
  return (
    <Dialog show={modalVisible} trapFocus>
      <Header>Exit Without Saving Changes?</Header>
      <Content>All unsaved changes will be discarded</Content>
      <Panel>
        <Button danger onClick={handleConfirmNavigationClick}>
          Discard changes and exit
        </Button>
        <Button primary onClick={closeModal}>
          Stay on the page
        </Button>
      </Panel>
    </Dialog>
  );
}
