import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setPagingParameters, setSortingParameters} from '../../store/ide-analytics';
import {getIdeAnalytics} from '../../api/ide-analytics';
import {PageTitle} from '../../components/page-layout/page-title';
import AnalyticsTable from './analytics-table';

export default function IdeAnalytics() {
  const columns = [
    {id: 'ideName', title: 'Name', sortable: true},
    {id: 'ideVersion', title: 'Version', sortable: true, rightAlign: true},
    {id: 'machinesCount', title: 'Devices', sortable: true, rightAlign: true},
    {id: 'right-placeholder', title: ''}
  ];

  const dispatch = useDispatch();

  const stateSelector = state => {
    return state.ideAnalytics;
  };
  const pageSize = useSelector(state => stateSelector(state).pageSize);

  const onSort = event => {
    dispatch(setSortingParameters(event));
  };

  const onRangeChange = newRange => {
    dispatch(dispatch => getIdeAnalytics(newRange, dispatch));
  };

  const onPageChange = nextPageIndex => {
    dispatch(setPagingParameters({nextPageIndex, pageSize}));
  };

  return (
    <>
      <PageTitle parts={[{title: 'Analytics'}, {title: 'IDEs Being Used'}]} />
      <AnalyticsTable
        columns={columns}
        onSort={onSort}
        onPageChange={onPageChange}
        onRangeChange={onRangeChange}
        stateSelector={stateSelector}
      />
    </>
  );
}
