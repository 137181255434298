import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import RegisteredSelect from '@components/hook-form/registered-select';
import { Required } from '@components/form/important-asterisk';
import { MARKETPLACE_SOURCE, TBE_SOURCE } from '@components/plugins/ide-plugins-types';
import { formatPluginSourceName } from '../../formatters';
const SOURCE_OPTIONS = [
    { key: MARKETPLACE_SOURCE, label: formatPluginSourceName(MARKETPLACE_SOURCE) },
    { key: TBE_SOURCE, label: formatPluginSourceName(TBE_SOURCE) }
];
function SourceField() {
    const { control, watch, setValue } = useFormContext();
    const watchSource = watch('source');
    const selectedSourceOption = SOURCE_OPTIONS.find(x => x.key === watchSource);
    const onChange = () => {
        setValue('pluginId', '');
        setValue('version', '');
    };
    return (_jsx(RegisteredSelect, { control: control, selectedLabel: Required('Source'), name: "source", data: SOURCE_OPTIONS, selected: selectedSourceOption, size: Size.L, changeListener: onChange }));
}
export default SourceField;
