/**
 * Should be primarily used to configure alert messages in the query's metadata fields
 * @param errorMessage
 * @param successMessage
 * @constructor
 */
export function getQueryAlertMessages(errorMessage, successMessage) {
    return { errorMessage, successMessage };
}
/**
 * Should be primarily used to configure alert messages in the mutation's
 * metadata fields
 * @param errorMessage
 * @param successMessage
 * @constructor
 */
export function getMutationAlertMessages(errorMessage, successMessage) {
    return { errorMessage, successMessage };
}
