import { jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import styles from './information-banner.css';
export function InformationBanner({ children, buttons, className, variant }) {
    const bannerClasses = classNames(styles.bannerContainer, className, {
        [styles.success]: variant === 'success',
        [styles.warning]: variant === 'warning',
        [styles.error]: variant === 'error'
    });
    return (_jsxs("div", { className: classNames(styles.bannerContainer, bannerClasses, className), children: [children, buttons] }));
}
