import { useQuery } from '@tanstack/react-query';
import { getActiveUsersStatistics, getConstituentCurrentUsage, getConstituentUsageStatistics, getCwmEvents, getCwmWidgetEventsStatistics, getIdeProvisioning } from '../api/dashboard';
import { formatApiError } from '../api/errors';
import { getQueryAlertMessages } from './formatters/alerts';
const keys = {
    all: ['dashboard'],
    ideProvisioning: () => ['dashboard', 'ideProvisioning'],
    cwmSessionsStatistics: () => ['dashboard', 'cwmSessionsStatistics'],
    activeUsersStatistics: () => ['dashboard', 'activeUsersStatistics'],
    constituentUsageStatistics: (constituent, timeframe) => ['dashboard', 'userUsageStatistics', { constituent, timeframe }],
    currentConstituentUsage: (constituent) => ['dashboard', 'currentlyAssignedUsers', { constituent }],
    cwmEvents: (params) => ['dashboard', 'cwmEvents', { params }]
};
export function useGetIdeProvisioning() {
    return useQuery({
        queryKey: keys.ideProvisioning(),
        queryFn: getIdeProvisioning,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load IDE Provisioning data'))
    });
}
export function useGetCwmEvents(params) {
    return useQuery({
        queryKey: keys.cwmEvents(params),
        queryFn: () => getCwmEvents(params),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load Code With Me sessions data'))
    });
}
export function useGetCwmWidgetStatistics() {
    return useQuery({
        queryKey: keys.cwmSessionsStatistics(),
        queryFn: () => getCwmWidgetEventsStatistics(),
        staleTime: Infinity,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load statistics for Code With Me widget'))
    });
}
export function useActiveUsersWidgetStatistics() {
    return useQuery({
        queryKey: keys.activeUsersStatistics(),
        queryFn: () => getActiveUsersStatistics(),
        staleTime: Infinity,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load logged in users statistics'))
    });
}
export function useConstituentUsagePerDays(constituent, timeframe, enabled = true) {
    return useQuery({
        queryKey: keys.constituentUsageStatistics(constituent.id, timeframe),
        queryFn: () => getConstituentUsageStatistics(constituent.id, timeframe),
        staleTime: Infinity,
        select: data => {
            const days = (data || []).map(it => ({
                from: it.from,
                total: it.usage
            }));
            const maxUsage = days.reduce((max, it) => Math.max(it.total, max), 0);
            return {
                days,
                maxUsageDay: days.filter(it => it.total === maxUsage).reverse()[0]
            };
        },
        meta: getQueryAlertMessages(error => formatApiError(error, `Failed to load per days usage statistics for constituent "${constituent.title}"`)),
        enabled
    });
}
export function useConstituentCurrentUsage(constituent, enabled = true) {
    return useQuery({
        queryKey: keys.currentConstituentUsage(constituent.id),
        queryFn: () => getConstituentCurrentUsage(constituent.id),
        meta: getQueryAlertMessages(error => formatApiError(error, `Failed to load current usage of ${constituent.title}`)),
        enabled
    });
}
