import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Toggle from '@jetbrains/ring-ui/components/toggle/toggle';
import Input from '@jetbrains/ring-ui/components/input/input';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import Text from '@jetbrains/ring-ui/components/text/text';
import { SETTINGS_UPDATE_INTERVAL_SECONDS_DEFAULT_VALUE, SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MAX, SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MIN } from '@app/pages/profiles/profile-details/profile-ide-provisioner/schemas/toolbox-settings-schema';
import HelpIcon from '@components/util/help-icon';
import styles from './settings-update-interval-setting-field.css';
export function SettingsUpdateIntervalSettingField() {
    const { control } = useFormContext();
    const { field: { value: settingsUpdateIntervalSeconds, onChange }, fieldState: { error } } = useController({
        control,
        name: 'content.settingsUpdateIntervalSeconds'
    });
    // The typing for the error from ReactForm is simply incorrect.
    // The actual error exists within error.value, since the validation happens on settingsUpdateIntervalSeconds.value
    // So we perform a type assertion here to set the interface ReactForm actually returns
    const settingsUpdateError = error;
    const [isEnabled, setIsEnabled] = useState(!!settingsUpdateIntervalSeconds);
    let inputValue;
    if (!settingsUpdateIntervalSeconds) {
        inputValue = `${SETTINGS_UPDATE_INTERVAL_SECONDS_DEFAULT_VALUE / 60}`;
    }
    else if (settingsUpdateIntervalSeconds.value === 0) {
        inputValue = '';
    }
    else {
        inputValue = `${settingsUpdateIntervalSeconds.value / 60}`;
    }
    return (_jsxs("div", { children: [_jsxs("div", { className: styles.container, children: [_jsxs("div", { className: styles.toggleContainer, children: [_jsx(Toggle, { checked: isEnabled, onChange: e => {
                                    setIsEnabled(e.target.checked);
                                    onChange(undefined);
                                }, children: "\"Check for updates\" frequency" }), _jsx(Tooltip, { title: _jsx(Text, { children: "If this setting is not specified, profile users will receive updates in the Toolbox App based on either the default value (120 minutes) or the value specified in their other profiles." }), children: _jsx(HelpIcon, { className: styles.helpIcon }) })] }), _jsx(Input, { className: styles.input, value: inputValue, onChange: e => {
                            onChange({
                                value: e.target.value ? parseInt(e.target.value) * 60 : 0
                            });
                        }, disabled: !isEnabled, type: "number", min: SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MIN / 60, max: SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MAX / 60 }), "\u00A0minutes"] }), settingsUpdateError && (_jsx(Text, { className: styles.error, children: settingsUpdateError.value.message }))] }));
}
