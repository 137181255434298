import * as z from 'zod';
import { tFiltersSchema } from './filters-schema';
export const tNotificationssSchema = z.object({
    id: z
        .string()
        .optional()
        .transform(val => val || crypto.randomUUID()),
    filters: tFiltersSchema,
    notification: z.object({
        id: z
            .string()
            .optional()
            .transform(val => val || crypto.randomUUID()),
        dueDate: z.union([
            z.date({ invalid_type_error: 'Please select date' }).transform(date => date.toISOString()),
            z.string().min(1, { message: 'Please select date' })
        ]),
        description: z.string().min(1, { message: 'Please enter notification text' })
    })
});
