// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fieldsContainer__c9c {
  margin-bottom: calc(var(--ring-unit) * 3.5);
  margin-top: calc(var(--ring-unit) * 2);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/user-machine-info-list.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,sCAAsC;AACxC","sourcesContent":[".fieldsContainer {\n  margin-bottom: calc(var(--ring-unit) * 3.5);\n  margin-top: calc(var(--ring-unit) * 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldsContainer": `fieldsContainer__c9c`
};
export default ___CSS_LOADER_EXPORT___;
