import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from '@jetbrains/ring-ui/components/button/button';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import { useFieldArray, useFormContext } from 'react-hook-form';
import close from '@jetbrains/icons/close';
import add from '@jetbrains/icons/add';
import RegisteredInput from '@components/hook-form/registered-input';
import { Required } from '@components/form/important-asterisk';
import { getEmptyVmOption } from '../../schemas/ctors';
import styles from './vm-options-field-array.css';
export function VmOptionsFieldArray() {
    const { control } = useFormContext();
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'content'
    });
    return (_jsxs(_Fragment, { children: [fields.map((vmOption, index) => (_jsxs("div", { className: styles.vmOptionContainer, children: [_jsx(RegisteredInput, { name: `content.${index}.value`, control: control, label: Required('VM option'), size: Size.FULL, autoComplete: "off", placeholder: "Example: -Xmx2048m" }), _jsx(Button, { className: fields.length === 1 ? styles.hiddenButton : '', icon: close, onClick: () => remove(index) })] }, vmOption.id))), _jsx(Button, { primary: true, icon: add, onClick: () => append(getEmptyVmOption().content), className: styles.addVmOptionButton, children: "Add VM option" })] }));
}
