import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isNil } from 'lodash';
import { parseAuditId } from '@api/audit-utils';
import { formatDateTimeFull } from '@components/util/i18n';
import NavigationLink from '@components/navigation/navigation-link';
export function AutomationTokenUpdatedBy(props) {
    const { token } = props;
    const { lastModifiedBy, lastModifiedDate } = token;
    if (!lastModifiedBy || !lastModifiedDate) {
        throw new Error('Invalid application state, lastModifiedBy or lastModifiedDate is null');
    }
    // fixme: hack! need proper backend model, may broke when token would be edited by some automation
    const date = new Date(lastModifiedDate);
    const userWithId = parseAuditId(lastModifiedBy);
    if (isNil(userWithId)) {
        return _jsx("span", { children: formatDateTimeFull(date) });
    }
    return (_jsxs("span", { children: [_jsx(NavigationLink, { href: `/users/${userWithId.userId}`, children: userWithId.userName }), " at", ' ', _jsx("span", { children: formatDateTimeFull(date) })] }));
}
