import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { isEqual, isNil } from 'lodash';
import { decodeQueryParams, objectToSearchString, searchStringToObject } from 'serialize-query-params';
import { useNavigate, useLocation } from 'react-router-dom';
function nothingToValidate(parsedFilters) {
    return parsedFilters;
}
export function useSyncedSearchArgs(parserSchema, validate = nothingToValidate) {
    const { search } = useLocation();
    const navigate = useNavigate();
    const locationWasSynced = useRef(false);
    const onUpdateValue = useCallback((newValue) => {
        navigate({
            search: objectToSearchString(Object.keys(newValue).reduce((accum, key) => {
                if (typeof newValue[key] === 'boolean' || newValue[key]) {
                    accum[key] = newValue[key];
                }
                return accum;
            }, {}))
        }, { replace: true });
    }, [navigate]);
    const decodedArguments = useMemo(() => {
        const args = decodeQueryParams(parserSchema, searchStringToObject(search));
        return args;
    }, [parserSchema, search]);
    const value = React.useMemo(() => {
        return validate(decodedArguments);
    }, [validate, decodedArguments]);
    const shouldUpdateLocation = useMemo(() => !isEqual(value, decodedArguments), [value, decodedArguments]);
    useEffect(() => {
        if (shouldUpdateLocation && !locationWasSynced.current) {
            locationWasSynced.current = true;
            onUpdateValue(value);
        }
    }, [onUpdateValue, shouldUpdateLocation, value]);
    return [value, onUpdateValue];
}
export function transformPseudoBooleanToBoolean(value) {
    const stringValue = value;
    if (isNil(stringValue)) {
        return null;
    }
    return stringValue === 'true';
}
