// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialogContent__c59 {
  min-width: 600px;
}

.scrollableWrapper__e54 {
  margin-top: calc(2 * var(--ring-unit));
  padding-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics/plugin-analytics-popup.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;EACtC,cAAc;AAChB","sourcesContent":[".dialogContent {\n  min-width: 600px;\n}\n\n.scrollableWrapper {\n  margin-top: calc(2 * var(--ring-unit));\n  padding-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContent": `dialogContent__c59`,
	"scrollableWrapper": `scrollableWrapper__e54`
};
export default ___CSS_LOADER_EXPORT___;
