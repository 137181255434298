// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__de6 {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
}

.button__ba6 {
  width: fit-content;
}

.textContainer__f43 {
  max-width: calc(var(--ring-unit) * 32.75);
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/toolbox-app.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n}\n\n.button {\n  width: fit-content;\n}\n\n.textContainer {\n  max-width: calc(var(--ring-unit) * 32.75);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__de6`,
	"button": `button__ba6`,
	"textContainer": `textContainer__f43`
};
export default ___CSS_LOADER_EXPORT___;
