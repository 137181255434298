export function getHashParams() {
    return window.location.hash
        .substring(1)
        .split('&')
        .reduce((initial, item) => {
        if (item) {
            const parts = item.split('=');
            initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
    }, {});
}
export function randomString(length) {
    // noinspection SpellCheckingInspection
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export function removeHashParamsFromUrl() {
    window.history.pushState('', document.title, window.location.pathname + window.location.search);
}
