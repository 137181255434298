// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smallColumn__ad0 {
  width: 10%;
  min-width: 5em !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics/collected-data-table.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,yBAAyB;AAC3B","sourcesContent":[".smallColumn {\n  width: 10%;\n  min-width: 5em !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallColumn": `smallColumn__ad0`
};
export default ___CSS_LOADER_EXPORT___;
