import { createAction } from '@reduxjs/toolkit';
export const setNewProfiles = createAction('SET_NEW_PROFILES');
export const updateVMOptions = createAction('UPDATE_VM_OPTIONS');
export const updateToolboxSettings = createAction('UPDATE_TOOLBOX_SETTINGS');
export const updateIdeSettings = createAction('UPDATE_IDE_SETTINGS');
export const updateAutoinstalledTools = createAction('UPDATE_AUTOINSTALLED_TOOLS');
export const deleteSetting = createAction('DELETE_NEW_PROFILE_SETTING');
export const updateDisabledPlugin = createAction('UPDATE_DISABLED_PLUGIN');
export const updateAutoInstalledPlugins = createAction('UPDATE_AUTO_INSTALLED_PLUGINS');
export const excludeApplication = createAction('EXCLUDE_APPLICATION');
export const updateApplications = createAction('UPDATE_APPLICATIONS');
export const addApplications = createAction('ADD_APPLICATIONS');
export const deleteNotification = createAction('DELETE_NOTIFICATION');
export const updateNotification = createAction('UPDATE_NOTIFICATION');
export const updateSettingFilters = createAction('UPDATE_FILTERS');
export const updateApplicationFilters = createAction('UPDATE_APPLICATION_FILTERS');
export const updateTools = createAction('UPDATE_TOOLS');
export const updateProfileSettings = createAction('UPDATE_PROFILE_SETTINGS');
export const resetProfileState = createAction('RESET_PROFILE_STATE');
export const updateCwmConstituent = createAction('UPDATE_PROFILE_CWM_CONSTITUENT');
export const updateAiConstituent = createAction('UPDATE_PROFILE_AI_CONSTITUENT');
export const updateIDEPConstituent = createAction('UPDATE_PROFILE_IDEP_CONSTITUENT');
