import { jsx as _jsx } from "react/jsx-runtime";
import { LicenseVaultLogo } from '@jetbrains/logos/react';
import { AiLogo } from '@components/ai/ai-logo/ai-logo';
import { IdeProvisionerLogo } from '@components/ide-provisioner/ide-provisioner-logo/ide-provisioner-logo';
import { CodeWithMeLogo } from '@components/code-with-me/code-with-me-logo/code-with-me-logo';
export const CONSTITUENTS_LOGOS = {
    ai: _jsx(AiLogo, {}),
    cwm: _jsx(CodeWithMeLogo, {}),
    idep: _jsx(IdeProvisionerLogo, {}),
    lv: _jsx(LicenseVaultLogo, {})
};
export function ConstituentLogo({ id }) {
    return CONSTITUENTS_LOGOS[id] || null;
}
