import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import sniffer from '@jetbrains/ring-ui/components/global/sniffer';
import Button from '@jetbrains/ring-ui/components/button/button';
import Select from '@jetbrains/ring-ui/components/select/select';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import downloadIcon from '@jetbrains/icons/download';
import classNames from 'classnames';
import { useToolboxInfo } from '../../queries/toolbox';
import HorizontalContainer from '../page-layout/horizontal-container';
import styles from './download-toolbox-app.css';
function tryToDetectMacArm() {
    try {
        const glcontext = document.createElement('canvas').getContext('webgl');
        const debugrenderer = glcontext ? glcontext.getExtension('WEBGL_debug_renderer_info') : null;
        const rendererName = (debugrenderer && (glcontext === null || glcontext === void 0 ? void 0 : glcontext.getParameter(debugrenderer.UNMASKED_RENDERER_WEBGL))) || '';
        return ((rendererName.includes('Apple') &&
            (rendererName.includes('M1') || rendererName.includes('M2'))) ||
            false);
    }
    catch (e) {
        console.warn(`can't determine MacOS architecture: ${e}`);
        return false;
    }
}
function getDefaultOptionKey() {
    switch (sniffer.sniff(navigator.userAgent).os.name) {
        case 'macos':
            if (tryToDetectMacArm()) {
                return 'MAC_OS_X-ARM64';
            }
            return 'MAC_OS_X-X64';
        case 'windows':
            return 'WINDOWS-ALL';
        case 'linux':
            return 'LINUX-ALL';
        default:
            return null;
    }
}
function getLabel(apiDownloadUrl) {
    if (apiDownloadUrl.os === 'WINDOWS' && apiDownloadUrl.arch === 'ALL') {
        return '.exe';
    }
    if (apiDownloadUrl.os === 'LINUX' && apiDownloadUrl.arch === 'ALL') {
        return '.tar.gz';
    }
    if (apiDownloadUrl.os === 'MAC_OS_X' && apiDownloadUrl.arch === 'ARM64') {
        return '.dmg (ARM)';
    }
    if (apiDownloadUrl.os === 'MAC_OS_X' && apiDownloadUrl.arch === 'X64') {
        return '.dmg (Intel)';
    }
    console.warn(`Unexpected binary type (OS: ${apiDownloadUrl.os}; arch: ${apiDownloadUrl.arch}`);
    if (apiDownloadUrl.arch != null) {
        return `${apiDownloadUrl.os} ${apiDownloadUrl.arch}`;
    }
    return `${apiDownloadUrl.os}`;
}
function getUrl(apiDownloadUrl, params) {
    const urlObj = new URL(apiDownloadUrl.url);
    Object.keys(params).forEach(key => {
        urlObj.searchParams.append(key, params[key]);
    });
    return urlObj.toString();
}
export function DownloadTbeToolboxApp({ className, icon = true }) {
    const query = useMemo(() => ({ type: 'tbe' }), []);
    return _jsx(DownloadToolboxApp, { className: className, icon: icon, query: query });
}
export function DownloadCwmInTbeToolboxApp({ className, icon = true, sessionID }) {
    const query = useMemo(() => ({
        type: 'cwm-in-tbe',
        cwmSessionID: sessionID
    }), [sessionID]);
    return _jsx(DownloadToolboxApp, { className: className, icon: icon, query: query });
}
function DownloadToolboxApp({ className, icon = true, query }) {
    const { data: toolboxInfo, isFetching } = useToolboxInfo();
    const selectItems = useMemo(() => {
        return ((toolboxInfo === null || toolboxInfo === void 0 ? void 0 : toolboxInfo.downloadUrls.map(urlInfo => ({
            key: `${urlInfo.os}-${urlInfo.arch}`,
            os: urlInfo.os,
            arch: urlInfo.arch,
            label: getLabel(urlInfo),
            downloadUrl: getUrl(urlInfo, query)
        }))) || []);
    }, [query, toolboxInfo === null || toolboxInfo === void 0 ? void 0 : toolboxInfo.downloadUrls]);
    const defaultBinaryTypeKey = useMemo(() => {
        return getDefaultOptionKey();
    }, []);
    const [selectedBinaryType, setSelectedBinaryType] = useState(null);
    useEffect(() => {
        const defaultBinaryType = selectItems.find(bt => bt.key === defaultBinaryTypeKey) || null;
        setSelectedBinaryType(defaultBinaryType);
    }, [defaultBinaryTypeKey, selectItems]);
    return (_jsxs(HorizontalContainer, { className: classNames(styles.wrapper, className), children: [_jsxs(Button, { text: true, className: styles.downloadButton, disabled: isFetching || selectedBinaryType === null, icon: !isFetching && icon ? downloadIcon : undefined, onClick: () => {
                    if (selectedBinaryType) {
                        window.open(selectedBinaryType.downloadUrl);
                    }
                }, children: [isFetching && _jsx(LoaderInline, { className: styles.fetchingLoader }), "Download\u00A0"] }), _jsx(Select, { type: Select.Type.INLINE, className: styles.archDropdown, disabled: isFetching, data: selectItems, selected: selectedBinaryType, onChange: setSelectedBinaryType })] }));
}
