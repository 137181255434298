import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Button } from '@jetbrains/ring-ui/components/button/button';
// eslint-disable-next-line no-restricted-imports
import ringLinkStyles from '@jetbrains/ring-ui/components/link/link.css';
/**
 * This is a workaround component for managing NavLinks that are outside Router (mostly because of RingUI confirm service, alert and dialog).
 * Todo: Should be fixed together with RingUI team
 * Fix for TBE-4412
 */
export function NavigationLinkOutsideRouter({ href, children }) {
    const handleNavigation = useCallback(() => {
        window.open(href, '_blank');
    }, [href]);
    return (_jsx(Button, { text: true, inline: true, onClick: handleNavigation, className: ringLinkStyles.link, children: children }));
}
