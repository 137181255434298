// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentLayout__e69 {
  display: flex;
  flex-direction: column;
  max-width: 750px;
}

.contentSection__e68 {
  margin-bottom: calc(var(--ring-unit) * 3);
}

.contentTextButton__f81 {
  margin: 2px calc(-1 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/offline-mode/offline-mode.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".contentLayout {\n  display: flex;\n  flex-direction: column;\n  max-width: 750px;\n}\n\n.contentSection {\n  margin-bottom: calc(var(--ring-unit) * 3);\n}\n\n.contentTextButton {\n  margin: 2px calc(-1 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentLayout": `contentLayout__e69`,
	"contentSection": `contentSection__e68`,
	"contentTextButton": `contentTextButton__f81`
};
export default ___CSS_LOADER_EXPORT___;
