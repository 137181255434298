import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { isEmpty, isNil } from 'lodash';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useFeedFilterProducts } from '@app/queries/ide-feed-filters';
import { filterDescription } from './filter-descriptions';
import { getQualityLabelByType } from './filter-build-options';
import styles from './filters-editor.css';
function useProductsMap() {
    const { id } = useParams();
    const { data: allProducts } = useFeedFilterProducts(id !== null && id !== void 0 ? id : '');
    return useMemo(() => (isNil(allProducts) ? new Map() : new Map(allProducts.map(x => [x.id, x]))), [allProducts]);
}
const joinIfNotEmpty = (entries) => (isEmpty(entries) ? '' : entries.join(', '));
function FilterEntry({ type, builds, products, arch, os, quality, descriptionClassName, title, showType = true, filtersEditorType = 'settings' }) {
    const productsMap = useProductsMap();
    const getProductName = (product) => { var _a, _b; return (_b = (_a = productsMap.get(product)) === null || _a === void 0 ? void 0 : _a.productName) !== null && _b !== void 0 ? _b : product; };
    const productNames = joinIfNotEmpty(products === null || products === void 0 ? void 0 : products.map(getProductName));
    const defaultTitle = showType
        ? `${filterDescription[filtersEditorType][type]} ${productNames}`
        : productNames;
    const buildsRendered = joinIfNotEmpty(builds);
    const osRendered = joinIfNotEmpty(os);
    const archsRendered = joinIfNotEmpty(arch);
    const qualityRendered = isNil(quality) ? null : getQualityLabelByType(type, quality.name);
    return (_jsxs(_Fragment, { children: [_jsx(Text, { children: title || defaultTitle }), _jsx("div", { className: classNames(styles.filterDescription, descriptionClassName), children: _jsxs(Text, { info: true, children: [buildsRendered && _jsxs("div", { children: ["Builds: ", buildsRendered] }), osRendered && _jsxs("div", { children: ["OS: ", osRendered] }), archsRendered && _jsxs("div", { children: ["Arch: ", archsRendered] }), qualityRendered && _jsxs("div", { children: ["Quality: ", qualityRendered] })] }) })] }));
}
export default FilterEntry;
