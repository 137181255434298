// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersData__e68 {
  margin-top: calc(var(--ring-unit) / 2);
}

.usersLimit__bab + .usedBy__b39 {
  margin-left: calc(var(--ring-unit) * 1.5);
}

.notInUseWarning__e3a {
  margin-top: calc(var(--ring-unit) / 2);
  color: var(--ring-warning-color);
}

.overLimitError__fad {
  margin-top: calc(var(--ring-unit) / 2);
  display: flex;
  align-items: center;
}

.overLimitIcon__b13 {
  color: var(--ring-error-color);
}

.overLimitErrorMsg__a3e {
  margin-left: calc(var(--ring-unit) / 2);
  color: var(--ring-error-color);
}

.contactMsg__ebb {
  color: var(--ring-error-color);
}

.actions__aae {
  margin-top: calc(var(--ring-unit) * 1.5);

  display: flex;
  gap: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/ide-provisioner/ide-provisioner-enabled/ide-provisioner-enabled.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,sCAAsC;EACtC,gCAAgC;AAClC;;AAEA;EACE,sCAAsC;EACtC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,uCAAuC;EACvC,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,wCAAwC;;EAExC,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".usersData {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.usersLimit + .usedBy {\n  margin-left: calc(var(--ring-unit) * 1.5);\n}\n\n.notInUseWarning {\n  margin-top: calc(var(--ring-unit) / 2);\n  color: var(--ring-warning-color);\n}\n\n.overLimitError {\n  margin-top: calc(var(--ring-unit) / 2);\n  display: flex;\n  align-items: center;\n}\n\n.overLimitIcon {\n  color: var(--ring-error-color);\n}\n\n.overLimitErrorMsg {\n  margin-left: calc(var(--ring-unit) / 2);\n  color: var(--ring-error-color);\n}\n\n.contactMsg {\n  color: var(--ring-error-color);\n}\n\n.actions {\n  margin-top: calc(var(--ring-unit) * 1.5);\n\n  display: flex;\n  gap: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usersData": `usersData__e68`,
	"usersLimit": `usersLimit__bab`,
	"usedBy": `usedBy__b39`,
	"notInUseWarning": `notInUseWarning__e3a`,
	"overLimitError": `overLimitError__fad`,
	"overLimitIcon": `overLimitIcon__b13`,
	"overLimitErrorMsg": `overLimitErrorMsg__a3e`,
	"contactMsg": `contactMsg__ebb`,
	"actions": `actions__aae`
};
export default ___CSS_LOADER_EXPORT___;
