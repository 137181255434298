import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Badge from '@jetbrains/ring-ui/components/badge/badge';
import trashIcon from '@jetbrains/icons/trash';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import { noop } from 'lodash';
import { createEnumParam, NumberParam, StringParam } from 'serialize-query-params';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TABLE_PAGE_SIZE, pagingSortOrder } from '@api/paging-utils';
import { ensureUsersByProfileId, useDeleteProfileMutation, usePaginatedProfiles } from '@app/queries/profiles';
import { useUnAssignUsersMutation } from '@app/queries/users';
import { FullWidthSelectableTable } from '@components/tables/full-width-selectable-table';
import LoadingError from '@components/errors/loading-error';
import NavigationLink from '@components/navigation/navigation-link';
import { TableActionButton, TableActionsContainer } from '@components/tables/table-action-button';
import SearchFilter from '@components/search-filter/search-filter';
import { useSyncedSearchArgs } from '@components/navigation/use-synced-search-args';
import { withSortableColumnFlag } from '@components/tables/table-columns';
import { assignTestId } from '@components/infra/tests/assign-test-id';
import confirmProfileDeletion from '../profile-details/profile-ide-provisioner/confirm-profile-deletion';
import styles from './profiles-admin-body.css';
function getProfileUrl(profile) {
    return `/profiles/${profile.id}`;
}
function getTitle(profile) {
    return (_jsxs("div", { className: styles.title, children: [_jsx(NavigationLink, { ...assignTestId('profile-link', 'profile-title'), href: getProfileUrl(profile), children: profile.title }), profile.isDefault ? (_jsx(Badge, { "data-test": "default-profile-badge", className: styles.badge, children: "Default" })) : null] }));
}
const sortableColumns = {
    title: 'title'
};
const tsTypeToQuerySchema = {
    query: StringParam,
    page: NumberParam,
    sortKey: createEnumParam(Object.values(sortableColumns)),
    sortOrder: createEnumParam(pagingSortOrder)
};
function validateProfilesFilter(parsed) {
    var _a, _b, _c;
    return {
        query: (_a = parsed.query) !== null && _a !== void 0 ? _a : '',
        page: parsed.page && parsed.page > 0 ? parsed.page : 1,
        sortKey: (_b = parsed.sortKey) !== null && _b !== void 0 ? _b : 'title',
        sortOrder: (_c = parsed.sortOrder) !== null && _c !== void 0 ? _c : 'ASC'
    };
}
export function ProfilesAdminBody() {
    var _a, _b, _c;
    const navigate = useNavigate();
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema, validateProfilesFilter);
    const unassignMutation = useUnAssignUsersMutation();
    const deleteMutation = useDeleteProfileMutation();
    const { data, isFetching, isError } = usePaginatedProfiles(urlParams);
    const profiles = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.profiles) !== null && _a !== void 0 ? _a : []; }, [data]);
    const totalCount = (_a = data === null || data === void 0 ? void 0 : data.totalCount) !== null && _a !== void 0 ? _a : 0;
    const onFiltersChange = useCallback((newFilters) => {
        setUrlParams({
            ...urlParams,
            query: newFilters.query,
            page: newFilters.query !== urlParams.query ? 1 : urlParams.page
        });
    }, [setUrlParams, urlParams]);
    const handleDelete = useCallback(async (profileId, profileTitle, usersList) => {
        if (!(usersList === null || usersList === void 0 ? void 0 : usersList.length)) {
            return deleteMutation.mutateAsync({ profileId, profileTitle });
        }
        await unassignMutation.mutateAsync({ profileId, usersList });
        return deleteMutation.mutateAsync({ profileId, profileTitle });
    }, [deleteMutation, unassignMutation]);
    const columns = useMemo(() => withSortableColumnFlag([
        {
            id: sortableColumns.title,
            title: 'Title',
            getValue: profile => getTitle(profile)
        },
        {
            id: 'description',
            title: 'Description',
            className: styles.description
        },
        {
            id: 'actionButtons',
            getValue: ({ id, title }) => {
                var _a;
                return (_jsx(TableActionsContainer, { children: _jsx(TableActionButton, { title: "Remove profile", icon: trashIcon, loader: deleteMutation.isLoading && ((_a = deleteMutation.variables) === null || _a === void 0 ? void 0 : _a.profileId) === id, "data-test": "remove-profile", action: () => {
                            setIsLoadingUsers(true);
                            ensureUsersByProfileId(id)
                                .then(users => {
                                var _a;
                                setIsLoadingUsers(false);
                                confirmProfileDeletion(title, (_a = users.items.length) !== null && _a !== void 0 ? _a : 0)
                                    .then(() => handleDelete(id, title, users.items))
                                    .catch(hideConfirm);
                            })
                                .catch(noop)
                                .finally(() => setIsLoadingUsers(false));
                        } }) }));
            }
        }
    ], sortableColumns), [deleteMutation.isLoading, (_b = deleteMutation.variables) === null || _b === void 0 ? void 0 : _b.profileId, handleDelete]);
    return (_jsxs(_Fragment, { children: [isError && _jsx(LoadingError, {}), _jsx(SearchFilter, { label: "Filter", value: (_c = urlParams.query) !== null && _c !== void 0 ? _c : '', onChange: value => onFiltersChange({ query: value }), placeholder: "Search by title or description" }), _jsx(FullWidthSelectableTable, { data: profiles, getItemKey: item => item.id, loading: isLoadingUsers !== null && isLoadingUsers !== void 0 ? isLoadingUsers : isFetching, columns: columns, selectable: false, onItemEnter: profile => navigate(getProfileUrl(profile)), sortKey: urlParams.sortKey, sortOrder: urlParams.sortOrder === 'ASC', onSort: event => setUrlParams({
                    ...urlParams,
                    sortKey: event.column.id,
                    sortOrder: event.order ? 'ASC' : 'DESC',
                    page: 1
                }), renderEmpty: () => 'No profiles found', pagerProps: {
                    total: totalCount,
                    disablePageSizeSelector: true,
                    currentPage: urlParams.page,
                    pageSize: DEFAULT_TABLE_PAGE_SIZE,
                    onPageChange: newPage => setUrlParams({
                        ...urlParams,
                        page: newPage
                    })
                } })] }));
}
