import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import { useGetGroupDetailsForSelect, useInfiniteGroupsQuery } from '@app/queries/groups';
import ToolbarItem from '@components/toolbar-item/toolbar-item';
const NO_GROUP_ID = 'no-group';
const NO_GROUP_OPTION = { key: NO_GROUP_ID, label: 'No group' };
function GroupFilter({ groupId, onChange }) {
    var _a;
    const [query, setQuery] = useState(null);
    const [enableGroupsOptions, setEnableGroupsOptions] = useState(false);
    const [enableGroupDetails, setEnableGroupDetails] = useState(groupId.trim().length > 0 && groupId !== NO_GROUP_ID);
    const { data: groupDetails } = useGetGroupDetailsForSelect(groupId, enableGroupDetails);
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isInitialLoading } = useInfiniteGroupsQuery({ query }, enableGroupsOptions);
    const fetchedSingleGroup = useMemo(() => {
        if (!(groupDetails === null || groupDetails === void 0 ? void 0 : groupDetails.id)) {
            return undefined;
        }
        return { key: groupDetails === null || groupDetails === void 0 ? void 0 : groupDetails.id, label: groupDetails === null || groupDetails === void 0 ? void 0 : groupDetails.name };
    }, [groupDetails]);
    const groupOptions = useMemo(() => {
        const groups = data === null || data === void 0 ? void 0 : data.pages.flatMap(page => page.items);
        const groupsWithKeys = groups === null || groups === void 0 ? void 0 : groups.map(item => ({ ...item, key: item.id, label: item.name }));
        if (!groupsWithKeys) {
            return [NO_GROUP_OPTION];
        }
        return [NO_GROUP_OPTION].concat(groupsWithKeys);
    }, [data]);
    const handleChange = useCallback((selected) => {
        setEnableGroupDetails(false);
        onChange((selected === null || selected === void 0 ? void 0 : selected.key) ? `${selected === null || selected === void 0 ? void 0 : selected.key}` : undefined);
    }, [onChange]);
    return (_jsx(ToolbarItem, { label: "Group", children: _jsx(Select, { clear: true, label: "All", size: Select.Size.M, loading: isFetchingNextPage || isInitialLoading, data: groupOptions, onBeforeOpen: () => setEnableGroupsOptions(true), selected: (_a = groupOptions.find(option => option.key === groupId)) !== null && _a !== void 0 ? _a : fetchedSingleGroup, onChange: handleChange, onLoadMore: hasNextPage ? fetchNextPage : undefined, filter: true, onFilter: val => setQuery(val) }) }));
}
export default GroupFilter;
