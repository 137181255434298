// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[8].use[1]!../../../node_modules/@jetbrains/ring-ui/components/global/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__f6b {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 100%;
  line-height: var(--ring-line-height);

  &.authorized__a02 {
    min-width: calc(${___CSS_LOADER_ICSS_IMPORT_0___.locals["breakpoint-middle"]} + var(--ring-unit) * 4);
  }
}

.innerWrapper__ab4 {
  display: flex;
  flex: 1 1 100%;
}

.sidebar__ede {
  flex: 0 0 240px;

  display: flex;
  flex-direction: column;

  background: var(--ring-sidebar-background-color);
}

.content__e5b {
  flex: 1 1 100%;
  padding: calc(var(--ring-unit) * 3) calc(var(--ring-unit) * 4);
  width: 100%;
}

.footerContainer__fc4 {
  margin-top: auto;
}

.footer__f4b {
  display: contents;
}
`, "",{"version":3,"sources":["webpack://./src/components/app-layout/app-layout.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,OAAO;EACP,sBAAsB;EACtB,WAAW;EACX,eAAe;EACf,YAAY;EACZ,oCAAoC;;EAEpC;IACE,oFAAyD;EAC3D;AACF;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,eAAe;;EAEf,aAAa;EACb,sBAAsB;;EAEtB,gDAAgD;AAClD;;AAEA;EACE,cAAc;EACd,8DAA8D;EAC9D,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["@value breakpoint-middle from '~@jetbrains/ring-ui/components/global/global.css';\n\n.app {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  width: 100%;\n  max-width: 100%;\n  height: 100%;\n  line-height: var(--ring-line-height);\n\n  &.authorized {\n    min-width: calc(breakpoint-middle + var(--ring-unit) * 4);\n  }\n}\n\n.innerWrapper {\n  display: flex;\n  flex: 1 1 100%;\n}\n\n.sidebar {\n  flex: 0 0 240px;\n\n  display: flex;\n  flex-direction: column;\n\n  background: var(--ring-sidebar-background-color);\n}\n\n.content {\n  flex: 1 1 100%;\n  padding: calc(var(--ring-unit) * 3) calc(var(--ring-unit) * 4);\n  width: 100%;\n}\n\n.footerContainer {\n  margin-top: auto;\n}\n\n.footer {\n  display: contents;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpoint-middle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["breakpoint-middle"]}`,
	"app": `app__f6b`,
	"authorized": `authorized__a02`,
	"innerWrapper": `innerWrapper__ab4`,
	"sidebar": `sidebar__ede`,
	"content": `content__e5b`,
	"footerContainer": `footerContainer__fc4`,
	"footer": `footer__f4b`
};
export default ___CSS_LOADER_EXPORT___;
