import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import searchIcon from '@jetbrains/icons/search';
import { H2 } from '@jetbrains/ring-ui/components/heading/heading';
import Input, { Size } from '@jetbrains/ring-ui/components/input/input';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import SmartTabs from '@jetbrains/ring-ui/components/tabs/smart-tabs';
import Text from '@jetbrains/ring-ui/components/text/text';
import { partition } from 'lodash';
import { useUserApplications } from '../../../queries/users';
import { ApplicationsTab } from './applications-tab';
import styles from './user-applications.css';
export function UserApplications({ id }) {
    const { data, isLoading, isFetching } = useUserApplications(id);
    const [filterValue, setFilterValue] = useState('');
    const filteredProducts = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.items.filter(({ name }) => name.toLowerCase().includes(filterValue.toLowerCase()))) !== null && _a !== void 0 ? _a : []; }, [filterValue, data]);
    const [availableItems, restrictedItems] = useMemo(() => partition(filteredProducts, item => item.available), [filteredProducts]);
    return (_jsx("div", { className: styles.applicationsContainer, children: isLoading ? (_jsx(Loader, {})) : (_jsxs(_Fragment, { children: [_jsxs(H2, { className: styles.header, children: [_jsx(Text, { children: "Applications" }), isFetching && _jsx(LoaderInline, {})] }), _jsx(Input, { icon: searchIcon, size: Size.FULL, placeholder: "Search for applications", onChange: e => setFilterValue(e.target.value), onClear: () => setFilterValue(''), value: filterValue }), _jsxs(SmartTabs, { children: [_jsx(ApplicationsTab, { title: _jsxs(Text, { children: ["Available ", _jsx(Text, { info: true, children: availableItems.length })] }), type: "Available", data: availableItems, filterValue: filterValue }), _jsx(ApplicationsTab, { title: _jsxs(Text, { children: ["Unavailable ", _jsx(Text, { info: true, children: restrictedItems.length })] }), type: "Unavailable", data: restrictedItems, filterValue: filterValue })] })] })) }));
}
