import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { formatDistance } from 'date-fns';
import { formatDateString } from '@components/dates/format';
import NavigationLink from '@components/navigation/navigation-link';
import styles from './license-details.css';
function LicenseDetails({ licenseData }) {
    var _a, _b;
    const distanceBetween = useMemo(() => (licenseData.expirationDate ? calculateDays(licenseData.expirationDate) : ''), [licenseData.expirationDate]);
    return (_jsxs("div", { children: [_jsxs("div", { className: styles.licenseContainer, children: [licenseData.id && _jsxs(Text, { children: ["License ID: ", licenseData.id] }), (licenseData === null || licenseData === void 0 ? void 0 : licenseData.expirationDate) && (_jsxs(Text, { children: ["Active until ", formatDateString(licenseData === null || licenseData === void 0 ? void 0 : licenseData.expirationDate, 'd MMM yyyy'), ",", ' ', distanceBetween, " left"] }))] }), _jsxs("div", { className: styles.licenseContainer, children: [((_a = licenseData === null || licenseData === void 0 ? void 0 : licenseData.activatedBy) === null || _a === void 0 ? void 0 : _a.username) && licenseData.activationDate && (_jsxs(Text, { children: ["Activated by ", (_b = licenseData === null || licenseData === void 0 ? void 0 : licenseData.activatedBy) === null || _b === void 0 ? void 0 : _b.username, " on", ' ', formatDateString(licenseData === null || licenseData === void 0 ? void 0 : licenseData.activationDate, 'd MMM yyyy')] })), _jsx(NavigationLink, { href: "/configuration/license-activation/history", children: "License history" })] })] }));
}
function calculateDays(date) {
    const currentDate = new Date();
    return formatDistance(new Date(date), currentDate);
}
export default LicenseDetails;
