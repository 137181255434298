import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { TitledBlock } from '@components/titled-block/titled-block';
import { assignTestId } from '@components/infra/tests/assign-test-id';
import styles from './constituent-block.css';
export function ConstituentBlock(props) {
    const { children, titleLogo, title, state, className } = props;
    return (_jsx(TitledBlock, { title: _jsxs("span", { className: styles.titleWrapper, children: [title, " ", _jsx(StateBadge, { state: state })] }), titleLogo: titleLogo, className: className, ...assignTestId('constituent-block'), children: children }));
}
function StateBadge(props) {
    const { state } = props;
    switch (state) {
        case 'enabled':
            return _jsx("span", { className: classNames(styles.badge, styles.enabled), children: "Enabled" });
        case 'disabled':
            return _jsx("span", { className: classNames(styles.badge, styles.disabled), children: "Disabled" });
        default:
            throw new Error(`Unknown state: ${state}`);
    }
}
