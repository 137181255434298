// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__ef5 {
  margin-top: auto;
  padding: 0 calc(2 * var(--ring-unit)) calc(2 * var(--ring-unit)) calc(4 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/components/app-layout/sidebar/sidebar-footer/sidebar-footer.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,2FAA2F;AAC7F","sourcesContent":[".footer {\n  margin-top: auto;\n  padding: 0 calc(2 * var(--ring-unit)) calc(2 * var(--ring-unit)) calc(4 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer__ef5`
};
export default ___CSS_LOADER_EXPORT___;
