// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__d60 {
  width: auto;
  min-width: calc(var(--ring-unit) * 58);
}

.header__e73 {
  display: inline-flex;
  align-items: center;
}

.logoWrapper__ce3 {
  height: 24px;
  width: 24px;
}

.logoWrapper__ce3 + .title__d28 {
  margin-left: calc(var(--ring-unit) / 2);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/shared/constituent-dialog/constituent-dialog.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sCAAsC;AACxC;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".wrapper {\n  width: auto;\n  min-width: calc(var(--ring-unit) * 58);\n}\n\n.header {\n  display: inline-flex;\n  align-items: center;\n}\n\n.logoWrapper {\n  height: 24px;\n  width: 24px;\n}\n\n.logoWrapper + .title {\n  margin-left: calc(var(--ring-unit) / 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__d60`,
	"header": `header__e73`,
	"logoWrapper": `logoWrapper__ce3`,
	"title": `title__d28`
};
export default ___CSS_LOADER_EXPORT___;
