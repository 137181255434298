// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.removeUserButton__a91 {
  visibility: hidden;
}

.toolbar__b27 {
  display: flex;
  justify-content: space-between;
}

.usernameBadge__ab7 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--ring-unit) / 2);
}

.constituentsLogos__af7 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--ring-unit) / 2);
}

.logoContainer__faf {
  width: 20px;
  height: 20px;
}

.logoContainerDisabled__f88 {
  filter: grayscale(100%);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/users-admin-page/users-admin-page.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".removeUserButton {\n  visibility: hidden;\n}\n\n.toolbar {\n  display: flex;\n  justify-content: space-between;\n}\n\n.usernameBadge {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: calc(var(--ring-unit) / 2);\n}\n\n.constituentsLogos {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: calc(var(--ring-unit) / 2);\n}\n\n.logoContainer {\n  width: 20px;\n  height: 20px;\n}\n\n.logoContainerDisabled {\n  filter: grayscale(100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeUserButton": `removeUserButton__a91`,
	"toolbar": `toolbar__b27`,
	"usernameBadge": `usernameBadge__ab7`,
	"constituentsLogos": `constituentsLogos__af7`,
	"logoContainer": `logoContainer__faf`,
	"logoContainerDisabled": `logoContainerDisabled__f88`
};
export default ___CSS_LOADER_EXPORT___;
