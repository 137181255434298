// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__bf9 {
  color: var(--ring-secondary-color);
}

.downloadButton__ea2 {
  color: inherit;
  padding: 0;
}

.archDropdown__d1a {
  color: inherit;
}

.archDropdown__d1a button {
  color: inherit;
}

.fetchingLoader__e5c {
  margin-right: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/components/machine-config/download-toolbox-app.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".wrapper {\n  color: var(--ring-secondary-color);\n}\n\n.downloadButton {\n  color: inherit;\n  padding: 0;\n}\n\n.archDropdown {\n  color: inherit;\n}\n\n.archDropdown button {\n  color: inherit;\n}\n\n.fetchingLoader {\n  margin-right: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__bf9`,
	"downloadButton": `downloadButton__ea2`,
	"archDropdown": `archDropdown__d1a`,
	"fetchingLoader": `fetchingLoader__e5c`
};
export default ___CSS_LOADER_EXPORT___;
