// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aceEditor__dc5 {
  border: 1px solid lightgray;
  border-radius: var(--ring-border-radius);
  z-index: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/editors/ace-editor.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,wCAAwC;EACxC,UAAU;AACZ","sourcesContent":[".aceEditor {\n  border: 1px solid lightgray;\n  border-radius: var(--ring-border-radius);\n  z-index: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aceEditor": `aceEditor__dc5`
};
export default ___CSS_LOADER_EXPORT___;
