import { createContext, useCallback, useContext, useMemo } from 'react';
import { noop } from 'lodash';
export const NotificationsContext = createContext({
    formState: { formType: 'content' },
    setFormState: noop
});
export function useNotificationsContext() {
    const context = useContext(NotificationsContext);
    if (context === undefined) {
        throw new Error('useNotificationsFormContext must be used within SharedFormContext');
    }
    const { formState, setFormState } = context;
    // region setFormState boilerplate
    const setContentFormState = useCallback((ruleId) => {
        setFormState({ formType: 'content', ruleId });
    }, [setFormState]);
    const setFiltersFormState = useCallback((ruleId) => {
        setFormState({ formType: 'filters', ruleId });
    }, [setFormState]);
    const resetFormState = useCallback(() => {
        setFormState(undefined);
    }, [setFormState]);
    // endregion
    // region showForm boilerplate
    const showContentForm = useMemo(() => (formState === null || formState === void 0 ? void 0 : formState.formType) === 'content', [formState]);
    const showFiltersForm = useMemo(() => (formState === null || formState === void 0 ? void 0 : formState.formType) === 'filters', [formState]);
    // endregion
    return {
        ruleId: formState === null || formState === void 0 ? void 0 : formState.ruleId,
        showContentForm,
        showFiltersForm,
        setContentFormState,
        setFiltersFormState,
        resetFormState
    };
}
