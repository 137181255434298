// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__add {
  display: flex;
  flex-direction: column;
}

.header__b53 {
  display: flex;
  align-items: center;
}

.icon__ce5 {
  color: var(--ring-error-color);
}

.title__b5e {
  margin-left: calc(var(--ring-unit) * 1.5);

  font-size: var(--ring-font-size-larger);
  line-height: var(--ring-line-height);
  color: var(--ring-white-text-color);
}

.text__f60 {
  margin-top: calc(var(--ring-unit) / 2);
}

.buttons__f1d {
  margin-top: calc(var(--ring-unit) * 1.5);
  white-space: nowrap;
}

.baseNotificationAlertCaption__f59 {
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/constituent-exceed-notification-controller/base-notification/base-notification.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,yCAAyC;;EAEzC,uCAAuC;EACvC,oCAAoC;EACpC,mCAAmC;AACrC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,wCAAwC;EACxC,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n}\n\n.icon {\n  color: var(--ring-error-color);\n}\n\n.title {\n  margin-left: calc(var(--ring-unit) * 1.5);\n\n  font-size: var(--ring-font-size-larger);\n  line-height: var(--ring-line-height);\n  color: var(--ring-white-text-color);\n}\n\n.text {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.buttons {\n  margin-top: calc(var(--ring-unit) * 1.5);\n  white-space: nowrap;\n}\n\n.baseNotificationAlertCaption {\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__add`,
	"header": `header__b53`,
	"icon": `icon__ce5`,
	"title": `title__b5e`,
	"text": `text__f60`,
	"buttons": `buttons__f1d`,
	"baseNotificationAlertCaption": `baseNotificationAlertCaption__f59`
};
export default ___CSS_LOADER_EXPORT___;
