import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
function formatText(usersAmount) {
    return usersAmount === 1 ? '1 user' : `${usersAmount} users`;
}
export default function confirmProfileDeletion(profileTitle, usersAmount) {
    return confirm({
        text: 'Delete Profile?',
        description: (_jsxs(_Fragment, { children: [_jsx("p", { children: usersAmount > 0 && (_jsxs(_Fragment, { children: [_jsx("b", { children: profileTitle }), " profile is assigned to ", _jsx("b", { children: formatText(usersAmount) }), ". They will lose access to configured applications, plugins, and settings.", _jsx("br", {}), "This action can't be undone."] })) }), _jsx("p", { children: usersAmount === 0 && (_jsxs(_Fragment, { children: [_jsx("b", { children: profileTitle }), " profile will be deleted immediately.", _jsx("br", {}), "This action can't be undone."] })) })] })),
        confirmLabel: 'Delete',
        rejectLabel: 'Cancel',
        cancelIsDefault: true
    });
}
