import { jsx as _jsx } from "react/jsx-runtime";
import { useRouteError } from 'react-router-dom';
import { isApiError } from '@api/is-api-error';
import NotFoundPage from '@components/errors/not-found-page';
import ForbiddenPage from '@components/errors/forbidden-page';
import UnknownErrorPage from '@components/errors/unknown-error-page';
import auth from '@components/auth/auth';
import { LicenseActivationPage } from '@components/license/license-activation-page';
export function RouteErrorBoundary() {
    var _a;
    const error = useRouteError();
    if (!auth.hasLicense()) {
        return _jsx(LicenseActivationPage, {});
    }
    if (isApiError(error)) {
        const { response } = error;
        const message = ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.message) || (response === null || response === void 0 ? void 0 : response.statusText) || error.message;
        if ((response === null || response === void 0 ? void 0 : response.status) === 404) {
            return _jsx(NotFoundPage, { message: message });
        }
        if ((response === null || response === void 0 ? void 0 : response.status) === 403) {
            return _jsx(ForbiddenPage, { message: message });
        }
        return _jsx(UnknownErrorPage, { code: `${(response === null || response === void 0 ? void 0 : response.status) || 'Unknown'}`, message: message });
    }
    // ignore non api errors
    throw error;
}
