import {formatProductCode, formatProductVersion} from '../components/util/i18n';
import {baseAnalyticsTableReducer} from './base-analytics-table';

const SET_IDE_ANALYTICS = 'SET_IDE_ANALYTICS';
const SET_IDE_ANALYTICS_IS_FETCHING = 'SET_IDE_ANALYTICS_IS_FETCHING';
const SET_IDE_ANALYTICS_FETCH_ERROR = 'SET_IDE_ANALYTICS_FETCH_ERROR';
const SET_IDE_ANALYTICS_PAGE_PARAMS = 'SET_IDE_ANALYTICS_PAGE_PARAMS';
const SET_IDE_ANALYTICS_SORT_PARAMS = 'SET_IDE_ANALYTICS_SORT_PARAMS';

const defaultState = {
  items: [],
  isFetching: true,
  isFetchError: false,
  isRefreshError: false,
  sortKey: 'machinesCount',
  currentPageIndex: 1,
  currentPageData: [],
  sortOrder: false,
  pageSize: 25,
  totalItems: 0
};

export default function ideAnalyticsReducer(state = defaultState, action) {
  const onUnknownEventType = (action, state) => state;
  const eventTypes = {
    SET_ITEMS: SET_IDE_ANALYTICS,
    IS_FETCHING: SET_IDE_ANALYTICS_IS_FETCHING,
    FETCHING_ERROR: SET_IDE_ANALYTICS_FETCH_ERROR,
    PAGE_PARAMS: SET_IDE_ANALYTICS_PAGE_PARAMS,
    SORT_PARAMS: SET_IDE_ANALYTICS_SORT_PARAMS
  };
  return baseAnalyticsTableReducer(eventTypes, onUnknownEventType, state, action);
}

export function setIdeAnalyticsInfo(data) {
  return {
    type: SET_IDE_ANALYTICS,
    payload: data.map(element => {
      return {
        ...element,
        ideName: formatProductCode(element.ideName),
        ideVersion: formatProductVersion(element.ideVersion)
      };
    })
  };
}

export function setIdeAnalyticsIsFetching(isFetching) {
  return {type: SET_IDE_ANALYTICS_IS_FETCHING, payload: isFetching};
}

export function setIdeAnalyticsFetchError(error) {
  return {type: SET_IDE_ANALYTICS_FETCH_ERROR, payload: error};
}

export function setPagingParameters(pageParams) {
  return {type: SET_IDE_ANALYTICS_PAGE_PARAMS, payload: pageParams};
}

export function setSortingParameters(params) {
  return {type: SET_IDE_ANALYTICS_SORT_PARAMS, payload: params};
}
