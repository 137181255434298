import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Table} from '@jetbrains/ring-ui/components/table/table';
import Selection from '@jetbrains/ring-ui/components/table/selection';
import Pager from '@jetbrains/ring-ui/components/pager/pager';
import Button from '@jetbrains/ring-ui/components/button/button';
import LoadingError from '../../components/errors/loading-error';
import {getUsageDataHistory, rescanUsageDataHistory} from '../../api/usage-data-history';
import {setPagingParameters, setSortingParameters} from '../../store/usage-data-history';
import styles from './collected-data-history.css';

export default function CollectedDataHistory() {
  const PAGE_SIZE = 25;

  const columns = [
    {id: 'batchName', title: 'Batch Name', sortable: true},
    {id: 'totalEvents', title: 'Event Logs', sortable: true, rightAlign: true},
    {id: 'totalErrors', title: 'Errors', sortable: true, rightAlign: true},
    {id: 'totalFreezes', title: 'Freezes', sortable: true, rightAlign: true},
    {id: 'right-placeholder', title: ''}
  ];

  const dispatch = useDispatch();

  const items = useSelector(state => state.usageDataHistory.items);
  const isFetching = useSelector(state => state.usageDataHistory.isFetching);
  const isFetchError = useSelector(state => state.usageDataHistory.isFetchError);
  const currentPageIndex = useSelector(state => state.usageDataHistory.currentPageIndex);
  const currentPageData = useSelector(state => state.usageDataHistory.currentPageData);
  const sortKey = useSelector(state => state.usageDataHistory.sortKey);
  const sortOrder = useSelector(state => state.usageDataHistory.sortOrder);

  const [selection, setSelection] = useState(new Selection());

  const onSort = event => {
    dispatch(setSortingParameters(event));
  };

  const onPageChange = nextPageIndex => {
    dispatch(setPagingParameters({nextPageIndex, pageSize: PAGE_SIZE}));
  };

  const onRescan = () => {
    dispatch(rescanUsageDataHistory());
  };

  useEffect(() => {
    dispatch(getUsageDataHistory());
  }, []);

  if (isFetchError) {
    return <LoadingError />;
  }

  return (
    <>
      <Button loader={isFetching} onClick={onRescan} className={styles.pullRight}>
        Rescan
      </Button>
      <Table
        data={currentPageData}
        getItemKey={item => item.batchName}
        loading={isFetching}
        columns={columns}
        onSort={onSort}
        onFocusRestore={() => {}}
        autofocus
        selectable={false}
        selection={selection}
        onSelect={setSelection}
        sortKey={sortKey}
        sortOrder={sortOrder}
      />
      <Pager
        total={items.length}
        pageSize={PAGE_SIZE}
        currentPage={currentPageIndex}
        disablePageSizeSelector
        onPageChange={onPageChange}
      />
    </>
  );
}
