import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { uniqBy } from 'lodash';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import classnames from 'classnames';
import ProductLogo from './product-logo';
import sharedStyles from './tools-preview.css';
export default function SimpleToolsPreview({ tools, className, isLoading = false }) {
    const uniqueTools = uniqBy(tools, 'product');
    return (_jsxs(Text, { "data-test": "applications-preview", className: classnames(sharedStyles.boxHeading, className), children: [uniqueTools.map(tool => (_jsx(Fragment, { children: _jsx(Tooltip, { title: tool.productName, children: _jsx(ProductLogo, { product: tool.product, className: sharedStyles.productLogo }) }) }, tool.product))), uniqueTools.length === 0 && 'No matching applications', isLoading && _jsx(LoaderInline, {})] }));
}
