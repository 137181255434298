import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import Select from '@jetbrains/ring-ui/components/select/select';
import RegisteredSelect from '@components/hook-form/registered-select';
import { PERSISTENT_STATE_XML_KIND, PERSISTENT_STATE_XPATH_KIND, REGISTRY_KIND, PROPERTIES_COMPONENT_KIND, CODESTYLE_XML_KIND, INSPECTION_PROFILE_XML_KIND, KEY_VALUE_LIST_KIND, ALL_KINDS } from '../../settings-builders';
export function formatKind(kind) {
    if (kind === PERSISTENT_STATE_XML_KIND) {
        return '(deprecated) Persistent state XML';
    }
    if (kind === PERSISTENT_STATE_XPATH_KIND) {
        return 'Persistent state XPATH';
    }
    if (kind === REGISTRY_KIND) {
        return 'Registry';
    }
    if (kind === PROPERTIES_COMPONENT_KIND) {
        return 'Properties component';
    }
    if (kind === CODESTYLE_XML_KIND) {
        return 'Code style';
    }
    if (kind === INSPECTION_PROFILE_XML_KIND) {
        return 'Inspection profile';
    }
    if (kind === KEY_VALUE_LIST_KIND) {
        return 'IDE Setting';
    }
    return kind;
}
const KIND_OPTIONS = ALL_KINDS.map(x => ({ label: formatKind(x), key: x }));
function toKindOption(kind) {
    return { key: kind, label: formatKind(kind) };
}
function KindField({ settingIndex }) {
    const { control, watch } = useFormContext();
    const watchKind = watch(`content.${settingIndex}.kind`);
    const selected = toKindOption(watchKind);
    return (_jsx(RegisteredSelect, { disabled: true, selectedLabel: "Kind", selected: selected, data: KIND_OPTIONS, control: control, name: `content.${settingIndex}.kind`, size: Select.Size.FULL }));
}
export default KindField;
