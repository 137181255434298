import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ConstituentEnabledGuard } from '@components/constituents/constituent-enabled-guard/constituent-enabled-guard';
import { PageTitle } from '../../components/page-layout/page-title';
import auth from '../../components/auth/auth';
import { PopularProfiles } from './popular-profiles';
import { ToolboxApp } from './toolbox-app';
import { CodeWithMeSessions } from './code-with-me-sessions';
import { Licenses } from './licenses';
import { ActiveUsers } from './active-users';
import { IdeProvisionerUsage } from './usage-statistics/ide-provisioner-usage/ide-provisioner-usage';
import { AIEnterpriseUsage } from './usage-statistics/ai-enterprise-usage/ai-enterprise-usage';
import { CodeWithMeUsage } from './usage-statistics/code-with-me-usage/code-with-me-usage';
import { AiEnterpriseUsagePlaceholder } from './usage-statistics/ai-enterprise-usage/ai-enterprise-usage-placeholder';
import { CodeWithMeUsagePlaceholder } from './usage-statistics/code-with-me-usage/code-with-me-usage-placeholder';
import { IdeProvisionerUsagePlaceholder } from './usage-statistics/ide-provisioner-usage/ide-provisioner-usage-placeholder';
import styles from './dashboard-page.css';
export function DashboardPage() {
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: "Dashboard" }), _jsxs("div", { className: styles.widgetsContainer, "data-test": "dashboard-widgets", children: [_jsx(ToolboxApp, {}), _jsx(ConstituentEnabledGuard, { constituent: "lv", children: _jsx(Licenses, {}) }), _jsx(PopularProfiles, {}), _jsx(ConstituentEnabledGuard, { constituent: "cwm", children: _jsx(CodeWithMeSessions, {}) }), auth.getFeatures().enableUserActivityAnalytics && _jsx(ActiveUsers, {}), _jsx(ConstituentEnabledGuard, { constituent: "idep", notEnabledFallback: _jsx(IdeProvisionerUsagePlaceholder, {}), children: _jsx(IdeProvisionerUsage, {}) }), _jsx(ConstituentEnabledGuard, { constituent: "ai", notEnabledFallback: _jsx(AiEnterpriseUsagePlaceholder, {}), children: _jsx(AIEnterpriseUsage, {}) }), _jsx(ConstituentEnabledGuard, { constituent: "cwm", notEnabledFallback: _jsx(CodeWithMeUsagePlaceholder, {}), children: _jsx(CodeWithMeUsage, {}) })] })] }));
}
