import confirm from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
export default function confirmUserDeactivation(userName) {
    return confirm({
        text: `Deactivate ${userName}?`,
        description: 'After deactivation, the user will no longer be managed by JetBrains IDE Services',
        confirmLabel: 'Deactivate',
        rejectLabel: 'Cancel',
        cancelIsDefault: true
    });
}
