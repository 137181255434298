import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import HorizontalContainer from '@components/page-layout/horizontal-container';
import SearchFilter from '@components/search-filter/search-filter';
import EventsFilter from './events-filter';
export function HistoryFilters({ filters, onFiltersChange }) {
    const handleSearchValueChange = useCallback(newValue => {
        if (typeof newValue === 'string') {
            onFiltersChange({
                ...filters,
                search: newValue.length > 0 ? newValue : undefined
            });
        }
    }, [onFiltersChange, filters]);
    return (_jsxs(HorizontalContainer, { children: [_jsx(SearchFilter, { value: filters.search || '', onChange: handleSearchValueChange, placeholder: "Search" }), _jsx(EventsFilter, { value: filters.type, onChange: value => onFiltersChange({ ...filters, type: value !== null && value !== void 0 ? value : undefined }) })] }));
}
