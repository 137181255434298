import { jsx as _jsx } from "react/jsx-runtime";
import { CollapsedDataItem } from './collapsed-data-item';
import styles from './nested-data-list.css';
/**
 * Custom component to display one-layer nested list.
 * Developed to cover different component behaviour than RingUI DataList.
 * DataList known issues:
 * - Does not maintain state after collapse
 * - Checkbox don't show selected nested items state
 * - Dependencies between selections are unclear based only on component types and storybook
 *
 * Will be replaced with DataList once those are resolved
 * @param {NestedDataListProps} NestedDataListProps
 * @returns {JSX.Element}
 */
export function NestedDataList({ data, excludedItemComponent }) {
    return (_jsx("div", { className: styles.nestedListContainer, children: data.map(item => (_jsx(CollapsedDataItem, { item: item, excludedItemComponent: excludedItemComponent }, item.id))) }));
}
