import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Toggle, { Size } from '@jetbrains/ring-ui/components/toggle/toggle';
import { CodeWithMeLogo } from '@jetbrains/logos/react';
import { ConstituentDialog } from '@app/pages/configuration-page/license/shared/constituent-dialog/constituent-dialog';
import { useCwmEnableMutation } from '@app/queries/code-with-me';
import styles from './cwm-enable-dialog.css';
export function CwmEnableDialog(props) {
    const { onCloseAttempt } = props;
    const { mutate: enable, isLoading } = useCwmEnableMutation(onCloseAttempt);
    const [confirmDisableGlobalAccess, setConfirmDisableGlobalAccess] = useState(false);
    const [confirmRelayServer, setConfirmRelayServer] = useState(false);
    const activateActive = confirmDisableGlobalAccess && confirmRelayServer;
    const handleClose = () => onCloseAttempt(undefined);
    const handleActivate = () => {
        enable({});
    };
    return (_jsxs(ConstituentDialog, { title: "Code With Me Enterprise", titleLogo: _jsx(CodeWithMeLogo, {}), children: [_jsxs(Content, { children: [_jsxs("p", { children: ["Code With Me Enterprise allows you to restrict", _jsx("br", {}), "Code With Me access to authenticated users"] }), _jsxs("p", { className: styles.confirmGlobalAccessWrapper, children: [_jsx(Toggle, { checked: confirmDisableGlobalAccess, onChange: e => setConfirmDisableGlobalAccess(e.target.checked), size: Size.Size16, children: "I confirm that global Code With Me access will be disabled" }), _jsxs("p", { className: styles.globalDisableWarning, children: ["Enabling Code With Me Enterprise disables access to the global", _jsx("br", {}), "Code With Me servers for all users connected to JetBrains IDE Services"] })] }), _jsx("p", { className: styles.relayToggleWrapper, children: _jsx(Toggle, { checked: confirmRelayServer, onChange: e => setConfirmRelayServer(e.target.checked), size: Size.Size16, children: "I confirm that one or more relay servers are configured and running" }) })] }), _jsxs(Panel, { children: [_jsx(Button, { onClick: handleActivate, disabled: !activateActive, loader: isLoading, primary: true, children: "Enable Code With Me Enterprise" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
