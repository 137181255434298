import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {isNil} from 'lodash';
import Select from '@jetbrains/ring-ui/components/select/select';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import Button, {IconSize} from '@jetbrains/ring-ui/components/button/button';
import newWindowIcon from '@jetbrains/icons/new-window';
import {H3} from '@jetbrains/ring-ui/components/heading/heading';

import {useNavigate} from 'react-router-dom';
import {LAST_7_DAYS, RANGE_OPTIONS} from '../analytics-table';
import {
  usePluginDownloads,
  usePluginDownloadsAnalytics,
  useTbePlugins
} from '../../../queries/plugins';
import {pluginPageResolver} from '../../../components/plugins/plugin-api-wrappers';
import HorizontalContainer from '../../../components/page-layout/horizontal-container';
import PluginDownloadsAnalyticsChart from './plugin-downloads-analytics-chart';
import DownloadsForSinglePlugin, {countTotalDownloads} from './downloads-for-single-plugin';
import {useTbePluginKeys} from './hooks';
import TbePluginsSelect from './tbe-plugins-select';
import styles from './plugin-downloads.css';

/**
 * Utility component that wraps a chart component
 * @param range
 * @param onLoadingChange
 * @return {JSX.Element}
 * @constructor
 */
function DownloadsAnalytics({range, onLoadingChange}) {
  const {data, isFetching, isError} = usePluginDownloadsAnalytics(range);
  const [tbeKeys, isFetchingTbeKeys] = useTbePluginKeys();

  // TBE-818 Don't render analytics for those plugins that don't exist in the plugins repository
  /** @type {ApiPluginDownloadsAnalytics} */
  const analytics = isNil(data)
    ? {entries: []}
    : {entries: data.entries.filter(x => tbeKeys.has(x.pluginId))};

  useEffect(
    () => onLoadingChange(isFetching || isFetchingTbeKeys),
    [isFetching, onLoadingChange, isFetchingTbeKeys]
  );

  if (isError || isFetching || isFetchingTbeKeys) {
    return null;
  }
  if (analytics.entries.length === 0) {
    return <h3>There are no plugin downloads for the given period</h3>;
  }
  return <PluginDownloadsAnalyticsChart analytics={analytics} />;
}

/**
 * Content which is rendered on a plugin downloads page when there is a plugin given
 * @param {Plugin} plugin
 * @param {string} range
 * @param {function(boolean): void} onLoadingChange
 * @return {JSX.Element}
 * @constructor
 */
function DownloadsForSinglePluginContent({plugin, range, onLoadingChange}) {
  const {data, isFetching} = usePluginDownloads(plugin?.key, range, !isNil(plugin.key));
  const total = countTotalDownloads(data);

  return (
    <>
      {!isFetching && (
        <H3>
          {total > 0 ? `Total: ${total}` : 'There are no plugin downloads for the given period'}
        </H3>
      )}

      <DownloadsForSinglePlugin
        pluginId={plugin.key}
        range={range}
        onLoadingChange={onLoadingChange}
      />
    </>
  );
}

export default function PluginDownloads({plugin}) {
  const [range, setRange] = useState(LAST_7_DAYS);
  const [selectedPlugin, setSelectedPlugin] = useState(plugin);
  const showAnalytics = !selectedPlugin;
  const {data: tbePlugins, isFetching: isFetchingTbePlugins} = useTbePlugins();
  const selectedPluginOption = useMemo(
    () => (tbePlugins || []).find(option => option.key === selectedPlugin?.id),
    [tbePlugins, selectedPlugin]
  );
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [loadingPluginDownloads, setLoadingPluginDownloads] = useState(false);
  const loading = (showAnalytics && loadingAnalytics) || (!showAnalytics && loadingPluginDownloads);

  const navigate = useNavigate();

  const onSelectPlugin = useCallback(
    entry => {
      setSelectedPlugin(entry);
      const encodededKey = entry?.key ? encodeURIComponent(entry.key) : '';
      (encodededKey ? pluginPageResolver(encodededKey) : Promise.resolve()).then(() => {
        navigate(`/analytics/plugins/downloads/${encodededKey}`, {
          state: {urlsafe: true},
          replace: true
        });
      });
    },
    [navigate]
  );

  return (
    <>
      <HorizontalContainer className={styles.settings}>
        <Select
          selectedLabel="Range"
          label="Range"
          data={RANGE_OPTIONS}
          selected={RANGE_OPTIONS.find(option => option.key === range)}
          onChange={option => setRange(option.key)}
          className={styles.range}
        />
        <TbePluginsSelect
          clear
          onPluginSelected={onSelectPlugin}
          selected={selectedPluginOption}
          plugins={tbePlugins}
          loading={isFetchingTbePlugins}
          onChange={item => isNil(item) && onSelectPlugin(null)}
          selectedLabel="Plugin"
        />
        {selectedPluginOption && (
          <Button
            primary
            icon={newWindowIcon}
            iconSize={IconSize.Size14}
            href={`/plugins/${encodeURIComponent(selectedPluginOption.key)}`}
            target="_blank"
          >
            &apos;{selectedPluginOption.label}&apos; plugin page
          </Button>
        )}
        {loading && <LoaderInline />}
      </HorizontalContainer>

      {showAnalytics && <DownloadsAnalytics onLoadingChange={setLoadingAnalytics} range={range} />}

      {selectedPluginOption && (
        <DownloadsForSinglePluginContent
          onLoadingChange={setLoadingPluginDownloads}
          plugin={selectedPluginOption}
          range={range}
        />
      )}
    </>
  );
}
