import { jsx as _jsx } from "react/jsx-runtime";
import Select from '@jetbrains/ring-ui/components/select/select';
import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';
import RingField from '@components/form/ring-field';
import { buildQualityOptions, toQualityOption } from '../filter-build-options';
function QualityField({ allQualities }) {
    var _a;
    const { setValue, getValues, watch } = useFormContext();
    const quality = (_a = getValues('quality')) !== null && _a !== void 0 ? _a : null;
    const type = watch('type').startsWith('include') ? 'include' : 'exclude';
    const qualityOptions = buildQualityOptions(allQualities !== null && allQualities !== void 0 ? allQualities : [], quality, type);
    return (_jsx(RingField, { fieldId: "qualityField", label: "Update channel", children: _jsx(Select, { label: type === 'include' ? 'Any build quality' : 'None of the listed', selected: !isNil(quality) ? toQualityOption(quality, type) : null, onChange: (selected) => {
                const newValue = (selected === null || selected === void 0 ? void 0 : selected.key) != null
                    ? {
                        orderValue: selected.key,
                        name: selected.qualityName,
                        condition: ''
                    }
                    : null;
                setValue('quality', newValue);
            }, filter: true, clear: true, data: qualityOptions, id: "qualityInequalityField" }) }));
}
export default QualityField;
