// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleWrapper__f3a {
  display: inline-flex;
  align-items: baseline;
}

.badge__ef5 {
  margin-left: var(--ring-unit);

  font-weight: 400;
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.badge__ef5.enabled__c8f {
  color: var(--ring-success-color);
}

.badge__ef5.disabled__e5b {
  color: var(--ring-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/shared/constituent-block/constituent-block.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;;EAE7B,gBAAgB;EAChB,wCAAwC;EACxC,2CAA2C;AAC7C;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".titleWrapper {\n  display: inline-flex;\n  align-items: baseline;\n}\n\n.badge {\n  margin-left: var(--ring-unit);\n\n  font-weight: 400;\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n}\n\n.badge.enabled {\n  color: var(--ring-success-color);\n}\n\n.badge.disabled {\n  color: var(--ring-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrapper": `titleWrapper__f3a`,
	"badge": `badge__ef5`,
	"enabled": `enabled__c8f`,
	"disabled": `disabled__e5b`
};
export default ___CSS_LOADER_EXPORT___;
