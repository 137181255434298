import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import Toggle, { Size } from '@jetbrains/ring-ui/components/toggle/toggle';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { useGenerateNewPublicKeyMutation } from '@app/queries/tbe-license';
import NavigationLink from '@components/navigation/navigation-link';
import { ExternalLinks } from '@components/constants/constants';
import styles from './license-generate-new-public-key-dialog.css';
export function LicenseGenerateNewPublicKeyDialog(props) {
    const { onCloseAttempt } = props;
    const { mutate: disable, isLoading } = useGenerateNewPublicKeyMutation(() => onCloseAttempt({ regenerated: true }));
    const [isConfirmed, setConfirmed] = useState(false);
    const handleDisable = useCallback(() => disable(), [disable]);
    const handleClose = useCallback(() => onCloseAttempt({ regenerated: false }), [onCloseAttempt]);
    return (_jsxs(Dialog, { show: true, children: [_jsx(Header, { children: "Generate New Public Key" }), _jsxs(Content, { children: [_jsxs("p", { children: ["Generating a new public key will result in the loss of all existing license details and will require server re-activation. Contact the", ' ', _jsx(NavigationLink, { href: `${ExternalLinks.SUPPORT}/hc/`, target: "_blank", children: "IDE Services support team" }), ' ', "before proceeding further."] }), _jsx("p", { className: styles.toggleWrapper, children: _jsx(Toggle, { size: Size.Size16, checked: isConfirmed, onChange: e => setConfirmed(e.target.checked), children: "I understand the effects of generating a new public key and have contacted the IDE Services support team" }) })] }), _jsxs(Panel, { children: [_jsx(Button, { disabled: !isConfirmed, onClick: handleDisable, loader: isLoading, primary: true, children: "Generate public key" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
