// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__aa1 {
  display: flex;
  justify-content: flex-end;
  padding: 0 calc(var(--ring-unit) * 2);

  &.withImage__a12 {
    justify-content: space-between;
  }
}

.textsContainer__ba8 {
  justify-self: flex-end;

  height: calc(var(--ring-unit) * 11);
  max-width: calc(var(--ring-unit) * 31);
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: calc(var(--ring-unit) / 2);
}

.dragActive__de4 {
  background-color: var(--ring-selected-background-color);
}

.errorMessage__d09 {
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/client-machine-configuration/logo-editor/upload-logo-dialog/image-file-attachment/image-file-attachment.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,qCAAqC;;EAErC;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE,sBAAsB;;EAEtB,mCAAmC;EACnC,sCAAsC;EACtC,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: flex-end;\n  padding: 0 calc(var(--ring-unit) * 2);\n\n  &.withImage {\n    justify-content: space-between;\n  }\n}\n\n.textsContainer {\n  justify-self: flex-end;\n\n  height: calc(var(--ring-unit) * 11);\n  max-width: calc(var(--ring-unit) * 31);\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) / 2);\n}\n\n.dragActive {\n  background-color: var(--ring-selected-background-color);\n}\n\n.errorMessage {\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__aa1`,
	"withImage": `withImage__a12`,
	"textsContainer": `textsContainer__ba8`,
	"dragActive": `dragActive__de4`,
	"errorMessage": `errorMessage__d09`
};
export default ___CSS_LOADER_EXPORT___;
