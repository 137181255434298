// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error__b29 {
  margin-top: calc(var(--ring-unit) * 2);
}
`, "",{"version":3,"sources":["webpack://./src/components/errors/not-found-error.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC","sourcesContent":[".error {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `error__b29`
};
export default ___CSS_LOADER_EXPORT___;
