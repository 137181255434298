// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expiredTokenClientId__edd {
  color: var(--ring-secondary-color);
}

.expiredBadge__f9c {
  padding: 2px 8px;
  border-radius: 4px;

  line-height: 1em;
  font-size: var(--ring-font-size);

  background-color: var(--ring-removed-background-color);
  border-color: var(--ring-removed-background-color);
}

.expiredDate__c6c {
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/automation-tokens/automation-tokens-table/cells/automation-token-cell.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;;EAElB,gBAAgB;EAChB,gCAAgC;;EAEhC,sDAAsD;EACtD,kDAAkD;AACpD;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".expiredTokenClientId {\n  color: var(--ring-secondary-color);\n}\n\n.expiredBadge {\n  padding: 2px 8px;\n  border-radius: 4px;\n\n  line-height: 1em;\n  font-size: var(--ring-font-size);\n\n  background-color: var(--ring-removed-background-color);\n  border-color: var(--ring-removed-background-color);\n}\n\n.expiredDate {\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expiredTokenClientId": `expiredTokenClientId__edd`,
	"expiredBadge": `expiredBadge__f9c`,
	"expiredDate": `expiredDate__c6c`
};
export default ___CSS_LOADER_EXPORT___;
