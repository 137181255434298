import React, {useEffect, useMemo, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {isNil, sortBy} from 'lodash';

import Loader from '@jetbrains/ring-ui/components/loader/loader';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import infoIcon from '@jetbrains/icons/info';
import Text from '@jetbrains/ring-ui/components/text/text';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';

import {PageTitle} from '../../components/page-layout/page-title';
import {getApdexTimeSeries, getProductCodes} from '../../api/completion-performance';
import {formatProductCode} from '../../components/util/i18n';
import {formatApiError} from '../../api/errors';
import CompletionChartOptionsPanel, {
  formatSeriesLabel,
  rangeOptions
} from './completion-chart-options-panel';
import ApdexChartExportAsTsvButton from './apdex-chart-export-as-tsv-button';
import ApdexChart from './apdex-chart';
import PRODUCT_CODES_KEY from './product-codes-key';
import styles from './completion-analytics.css';

/**
 * @param {string[]} allProductCodes
 * @returns {CompletionChartOptions}
 */
function buildDefaultChartOptions(allProductCodes) {
  return {
    range: '28_DAYS',
    series: sortBy(allProductCodes, formatProductCode).map(productCode => ({
      productCode,
      productVersion: null,
      language: null
    }))
  };
}

/**
 * @param {CompletionChartOptions} options
 * @returns {Promise<ApdexChartData>}
 */
async function getChartData(options) {
  const now = new Date();
  const range = rangeOptions.find(option => option.key === options.range).toRange(now);

  const results = await Promise.all(
    options.series.map(series =>
      getApdexTimeSeries({
        interval: range.interval,
        minDate: range.minDate,
        maxDate: range.maxDate,
        productCode: series.productCode,
        productBuildPrefix: series.productVersion,
        language: series.language
      })
    )
  );

  return {
    range,
    series: results.map((result, index) => ({
      label: formatSeriesLabel(options.series[index]),
      data: result.data
    }))
  };
}

/**
 * Hook that wraps an api call and chart options mapping
 * @param {object} queryOptions
 * @return {CompletionChartOptions}
 */
function useChartOptions(queryOptions) {
  const {data: productCodes} = useQuery(PRODUCT_CODES_KEY, getProductCodes, queryOptions);
  return useMemo(
    () => (productCodes ? buildDefaultChartOptions(productCodes) : undefined),
    [productCodes]
  );
}

/**
 * Utility function that takes top N elements using the sorting function.
 * @template T
 * @param {T[]} elements - input elements
 * @param n - amount of elements in the output array
 * @param {function(T, T): number} comparator - function that compares two elements. See array sort
 * @return {{element: T, index: number}[]}
 */
function topNIndexed(elements, n, comparator) {
  const top = [];
  const updateTop = (element, index) => {
    top.push({element, index});
    top.sort((a, b) => comparator(a.element, b.element));
    if (top.length > n) {
      top.splice(0, 1);
    }
  };
  elements.forEach(updateTop);
  return top;
}

function filterByIndexes(elements, indexes) {
  const out = [];
  indexes.forEach(x => out.push(elements[x]));
  return out;
}

/**
 * Hook that calls onUpdate only one time. It's primarily used to update the
 * chartData and chartOptions in order to reduce the amount of data which
 * will be rendered on the chart. It runs an algorithm that takes top three chart series
 * amount of
 * @param chartData
 * @param chartOptions
 * @param {function(ApdexChartData, CompletionChartOptions)} onUpdate
 */
function useFilteredChartOptionsAndData(chartData, chartOptions, onUpdate) {
  const [done, setDone] = useState(false);
  useEffect(() => {
    if (!done && chartData && chartOptions) {
      const indexes = topNIndexed(
        chartData.series.map(x => x.data.length),
        3,
        (a, b) => a - b
      ).map(x => x.index);

      onUpdate(
        {...chartData, series: filterByIndexes(chartData.series, indexes)},
        {...chartOptions, series: filterByIndexes(chartOptions.series, indexes)}
      );

      setDone(true);
    }
  }, [done, chartData, chartOptions, onUpdate]);
}

export default function CompletionAnalytics() {
  const [chartOptions, setChartOptions] = useState(/** @type {?CompletionChartOptions} */ null);
  const [chartData, setChartData] = useState(/** @type {?ApdexChartData} */ null);
  const fetchedChartOptions = useChartOptions({
    staleTime: Infinity,
    onError: e => alert.error(formatApiError(e, 'Failed to load product codes'))
  });
  const {data: fetchedChartData} = useQuery(
    ['chart-data', chartOptions],
    () => getChartData(chartOptions),
    {
      staleTime: 60 * 1000, // 1 min
      enabled: !isNil(chartOptions),
      onError: e => alert.error(formatApiError(e, 'Failed to load completion analytics'))
    }
  );

  useEffect(() => setChartOptions(fetchedChartOptions), [fetchedChartOptions]);
  useEffect(() => setChartData(fetchedChartData), [fetchedChartData]);
  useFilteredChartOptionsAndData(chartData, chartOptions, (d, o) => {
    setChartData(d);
    setChartOptions(o);
  });

  const loading = isNil(chartData) || isNil(chartOptions);
  const empty = chartOptions?.series?.length === 0;

  return (
    <div>
      <PageTitle parts={[{title: 'Analytics'}, {title: 'Code Completion Performance'}]} />
      {loading && <Loader />}
      {!loading && empty && (
        <Text>
          <Icon glyph={infoIcon} /> No data related to completion performance has been collected
          yet.
        </Text>
      )}
      {!loading && !empty && (
        <div className={styles.layout}>
          <CompletionChartOptionsPanel options={chartOptions} onOptionsChange={setChartOptions} />
          <div className={styles.layoutRight}>
            <ApdexChart loading={loading} data={chartData} />
            <ApdexChartExportAsTsvButton className={styles.exportButton} data={chartData} />
            <p>
              APDEX is an uniform way to analyze how software performance meets user expectations.
            </p>
            <p>
              <Text info>
                JetBrains IDE Services measures how long it takes for an IDE to suggest code
                completion options. The results are classified as <em>satisfying</em> (≤ 150 ms),{' '}
                <em>tolerating</em> (≤ 300 ms), or <em>frustrating</em>. Based on the number of
                samples in each group, JetBrains IDE Services calculates an APDEX score, which
                represents user satisfaction.
              </Text>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
