import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Size } from '@jetbrains/ring-ui/components/input/input';
import Select from '@jetbrains/ring-ui/components/select/select';
import styles from '@app/pages/profiles/profile-details/profile-ide-provisioner/ide-plugins/new-auto-installed-plugins/fields/content-field.css';
import { usePluginChannels } from '@app/queries/plugins';
import { TBE_SOURCE } from '@components/plugins/ide-plugins-types';
function TbePluginChannelField() {
    const { watch, setValue } = useFormContext();
    const watchChannel = watch('channel');
    const watchPluginId = watch('pluginId');
    const disabled = isEmpty(watchPluginId);
    const { data, isFetching } = usePluginChannels(watchPluginId, !disabled);
    const channels = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.items) === null || _a === void 0 ? void 0 : _a.filter(x => x.name !== 'Stable'); }, [data]);
    const selected = isEmpty(watchChannel)
        ? undefined
        : { key: watchChannel.id, label: watchChannel.name };
    const handleSelect = (value) => {
        if (value === null) {
            return setValue('channel', null);
        }
        return setValue('channel', { id: `${value === null || value === void 0 ? void 0 : value.key}`, name: `${value === null || value === void 0 ? void 0 : value.label}` });
    };
    return (_jsx(Select, { onChange: handleSelect, label: "Unstable recommended channel", selectedLabel: "Channel", disabled: disabled, data: channels === null || channels === void 0 ? void 0 : channels.map(x => ({ key: x.id, label: x.name })), size: Size.L, selected: selected, loading: isFetching, clear: true }));
}
function ChannelField() {
    const { watch, setValue } = useFormContext();
    const watchSource = watch('source');
    useEffect(() => {
        setValue('channel', null);
    }, [setValue, watchSource]);
    return (_jsxs(_Fragment, { children: [watchSource === TBE_SOURCE && _jsx(TbePluginChannelField, {}), _jsx("div", { className: styles.contentFieldBlockDescription, children: "The newest suitable plugin will be picked from either Stable or this channel. User can manually override this channel unless this plugin is marked as forced." })] }));
}
export default ChannelField;
