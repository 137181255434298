import {DEFAULT_TABLE_PAGE_SIZE} from '../api/paging-utils';

const SET_FREEZES_ANALYTICS = 'SET_FREEZES_ANALYTICS';
const SET_FREEZES_ANALYTICS_IS_FETCHING = 'SET_FREEZES_ANALYTICS_IS_FETCHING';
const SET_FREEZES_ANALYTICS_FETCH_ERROR = 'SET_FREEZES_ANALYTICS_FETCH_ERROR';
const SET_FREEZES_ANALYTICS_PAGE_PARAMS = 'SET_FREEZES_ANALYTICS_PAGE_PARAMS';
const SET_FREEZES_ANALYTICS_SORT_PARAMS = 'SET_FREEZES_ANALYTICS_SORT_PARAMS';

const defaultState = {
  currentPageData: [],
  isFetching: false,
  isFetchError: false,
  sortKey: 'durationMs',
  currentPageIndex: 1,
  sortOrder: false,
  pageSize: DEFAULT_TABLE_PAGE_SIZE,
  totalItems: 0
};

export default function freezesAnalyticsReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_FREEZES_ANALYTICS:
      const itemsWithKeys = action.payload.items.map(e => {
        return {
          ...e,
          key: Object.values(e).join('_')
        };
      });

      return {
        ...state,
        isFetching: false,
        isFetchError: false,
        currentPageData: itemsWithKeys,
        totalItems: action.payload.totalCount
      };
    case SET_FREEZES_ANALYTICS_IS_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case SET_FREEZES_ANALYTICS_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchError: true
      };
    case SET_FREEZES_ANALYTICS_PAGE_PARAMS:
      return {
        ...state,
        currentPageIndex: action.payload.nextPageIndex,
        pageSize: action.payload.pageSize
      };
    case SET_FREEZES_ANALYTICS_SORT_PARAMS:
      return {
        ...state,
        sortKey: action.payload.column.id,
        sortOrder: action.payload.order
      };
    default:
      return state;
  }
}

export function setFreezesAnalyticsInfo(data, totalCount) {
  return {type: SET_FREEZES_ANALYTICS, payload: {items: data, totalCount}};
}

export function setFreezesAnalyticsIsFetching(isFetching) {
  return {type: SET_FREEZES_ANALYTICS_IS_FETCHING, payload: isFetching};
}

export function setFreezesAnalyticsFetchError(error) {
  return {type: SET_FREEZES_ANALYTICS_FETCH_ERROR, payload: error};
}

export function setPagingParameters(pageParams) {
  return {type: SET_FREEZES_ANALYTICS_PAGE_PARAMS, payload: pageParams};
}

export function setSortingParameters(params) {
  return {type: SET_FREEZES_ANALYTICS_SORT_PARAMS, payload: params};
}
