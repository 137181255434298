import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import trashIcon from '@jetbrains/icons/trash';
import Badge from '@jetbrains/ring-ui/components/badge/badge';
import Text from '@jetbrains/ring-ui/components/text/text';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import { NumberParam, StringParam, createEnumParam } from 'serialize-query-params';
import { isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import classnames from 'classnames';
import { booleanSortOrder, DEFAULT_TABLE_PAGE_SIZE, pagingSortOrder } from '@api/paging-utils';
import { roleTexts } from '@api/users';
import { usePatchUserMutation, useUsers } from '@app/queries/users';
import { EnumerableTextElements } from '@components/text-formattings/enumerable-text-elements';
import { FullWidthSelectableTable } from '@components/tables/full-width-selectable-table';
import NavigationLink from '@components/navigation/navigation-link';
import { TableActionButton, TableActionsContainer } from '@components/tables/table-action-button';
import { transformPseudoBooleanToBoolean, useSyncedSearchArgs } from '@components/navigation/use-synced-search-args';
import { withSortableColumnFlag } from '@components/tables/table-columns';
import { ConstituentLogo } from '@components/constituent-logo/constituent-logo';
import { CONSTITUENTS_OPTIONS } from '@components/constituents/constants';
import { assignTestId } from '@components/infra/tests/assign-test-id';
import auth from '@components/auth/auth';
import { UsersFilters } from './search-filters/users-filters';
import confirmUserDeactivation from './confirm-user-deactivation';
import styles from './users-admin-page.css';
function getUserPageUrl(user) {
    return `/users/${user.id}`;
}
function getUsername(user) {
    return (_jsxs("div", { className: styles.usernameBadge, children: [_jsx(NavigationLink, { href: `/users/${user.id}`, ...assignTestId('username', 'user-link'), children: user.username }), user.isActive ? null : _jsx(Badge, { gray: true, children: "Deactivated" })] }));
}
function getConstituent(user) {
    return (_jsx("div", { className: styles.constituentsLogos, children: user.constituents
            .filter(({ state }) => state === 'CONFIGURED' || state === 'DISABLED_BY_LIMITS')
            .map(item => {
            var _a, _b;
            return (_jsx(Tooltip, { title: item.state === 'DISABLED_BY_LIMITS'
                    ? `Restricted: exceeds ${((_a = CONSTITUENTS_OPTIONS.find(option => option.key === item.constituentId)) === null || _a === void 0 ? void 0 : _a.label) ||
                        ''} limit`
                    : (_b = CONSTITUENTS_OPTIONS.find(option => option.key === item.constituentId)) === null || _b === void 0 ? void 0 : _b.label, children: _jsx("span", { className: item.state === 'DISABLED_BY_LIMITS'
                        ? classnames(styles.logoContainer, styles.logoContainerDisabled)
                        : styles.logoContainer, children: _jsx(ConstituentLogo, { id: item.constituentId }) }) }, `${user.id}-${item.constituentId}`));
        }) }));
}
const sortableColumns = {
    username: 'username',
    lastActionDate: 'lastActionDate',
    email: 'email',
    role: 'role'
};
const availableConstituents = ['ai', 'idep'];
const tsTypeToQuerySchema = {
    role: StringParam,
    isActive: createEnumParam(booleanSortOrder),
    query: StringParam,
    profileId: StringParam,
    page: NumberParam,
    sortKey: createEnumParam(Object.values(sortableColumns)),
    sortOrder: createEnumParam(pagingSortOrder),
    groupId: StringParam,
    constituentId: createEnumParam(availableConstituents)
};
function validateUsersFilter(parsed) {
    var _a, _b, _c, _d, _e, _f, _g;
    const isActive = transformPseudoBooleanToBoolean(parsed.isActive);
    return {
        role: (_a = parsed.role) !== null && _a !== void 0 ? _a : null,
        isActive,
        query: (_b = parsed.query) !== null && _b !== void 0 ? _b : '',
        profileId: (_c = parsed.profileId) !== null && _c !== void 0 ? _c : null,
        page: parsed.page && parsed.page > 0 ? parsed.page : 1,
        sortKey: (_d = parsed.sortKey) !== null && _d !== void 0 ? _d : 'username',
        sortOrder: (_e = parsed.sortOrder) !== null && _e !== void 0 ? _e : 'ASC',
        groupId: (_f = parsed.groupId) !== null && _f !== void 0 ? _f : null,
        constituentId: (_g = parsed.constituentId) !== null && _g !== void 0 ? _g : null
    };
}
function UsersPageBody({ onSelectUsers }) {
    var _a;
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema, validateUsersFilter);
    const navigate = useNavigate();
    const { data, isFetching } = useUsers(urlParams);
    const mutation = usePatchUserMutation('Deactivate');
    const users = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.users) !== null && _a !== void 0 ? _a : []; }, [data]);
    const total = (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0;
    const pageSize = DEFAULT_TABLE_PAGE_SIZE;
    const { idpImportEnabled } = auth.getFeatures();
    const doSetSelection = useCallback((updatedSelection) => {
        onSelectUsers(Array.from(updatedSelection.getActive()));
    }, [onSelectUsers]);
    const onDisableUser = useCallback((userId) => {
        const user = users.find(user => user.id === userId);
        if (!user) {
            return;
        }
        mutation.mutate({
            user: {
                version: user.version,
                isActive: false
            },
            userId
        });
    }, [mutation, users]);
    const onEnterUser = useCallback((user) => {
        navigate(getUserPageUrl(user));
    }, [navigate]);
    const onFiltersChange = useCallback((newFilters) => {
        setUrlParams({
            ...urlParams,
            ...newFilters,
            page: isEqual(newFilters, urlParams) ? urlParams.page : 1
        });
    }, [setUrlParams, urlParams]);
    const columns = useMemo(() => withSortableColumnFlag([
        {
            id: sortableColumns.username,
            title: 'Name',
            getValue: (user) => getUsername(user)
        },
        {
            id: sortableColumns.email,
            title: 'Email'
        },
        {
            id: sortableColumns.role,
            title: 'Role',
            getValue: (user) => roleTexts[user.role] || ''
        },
        {
            id: 'profileTitles',
            title: 'Profiles',
            getValue: user => {
                return (_jsx(EnumerableTextElements, { getKey: profile => `user-profile-${profile.id}`, getValue: profile => (_jsx(NavigationLink, { href: `/profiles/${profile.id}`, children: profile.title })), items: user.profiles }));
            }
        },
        idpImportEnabled
            ? {
                id: 'groupsTitles',
                title: 'Groups',
                getValue: user => {
                    return (_jsx(EnumerableTextElements, { getKey: group => `user-group-${group.id}`, getValue: group => _jsx(Text, { children: group.name }), items: user.groups, maxVisibleElems: 2 }));
                }
            }
            : { id: 'placeholderGroupsTitles' },
        {
            id: 'constituents',
            title: 'Products',
            getValue: getConstituent
        },
        {
            id: sortableColumns.lastActionDate,
            title: 'Last Online',
            rightAlign: true
        },
        {
            id: 'actionButtons',
            getValue: ({ id, username, isActive }) => (_jsx(TableActionsContainer, { children: _jsx(TableActionButton, { title: "Deactivate user", disabled: !isActive, icon: trashIcon, action: () => confirmUserDeactivation(username)
                        .then(() => onDisableUser(id))
                        .catch(hideConfirm) }) }))
        }
    ], sortableColumns), [onDisableUser, idpImportEnabled]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.toolbar, children: _jsx(UsersFilters, { filters: { ...urlParams }, onFiltersChange: onFiltersChange }) }), _jsx(FullWidthSelectableTable, { data: users, loading: isFetching, columns: columns, onItemEnter: onEnterUser, sortKey: urlParams.sortKey, sortOrder: urlParams.sortOrder === 'ASC', onSort: event => setUrlParams({
                    ...urlParams,
                    sortKey: event.column.id,
                    sortOrder: event.order ? 'ASC' : 'DESC',
                    page: 1
                }), renderEmpty: () => (isFetching ? 'Loading...' : 'No users found'), onSelect: doSetSelection, pagerProps: {
                    total,
                    currentPage: urlParams.page,
                    pageSize,
                    onPageChange: newPage => {
                        setUrlParams({ ...urlParams, page: newPage });
                    },
                    disablePageSizeSelector: true
                } })] }));
}
export default UsersPageBody;
