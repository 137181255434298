import { jsx as _jsx } from "react/jsx-runtime";
import RingFooter from '@jetbrains/ring-ui/components/footer/footer';
import { AppGlobal, ExternalLinks } from '@components/constants/constants';
import auth from '@components/auth/auth';
import { Footer } from '../footer/footer';
import styles from './app-footer.css';
export function AppFooter() {
    const hasAnalyticsEnabled = !!auth.getFeatures().analyticsEnabled;
    const dataCollectionStatement = hasAnalyticsEnabled
        ? [
            {
                key: 'transparency',
                url: '/transparency/ide_details',
                label: 'Data Collection Statement'
            }
        ]
        : [];
    return (_jsx(Footer, { children: _jsx(RingFooter, { className: styles.footer, left: [
                {
                    key: 'copyright',
                    label: AppGlobal.COPYRIGHT
                }
            ], center: [
                {
                    key: 'help',
                    label: 'Documentation',
                    url: ExternalLinks.JB_TBE_DOCS,
                    target: '_blank'
                },
                {
                    key: 'feedback',
                    label: 'Share feedback',
                    url: ExternalLinks.FEEDBACK,
                    target: '_blank'
                },
                {
                    key: 'third-party-licenses',
                    url: '/third-party-licenses',
                    label: 'Third-party licenses'
                }
            ].concat(dataCollectionStatement), right: [
                {
                    key: 'product',
                    label: AppGlobal.BUILD_NUMBER
                }
            ] }) }));
}
