export const PERSISTENT_STATE_XML_KIND = 'PERSISTENT_STATE_XML';
export const PERSISTENT_STATE_XPATH_KIND = 'PERSISTENT_STATE_XPATH';
export const REGISTRY_KIND = 'REGISTRY';
export const PROPERTIES_COMPONENT_KIND = 'PROPERTIES_COMPONENT';
export const CODESTYLE_XML_KIND = 'CODESTYLE_XML';
export const INSPECTION_PROFILE_XML_KIND = 'INSPECTION_PROFILE_XML';
export const KEY_VALUE_LIST_KIND = 'KEY_VALUE_LIST';
export const ALL_KINDS = [
    PERSISTENT_STATE_XML_KIND,
    PERSISTENT_STATE_XPATH_KIND,
    REGISTRY_KIND,
    PROPERTIES_COMPONENT_KIND,
    CODESTYLE_XML_KIND,
    INSPECTION_PROFILE_XML_KIND,
    KEY_VALUE_LIST_KIND
];
export function KindAware(kind, description) {
    return { kind, description };
}
export function Metadata(kind, scope, forced, description) {
    return { kind, description, scope, forced };
}
export function PersistentStateSettingXmlKind(fqName = '', xmlPayload = '', pluginId, metadata = Metadata(PERSISTENT_STATE_XML_KIND, 'APPLICATION', false, '(deprecated) Blank setting of type "persistent state xml"')) {
    return { fqName, xmlPayload, pluginId, ...metadata };
}
export function PersistentStateSettingXpathKind(fqName = '', specs = [], pluginId, metadata = Metadata(PERSISTENT_STATE_XPATH_KIND, 'APPLICATION', false, 'Blank setting of type "persistent state xpath"')) {
    return { fqName, specs, pluginId, ...metadata };
}
export function RegistrySetting(key = '', value = '', metadata = Metadata(REGISTRY_KIND, 'APPLICATION', false, 'Blank setting of type "registry"')) {
    return { key, value, ...metadata };
}
export function PropertiesComponentSetting(key = '', value = '', metadata = Metadata(PROPERTIES_COMPONENT_KIND, 'APPLICATION', false, 'Blank setting of type "properties component"')) {
    return { key, value, ...metadata };
}
export function KeyValueListSetting(entries = {}, metadata = KindAware(KEY_VALUE_LIST_KIND, 'Blank setting of type "key-value list" (for IDE versions 2024.1 and later)')) {
    return { ...metadata, entries };
}
export function CustomKindSetting(metadata = KindAware('PUT_YOUR_KIND_HERE', 'Blank setting of custom type')) {
    return { ...metadata };
}
export function CodeStyleSetting(xmlPayload = '', metadata = Metadata(CODESTYLE_XML_KIND, 'PROJECT', false, 'Blank setting of type "code style"')) {
    return { xmlPayload, ...metadata };
}
export function InspectionProfileSetting(xmlPayload = '', metadata = Metadata(INSPECTION_PROFILE_XML_KIND, 'PROJECT', false, 'Blank setting of type "inspection profile"')) {
    return { xmlPayload, ...metadata };
}
