// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detailsContainer__ede {
  margin: 2px 0 2px 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.propertyName__f48 {
  letter-spacing: 1px;
  font-weight: bold;
}

.property__d36 {
  font-size: var(--ring-font-size-smaller);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-history/profile-history-item-details.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".detailsContainer {\n  margin: 2px 0 2px 12px;\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n}\n\n.propertyName {\n  letter-spacing: 1px;\n  font-weight: bold;\n}\n\n.property {\n  font-size: var(--ring-font-size-smaller);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsContainer": `detailsContainer__ede`,
	"propertyName": `propertyName__f48`,
	"property": `property__d36`
};
export default ___CSS_LOADER_EXPORT___;
