// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageContainer__cb0 {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.leftContainer__a68 {
  flex-grow: 1;
}

.revokeButton__fce {
  height: calc(var(--ring-unit) * 4.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.revokeButton__fce > button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/user-edit-page.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".pageContainer {\n  display: flex;\n  justify-content: space-between;\n  flex-grow: 1;\n}\n\n.leftContainer {\n  flex-grow: 1;\n}\n\n.revokeButton {\n  height: calc(var(--ring-unit) * 4.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.revokeButton > button {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": `pageContainer__cb0`,
	"leftContainer": `leftContainer__a68`,
	"revokeButton": `revokeButton__fce`
};
export default ___CSS_LOADER_EXPORT___;
