import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import exceptionIcon from '@jetbrains/icons/exception';
import Icon from '@jetbrains/ring-ui/components/icon';
import Text from '@jetbrains/ring-ui/components/text/text';
import { Size } from '@jetbrains/ring-ui/components/icon/icon__constants';
import { useSelector } from 'react-redux';
import { getApplications } from '@app/store/profiles/selectors';
import { InformationBanner } from '@components/information-banner/information-banner';
import auth from '@components/auth/auth';
import { ExternalLinks } from '@components/constants/constants';
import NavigationLink from '@components/navigation/navigation-link';
import styles from './tba-banner.css';
/**
 * Error banner that displays in offline mode when there are no applications available.
 */
function OfflineModeBanner() {
    const { content } = useSelector(getApplications);
    const { offlineModeEnabled } = auth.getFeatures();
    if (!offlineModeEnabled || content.length > 0) {
        return null;
    }
    return (_jsx(InformationBanner, { variant: "error", children: _jsxs("span", { children: [_jsx(Icon, { glyph: exceptionIcon, className: styles.errorIcon, size: Size.Size18 }), _jsxs(Text, { children: ["JetBrains IDE Services requires artifacts from the internet to bootstrap offline mode. Please refer to the", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_TBE_OFFLINE_MODE_DOCS, children: "Offline Mode documentation" }), ' ', "for information on how to request and download these artifacts."] })] }) }));
}
export default OfflineModeBanner;
