// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chartContainer__a10 {
  --var-header-and-toolbar-approximate-heigth: 300px;
  max-height: calc(100vh - var(--var-header-and-toolbar-approximate-heigth));
  min-height: 500px;
  padding: calc(3 * var(--ring-unit)) calc(2 * var(--ring-unit)) var(--ring-unit) 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics/plugin-downloads/chart.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,0EAA0E;EAC1E,iBAAiB;EACjB,iFAAiF;AACnF","sourcesContent":[".chartContainer {\n  --var-header-and-toolbar-approximate-heigth: 300px;\n  max-height: calc(100vh - var(--var-header-and-toolbar-approximate-heigth));\n  min-height: 500px;\n  padding: calc(3 * var(--ring-unit)) calc(2 * var(--ring-unit)) var(--ring-unit) 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartContainer": `chartContainer__a10`
};
export default ___CSS_LOADER_EXPORT___;
