import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import closeIcon from '@jetbrains/icons/close';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { useGetGroupsForProfilesQuery, useRemoveGroupFromProfile } from '@app/queries/groups';
import { FullWidthSelectableTable } from '@components/tables/full-width-selectable-table';
import { withSortableColumnFlag } from '@components/tables/table-columns';
import NavigationLink from '@components/navigation/navigation-link';
import { TableActionButton, TableActionsContainer } from '@components/tables/table-action-button';
import { GroupsFilters } from './filters/groups-filter';
import { confirmGroupRemoval } from './confirm-group-removal';
import { GroupUsersCell } from './group-users-cell';
import styles from './table-list-body.css';
function GroupsListBody({ profileId, profileTitle, urlParams, setUrlParams }) {
    const navigate = useNavigate();
    const { data, isFetching } = useGetGroupsForProfilesQuery(profileId, { ...urlParams });
    const removeGroupMutation = useRemoveGroupFromProfile();
    const groups = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.items) !== null && _a !== void 0 ? _a : []; }, [data]);
    const total = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.total) !== null && _a !== void 0 ? _a : 0; }, [data]);
    const onFiltersChange = useCallback((newFilters) => {
        setUrlParams({
            ...urlParams,
            ...newFilters,
            page: isEqual(newFilters, urlParams) ? urlParams.page : 1
        });
    }, [setUrlParams, urlParams]);
    const onEnterUser = useCallback((group) => {
        navigate(`/users?groupId=${group.id}`);
    }, [navigate]);
    const handleRemoveGroup = useCallback((item) => confirmGroupRemoval(item, profileTitle)
        .then(() => removeGroupMutation.mutate({
        profileId,
        groupId: item.id
    }))
        .catch(hideConfirm), [profileId, profileTitle, removeGroupMutation]);
    const columns = useMemo(() => withSortableColumnFlag([
        {
            id: 'name',
            title: 'Name',
            getValue: getGroupName
        },
        {
            id: 'users',
            title: 'Users',
            getValue: getUsers
        },
        {
            id: 'actionButtons',
            getValue: group => getTableActions(() => handleRemoveGroup({ ...group }))
        }
    ], groupsSortableColumns), [handleRemoveGroup]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.actionButtons, children: _jsx(GroupsFilters, { filters: { ...urlParams }, onFiltersChange: onFiltersChange }) }), _jsx(FullWidthSelectableTable, { data: groups, loading: isFetching, columns: columns, onItemEnter: onEnterUser, renderEmpty: () => (isFetching ? 'Loading...' : 'No users found'), sortKey: urlParams.sortKey, sortOrder: urlParams.sortOrder === 'ASC', onSort: event => setUrlParams({
                    ...urlParams,
                    sortKey: event.column.id,
                    sortOrder: event.order ? 'ASC' : 'DESC',
                    page: 1
                }), selectable: false, pagerProps: {
                    total,
                    currentPage: urlParams.page,
                    pageSize: DEFAULT_TABLE_PAGE_SIZE,
                    onPageChange: newPage => {
                        setUrlParams({ ...urlParams, page: newPage });
                    },
                    disablePageSizeSelector: true
                } })] }));
}
function getGroupName(group) {
    return _jsx(NavigationLink, { href: `/users?groupId=${group.id}`, children: group.name });
}
function getUsers(group) {
    return _jsx(GroupUsersCell, { id: group.id, name: group.name }, group.id);
}
function getTableActions(callback) {
    return (_jsx(TableActionsContainer, { children: _jsx(TableActionButton, { title: "Remove group from the Profile", icon: closeIcon, action: callback }) }));
}
export const groupsSortableColumns = {
    name: 'name'
};
export default GroupsListBody;
