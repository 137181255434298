// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settingControls__c49 {
  display: flex;
  align-items: center;
}

.toggle__cb3 {
  flex-grow: 1;
}

.grayAnchor__b4a > button {
  color: var(--ring-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/toolbox-setting-field.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".settingControls {\n  display: flex;\n  align-items: center;\n}\n\n.toggle {\n  flex-grow: 1;\n}\n\n.grayAnchor > button {\n  color: var(--ring-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingControls": `settingControls__c49`,
	"toggle": `toggle__cb3`,
	"grayAnchor": `grayAnchor__b4a`
};
export default ___CSS_LOADER_EXPORT___;
