import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { endOfDay, format, startOfDay } from 'date-fns';
import Select from '@jetbrains/ring-ui/components/select/select';
import { THIS_MONTH_TIMEFRAME } from '@api/dashboard';
const startOfStatisticsGathering = startOfDay(new Date(2024, 0, 1));
const intervalOptions = getIntervalOptions();
export default function ChartTimeframeSelector({ timeframe, onSelect }) {
    const selectedIntervalOption = useMemo(() => intervalOptions.filter(it => it.model.from === (timeframe === null || timeframe === void 0 ? void 0 : timeframe.from) && it.model.to === (timeframe === null || timeframe === void 0 ? void 0 : timeframe.to))[0] || intervalOptions[0].model, [timeframe]);
    return (_jsx(Select, { type: Select.Type.INLINE, data: intervalOptions, selected: selectedIntervalOption, onSelect: option => option && onSelect(option.model), filter: true }));
}
function getIntervalOptions() {
    const thisMonthOption = {
        ...THIS_MONTH_TIMEFRAME,
        label: 'last 4 weeks'
    };
    const options = [];
    let from = startOfStatisticsGathering;
    while (from.getTime() < Date.now()) {
        const to = endOfDay(new Date(from.getFullYear(), from.getMonth() + 1, 0));
        options.push({
            from,
            to,
            label: format(from, 'MMMM yyyy')
        });
        from = startOfDay(new Date(from.getFullYear(), from.getMonth() + 1, 1));
    }
    return options
        .concat([thisMonthOption])
        .reverse()
        .map(it => ({
        key: `interval-from-${it.from.getTime()}-${it.to.getTime()}`,
        label: it.label,
        model: it
    }));
}
