import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useNavigate } from 'react-router-dom';
import { isNil } from 'lodash';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import userWarningIcon from '@jetbrains/icons/user-warning';
import { Size } from '@jetbrains/ring-ui/components/icon/icon__constants';
import { objectToSearchString } from 'serialize-query-params';
import { IdeProvisionerLogo } from '@components/ide-provisioner/ide-provisioner-logo/ide-provisioner-logo';
import { ExternalLinks } from '@components/constants/constants';
import NavigationLink from '@components/navigation/navigation-link';
import { ConstituentBlock } from '../../shared/constituent-block/constituent-block';
import styles from './ide-provisioner-enabled.css';
export function IdeProvisionerEnabled(props) {
    const { className, allowEditing, onEditClick, onDisableClick, data } = props;
    const navigate = useNavigate();
    const { assignedUsersLimit, activeUsersCount, disabledByLimitsUsersCount } = data;
    const overLimitDisplayed = !isNil(assignedUsersLimit) && disabledByLimitsUsersCount > 0;
    const handleShowUsersClick = useCallback(() => navigate({
        pathname: '/users',
        search: objectToSearchString({ constituentId: 'idep' })
    }), [navigate]);
    const handleGoToProfilesClick = useCallback(() => navigate('/profiles'), [navigate]);
    return (_jsxs(ConstituentBlock, { title: "IDE Provisioner", titleLogo: _jsx(IdeProvisionerLogo, {}), state: "enabled", className: className, children: [_jsx("p", { children: "IDE Provisioner supplies your development teams with a curated selection of pre-configured plugins, settings, and tools." }), (!isNil(assignedUsersLimit) || !!activeUsersCount) && (_jsxs("p", { className: styles.usersData, children: [!isNil(assignedUsersLimit) && (_jsxs("span", { className: styles.usersLimit, children: ["User limit: ", assignedUsersLimit] })), !!activeUsersCount && (_jsxs("span", { className: styles.usedBy, children: ["Current users: ", activeUsersCount] }))] })), !activeUsersCount && (_jsx("p", { className: styles.notInUseWarning, children: "Enable IDE Provisioner in relevant profiles to provide users with access to the product functionality." })), overLimitDisplayed && (_jsxs(_Fragment, { children: [_jsxs("p", { className: styles.overLimitError, children: [_jsx(Icon, { className: styles.overLimitIcon, glyph: userWarningIcon, size: Size.Size16, suppressSizeWarning: true }), _jsxs("span", { className: styles.overLimitErrorMsg, children: [disabledByLimitsUsersCount, " users lack access rights"] })] }), !allowEditing && (_jsxs("p", { className: styles.contactMsg, children: ["Contact the", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_SALES, children: "JetBrains IDE Services sales team" }), ' ', "to increase the user limits."] }))] })), _jsxs("div", { className: styles.actions, children: [activeUsersCount ? (_jsx(Button, { onClick: handleShowUsersClick, children: "Go to Users" })) : (_jsx(Button, { onClick: handleGoToProfilesClick, primary: true, children: "Go to Profiles" })), allowEditing && _jsx(Button, { onClick: onEditClick, children: "Edit limits" }), _jsx(Button, { onClick: onDisableClick, children: "Disable..." })] })] }));
}
