// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sessionsData__b10 {
  margin-top: calc(var(--ring-unit) / 2);
}

.sessionsLimit__f9f + .currentConcurrent__e4d {
  margin-left: calc(var(--ring-unit) * 1.5);
}

.notInUseWarning__c35 {
  margin-top: calc(var(--ring-unit) / 2);
  color: var(--ring-warning-color);
}

.actions__e84 {
  margin-top: calc(var(--ring-unit) * 1.5);

  display: flex;
  gap: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/code-with-me/code-with-me-enabled/code-with-me-enabled.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,sCAAsC;EACtC,gCAAgC;AAClC;;AAEA;EACE,wCAAwC;;EAExC,aAAa;EACb,qBAAqB;AACvB","sourcesContent":[".sessionsData {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.sessionsLimit + .currentConcurrent {\n  margin-left: calc(var(--ring-unit) * 1.5);\n}\n\n.notInUseWarning {\n  margin-top: calc(var(--ring-unit) / 2);\n  color: var(--ring-warning-color);\n}\n\n.actions {\n  margin-top: calc(var(--ring-unit) * 1.5);\n\n  display: flex;\n  gap: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sessionsData": `sessionsData__b10`,
	"sessionsLimit": `sessionsLimit__f9f`,
	"currentConcurrent": `currentConcurrent__e4d`,
	"notInUseWarning": `notInUseWarning__c35`,
	"actions": `actions__e84`
};
export default ___CSS_LOADER_EXPORT___;
