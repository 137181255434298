import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchAgreementJson, resetAgreement, uploadNewAgreement } from '@api/agreement';
import { formatApiError } from '@api/errors';
import queryClient, { DEFAULT_STALE_TIME } from './query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from './formatters/alerts';
const keys = {
    all: ['agreement']
};
export function useGetAgreementJson() {
    return useQuery({
        queryKey: keys.all,
        queryFn: fetchAgreementJson,
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load data collection statement'))
    });
}
export function useUploadAgreementMutation() {
    return useMutation({
        mutationFn: (file) => uploadNewAgreement(file),
        onSuccess: data => {
            queryClient.setQueryData(keys.all, data);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to upload new data collection statement'), () => `New data collection statement uploaded`)
    });
}
export function useResetAgreementMutation() {
    return useMutation({
        mutationFn: () => resetAgreement(),
        onSuccess: data => {
            queryClient.setQueryData(keys.all, data);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to reset data collection statement'), () => `Data collection statement has been reset`)
    });
}
