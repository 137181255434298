import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import add from '@jetbrains/icons/add';
import close from '@jetbrains/icons/close';
import Button from '@jetbrains/ring-ui/components/button/button';
import Pager from '@jetbrains/ring-ui/components/pager/pager';
import RingFieldsList from '@components/form/ring-fields-list';
import { getEmptyDisabledPluginField } from '../../schemas/ctors';
import { PluginsField } from './plugins-field';
import ForcedField from './forced-field';
import DescriptionField from './description-field';
import styles from './plugin-field-array.css';
const pageSize = 15;
export function PluginFieldArray() {
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'content'
    });
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const currentPageOfFields = useMemo(() => {
        return fields.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize);
    }, [fields, currentPageNumber]);
    const onAddPlugin = useCallback(() => {
        append(getEmptyDisabledPluginField());
        const newFieldsLength = fields.length + 1;
        setCurrentPageNumber(Math.ceil(newFieldsLength / pageSize));
    }, [fields, append]);
    const onRemovePlugin = useCallback((pluginIdx) => {
        remove(pluginIdx);
        const newFieldsLength = fields.length - 1;
        const maxPageNumber = Math.ceil(newFieldsLength / pageSize);
        setCurrentPageNumber(Math.min(maxPageNumber, currentPageNumber));
    }, [currentPageNumber, fields.length, remove]);
    return (_jsxs("div", { className: styles.mainContainer, children: [currentPageOfFields.map((plugin, index) => (_jsx(SinglePluginEditor, { className: styles.optionsContainer, pluginIndex: index + pageSize * (currentPageNumber - 1), isTheOnlyPlugin: fields.length === 1, onRemove: onRemovePlugin }, plugin.id))), pageSize < fields.length && (_jsx(Pager, { total: fields.length, visiblePagesLimit: 3, pageSize: pageSize, currentPage: currentPageNumber, onPageChange: setCurrentPageNumber, disablePageSizeSelector: true })), _jsx(Button, { icon: add, onClick: onAddPlugin, className: styles.addPluginButton, children: "Add plugin" })] }));
}
function SinglePluginEditor({ pluginIndex, isTheOnlyPlugin, onRemove, ...restProps }) {
    const doRemove = useCallback(() => onRemove(pluginIndex), [pluginIndex, onRemove]);
    return (_jsx("div", { ...restProps, children: _jsxs("div", { className: styles.pluginContainer, children: [_jsxs(RingFieldsList, { children: [_jsx(PluginsField, { pluginIndex: pluginIndex }), _jsx(DescriptionField, { pluginIndex: pluginIndex }), _jsx(ForcedField, { pluginIndex: pluginIndex })] }), _jsx(Button, { className: isTheOnlyPlugin ? styles.hiddenButton : styles.closeButton, icon: close, onClick: doRemove })] }) }));
}
