import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import userWarningIcon from '@jetbrains/icons/user-warning';
import { Size } from '@jetbrains/ring-ui/components/icon/icon__constants';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import styles from './submit-error.css';
export default function SubmitError({ error }) {
    if (error == null || error === '') {
        return null;
    }
    return (_jsxs("div", { className: styles.errorMessage, children: [_jsx(Icon, { className: styles.icon, glyph: userWarningIcon, size: Size.Size32, suppressSizeWarning: true }), _jsx("div", { className: styles.content, children: _jsx("div", { className: styles.title, children: error }) })] }));
}
