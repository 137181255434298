// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentContainer__c5c > div > div {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
}

.copyLink__ae2 {
  color: var(--ring-action-link-color);
  display: inline;
  margin-right: calc(var(--ring-unit) * 0.5);
}

.dialogContainer__ad9.dialogContainer__ad9 {
  min-width: 760px;
}

.licenseTextarea__b11 {
  min-height: calc(6 * var(--ring-line-height-taller));
  max-height: calc(12 * var(--ring-line-height-taller));
}

.successMessageContainer__ac8 {
  display: flex;
  gap: var(--ring-unit);
}

.successIcon__f2f {
  color: var(--ring-icon-success-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/license/license-activation-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,0CAA0C;AAC5C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oDAAoD;EACpD,qDAAqD;AACvD;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".contentContainer > div > div {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.copyLink {\n  color: var(--ring-action-link-color);\n  display: inline;\n  margin-right: calc(var(--ring-unit) * 0.5);\n}\n\n.dialogContainer.dialogContainer {\n  min-width: 760px;\n}\n\n.licenseTextarea {\n  min-height: calc(6 * var(--ring-line-height-taller));\n  max-height: calc(12 * var(--ring-line-height-taller));\n}\n\n.successMessageContainer {\n  display: flex;\n  gap: var(--ring-unit);\n}\n\n.successIcon {\n  color: var(--ring-icon-success-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": `contentContainer__c5c`,
	"copyLink": `copyLink__ae2`,
	"dialogContainer": `dialogContainer__ad9`,
	"licenseTextarea": `licenseTextarea__b11`,
	"successMessageContainer": `successMessageContainer__ac8`,
	"successIcon": `successIcon__f2f`
};
export default ___CSS_LOADER_EXPORT___;
