import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import { Button } from '@jetbrains/ring-ui/components/button/button';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import classNames from 'classnames';
import { noop } from 'lodash';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
import Icon from '@jetbrains/ring-ui/components/icon';
import chevronDownIcon from '@jetbrains/icons/chevron-down.svg';
import { useSynchronizeLicense, useTbeLicense } from '@app/queries/tbe-license';
import { ExternalLinks } from '@components/constants/constants';
import HorizontalContainer from '@components/page-layout/horizontal-container';
import auth from '@components/auth/auth';
import { useModalsController } from '@components/modals';
import { LicenseActivationDialog } from '@components/license/license-activation-dialog';
import { formatDateString } from '@components/dates/format';
import NavigationLink from '@components/navigation/navigation-link';
import { useOpenModal } from '@components/modals/hooks';
import { assignTestId } from '@components/infra/tests/assign-test-id';
import { LicensePublicKeyDialog } from '@components/license/license-public-key-dialog';
import { LicenseGenerateNewPublicKeyDialog } from '@components/license/license-generate-new-public-key-dialog';
import ServiceHeader from '../shared/service-header';
import UsageStats from './usage-stats';
import LicenseDetails from './license-details';
import styles from './license-activation.css';
const getPlanLabels = (plan) => {
    switch (plan) {
        case 'PREPAID':
            return 'Prepaid';
        case 'POSTPAID':
            return 'Pay-as-you-go';
        default:
            return '';
    }
};
function LicenseActivation(props) {
    const { className } = props;
    const modalsController = useModalsController();
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    const { mutateAsync: syncLicenseMutation, isLoading: isSyncing } = useSynchronizeLicense();
    const { data } = useTbeLicense();
    const handleActivateLicense = useCallback(async () => {
        const licenseActivationDialog = modalsController.openModal(LicenseActivationDialog, {
            show: true,
            onClose() {
                licenseActivationDialog.closeModal();
            }
        });
    }, [modalsController]);
    const generateNewPublicKey = useOpenModal(LicenseGenerateNewPublicKeyDialog, {});
    const viewPublicKey = useOpenModal(LicensePublicKeyDialog, {
        licenseData: data
    });
    const handleSynchroniseLicense = useCallback(() => {
        syncLicenseMutation().catch(noop);
    }, [syncLicenseMutation]);
    const publicKeyActions = useMemo(() => {
        const options = (data === null || data === void 0 ? void 0 : data.publicKey)
            ? [
                {
                    key: 'view-public-key',
                    label: 'View public key...',
                    onClick: viewPublicKey
                }
            ]
            : [];
        return options.concat([
            {
                key: 'regenerate-public-key',
                label: 'Regenerate public key...',
                className: styles.danger,
                async onClick() {
                    const result = await generateNewPublicKey();
                    if (result === null || result === void 0 ? void 0 : result.regenerated) {
                        window.location.reload();
                    }
                }
            }
        ]);
    }, [viewPublicKey, generateNewPublicKey, data === null || data === void 0 ? void 0 : data.publicKey]);
    return (_jsxs("div", { className: classNames(styles.container, className), children: [_jsx(ServiceHeader, { title: "JetBrains IDE Services", status: getPlanLabels(data === null || data === void 0 ? void 0 : data.plan) }), _jsxs(Text, { children: ["Contact the", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_SALES, children: "JetBrains IDE Services sales team" }), ' ', "to learn more about pricing", (data === null || data === void 0 ? void 0 : data.plan) === 'PREPAID' && ' or increase your product usage limits', "."] }), data && _jsx(LicenseDetails, { licenseData: data }), _jsxs(HorizontalContainer, { className: styles.buttonsContainer, children: [_jsx(DropdownMenu, { data: publicKeyActions, anchor: ({ active }) => (_jsxs(Button, { active: active, children: ["Public key ", _jsx(Icon, { glyph: chevronDownIcon })] })) }), _jsx(Button, { onClick: handleActivateLicense, disabled: isSyncing, ...assignTestId('activate-new-license-button'), children: "Activate new license..." }), _jsx(Button, { disabled: isSyncing || offlineModeEnabled, onClick: handleSynchroniseLicense, children: "Refresh license" }), (data === null || data === void 0 ? void 0 : data.lastRefreshedDate) && (_jsxs(Text, { info: true, size: "s", children: ["Last refreshed on ", formatDateString(data.lastRefreshedDate, 'd MMM yyyy')] }))] }), ((data === null || data === void 0 ? void 0 : data.status) === 'VALIDATING' || isSyncing) && (_jsx("div", { children: _jsx(LoaderInline, { children: "JetBrains IDE Services is currently updating your license details." }) })), _jsx(UsageStats, {})] }));
}
export default LicenseActivation;
