import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { resetProfileState, setNewProfiles } from '@app/store/profiles/actions';
import { resetImmutableProfilesState, setImmutableProfilesState } from '@app/store/profiles/immutableProfilesState/actions';
import { isStateChanged } from '@app/store/profiles/selectors';
import { getImmutableProfile } from '@app/store/profiles/immutableProfilesState/selectors';
import { useProfilesParams } from '../context/profiles-params-context';
/**
 * Function that checks whether the profile has been changed on the server and locally at the same time.
 * If this type of edit conflict occurs then we cannot POST profile changes without reloading
 * (or synchronizing) state with the server
 * @param profileFromServer
 */
export function useIsProfileChangedOnServerAndLocally(profileFromServer) {
    const immutableProfile = useSelector(getImmutableProfile);
    const changedLocally = useSelector(isStateChanged);
    const changedOnServer = !isEqual(profileFromServer, immutableProfile);
    return changedOnServer && changedLocally;
}
/**
 * Function that sets the profile state in the Redux store to the given 'profilesState' only when state
 * is not changed yet not to erase current work in progress changes
 * @param profilesState
 */
export function useSynchronizedProfilesState(profilesState) {
    const dispatch = useDispatch();
    const { clearParams } = useProfilesParams();
    const stateChanged = useSelector(isStateChanged);
    useEffect(() => {
        if (profilesState && !stateChanged) {
            batch(() => {
                dispatch(setNewProfiles(profilesState));
                dispatch(setImmutableProfilesState(profilesState));
                clearParams();
            });
        }
    }, [profilesState, dispatch, stateChanged, clearParams]);
    useEffect(() => {
        return () => {
            batch(() => {
                dispatch(resetProfileState());
                dispatch(resetImmutableProfilesState());
            });
        };
    }, [dispatch]);
}
