// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marginTop__bc0 {
  margin-top: calc(var(--ring-unit));
}

.marginTop2__c32 {
  margin-top: calc(2 * var(--ring-unit));
}

.alignCenter__e72 {
  text-align: center;
}

.zeroPadded__e72 {
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/util/utils.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".marginTop {\n  margin-top: calc(var(--ring-unit));\n}\n\n.marginTop2 {\n  margin-top: calc(2 * var(--ring-unit));\n}\n\n.alignCenter {\n  text-align: center;\n}\n\n.zeroPadded {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginTop": `marginTop__bc0`,
	"marginTop2": `marginTop2__c32`,
	"alignCenter": `alignCenter__e72`,
	"zeroPadded": `zeroPadded__e72`
};
export default ___CSS_LOADER_EXPORT___;
