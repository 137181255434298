import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import editIcon from '@jetbrains/icons/pencil';
import removeIcon from '@jetbrains/icons/trash';
import { TableActionButton, TableActionsContainer } from '@components/tables/table-action-button';
export function AutomationTokenActions(props) {
    const { token, onEditTokenClick, onDeleteTokenClick } = props;
    const [actionPerforming, setActionPerforming] = useState(false);
    const handleEditTokenClick = () => onEditTokenClick(token);
    const handleDeleteTokenClick = useCallback(async () => {
        setActionPerforming(true);
        await onDeleteTokenClick(token);
        setActionPerforming(false);
    }, [token, onDeleteTokenClick]);
    return (_jsxs(TableActionsContainer, { children: [_jsx(TableActionButton, { text: true, title: "Edit token", icon: editIcon, loader: actionPerforming, action: handleEditTokenClick }), _jsx(TableActionButton, { title: "Delete token", loader: actionPerforming, disabled: actionPerforming, icon: removeIcon, "data-test": "remove-plugin", action: handleDeleteTokenClick })] }));
}
