import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { noop } from 'lodash';
import removeIcon from '@jetbrains/icons/trash';
import linkIcon from '@jetbrains/icons/link';
import { NumberParam, StringParam } from 'serialize-query-params';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TABLE_PAGE_SIZE } from '@api/paging-utils';
import { useDeletePluginMutation, usePaginatedPlugins, usePluginRepositoryRestrictedMode } from '@app/queries/plugins';
import { FullWidthSelectableTable } from '@components/tables/full-width-selectable-table';
import LoadingError from '@components/errors/loading-error';
import HorizontalContainer from '@components/page-layout/horizontal-container';
import { TableActionButton, TableActionsContainer } from '@components/tables/table-action-button';
import NavigationLink from '@components/navigation/navigation-link';
import { useSyncedSearchArgs } from '@components/navigation/use-synced-search-args';
import PluginSearchPanel from './plugin-search-panel';
import { confirmPluginDeletion } from './confirmations';
import InstallPluginDialog from './plugin-page/install-plugin-dialog';
import { PluginsRestrictionBanner } from './plugins-restriction-banner';
function getPluginUrl(plugin) {
    return `/plugins/${encodeURIComponent(plugin.id)}`;
}
const tsTypeToQuerySchema = {
    searchValue: StringParam,
    page: NumberParam
};
function validatePluginsFilter(parsed) {
    var _a;
    return {
        searchValue: (_a = parsed.searchValue) !== null && _a !== void 0 ? _a : '',
        page: parsed.page && parsed.page > 0 ? parsed.page : 1
    };
}
export default function PluginsList() {
    const { data: restrictedModeData, isLoading: isRestrictedModeLoading } = usePluginRepositoryRestrictedMode();
    const navigate = useNavigate();
    const pageSize = DEFAULT_TABLE_PAGE_SIZE;
    const mutation = useDeletePluginMutation();
    const [urlParams, setUrlParams] = useSyncedSearchArgs(tsTypeToQuerySchema, validatePluginsFilter);
    const [showCopyInstallLinkDialogForPlugin, setShowCopyInstallLinkDialogForPlugin] = useState();
    const { data, isError, isLoading, isFetching } = usePaginatedPlugins(urlParams.page, urlParams.searchValue);
    const onEdit = useCallback(plugin => {
        navigate(getPluginUrl(plugin), { state: { urlsafe: true } });
    }, [navigate]);
    const { plugins, total } = useMemo(() => {
        var _a, _b;
        return {
            plugins: (_a = data === null || data === void 0 ? void 0 : data.items) !== null && _a !== void 0 ? _a : [],
            total: (_b = data === null || data === void 0 ? void 0 : data.total) !== null && _b !== void 0 ? _b : 0
        };
    }, [data]);
    const columns = useMemo(() => [
        {
            id: 'name',
            title: 'Plugin Name',
            getValue: plugin => (_jsx(NavigationLink, { href: getPluginUrl(plugin), children: plugin.name }))
        },
        { id: 'vendor', title: 'Vendor' },
        {
            id: 'versions',
            title: 'Versions',
            getValue: plugin => {
                if (plugin.minVersion && plugin.maxVersion && plugin.minVersion !== plugin.maxVersion) {
                    return `${plugin.minVersion} - ${plugin.maxVersion}`;
                }
                return plugin.minVersion || plugin.maxVersion || '';
            }
        },
        {
            id: 'actionButtons',
            getValue: plugin => {
                const isMutating = mutation.isLoading && mutation.variables === plugin.id;
                return (_jsxs(TableActionsContainer, { children: [_jsx(TableActionButton, { text: true, title: "Copy plugin installation link", icon: linkIcon, action: () => {
                                setShowCopyInstallLinkDialogForPlugin({
                                    ...plugin
                                });
                            } }), _jsx(TableActionButton, { title: "Remove plugin", loader: isMutating, disabled: isMutating, icon: removeIcon, "data-test": "remove-plugin", action: () => confirmPluginDeletion(plugin)
                                .then(() => mutation.mutateAsync(plugin.id))
                                .catch(noop) })] }));
            }
        }
    ], [mutation]);
    const handleSearchValueChange = useCallback((newValue) => {
        setUrlParams({
            ...urlParams,
            searchValue: newValue,
            page: newValue !== urlParams.searchValue ? 1 : urlParams.page
        });
    }, [setUrlParams, urlParams]);
    if (isError) {
        return _jsx(LoadingError, {});
    }
    return (_jsxs(_Fragment, { children: [!isRestrictedModeLoading && (restrictedModeData === null || restrictedModeData === void 0 ? void 0 : restrictedModeData.isRestrictedMode) && (_jsx(PluginsRestrictionBanner, { restrictedModePlugins: restrictedModeData.restrictedModePlugins })), _jsx(HorizontalContainer, { children: _jsx(PluginSearchPanel, { state: { ...urlParams }, loading: isFetching, onSearchValueChange: handleSearchValueChange }) }), _jsx(FullWidthSelectableTable, { data: plugins, getItemKey: plugin => plugin.id, loading: isLoading, columns: columns, renderEmpty: () => 'No plugins in the local repository yet', onItemEnter: onEdit, selectable: false, pagerProps: {
                    total,
                    pageSize,
                    currentPage: urlParams.page,
                    disablePageSizeSelector: true,
                    onPageChange: newPage => setUrlParams({ ...urlParams, page: newPage })
                } }), showCopyInstallLinkDialogForPlugin && (_jsx(InstallPluginDialog, { plugin: showCopyInstallLinkDialogForPlugin, onClose: () => {
                    setShowCopyInstallLinkDialogForPlugin(undefined);
                } }))] }));
}
