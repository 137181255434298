// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel__bd1 {
  display: flex;
  justify-content: space-between;
}

.okButton__a08 {
  margin-right: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/plugins/plugin-page/expiration-date-editor.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".panel {\n  display: flex;\n  justify-content: space-between;\n}\n\n.okButton {\n  margin-right: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `panel__bd1`,
	"okButton": `okButton__a08`
};
export default ___CSS_LOADER_EXPORT___;
