import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import Text from '@jetbrains/ring-ui/components/text/text';
import Icon from '@jetbrains/ring-ui/components/icon';
import successSessionsIcon from '@jetbrains/icons/ok';
import failedSessionsIcon from '@jetbrains/icons/exception';
import { useGetCwmWidgetStatistics } from '../../queries/dashboard';
import { WidgetLayout } from './common/widget-layout';
import { IntervalBarChart } from './common/interval-bar-chart';
import widgetStyles from './common/widget-layout.css';
const baseLink = '/dashboard/cwm-sessions';
export function CodeWithMeSessions() {
    const { data, isLoading, isLoadingError } = useGetCwmWidgetStatistics();
    const [activeInterval, setActiveInterval] = useState();
    const formattedActiveDayDate = useMemo(() => activeInterval && format(activeInterval === null || activeInterval === void 0 ? void 0 : activeInterval.from, 'MMM d'), [activeInterval]);
    const totalCreatedSessions = useMemo(() => (data || []).reduce((accum, current) => accum + current.sessionsCount, 0), [data]);
    const chartIntervals = useMemo(() => (data || []).map(it => ({
        from: it.from,
        to: it.to,
        total: it.sessionsCount + it.sessionsFailureCount,
        error: it.sessionsFailureCount,
        link: `${baseLink}?from=${it.from.getTime()}&to=${it.to.getTime()}`
    })), [data]);
    const titleLink = useMemo(() => {
        return data && data.length
            ? `${baseLink}?from=${data[0].from.getTime()}&to=${data[data.length - 1].to.getTime()}`
            : baseLink;
    }, [data]);
    useEffect(() => {
        if (!activeInterval && chartIntervals.length) {
            setActiveInterval(chartIntervals[chartIntervals.length - 1]);
        }
    }, [activeInterval, chartIntervals]);
    return (_jsxs(WidgetLayout, { link: titleLink, loader: isLoading, isLoadingError: isLoadingError, title: !totalCreatedSessions
            ? 'Code With Me Sessions'
            : `Code With Me Sessions: ${totalCreatedSessions}`, titleHint: `${totalCreatedSessions} sessions created in the last 2 weeks`, dataTest: "cwm-sessions", children: [totalCreatedSessions === 0 && _jsx(Text, { info: true, children: "No sessions in the last two weeks" }), totalCreatedSessions > 0 && (data === null || data === void 0 ? void 0 : data.length) && activeInterval && (_jsxs(_Fragment, { children: [_jsx(IntervalBarChart, { colorScheme: "success-and-failure", intervals: chartIntervals, activeInterval: activeInterval, onActivateInterval: setActiveInterval }), _jsx("div", { children: _jsxs(Text, { children: [_jsx("span", { className: widgetStyles.successful, children: "Successful" }), " and", ' ', _jsx("span", { className: widgetStyles.failed, children: "failed" }), " sessions for the last two weeks"] }) }), _jsxs("div", { className: widgetStyles.footer, children: [_jsxs("div", { children: [_jsxs("div", { className: widgetStyles.footerItem, children: [_jsx(Icon, { glyph: successSessionsIcon }), _jsx(Text, { size: "l", children: activeInterval.total - (activeInterval.error || 0) })] }), _jsx(Text, { size: "s", info: true, className: widgetStyles.footerItemLabel, children: activeInterval === chartIntervals[data.length - 1]
                                            ? 'Sessions created today'
                                            : `Sessions created on ${formattedActiveDayDate}` })] }), _jsx("div", { children: activeInterval.error !== 0 && (_jsxs(_Fragment, { children: [_jsxs("div", { className: widgetStyles.footerItem, children: [_jsx(Icon, { glyph: failedSessionsIcon, color: Icon.Color.RED }), _jsx(Text, { size: "l", className: widgetStyles.failed, children: activeInterval.error })] }), _jsx(Text, { size: "s", info: true, className: widgetStyles.footerItemLabel, children: activeInterval === chartIntervals[data.length - 1]
                                                ? 'Sessions failed today'
                                                : `Sessions failed on ${formattedActiveDayDate}` })] })) })] })] }))] }));
}
