import { jsx as _jsx } from "react/jsx-runtime";
import ButtonGroup from '@jetbrains/ring-ui/components/button-group/button-group';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useFormContext } from 'react-hook-form';
import RingField from '@components/form/ring-field';
function TypeField({ typeOptions }) {
    const { setValue, watch } = useFormContext();
    const type = watch('type');
    return (_jsx(RingField, { fieldId: "typeField", label: "Type", children: _jsx(ButtonGroup, { children: typeOptions.map(x => (_jsx(Button, { active: type === x.key, onClick: () => {
                    setValue('type', x.key.startsWith('include') ? 'include' : 'exclude');
                }, children: x.label }, x.key))) }) }));
}
export default TypeField;
