// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usernameBadge__d96 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--ring-unit) / 2);
}

.actionButtons__cbb {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--ring-unit) * 1.5);
  margin-top: var(--ring-unit);
  height: max-content;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-users/table-list-body.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iCAAiC;EACjC,4BAA4B;EAC5B,mBAAmB;AACrB","sourcesContent":[".usernameBadge {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: calc(var(--ring-unit) / 2);\n}\n\n.actionButtons {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: calc(var(--ring-unit) * 1.5);\n  margin-top: var(--ring-unit);\n  height: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usernameBadge": `usernameBadge__d96`,
	"actionButtons": `actionButtons__cbb`
};
export default ___CSS_LOADER_EXPORT___;
