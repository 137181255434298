import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@jetbrains/ring-ui/components/button/button';
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { FormProvider, useForm } from 'react-hook-form';
import { useDialogCancelConfirmation } from '@app/hooks/use-dialog-cancel-confirmation';
import { tDisabledPluginsSchema } from '../../schemas/disabled-plugins-schema';
import { getEmptyDisabledPluginsFormValues } from '../../schemas/ctors';
import SettingFiltersField from '../../common/setting-filters-field';
import { useUserPluginRestrictedMode } from '../../hooks/user-restricted-modes';
import { PluginFieldArray } from './plugin-field-array';
import styles from './disabled-plugins-dialog.css';
export function DisabledPluginsDialogForm({ item, onCloseAttempt: onFinishDialogAttempt // maybe it is a better to introduce new name ?
 }) {
    const { isRestricted, restrictedPlugins } = useUserPluginRestrictedMode();
    const form = useForm({
        defaultValues: item !== null && item !== void 0 ? item : getEmptyDisabledPluginsFormValues(),
        resolver: zodResolver(tDisabledPluginsSchema(isRestricted, restrictedPlugins))
    });
    const { formState: { isDirty, isSubmitting }, handleSubmit } = form;
    const handleCancel = useCallback(() => onFinishDialogAttempt(undefined), [onFinishDialogAttempt]);
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(handleCancel, isDirty);
    const [isFetchingTools, setIsFetchingTools] = useState(false);
    const canSubmit = isDirty && !isFetchingTools;
    const submit = (formData) => {
        onFinishDialogAttempt(formData);
    };
    return (_jsxs(Dialog, { show: true, autoFocusFirst: false, onCloseAttempt: onCloseAttempt, contentClassName: styles.dialogContent, children: [_jsx(Header, { children: "Disable Plugins" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(submit), className: styles.formContainer, children: [_jsx(SettingFiltersField, { onIsFetchingChange: setIsFetchingTools, disabled: isRestricted, disabledText: "Filter modification is disallowed in Restricted Mode" }), _jsx(PluginFieldArray, {})] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(submit), type: "submit", loader: isSubmitting, disabled: !canSubmit, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: handleCancel, children: "Cancel" })] })] }));
}
