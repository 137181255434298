import { useEffect } from 'react';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import styles from './base-notification/base-notification.css';
export function NotificationRenderer(props) {
    const { children, onCancel } = props;
    useEffect(() => {
        const openedNotification = alert.addAlert(children, undefined, 0, {
            captionClassName: styles.baseNotificationAlertCaption,
            onCloseRequest: onCancel
        });
        return () => {
            alert.remove(openedNotification);
        };
        // render only once, as we are unable to update props of the Opened notification
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
}
