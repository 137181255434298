class TabStateListener {
    constructor() {
        const eventsMap = {
            hidden: 'visibilitychange',
            webkitHidden: 'webkitvisibilitychange',
            mozHidden: 'mozvisibilitychange',
            msHidden: 'msvisibilitychange'
        };
        let stateKey;
        // eslint-disable-next-line no-restricted-syntax
        for (stateKey in eventsMap) {
            // @ts-expect-error
            if (document[stateKey] !== undefined) {
                this.stateKey = stateKey;
                this.eventKey = eventsMap[stateKey];
                break;
            }
        }
    }
    on(callback) {
        if (this.eventKey) {
            const onStateChange = () => {
                if (this.stateKey) {
                    callback(Boolean(
                    // @ts-expect-error
                    !document[this.stateKey]));
                }
            };
            document.addEventListener(this.eventKey, onStateChange, false);
            console.debug(`subscribed [${this.eventKey}]`);
            return () => {
                console.debug(`unsubscribed [${this.eventKey}]`);
                document.removeEventListener(this.eventKey, onStateChange);
            };
        }
        return () => { };
    }
}
const tabStateListener = new TabStateListener();
export default tabStateListener;
