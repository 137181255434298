import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from '@jetbrains/ring-ui/components/select/select';
import {Tabs} from '@jetbrains/ring-ui/components/tabs/tabs';
import Tab from '@jetbrains/ring-ui/components/tabs/tab';
import Button from '@jetbrains/ring-ui/components/button/button';
import {useLoaderData, useNavigate, useParams} from 'react-router-dom';
import {
  setPagingParameters,
  setPluginsAnalyticsTableType,
  setSortingParameters
} from '../../store/plugins-analytics';
import {getAnalyticsForAllPlugins} from '../../api/plugins-analytics';
import {PageTitle} from '../../components/page-layout/page-title';
import PluginDownloads from './plugin-downloads/plugin-downloads';
import PluginAnalyticsPopup from './plugin-analytics-popup';
import AnalyticsTable from './analytics-table';

function useTabs() {
  const navigate = useNavigate();
  const {tab: tabParam} = useParams();
  const [tab, origSetTab] = useState(tabParam === 'downloads' ? tabParam : 'usages');

  const setTab = useCallback(
    newTab => {
      navigate(`/analytics/plugins/${newTab}`);
      origSetTab(newTab);
    },
    [navigate]
  );

  return [tab, setTab];
}

export default function PluginsAnalytics() {
  const plugin = useLoaderData();
  const [chosenAnalyticsEntry, setChosenAnalyticsEntry] = useState(undefined);

  const columns = [
    {
      id: 'pluginId',
      title: 'Id',
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      getValue(entry) {
        return (
          <Button short text onClick={() => setChosenAnalyticsEntry(entry)}>
            {entry.pluginId}
          </Button>
        );
      }
    },
    {id: 'machinesCount', title: 'Devices', sortable: true, rightAlign: true},
    {id: 'right-placeholder', title: ''}
  ];

  const tableTypes = [
    {
      key: 'DISABLED_BUNDLED',
      label: 'Bundled but disabled'
    },
    {
      key: 'ENABLED_NOT_BUNDLED',
      label: 'Enabled but not bundled'
    }
  ];
  const dispatch = useDispatch();
  const stateSelector = state => state.pluginsAnalytics;
  const pageSize = useSelector(state => stateSelector(state).pageSize);

  const [range, setRange] = useState('LAST_7_DAYS');
  const tableType = useSelector(state => stateSelector(state).tableType);
  const [tab, setTab] = useTabs();

  useEffect(() => {
    dispatch(d => getAnalyticsForAllPlugins({period: range, pluginType: tableType}, d)).then(
      r => r
    );
  }, [dispatch, tableType, range]);

  const onSort = event => {
    dispatch(setSortingParameters(event));
  };

  const onPageChange = nextPageIndex => {
    dispatch(setPagingParameters({nextPageIndex, pageSize}));
  };

  return (
    <>
      <PageTitle parts={[{title: 'Analytics'}, {title: 'Plugins'}]} />
      <Tabs selected={tab} onSelect={x => setTab(x)}>
        <Tab id="usages" title="Usages">
          <AnalyticsTable
            columns={columns}
            onSort={onSort}
            onPageChange={onPageChange}
            onRangeChange={setRange}
            onItemEnter={setChosenAnalyticsEntry}
            stateSelector={state => state.pluginsAnalytics}
            additionalSettingsComponents={
              <Select
                selectedLabel="Plugin information"
                label="Plugin information"
                size={Select.Size.M}
                data={tableTypes}
                selected={tableTypes.find(option => option.key === tableType)}
                onChange={option => dispatch(setPluginsAnalyticsTableType(option.key))}
              />
            }
          />
          {chosenAnalyticsEntry && (
            <PluginAnalyticsPopup
              pluginId={chosenAnalyticsEntry.pluginId}
              filteringParameters={{pluginType: tableType, period: range}}
              onReject={() => setChosenAnalyticsEntry(undefined)}
            />
          )}
        </Tab>
        <Tab id="downloads" title="Downloads">
          <PluginDownloads plugin={plugin} />
        </Tab>
      </Tabs>
    </>
  );
}
