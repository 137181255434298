import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import Toggle, { Size } from '@jetbrains/ring-ui/components/toggle/toggle';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import { useCwmDisableMutation } from '@app/queries/code-with-me';
import { ConstituentDialog } from '../../../shared/constituent-dialog/constituent-dialog';
import styles from './cwm-disable-dialog.css';
export function CwmDisableDialog(props) {
    const { onCloseAttempt } = props;
    const { mutate: disable, isLoading } = useCwmDisableMutation(() => onCloseAttempt(undefined));
    const [isConfirmed, setConfirmed] = useState(false);
    const handleDisable = useCallback(() => disable(), [disable]);
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    return (_jsxs(ConstituentDialog, { title: "Disable Code With Me Enterprise?", children: [_jsxs(Content, { children: [_jsxs("p", { children: ["Disabling Code With Me Enterprise will enable developers to use", _jsx("br", {}), "the public Code With Me plugin if it is available to them"] }), _jsx("p", { className: styles.toggleWrapper, children: _jsx(Toggle, { size: Size.Size16, checked: isConfirmed, onChange: e => setConfirmed(e.target.checked), children: "I confirm the activation of the public Code With Me plugin" }) })] }), _jsxs(Panel, { children: [_jsx(Button, { disabled: !isConfirmed, onClick: handleDisable, loader: isLoading, danger: true, children: "Disable" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
