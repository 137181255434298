// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sizeM__c0a {
  --logo-size: 10;
}

.sizeL__ffc {
  --logo-size: 12;
}

.logo__f49 {
  width: calc(var(--logo-size) * var(--ring-unit));
  height: calc(var(--logo-size) * var(--ring-unit));
  border-radius: var(--ring-border-radius);

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
}

.logo__f49 img {
  max-width: 100%;
  max-height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/company-logo/company-logo.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gDAAgD;EAChD,iDAAiD;EACjD,wCAAwC;;EAExC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".sizeM {\n  --logo-size: 10;\n}\n\n.sizeL {\n  --logo-size: 12;\n}\n\n.logo {\n  width: calc(var(--logo-size) * var(--ring-unit));\n  height: calc(var(--logo-size) * var(--ring-unit));\n  border-radius: var(--ring-border-radius);\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  box-sizing: border-box;\n}\n\n.logo img {\n  max-width: 100%;\n  max-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sizeM": `sizeM__c0a`,
	"sizeL": `sizeL__ffc`,
	"logo": `logo__f49`
};
export default ___CSS_LOADER_EXPORT___;
