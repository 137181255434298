import React, {useState} from 'react';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
// eslint-disable-next-line no-restricted-imports
import Link from '@jetbrains/ring-ui/components/link/link';
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import {downloadBlob} from '../../components/util/blob';
import auth from '../../components/auth/auth';
import styles from './collected-data-error-attachments.css';

export default function CollectedDataErrorAttachmentEntry({title, url}) {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = () => {
    if (isDownloading) {
      return;
    }
    setIsDownloading(true);
    auth
      .getClient()
      .get(url, {responseType: 'blob'})
      .then(response => {
        if (response.status === 200) {
          downloadBlob(new Blob([response.data]), `${title}.zip`);
        } else {
          alert.error(`Couldn't download error report ${title} : ${response.statusText}`);
        }
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  // fixme: replace Link with Button
  return (
    <span className={styles.entry}>
      <Link onClick={() => handleDownload()} disabled={isDownloading}>
        {title}
      </Link>
      {isDownloading && <InlineLoader />}
    </span>
  );
}
