// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__ca7 {
  display: flex;
  align-items: baseline;
  gap: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/shared/service-header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,qBAAqB;AACvB","sourcesContent":[".container {\n  display: flex;\n  align-items: baseline;\n  gap: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__ca7`
};
export default ___CSS_LOADER_EXPORT___;
