// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widgetsContainer__eae {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: calc(2 * var(--ring-unit));
  row-gap: calc(var(--ring-unit) * 2);
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/dashboard-page.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;EAC3B,sCAAsC;EACtC,mCAAmC;EACnC,eAAe;AACjB","sourcesContent":[".widgetsContainer {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  column-gap: calc(2 * var(--ring-unit));\n  row-gap: calc(var(--ring-unit) * 2);\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetsContainer": `widgetsContainer__eae`
};
export default ___CSS_LOADER_EXPORT___;
