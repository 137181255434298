import { isEmpty } from 'lodash';
/**
 * Utility function to render empty placeholder in case no settings given
 * @param settings
 */
export function showNoSettingsPlaceholder(settings) {
    return isEmpty(settings);
}
/**
 * Utility function to render hidden settings in case there are some settings but all of them are filtered
 * @param settings
 * @param filteredSettings
 */
export function showHiddenSettingsPlaceholder(settings, filteredSettings) {
    return !isEmpty(settings) && isEmpty(filteredSettings);
}
/**
 * Utility function to render filtered settings only when they are not empty
 * @param filteredSettings
 */
export function showFilteredSettings(filteredSettings) {
    return !isEmpty(filteredSettings);
}
