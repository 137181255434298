import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import List from '@jetbrains/ring-ui/components/list/list';
import { MARKETPLACE_SOURCE } from '@components/plugins/ide-plugins-types';
import auth from '@components/auth/auth';
const getOfflineModeEnabled = () => auth.getFeatures().offlineModeEnabled === true;
const PluginSourceLabels = {
    TBE: 'Corporate plugin repository',
    MARKETPLACE: 'Marketplace'
};
export function formatPluginSourceName(pluginSource) {
    if (pluginSource === MARKETPLACE_SOURCE && getOfflineModeEnabled()) {
        return 'Bundled plugins';
    }
    return PluginSourceLabels[pluginSource] || '';
}
export function toPluginSelectItem(plugin, renderSource = true) {
    const suffix = renderSource ? ` (${formatPluginSourceName(plugin.source)})` : '';
    return {
        ...plugin,
        rgItemType: List.ListProps.Type.CUSTOM,
        template: (_jsxs(_Fragment, { children: [_jsx("div", { children: plugin.label }), _jsx(Text, { info: true, children: plugin.key + suffix })] }))
    };
}
