import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import changeIcon from '@jetbrains/icons/change';
import article from '@jetbrains/icons/article';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
import Icon from '@jetbrains/ring-ui/components/icon';
import moreOptionsIcon from '@jetbrains/icons/more-options';
import { ListProps } from '@jetbrains/ring-ui/components/popup-menu/popup-menu';
import Group from '@jetbrains/ring-ui/components/group/group';
import Text from '@jetbrains/ring-ui/components/text/text';
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../../components/page-layout/page-title';
import HelpIcon from '../../components/util/help-icon';
import { useSynchronizePluginsStateMutation } from '../../queries/plugins';
import PluginsList from './plugins-list';
import UploadPlugin from './upload-plugin/upload-plugin';
import ConfigureCustomChannelDialog from './configure-custom-channel-dialog';
import styles from './plugins-page.css';
export default function PluginsPage() {
    const navigate = useNavigate();
    const synchronizeMutation = useSynchronizePluginsStateMutation();
    const [showPluginVersionUpload, setShowPluginVersionUpload] = useState(false);
    const dropdownOptions = useMemo(() => {
        return [
            {
                key: 'edits-history',
                rgItemType: ListProps.Type.ITEM,
                label: 'View update history',
                icon: article,
                onClick: () => navigate('/plugins/audit-logs')
            },
            {
                key: 'synchronize-plugins',
                rgItemType: ListProps.Type.ITEM,
                label: (_jsxs(_Fragment, { children: [_jsx(Text, { className: styles.synchronizationText, children: "Synchronize plugins" }), _jsx(Tooltip, { title: "Initiate synchronization between plugins in the object storage and the database in case you\n            notice any data inconsistency", children: _jsx(HelpIcon, {}) })] })),
                icon: changeIcon,
                onClick: () => synchronizeMutation.mutate()
            }
        ];
    }, [navigate, synchronizeMutation]);
    const [isConfiguringChannel, setIsConfiguringChannel] = useState(false);
    return (_jsxs("div", { children: [_jsx(PageTitle, { title: "Plugins", rightPanel: _jsxs(_Fragment, { children: [synchronizeMutation.isLoading && _jsx(LoaderInline, {}), _jsx(Button, { onClick: () => setIsConfiguringChannel(true), text: true, children: "Copy plugin repository URL" }), _jsxs(Group, { children: [_jsx(Button, { primary: true, "data-test": "upload-plugin", onClick: () => setShowPluginVersionUpload(true), children: "Upload plugin" }), _jsx(DropdownMenu, { data: dropdownOptions, anchor: _jsx(Button, { short: true, children: _jsx(Icon, { glyph: moreOptionsIcon }) }) })] })] }) }), _jsx(PluginsList, {}), isConfiguringChannel && (_jsx(ConfigureCustomChannelDialog, { onClose: () => setIsConfiguringChannel(false) })), showPluginVersionUpload && (_jsx(UploadPlugin, { onClose: () => setShowPluginVersionUpload(false), title: "Upload Plugin" }))] }));
}
