import { useQuery } from '@tanstack/react-query';
import { formatApiError } from '@api/errors';
import { getConstituentsList } from '@api/constituents-common';
import { getQueryAlertMessages } from '@app/queries/formatters/alerts';
import { DEFAULT_POLLING_INTERVAL } from '@app/queries/query-client';
const keys = {
    constituents: () => ['constituents']
};
const constituentsListQueryArgs = {
    queryKey: keys.constituents(),
    queryFn: () => getConstituentsList(),
    meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load the list of constituents'))
};
export function useConstituentState(constituentId) {
    return useQuery({
        ...constituentsListQueryArgs,
        select: constituents => {
            const constituent = constituents.filter(it => it.id === constituentId)[0];
            return constituent === null || constituent === void 0 ? void 0 : constituent.state;
        }
    });
}
export function useGetConstituents() {
    return useQuery({
        ...constituentsListQueryArgs
    });
}
export function useConstitutionOverusePolling(enabled = true) {
    return useQuery({
        ...constituentsListQueryArgs,
        refetchInterval: DEFAULT_POLLING_INTERVAL,
        enabled
    });
}
