export class ModalModel {
    constructor(modalId, component, externalProps, onClose, onModalPropsUpdate) {
        this.modalId = modalId;
        this.component = component;
        this.externalProps = externalProps;
        this.onClose = onClose;
        this.onModalPropsUpdate = onModalPropsUpdate;
        this.onCloseAttempt = (closeResult) => {
            this.resolvePromise(closeResult);
        };
        this.promise = new Promise(resolve => {
            this.resolvePromise = resolve;
        });
    }
    get props() {
        return {
            ...this.externalProps,
            onCloseAttempt: this.onCloseAttempt
        };
    }
    closeModal() {
        this.onClose(this.modalId);
    }
    updateProps(props) {
        this.externalProps = props;
        this.onModalPropsUpdate();
    }
}
