import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import { useProfileDetailsForSelect, useProfileOptions } from '@app/queries/profiles';
import ToolbarItem from '@components/toolbar-item/toolbar-item';
const NO_PROFILE_ID = 'no-profile';
const NO_PROFILE_OPTION = { key: NO_PROFILE_ID, label: 'No profile' };
function ProfileFilter({ profileId, onChange }) {
    var _a;
    const [enableProfileOptions, setEnableProfileOptions] = useState(false);
    const [enableProfileDetails, setEnableProfileDetails] = useState(profileId.trim().length > 0 && profileId !== NO_PROFILE_ID);
    const { data: allProfiles, isFetching } = useProfileOptions(enableProfileOptions);
    const { data: profileDetails } = useProfileDetailsForSelect(profileId, enableProfileDetails);
    const profileOptions = useMemo(() => {
        if (!allProfiles) {
            return [NO_PROFILE_OPTION];
        }
        return [NO_PROFILE_OPTION].concat(allProfiles.map(profile => ({
            key: profile.id,
            label: profile.title
        })));
    }, [allProfiles]);
    const fetchedSingleProfile = useMemo(() => {
        if (!(profileDetails === null || profileDetails === void 0 ? void 0 : profileDetails.id)) {
            return undefined;
        }
        return { key: profileDetails === null || profileDetails === void 0 ? void 0 : profileDetails.id, label: profileDetails === null || profileDetails === void 0 ? void 0 : profileDetails.title };
    }, [profileDetails]);
    const handleChange = useCallback((selected) => {
        setEnableProfileDetails(false);
        onChange((selected === null || selected === void 0 ? void 0 : selected.key) ? `${selected === null || selected === void 0 ? void 0 : selected.key}` : undefined);
    }, [onChange]);
    return (_jsx(ToolbarItem, { label: "Profile", children: _jsx(Select, { clear: true, filter: true, label: "All", size: Select.Size.M, loading: isFetching, data: profileOptions, onBeforeOpen: () => setEnableProfileOptions(true), selected: (_a = profileOptions.find(option => option.key === profileId)) !== null && _a !== void 0 ? _a : fetchedSingleProfile, onChange: handleChange }) }));
}
export default ProfileFilter;
