// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leftBannerContainer__b90 {
  display: flex;
  gap: calc(var(--ring-unit) / 2);
  align-items: flex-start;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/banner/newly-included-apps-banner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".leftBannerContainer {\n  display: flex;\n  gap: calc(var(--ring-unit) / 2);\n  align-items: flex-start;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftBannerContainer": `leftBannerContainer__b90`
};
export default ___CSS_LOADER_EXPORT___;
