import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import styles from './company-logo.css';
export var LogoSize;
(function (LogoSize) {
    LogoSize["M"] = "M";
    LogoSize["L"] = "L";
})(LogoSize || (LogoSize = {}));
export function CompanyLogo(props) {
    const { logoUrl, className, size = LogoSize.M } = props;
    return (_jsx("div", { className: classNames(styles.logo, className, {
            [styles.sizeM]: size === LogoSize.M,
            [styles.sizeL]: size === LogoSize.L
        }), children: logoUrl && _jsx("img", { src: logoUrl, alt: "Company Logo", "data-test": "company-logo" }) }));
}
