import { jsx as _jsx } from "react/jsx-runtime";
import { DiffMatchPatch } from 'diff-match-patch-typescript';
import css from './text-diff.css';
const TEXT_DIFF_OPERATION = {
    Delete: -1,
    Insert: 1,
    Equal: 0
};
function mapTextDiff(diff, fn) {
    const result = [];
    for (let x = 0; x < diff.length; x += 1) {
        const operation = diff[x][0];
        const text = diff[x][1];
        result[x] = fn(operation, text);
    }
    return result;
}
/**
 * @description Computes the difference between two texts
 * using words diff algorithm for small
 * changes and line diff algorithm if the text changed a lot to
 * prevent a lot of small diffs in the same paragraph.
 */
function diffWordsLines(oldText, newText) {
    function indexOf(str, regExp, fromIndex) {
        let index = fromIndex;
        while (index < str.length) {
            if (regExp.test(str[index])) {
                return index;
            }
            index += 1;
        }
        return -1;
    }
    /**
     * @description
     * Split two texts into an array of strings. Reduce the texts to a string of
     * hashes where each Unicode character represents one word
     * @see https://github.com/google/diff-match-patch/wiki/Line-or-Word-Diffs
     */
    function diffWordsToChars(oldText, newText) {
        const wordArray = [];
        const wordHash = {};
        const { hasOwnProperty } = Object;
        // '\x00' is a valid character, but various debuggers don't like it.
        // So we'll insert a junk entry to avoid generating a null character.
        wordArray[0] = '';
        function encodeWordsToChars(text) {
            let chars = '';
            let wordStart = 0;
            let wordEnd = 0;
            let wordArrayLength = wordArray.length;
            const textEnd = text.length;
            const wb = /\B/;
            while (wordEnd < textEnd) {
                wordEnd = wb.test(text[wordStart])
                    ? Math.min(wordEnd + 1, textEnd)
                    : indexOf(text, wb, wordStart);
                if (wordEnd === -1) {
                    wordEnd = textEnd;
                }
                const word = text.substring(wordStart, wordEnd);
                wordStart = wordEnd;
                if (!hasOwnProperty.call(wordHash, word)) {
                    wordHash[word] = wordArrayLength;
                    wordArray[wordArrayLength] = word;
                    wordArrayLength += 1;
                }
                chars += String.fromCharCode(wordHash[word]);
            }
            return chars;
        }
        const chars1 = encodeWordsToChars(oldText);
        const chars2 = encodeWordsToChars(newText);
        return { chars1, chars2, lineArray: wordArray };
    }
    function diffByWordsLines(dmp, oldText, newText) {
        const { chars1: lineText1, chars2: lineText2, lineArray } = diffWordsToChars(oldText, newText);
        const diffs = dmp.diff_main(lineText1, lineText2, false);
        // diff_charsToLines function works fine in word-mode
        dmp.diff_charsToLines(diffs, lineArray);
        dmp.diff_cleanupSemantic(diffs);
        return diffs;
    }
    const dmp = new DiffMatchPatch();
    return diffByWordsLines(dmp, oldText, newText);
}
export function TextDiff({ oldText, newText, ...restProps }) {
    const diff = diffWordsLines(oldText || '', newText || '');
    const diffFragment = mapTextDiff(diff, (operation, text) => {
        const DIFF_DELETE = TEXT_DIFF_OPERATION.Delete;
        const DIFF_INSERT = TEXT_DIFF_OPERATION.Insert;
        switch (operation) {
            case DIFF_INSERT:
                return (_jsx("ins", { "data-test": "text-diff-insert", className: css.ins, children: text }, `${DIFF_INSERT}-${text}`));
            case DIFF_DELETE:
                return (_jsx("del", { "data-test": "text-diff-delete", className: css.del, children: text }, `${DIFF_DELETE}-${text}`));
            default:
                return text;
        }
    });
    return (_jsx("div", { "data-test": "text-diff", ...restProps, children: diffFragment }));
}
