import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { useSelector } from 'react-redux';
import { getImmutableProfile } from '@app/store/profiles/immutableProfilesState/selectors';
import { getApplications } from '@app/store/profiles/selectors';
import { findNewlyIncludedAppRules } from '@app/store/profiles/utils';
import { InformationBanner } from '@components/information-banner/information-banner';
import toolsPreviewStyles from '../profile-ide-provisioner/common/tools-preview.css';
import ProductLogo from '../profile-ide-provisioner/common/product-logo';
import { toTool } from './formatters';
import styles from './newly-included-apps-banner.css';
function format(tool, apps, productToRules) {
    var _a, _b, _c;
    const { product } = tool;
    const name = ((_a = apps.find(x => x.product === product)) === null || _a === void 0 ? void 0 : _a.productName) || product;
    const builds = (_c = (_b = productToRules === null || productToRules === void 0 ? void 0 : productToRules.get(product)) === null || _b === void 0 ? void 0 : _b.map(x => `${x.build}-${x.version}`)) === null || _c === void 0 ? void 0 : _c.join(', ');
    const suffix = builds ? ` ${builds}` : '';
    return `${name}${suffix}`;
}
/**
 * Banner which renders applications which were excluded and became included
 * @constructor
 */
function NewlyIncludedAppsBanner() {
    const immutableProfile = useSelector(getImmutableProfile);
    const immutableApps = immutableProfile.applications.content;
    const mutableProfileApplications = useSelector(getApplications);
    const mutableApps = mutableProfileApplications.content;
    const productToRules = findNewlyIncludedAppRules(immutableApps, mutableApps);
    const newlyIncludedToolsAndDescriptions = mutableApps
        .filter(x => productToRules === null || productToRules === void 0 ? void 0 : productToRules.has(x.product))
        .map(toTool)
        .sort((x, y) => x.productName.localeCompare(y.productName));
    if (!productToRules || productToRules.size === 0) {
        return null;
    }
    return (_jsx(InformationBanner, { children: _jsxs("div", { className: styles.leftBannerContainer, children: [_jsx(Text, { children: "After saving the changes, these applications will become available to profile users:" }), newlyIncludedToolsAndDescriptions.map(tool => (_jsx(Tooltip, { title: format(tool, mutableApps, productToRules), children: _jsx(ProductLogo, { product: tool.product, className: toolsPreviewStyles.productLogo }) }, tool.product)))] }) }));
}
export default NewlyIncludedAppsBanner;
