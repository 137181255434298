import { jsx as _jsx } from "react/jsx-runtime";
import Tab from '@jetbrains/ring-ui/components/tabs/tab';
import Text from '@jetbrains/ring-ui/components/text/text';
import { isEmpty } from 'lodash';
import { ApplicationsListItem } from './applications-list-item';
import styles from './applications-tab.css';
const getPlaceholderText = (type, filterValue) => {
    const prefix = `No ${type.toLowerCase()} applications`;
    const suffix = isEmpty(filterValue) ? '' : ` matched with "${filterValue}"`;
    return `${prefix}${suffix}`;
};
export function ApplicationsTab({ data, title, type, filterValue }) {
    return (_jsx(Tab, { title: title, className: styles.tabContainer, children: data.length === 0 ? (_jsx(Text, { children: getPlaceholderText(type, filterValue) })) : (data
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(item => _jsx(ApplicationsListItem, { item: item }, item.name))) }));
}
