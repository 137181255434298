import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Icon, { Size } from '@jetbrains/ring-ui/components/icon';
import appLogo from '@app/assets/ide-services-logo.svg';
import appLogoText from '@app/assets/ide-services-logo-text.svg';
import styles from './ide-services-logo.css';
export function IdeServicesLogo(props) {
    const { className, size = Size.Size32, collapsible } = props;
    return (_jsxs("div", { style: toCssProperties(size), className: classNames(styles.wrapper, className, { [styles.collapsible]: collapsible }), children: [_jsx(Icon, { className: classNames(styles.logo, styles.icon), glyph: appLogo }), _jsx(Icon, { className: classNames(styles.logoText, styles.icon), glyph: appLogoText })] }));
}
function toCssProperties(size) {
    return { '--logo-size': `${size}px` };
}
