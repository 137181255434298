import {useMemo} from 'react';
import {isNil} from 'lodash';
import {useTbePlugins} from '../../../queries/plugins';

/**
 * Hook that returns plugins' keys from the plugins repository and the isFetching state
 * @return {[(Set<string>),boolean]}
 */
export function useTbePluginKeys() {
  const {data: tbePlugins, isFetching: isFetchingTbePlugins} = useTbePlugins();
  return useMemo(() => {
    const keys =
      !isFetchingTbePlugins && !isNil(tbePlugins) ? new Set(tbePlugins.map(x => x.key)) : new Set();
    return [keys, isFetchingTbePlugins];
  }, [tbePlugins, isFetchingTbePlugins]);
}
