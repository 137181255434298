import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import { Directions } from '@jetbrains/ring-ui/components/popup/popup.consts';
import Text from '@jetbrains/ring-ui/components/text/text';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown';
import HelpIcon from '@components/util/help-icon';
import NavigationLink from '@components/navigation/navigation-link';
import { useMachineConfig } from '../../../../queries/machine-config';
import styles from './server-url.css';
export function ServerUrl({ className }) {
    const { data, isLoading, isError } = useMachineConfig();
    return (_jsxs("span", { className: className, children: [isLoading && _jsx(InlineLoader, {}), !isLoading && data && (_jsxs(_Fragment, { children: [_jsx(NavigationLink, { href: data.tbeServerUrl, target: "_blank", children: data.tbeServerUrl }), _jsx(Tooltip, { title: _jsxs(_Fragment, { children: ["JetBrains IDE Services retrieves the server URL from the", ' ', _jsx("code", { children: "application.yaml" }), " file"] }), popupProps: { directions: [Directions.RIGHT_TOP] }, delay: Dropdown.defaultProps.hoverShowTimeOut, children: _jsx(HelpIcon, { className: styles.helpIcon }) })] })), isError && _jsx(Text, { children: "-" })] }));
}
