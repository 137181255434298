// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warningText__a16 {
  color: var(--ring-text-color);
}

.errorText__ef3 {
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/ide-settings/json-editor/json-editor-fields.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".warningText {\n  color: var(--ring-text-color);\n}\n\n.errorText {\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningText": `warningText__a16`,
	"errorText": `errorText__ef3`
};
export default ___CSS_LOADER_EXPORT___;
