import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { MIN_LIST_SIZE } from '../api/paging-utils';
import { addProfileToUsers, createUser, getUnassignedUsersTotal, getUser, getUserAvailableApps, getUsersAndTotal, patchUser, revokeUserToken, unAssignUsersFromProfile } from '../api/users';
import { formatApiError } from '../api/errors';
import queryClient, { DEFAULT_STALE_TIME } from './query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from './formatters/alerts';
const keys = {
    all: ['users'],
    count: ['users', 'count'],
    infinite: (filters) => ['users', 'infinite', { filters }],
    list: (filters) => ['users', 'list', { filters }],
    details: () => ['users', 'detail'],
    detail: (id) => ['users', 'detail', id],
    detailCtx: (id) => ({
        applications: ['users', 'detail', id, 'applications']
    })
};
const usersDetailQuery = (id) => ({
    queryKey: keys.detail(id),
    queryFn: () => getUser(id),
    enabled: !!id
});
export function useUsers(params) {
    return useQuery({
        queryKey: keys.list(params),
        queryFn: () => getUsersAndTotal(params, undefined),
        staleTime: DEFAULT_STALE_TIME,
        keepPreviousData: true,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load users'))
    });
}
export function useUnassignedUsersCount() {
    return useQuery({
        queryKey: keys.count,
        queryFn: () => getUnassignedUsersTotal(),
        staleTime: DEFAULT_STALE_TIME,
        keepPreviousData: true,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load users'))
    });
}
export function ensureUserDetailsQueryData(id) {
    return queryClient.ensureQueryData({ ...usersDetailQuery(id) });
}
export function useUser(id) {
    return useQuery({
        ...usersDetailQuery(id),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load user'))
    });
}
export function useUserApplications(userId) {
    return useQuery({
        queryKey: keys.detailCtx(userId).applications,
        queryFn: () => getUserAvailableApps(userId),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load applications'))
    });
}
export function usePatchUserMutation(action = 'Update') {
    return useMutation({
        mutationFn: (args) => patchUser(args.userId, args.user),
        onSuccess: async (data) => {
            const detailKey = keys.detail(data.id);
            queryClient.setQueryData(detailKey, data);
            await queryClient.invalidateQueries({
                predicate: query => {
                    return query.queryKey[0] !== 'tbe-license' && !isEqual(query.queryKey, detailKey);
                }
            });
            return data;
        },
        meta: getMutationAlertMessages((error, vars) => formatApiError(error, action === 'Deactivate'
            ? `Failed to deactivate user ${vars.user.username || '(empty username)'}`
            : `Failed to update user '${vars.user.username || '(empty username)'}'`), vars => action === 'Deactivate'
            ? `User '${vars.username || '(empty username)'}' deactivated`
            : `User '${vars.username || '(empty username)'}' updated`)
    });
}
export function useCreateUserMutation() {
    return useMutation({
        mutationFn: (userData) => createUser({
            ...userData,
            email: userData.email ? userData.email : undefined
        }),
        onSuccess: async (data) => {
            const detailKey = keys.detail(data.id);
            const allDetailsKey = keys.details();
            queryClient.setQueryData(detailKey, data);
            await queryClient.invalidateQueries({
                predicate: query => {
                    return !isEqual(query.queryKey.slice(0, allDetailsKey.length), allDetailsKey);
                }
            });
        },
        meta: getMutationAlertMessages((error, vars) => formatApiError(error, `Failed to create user '${vars.username || '(empty username)'}'`), (_data, vars) => `User '${vars.username || '(empty username)'}' created`)
    });
}
export function useAddProfileToUsersMutation(profileName, userNames) {
    return useMutation({
        mutationFn: ({ users, profileId }) => addProfileToUsers(users, profileId),
        onSuccess: async () => {
            await queryClient.invalidateQueries();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, `Failed to assign profile ${profileName || ''} to ${userNames
            ? `${userNames.length === 1 ? 'user' : 'users'} ${userNames === null || userNames === void 0 ? void 0 : userNames.join(' ,')}.`
            : 'user.'}`), `Profile ${profileName || ''} assigned to ${userNames
            ? `${userNames.length === 1 ? 'user' : 'users'} ${userNames === null || userNames === void 0 ? void 0 : userNames.join(' ,')}.`
            : 'user.'}`)
    });
}
export function useUnAssignUsersMutation() {
    return useMutation({
        mutationFn: ({ profileId, usersList }) => unAssignUsersFromProfile(profileId, usersList),
        onSuccess: async () => {
            await queryClient.invalidateQueries();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to remove assigned profile'), response => `${Array.isArray(response) && response.length === 1 ? 'User' : 'Users'} have been successfully removed from Profile`)
    });
}
const getPageParam = (pageParam) => ({
    page: pageParam,
    sortKey: 'username',
    sortOrder: 'ASC',
    limit: 10
});
export function useInfiniteUsersQuery(filters, enabled = true) {
    return useInfiniteQuery({
        enabled,
        queryKey: keys.infinite(filters),
        queryFn: ({ pageParam = 1 }) => getUsersAndTotal({ ...getPageParam(pageParam), ...filters }, undefined),
        getNextPageParam: (lastPage, allPages) => {
            const { total } = lastPage;
            const current = allPages.flatMap(({ users }) => users).length;
            if (total > current) {
                return current / MIN_LIST_SIZE + 1;
            }
            return undefined;
        },
        staleTime: DEFAULT_STALE_TIME,
        keepPreviousData: true,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load users'))
    });
}
export function fetchUserData(id) {
    return queryClient.fetchQuery({ ...usersDetailQuery(id) });
}
export function useRevokeUserToken(id) {
    return useMutation({
        mutationFn: () => revokeUserToken(id),
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to revoke user token'), () => 'User token has been revoked')
    });
}
