import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Type } from '@jetbrains/ring-ui/components/list/consts';
import { Outlet } from 'react-router-dom';
import { Sidebar, SidebarMenu } from '@components/app-layout';
import auth from '../../components/auth/auth';
const errorsUiEnabled = () => auth.getFeatures().errorsUiEnabled === true;
const freezesUiEnabled = () => auth.getFeatures().freezesUiEnabled === true;
const analyticsExtendedEnabled = () => auth.getFeatures().analyticsExtendedEnabled === true;
const menuItems = [
    { label: 'Usage', rgItemType: Type.TITLE },
    { label: 'IDEs', page: '/analytics/ides', level: 1 },
    { label: 'Plugins', page: '/analytics/plugins', level: 1 },
    { label: 'Operating Systems', page: '/analytics/operating-systems', level: 1 },
    {
        label: 'Error Reports',
        rgItemType: Type.TITLE,
        condition: () => analyticsExtendedEnabled() && (errorsUiEnabled() || freezesUiEnabled())
    },
    {
        label: 'Errors',
        page: '/analytics/collected-data/errors',
        level: 1,
        condition: () => analyticsExtendedEnabled() && errorsUiEnabled()
    },
    {
        label: 'Freezes',
        page: '/analytics/collected-data/freezes',
        level: 1,
        condition: () => analyticsExtendedEnabled() && freezesUiEnabled
    },
    {
        label: 'Performance',
        rgItemType: Type.TITLE,
        condition: analyticsExtendedEnabled
    },
    {
        label: 'Code Completion',
        page: '/analytics/code-completion',
        level: 1,
        condition: analyticsExtendedEnabled
    },
    { label: 'More', rgItemType: Type.TITLE, condition: analyticsExtendedEnabled },
    {
        label: 'Collected Data',
        page: '/analytics/collected-data',
        level: 1,
        condition: analyticsExtendedEnabled
    }
];
export default function AnalyticsPage() {
    const availableMenuItems = menuItems.filter(item => (item.condition ? item.condition() : true));
    return (_jsxs(_Fragment, { children: [_jsx(Sidebar, { children: _jsx(SidebarMenu, { menuItems: availableMenuItems }) }), _jsx(Outlet, {})] }));
}
