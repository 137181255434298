export const filterDescription = {
    restrictions: {
        'include-all': 'Restrict all applications',
        'exclude-all': "Don't restrict all applications",
        include: 'Restrict',
        exclude: "Don't restrict"
    },
    applications: {
        'include-all': 'Include all applications',
        include: 'Include',
        'exclude-all': 'Exclude all applications',
        exclude: 'Exclude'
    },
    settings: {
        'include-all': 'Include all applications enabled in the profile',
        include: 'Include',
        'exclude-all': 'Exclude all applications enabled in the profile',
        exclude: 'Exclude'
    }
};
