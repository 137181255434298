// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textContainer__e12 {
  max-width: 50em;
}
`, "",{"version":3,"sources":["webpack://./src/pages/analytics/collected-data.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB","sourcesContent":[".textContainer {\n  max-width: 50em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textContainer": `textContainer__e12`
};
export default ___CSS_LOADER_EXPORT___;
