// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popupContent__ec7 {
  padding: calc(1.5 * var(--ring-unit));
  max-width: 350px;
}

.anchor__b90 {
  color: var(--ring-link-color);
  cursor: pointer;

  &:hover {
    color: var(--ring-link-hover-color);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/text-formattings/enumerable-text-elements.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;;EAEf;IACE,mCAAmC;EACrC;AACF","sourcesContent":[".popupContent {\n  padding: calc(1.5 * var(--ring-unit));\n  max-width: 350px;\n}\n\n.anchor {\n  color: var(--ring-link-color);\n  cursor: pointer;\n\n  &:hover {\n    color: var(--ring-link-hover-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupContent": `popupContent__ec7`,
	"anchor": `anchor__b90`
};
export default ___CSS_LOADER_EXPORT___;
