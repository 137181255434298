import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import Text from '@jetbrains/ring-ui/components/text/text';
import Icon from '@jetbrains/ring-ui/components/icon';
import userIcon from '@jetbrains/icons/user';
import { useActiveUsersWidgetStatistics } from '../../queries/dashboard';
import { WidgetLayout } from './common/widget-layout';
import { IntervalBarChart } from './common/interval-bar-chart';
import widgetStyles from './common/widget-layout.css';
export function ActiveUsers() {
    const { data, isLoading, isLoadingError } = useActiveUsersWidgetStatistics();
    const [activeInterval, setActiveInterval] = useState();
    const formattedActiveDayDate = useMemo(() => activeInterval && format(activeInterval === null || activeInterval === void 0 ? void 0 : activeInterval.from, 'MMM d'), [activeInterval]);
    const chartIntervals = useMemo(() => ((data === null || data === void 0 ? void 0 : data.perDays) || [])
        .map(it => ({
        from: parseISO(it.date),
        to: parseISO(it.date),
        total: it.activeUsersCount
    }))
        .sort((a, b) => {
        if (a.from.getTime() === b.from.getTime()) {
            return 0;
        }
        return a.from.getTime() < b.from.getTime() ? -1 : 1;
    }), [data]);
    const hasActiveUsers = useMemo(() => !!(data === null || data === void 0 ? void 0 : data.total) && (data === null || data === void 0 ? void 0 : data.total) > 0, [data]);
    useEffect(() => {
        if (!activeInterval && chartIntervals.length) {
            setActiveInterval(chartIntervals[chartIntervals.length - 1]);
        }
    }, [activeInterval, chartIntervals]);
    return (_jsxs(WidgetLayout, { loader: isLoading, isLoadingError: isLoadingError, title: !(data === null || data === void 0 ? void 0 : data.total) ? 'Active Users this Month' : `Active Users this Month: ${data === null || data === void 0 ? void 0 : data.total}`, dataTest: "active-users", children: [!hasActiveUsers && _jsx(Text, { info: true, children: "No active users this month" }), hasActiveUsers && activeInterval && (_jsxs(_Fragment, { children: [_jsx(IntervalBarChart, { intervals: chartIntervals, activeInterval: activeInterval, onActivateInterval: setActiveInterval }), _jsx("div", { children: _jsx(Text, { children: "Daily count of unique active users within the organization this month" }) }), _jsx("div", { className: widgetStyles.footer, children: _jsxs("div", { children: [_jsxs("div", { className: widgetStyles.footerItem, children: [_jsx(Icon, { glyph: userIcon }), _jsx(Text, { size: "l", children: (activeInterval === null || activeInterval === void 0 ? void 0 : activeInterval.total) || '0' })] }), _jsx(Text, { size: "s", info: true, className: widgetStyles.footerItemLabel, children: activeInterval === chartIntervals[chartIntervals.length - 1] ? (_jsx("span", { children: "Users active today" })) : (_jsxs("span", { children: ["Users active", _jsx("br", {}), "on ", formattedActiveDayDate] })) })] }) })] }))] }));
}
