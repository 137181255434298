// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentWrapper__ada {
  display: flex;
  flex-direction: column;
}

.info__b34 + .limitEditor__b12 {
  margin-top: calc(var(--ring-unit) * 3);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/ide-provisioner/dialogs/idep-settings-dialog/idep-settings-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".contentWrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.info + .limitEditor {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `contentWrapper__ada`,
	"info": `info__b34`,
	"limitEditor": `limitEditor__b12`
};
export default ___CSS_LOADER_EXPORT___;
