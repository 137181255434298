import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Code from '@jetbrains/ring-ui/components/code/code';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { useLoaderData } from 'react-router-dom';
import KeyValueRow from '../../components/util/key-value-row';
import { getFreezeZip } from '../../api/collected-data-freezes';
import kvStyles from '../../components/util/key-value-row.css';
import { downloadBlob } from '../../components/util/blob';
import { formatDateAndTime } from '../../components/util/i18n';
import { millisToMinutesAndSeconds } from '../../components/dates/format';
import { PageTitle } from '../../components/page-layout/page-title';
import { FREEZES_PATH } from './collected-data-freezes';
import styles from './collected-data-reports-page.css';
export default function CollectedDataFreezePage() {
    const [isDownloading, setIsDownloading] = useState(false);
    const state = useLoaderData();
    const handleDownloadClick = () => {
        if (!isDownloading && state.id) {
            setIsDownloading(true);
            getFreezeZip(state.id)
                .then(response => {
                downloadBlob(new Blob([response.data]), `freeze-${state.id}.zip`);
            })
                .catch(error => {
                alert.error(`Couldn't download freeze: ${error.message}`);
            })
                .finally(() => {
                setIsDownloading(false);
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { parts: [
                    { title: 'Analytics' },
                    { title: 'Freezes', href: FREEZES_PATH },
                    { title: `F-${state.id}` }
                ], rightPanel: _jsx(Button, { onClick: handleDownloadClick, loader: isDownloading, primary: true, children: "Download freeze" }) }), _jsx(KeyValueRow, { lhs: "ID", rhs: `F-${state.id}` }), _jsx(KeyValueRow, { lhs: "Duration", rhs: `${millisToMinutesAndSeconds(state.durationMs)}` }), _jsx(KeyValueRow, { lhs: "Appeared at", rhs: formatDateAndTime(state.timestamp) }), state.report && (_jsxs("div", { className: kvStyles.row, children: [_jsx("div", { className: kvStyles.key, children: "Report:" }), state.tooLargeToInterpret && (_jsx("div", { children: "Report too large to process. Please download it manually" })), _jsx(Code, { className: styles.contentBody, code: state.report })] }))] }));
}
