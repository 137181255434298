// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonsContainer__dcb {
  display: flex;
  align-items: center;
}

.container__fc7 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
  padding: var(--ring-unit) calc(var(--ring-unit) * 2) calc(var(--ring-unit) * 2)
    calc(var(--ring-unit) * 2);
  min-width: 560px;
  max-width: fit-content;
  background-color: var(--ring-sidebar-background-color);
  border: 1px solid var(--ring-line-color);
  border-radius: calc(var(--ring-unit) / 2);
}

.danger__f34 {
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/license-activation/license-activation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;EACjC;8BAC4B;EAC5B,gBAAgB;EAChB,sBAAsB;EACtB,sDAAsD;EACtD,wCAAwC;EACxC,yCAAyC;AAC3C;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".buttonsContainer {\n  display: flex;\n  align-items: center;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n  padding: var(--ring-unit) calc(var(--ring-unit) * 2) calc(var(--ring-unit) * 2)\n    calc(var(--ring-unit) * 2);\n  min-width: 560px;\n  max-width: fit-content;\n  background-color: var(--ring-sidebar-background-color);\n  border: 1px solid var(--ring-line-color);\n  border-radius: calc(var(--ring-unit) / 2);\n}\n\n.danger {\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": `buttonsContainer__dcb`,
	"container": `container__fc7`,
	"danger": `danger__f34`
};
export default ___CSS_LOADER_EXPORT___;
