import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import Icon from '@jetbrains/ring-ui/components/icon';
import permissionIcon from '@jetbrains/icons/permission';
import userIcon from '@jetbrains/icons/user';
import mutedIcon from '@jetbrains/icons/muted';
import { licenseVaultApi } from '@jetbrains-license-vault/client/lib';
import { format, parseISO } from 'date-fns';
import classNames from 'classnames';
import { useDeniedLicensesNumber } from '@app/queries/license-vault';
import NavigationLink from '../../components/navigation/navigation-link';
import { WidgetLayout } from './common/widget-layout';
import widgetStyles from './common/widget-layout.css';
export function Licenses() {
    var _a, _b;
    const planInfo = licenseVaultApi.server.usePlanInfo();
    // @ts-ignore
    const licenses = licenseVaultApi.licenses.useLicenses('product');
    const validUntil = useMemo(() => {
        var _a, _b;
        return ((_a = planInfo.data) === null || _a === void 0 ? void 0 : _a.validUntil)
            ? parseISO((_b = planInfo.data) === null || _b === void 0 ? void 0 : _b.validUntil)
            : undefined;
    }, [planInfo.data]);
    const { data: deniedRequestsNumber, error: deniedRequestsNumberError } = useDeniedLicensesNumber();
    const usersTotal = licenseVaultApi.users.useUsersTotal();
    const licensesStat = useMemo(() => {
        const data = Array.isArray(licenses.data) && !licenses.error ? licenses.data : [];
        return data.reduce((accum, license) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            const { usage } = license;
            return {
                available: accum.available +
                    (((_a = usage === null || usage === void 0 ? void 0 : usage.virtual) === null || _a === void 0 ? void 0 : _a.available) || 0) +
                    (((_b = usage === null || usage === void 0 ? void 0 : usage.regular) === null || _b === void 0 ? void 0 : _b.available) || 0) +
                    (((_c = usage === null || usage === void 0 ? void 0 : usage.trueUp) === null || _c === void 0 ? void 0 : _c.available) || 0),
                used: accum.used +
                    (((_e = (_d = license === null || license === void 0 ? void 0 : license.usage) === null || _d === void 0 ? void 0 : _d.virtual) === null || _e === void 0 ? void 0 : _e.used) || 0) +
                    (((_g = (_f = license === null || license === void 0 ? void 0 : license.usage) === null || _f === void 0 ? void 0 : _f.regular) === null || _g === void 0 ? void 0 : _g.used) || 0) +
                    (((_j = (_h = license === null || license === void 0 ? void 0 : license.usage) === null || _h === void 0 ? void 0 : _h.trueUp) === null || _j === void 0 ? void 0 : _j.used) || 0)
            };
        }, { available: 0, used: 0 });
    }, [licenses]);
    return (_jsxs(WidgetLayout, { loader: !planInfo.data || !licenses.data, link: "/license-vault/dashboard", titleHint: "Number of available product licenses", isLoadingError: !!(licenses.error || deniedRequestsNumberError), title: "Licenses", dataTest: "licenses", children: [_jsx("div", { children: planInfo.data ? (_jsxs(Text, { children: ["Plan:", ' ', _jsx(NavigationLink, { href: "/license-vault/settings", children: (_a = planInfo.data) === null || _a === void 0 ? void 0 : _a.name }), ' ', validUntil && _jsxs(Text, { info: true, children: ["until ", format(validUntil, 'dd MMM yyyy')] })] })) : (_jsx(Text, { info: true, children: "No licenses purchased yet" })) }), _jsxs("div", { className: widgetStyles.footer, children: [_jsxs("div", { children: [_jsxs("div", { className: widgetStyles.footerItem, children: [_jsx(Icon, { glyph: permissionIcon }), _jsx(Text, { size: "l", "data-test": "licenses-used", children: licensesStat.used })] }), _jsxs(Text, { size: "s", info: true, className: widgetStyles.footerItemLabel, children: ["Licenses", _jsx("br", {}), "in use"] })] }), _jsxs("div", { children: [_jsxs("div", { className: widgetStyles.footerItem, children: [_jsx(Icon, { glyph: userIcon }), usersTotal.data && _jsx(Text, { size: "l", children: (_b = usersTotal.data) === null || _b === void 0 ? void 0 : _b.total })] }), _jsx(Text, { size: "s", info: true, className: widgetStyles.footerItemLabel, children: "Active users" })] }), !!deniedRequestsNumber && (_jsxs("div", { children: [_jsxs("div", { className: widgetStyles.footerItem, children: [_jsx(Icon, { glyph: mutedIcon, color: deniedRequestsNumber ? Icon.Color.RED : Icon.Color.DEFAULT }), _jsx(Text, { size: "l", className: classNames({
                                            [widgetStyles.failed]: deniedRequestsNumber
                                        }), children: deniedRequestsNumber })] }), _jsx(Text, { size: "s", info: true, className: widgetStyles.footerItemLabel, children: "Requests denied for 24h" })] }))] })] }));
}
