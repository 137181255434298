import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {FullWidthSelectableTable} from '@components/tables/full-width-selectable-table';
import LoadingError from '../../components/errors/loading-error';
import {Breadcrumbs} from '../../components/page-layout/breadcrumbs';
import HorizontalContainer from '../../components/page-layout/horizontal-container';
import {Range} from './range';

import styles from './analytics-table.css';

export const LAST_1_DAY = 'LAST_1_DAY';
export const LAST_7_DAYS = 'LAST_7_DAYS';
export const LAST_28_DAYS = 'LAST_28_DAYS';
export const LAST_1_YEAR = 'LAST_1_YEAR';

export const RANGE_OPTIONS = [
  {
    key: LAST_1_DAY,
    label: 'Last day'
  },
  {
    key: LAST_7_DAYS,
    label: 'Last 7 days'
  },
  {
    key: LAST_28_DAYS,
    label: 'Last 4 weeks'
  },
  {
    key: LAST_1_YEAR,
    label: '1 year'
  }
];

export default function AnalyticsTable({
  title,
  columns,
  stateSelector,
  onSort,
  onPageChange,
  onRangeChange,
  onItemEnter,
  tableClassNames,
  additionalSettingsComponents
}) {
  const {
    isFetching,
    isFetchError,
    currentPageIndex,
    currentPageData,
    sortKey,
    sortOrder,
    pageSize,
    totalItems
  } = useSelector(state => stateSelector(state));

  const [range, setRange] = useState(LAST_7_DAYS);

  useEffect(() => {
    onRangeChange(range);
  }, [range]);

  if (isFetchError) {
    return <LoadingError />;
  }

  return (
    <div className={styles.container}>
      {!!title && <Breadcrumbs parts={[{title}]} />}
      <HorizontalContainer>
        <Range range={range} rangeOptions={RANGE_OPTIONS} onRangeChange={setRange} />
        {additionalSettingsComponents}
      </HorizontalContainer>
      <FullWidthSelectableTable
        className={tableClassNames}
        data={currentPageData}
        getItemKey={item => item.key}
        loading={isFetching}
        columns={columns}
        onSort={evt => onSort(evt, range)}
        selectable={false}
        onItemEnter={onItemEnter}
        sortKey={sortKey}
        sortOrder={sortOrder}
        renderEmpty={() => 'No data available for selected period'}
        pagerProps={{
          total: totalItems,
          pageSize,
          currentPage: currentPageIndex,
          disablePageSizeSelector: true,
          onPageChange: evt => onPageChange(evt, range)
        }}
      />
    </div>
  );
}
