export const initialState = {
    id: '',
    title: '',
    description: '',
    isDefault: false,
    applications: {
        content: [],
        rules: []
    },
    settings: {
        ide: [],
        vmOptions: [],
        toolbox: [],
        autoinstalledTools: []
    },
    plugins: {
        disabled: [],
        autoInstalled: []
    },
    restrictions: {
        rules: []
    },
    constituents: {
        ai: { isEnabled: false },
        idep: { isEnabled: false },
        cwm: { isEnabled: false, canUsersHostSessions: false }
    }
};
