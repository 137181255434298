// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warningText__b7e {
  color: var(--ring-warning-color);
  text-align: justify;
}

.warningIcon__fcc {
  margin-right: var(--ring-unit);
}

.infoText__dc3 {
  text-align: justify;
}

.formContainer__a19 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
}

.dialogContainer__a17.dialogContainer__a17 > div > div {
  min-width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/autoinstalled-tools/profiles-autoinstalled-tools-form.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".warningText {\n  color: var(--ring-warning-color);\n  text-align: justify;\n}\n\n.warningIcon {\n  margin-right: var(--ring-unit);\n}\n\n.infoText {\n  text-align: justify;\n}\n\n.formContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.dialogContainer.dialogContainer > div > div {\n  min-width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningText": `warningText__b7e`,
	"warningIcon": `warningIcon__fcc`,
	"infoText": `infoText__dc3`,
	"formContainer": `formContainer__a19`,
	"dialogContainer": `dialogContainer__a17`
};
export default ___CSS_LOADER_EXPORT___;
