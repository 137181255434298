import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { noop } from 'lodash';
import Button from '@jetbrains/ring-ui/components/button/button';
import Selection from '@jetbrains/ring-ui/components/table/selection';
import trash from '@jetbrains/icons/trash';
import addIcon from '@jetbrains/icons/add';
import classNames from 'classnames';
import Table from '@jetbrains/ring-ui/components/table/table';
import { getEmptyFilter } from '../../../schemas/ctors';
import styles from './array-editor.css';
export default function ArrayEditor({ items: rawItems = [], onChange, viewItem, editItemDialog, allowReorder = false, addActionText = 'Add' }) {
    var _a;
    const items = rawItems.map((item, index) => ({
        index,
        item,
        id: JSON.stringify(item)
    }));
    const [editedRule, setEditedRule] = useState(null);
    const handleAdd = () => {
        setEditedRule({ index: -1, item: getEmptyFilter(), id: '' });
    };
    const handleReorder = (newOrder) => {
        onChange(newOrder.map(r => r.item));
    };
    const handleRemove = useCallback((item, event) => {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        onChange(items.filter(r => r.index !== item.index).map(r => r.item));
    }, [items, onChange]);
    const handleEdit = (item, event) => {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        setEditedRule(item);
    };
    const arrayEditorComponentProps = {
        item: (_a = editedRule === null || editedRule === void 0 ? void 0 : editedRule.item) !== null && _a !== void 0 ? _a : getEmptyFilter(),
        onReject: () => setEditedRule(null),
        onSubmit: item => {
            if (editedRule != null && (editedRule === null || editedRule === void 0 ? void 0 : editedRule.index) >= 0) {
                onChange(items.map(r => (r.index === editedRule.index ? item : r.item)));
            }
            else {
                onChange([...items.map(r => r.item), item]);
            }
            setEditedRule(null);
        }
    };
    const columns = useMemo(() => [
        {
            id: 'rule',
            className: classNames(styles.firstCell),
            getValue: opt => viewItem({ item: opt.item })
        },
        {
            id: 'actions-remove',
            className: classNames(styles.removeButtonCell),
            getValue: rule => (_jsx(Button, { icon: trash, text: true, inline: true, className: styles.removeButton, onClick: event => handleRemove(rule, event) }))
        }
    ], [handleRemove, viewItem]);
    return (_jsxs(_Fragment, { children: [items.length > 0 && (_jsx(Table, { className: styles.table, data: items, columns: columns, getItemKey: i => i.index, selection: new Selection(), selectable: false, draggable: allowReorder, alwaysShowDragHandle: allowReorder, onReorder: ({ data }) => handleReorder(data), onItemClick: handleEdit, onSelect: noop })), _jsx(Button, { className: styles.addButton, primary: true, text: true, icon: addIcon, onClick: handleAdd, children: addActionText }), editedRule != null && editItemDialog(arrayEditorComponentProps)] }));
}
