import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import Toggle from '@jetbrains/ring-ui/components/toggle/toggle';
function RegisteredToggle({ name, control, controllerProps, ...rest }) {
    const { field: { name: fieldName, onChange: fieldOnChange, value, onBlur } } = useController({
        name,
        defaultValue: false,
        control,
        ...controllerProps
    });
    return (_jsx(Toggle, { defaultChecked: value, value: value || '', onChange: e => fieldOnChange(e), onBlur: onBlur, name: fieldName, id: name, ...rest }));
}
export default RegisteredToggle;
