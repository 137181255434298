// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[8].use[1]!../../../../node_modules/@jetbrains/ring-ui/components/global/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__d2b {
  border: none;
  position: relative;
  margin: 0;
  height: 36px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
}

.footer__d2b div:nth-child(2) ul {
  display: flex;
  gap: var(--ring-unit);
}

.footer__d2b div:nth-child(2) ul li a {
  color: var(--ring-text-color);
  white-space: nowrap;
}

.footer__d2b > div {
  top: initial;
  display: flex;
  align-items: center;

  &:nth-child(3) {
    justify-content: flex-end;
  }
}

@media (max-width: calc(${___CSS_LOADER_ICSS_IMPORT_0___.locals["breakpoint-middle"]} - 1px)) {
  .footer__d2b div:nth-child(2) {
    justify-content: center;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app-layout/app-footer/app-footer.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;;EAEnB;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,uBAAuB;;IAEvB;MACE,aAAa;MACb,sBAAsB;MACtB,mBAAmB;IACrB;EACF;AACF","sourcesContent":["@value breakpoint-middle from '~@jetbrains/ring-ui/components/global/global.css';\n\n.footer {\n  border: none;\n  position: relative;\n  margin: 0;\n  height: 36px;\n  box-sizing: content-box;\n  display: flex;\n  align-items: center;\n}\n\n.footer div:nth-child(2) ul {\n  display: flex;\n  gap: var(--ring-unit);\n}\n\n.footer div:nth-child(2) ul li a {\n  color: var(--ring-text-color);\n  white-space: nowrap;\n}\n\n.footer > div {\n  top: initial;\n  display: flex;\n  align-items: center;\n\n  &:nth-child(3) {\n    justify-content: flex-end;\n  }\n}\n\n@media (max-width: calc(breakpoint-middle - 1px)) {\n  .footer div:nth-child(2) {\n    justify-content: center;\n\n    ul {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpoint-middle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["breakpoint-middle"]}`,
	"footer": `footer__d2b`
};
export default ___CSS_LOADER_EXPORT___;
