import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { modalsContext } from './context';
export function ModalsRenderer() {
    const modalContext = useContext(modalsContext);
    if (!modalContext) {
        throw new Error('ModalRenderer must be used inside ModalContextProvider');
    }
    return (_jsx(_Fragment, { children: modalContext.map(modal => {
            const ModalComponent = modal.component;
            return _jsx(ModalComponent, { ...modal.props }, modal.modalId);
        }) }));
}
