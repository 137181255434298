// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerNote__dfc {
  padding: 0 calc(var(--ring-unit) * 4);
}

.globalDisableWarning__f71 {
  margin-top: calc(var(--ring-unit) / 2);
  color: var(--ring-warning-color);
}

.confirmGlobalAccessWrapper__bea {
  margin-top: calc(var(--ring-unit) * 3);
}

.relayToggleWrapper__a63 {
  margin-top: calc(var(--ring-unit) * 1.5);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/code-with-me/dialogs/cwm-enable-dialog/cwm-enable-dialog.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,sCAAsC;EACtC,gCAAgC;AAClC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".headerNote {\n  padding: 0 calc(var(--ring-unit) * 4);\n}\n\n.globalDisableWarning {\n  margin-top: calc(var(--ring-unit) / 2);\n  color: var(--ring-warning-color);\n}\n\n.confirmGlobalAccessWrapper {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.relayToggleWrapper {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerNote": `headerNote__dfc`,
	"globalDisableWarning": `globalDisableWarning__f71`,
	"confirmGlobalAccessWrapper": `confirmGlobalAccessWrapper__bea`,
	"relayToggleWrapper": `relayToggleWrapper__a63`
};
export default ___CSS_LOADER_EXPORT___;
