import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import Icon from '@jetbrains/ring-ui/components/icon';
import Text from '@jetbrains/ring-ui/components/text/text';
import Checkbox from '@jetbrains/ring-ui/components/checkbox/checkbox';
import { NotificationContext } from '../../notification-context';
import styles from './actionable-notification-content.css';
export function ActionableNotificationContent(props) {
    const { icon, title, description, actionButton } = props;
    const ctx = useContext(NotificationContext);
    if (!ctx) {
        throw new Error('Invalid Application State, No notification context');
    }
    const { setStopShowingNotification } = ctx;
    const handleChange = useCallback((evt) => setStopShowingNotification(evt.target.checked), [setStopShowingNotification]);
    return (_jsxs("div", { className: styles.gridContainer, children: [_jsx(Icon, { glyph: icon }), title && _jsx(Text, { className: styles.headerText, children: title }), _jsx(Text, { info: true, className: styles.bodyText, children: description }), actionButton, _jsx(Checkbox, { "data-test": "stop-showing-checkbox", onChange: handleChange, children: "Don't show this message again" })] }));
}
