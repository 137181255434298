import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@jetbrains/ring-ui/components/input/input';
import searchIcon from '@jetbrains/icons/search';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useNavigate } from 'react-router-dom';
import { FullWidthSelectableTable } from '@components/tables/full-width-selectable-table';
import NavigationLink from '@components/navigation/navigation-link';
import { PageTitle } from '../../components/page-layout/page-title';
import LoadingError from '../../components/errors/loading-error';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../api/paging-utils';
import { getAuditLogs } from '../../api/audit-logs';
import { setCurrentPage, setSortKey } from '../../store/plugins-audit-log-reducer';
import { useDebounce } from '../../hooks/use-debounce-component-value';
import styles from './audit-logs.css';
function makePluginLink(auditLogEntry) {
    var _a;
    return `/plugins/${encodeURIComponent((_a = auditLogEntry === null || auditLogEntry === void 0 ? void 0 : auditLogEntry.plugin) === null || _a === void 0 ? void 0 : _a.id)}`;
}
const auditLogColumns = [
    {
        id: 'createdDate',
        title: 'Created Date',
        sortable: true,
        className: styles.smallColumn
    },
    { id: 'createdBy', title: 'Created By', sortable: true },
    {
        id: 'plugin',
        title: 'Plugin and Version',
        sortable: false,
        getValue(entry) {
            return (_jsxs(_Fragment, { children: [_jsx(NavigationLink, { href: makePluginLink(entry), children: entry.plugin.id }), ' ', entry.plugin.version && _jsxs(Text, { info: true, children: ["\u2013\u00A0", entry.plugin.version] })] }));
        }
    },
    {
        id: 'description',
        title: 'Action',
        sortable: false,
        getValue({ readableName, description }) {
            return _jsx(Tooltip, { title: readableName, children: description });
        }
    }
];
export default function AuditLogs() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auditLogs = useSelector(state => 
    // @ts-ignore
    state.pluginsAuditLog.items);
    const { page, sortKey, sortOrder, totalCount, isFetching, isFetchError } = 
    // @ts-ignore
    useSelector(state => state.pluginsAuditLog);
    const [filter, setFilter] = useState('');
    const debouncedFilter = useDebounce(filter, 500);
    const prevDebouncedFilterRef = useRef(debouncedFilter);
    useEffect(() => {
        if (page === 1 || prevDebouncedFilterRef.current === debouncedFilter) {
            dispatch(getAuditLogs(page, sortKey, sortOrder, debouncedFilter));
            prevDebouncedFilterRef.current = debouncedFilter;
        }
        else {
            dispatch(setCurrentPage(1));
        } // we need to reset page if filter changes, so we can present results from page 1
    }, [page, sortKey, sortOrder, debouncedFilter, dispatch]);
    return (_jsxs("div", { children: [_jsx(PageTitle, { parts: [{ title: 'Plugins', href: '/plugins' }, { title: 'Audit Logs' }] }), isFetchError && _jsx(LoadingError, {}), _jsx(Input, { id: "subjectField", icon: searchIcon, placeholder: "Filter by plugin id", onClear: () => setFilter(''), onChange: x => setFilter(x.target.value), value: filter, autoComplete: "off" }), _jsx(FullWidthSelectableTable, { data: auditLogs, getItemKey: x => x.id, loading: isFetching, columns: auditLogColumns, selectable: false, sortKey: sortKey, sortOrder: sortOrder, onSort: event => dispatch(setSortKey(event.column.id, event.order)), renderEmpty: () => 'No audit logs found', onItemEnter: auditLogEntry => navigate(makePluginLink(auditLogEntry)), pagerProps: {
                    total: totalCount,
                    disablePageSizeSelector: true,
                    currentPage: page,
                    pageSize: DEFAULT_TABLE_PAGE_SIZE,
                    onPageChange: newPage => dispatch(setCurrentPage(newPage))
                } })] }));
}
