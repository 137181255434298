// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.codeBlock__eb1 > code {
  background-color: var(--ring-hover-background-color);
}

.contentContainer__bd5 {
  width: min-content;
  max-width: 80vw;
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/users-admin-page/configuration-file-popup.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;AACtD;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".codeBlock > code {\n  background-color: var(--ring-hover-background-color);\n}\n\n.contentContainer {\n  width: min-content;\n  max-width: 80vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"codeBlock": `codeBlock__eb1`,
	"contentContainer": `contentContainer__bd5`
};
export default ___CSS_LOADER_EXPORT___;
