// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.licenceActivationForm__d24 {
  width: 800px;
  max-width: 90vw;
  display: flex;
  gap: calc(2 * var(--ring-unit));
  flex-direction: column;
}

.licenceActivationPrimaryActions__f73 {
  align-items: center;
  display: inline-flex;
  gap: calc(3 * var(--ring-unit));
}

.generateNewKeyButton__a95 {
  float: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/license/license-activation-page.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,+BAA+B;EAC/B,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,+BAA+B;AACjC;;AAEA;EACE,YAAY;AACd","sourcesContent":[".licenceActivationForm {\n  width: 800px;\n  max-width: 90vw;\n  display: flex;\n  gap: calc(2 * var(--ring-unit));\n  flex-direction: column;\n}\n\n.licenceActivationPrimaryActions {\n  align-items: center;\n  display: inline-flex;\n  gap: calc(3 * var(--ring-unit));\n}\n\n.generateNewKeyButton {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"licenceActivationForm": `licenceActivationForm__d24`,
	"licenceActivationPrimaryActions": `licenceActivationPrimaryActions__f73`,
	"generateNewKeyButton": `generateNewKeyButton__a95`
};
export default ___CSS_LOADER_EXPORT___;
