import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import { useAiDisableMutation } from '@app/queries/ai-enterprise';
import { ConstituentDialog } from '@app/pages/configuration-page/license/shared/constituent-dialog/constituent-dialog';
export function AiDisableDialog(props) {
    const { onCloseAttempt } = props;
    const { mutate: disableAi, isLoading } = useAiDisableMutation(() => onCloseAttempt(undefined));
    const handleDisable = useCallback(() => disableAi(), [disableAi]);
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    return (_jsxs(ConstituentDialog, { title: "Disable AI Enterprise?", children: [_jsx(Content, { children: _jsxs("p", { children: ["If you disable AI Enterprise, developers connected to this server", _jsx("br", {}), "will no longer have access to AI Enterprise.", _jsx("br", {}), "If AI access is enabled on your JetBrains Account,", _jsx("br", {}), "all connected users will be able to initiate AI trials and use AI Pro", _jsx("br", {}), "with a separate license."] }) }), _jsxs(Panel, { children: [_jsx(Button, { onClick: handleDisable, loader: isLoading, danger: true, children: "Disable" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
