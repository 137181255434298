import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Text from '@jetbrains/ring-ui/components/text/text';
import { isEmpty } from 'lodash';
import { getApplication } from '@app/store/profiles/selectors';
import { NestedDataList } from '../common/nested-data-list/nested-data-list';
import { buildDataListOptions } from '../common/nested-data-list/build-data-list-options';
import styles from './builds-list.css';
export function BuildsList({ item, editedNotificationId, allSelected = false }) {
    const applicationData = useSelector(state => getApplication(state, item));
    const buildOptions = useMemo(() => applicationData
        ? buildDataListOptions(applicationData, allSelected, editedNotificationId)
        : undefined, [applicationData, allSelected, editedNotificationId]);
    return (_jsxs("div", { className: styles.buildListContainer, children: [_jsx(Text, { info: true, children: "Versions" }), buildOptions && _jsx(NestedDataList, { data: buildOptions }), !isEmpty(applicationData === null || applicationData === void 0 ? void 0 : applicationData.exclude) && (_jsx(Text, { info: true, children: "Crossed IDE versions are not available for this profile." }))] }));
}
