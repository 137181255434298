// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nestedItemContainer__c0a {
  padding: var(--ring-unit) calc(var(--ring-unit) * 3);
  display: flex;
  gap: calc(var(--ring-unit) / 2);
}

.nestedListContainer__b15 {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
}

.textContainer__b3b {
  display: flex;
  gap: var(--ring-unit);
}

.cursor__c7e {
  cursor: pointer;
}

.crossed__faf {
  text-decoration: line-through;
}

.notificationIcon__f64 {
  color: var(--ring-icon-warning-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/nested-data-list/nested-data-list.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;EACpD,aAAa;EACb,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".nestedItemContainer {\n  padding: var(--ring-unit) calc(var(--ring-unit) * 3);\n  display: flex;\n  gap: calc(var(--ring-unit) / 2);\n}\n\n.nestedListContainer {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n}\n\n.textContainer {\n  display: flex;\n  gap: var(--ring-unit);\n}\n\n.cursor {\n  cursor: pointer;\n}\n\n.crossed {\n  text-decoration: line-through;\n}\n\n.notificationIcon {\n  color: var(--ring-icon-warning-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nestedItemContainer": `nestedItemContainer__c0a`,
	"nestedListContainer": `nestedListContainer__b15`,
	"textContainer": `textContainer__b3b`,
	"cursor": `cursor__c7e`,
	"crossed": `crossed__faf`,
	"notificationIcon": `notificationIcon__f64`
};
export default ___CSS_LOADER_EXPORT___;
