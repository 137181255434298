// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageTitle__c1b {
  margin-bottom: 0;
}

.description__cda {
  margin-top: var(--ring-unit);
}

.tabs__eca {
  margin-top: calc(var(--ring-unit) * 3);
}

.banners__f60 {
  margin-top: calc(var(--ring-unit) * 2);
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);

  &:empty {
    display: none;
  }
}

.inactiveLabel__df6 {
  margin-left: calc(var(--ring-unit) / 2);
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-details-page.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;EACtC,aAAa;EACb,sBAAsB;EACtB,qBAAqB;;EAErB;IACE,aAAa;EACf;AACF;;AAEA;EACE,uCAAuC;EACvC,gBAAgB;AAClB","sourcesContent":[".pageTitle {\n  margin-bottom: 0;\n}\n\n.description {\n  margin-top: var(--ring-unit);\n}\n\n.tabs {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.banners {\n  margin-top: calc(var(--ring-unit) * 2);\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n\n  &:empty {\n    display: none;\n  }\n}\n\n.inactiveLabel {\n  margin-left: calc(var(--ring-unit) / 2);\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageTitle": `pageTitle__c1b`,
	"description": `description__cda`,
	"tabs": `tabs__eca`,
	"banners": `banners__f60`,
	"inactiveLabel": `inactiveLabel__df6`
};
export default ___CSS_LOADER_EXPORT___;
