import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useController, useFormContext } from 'react-hook-form';
import Button from '@jetbrains/ring-ui/components/button/button';
import pencilIcon from '@jetbrains/icons/pencil';
import XmlEditor from '@components/editors/xml-editor';
import { formatXml } from '../../settings-templates';
import XmlFileAttachment from './xml-file-attachment';
import styles from './xml-payload-field.css';
function XmlPayloadField({ settingIndex }) {
    const { control } = useFormContext();
    const { field: { onChange, value } } = useController({
        name: `content.${settingIndex}.xmlPayload`,
        defaultValue: '',
        control
    });
    return (_jsxs(_Fragment, { children: [_jsx(XmlEditor, { minLines: 20, maxLines: 50, onChange: onChange, value: value }), _jsxs("div", { className: styles.toolbar, children: [_jsx(XmlFileAttachment, { onFileRead: onChange }), _jsx(Button, { primary: true, onClick: () => onChange(formatXml(value)), icon: pencilIcon, children: "Reformat XML" })] })] }));
}
export default XmlPayloadField;
