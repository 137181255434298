import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApplications } from '@app/store/profiles/selectors';
import { useProfilesParams } from '../context/profiles-params-context';
/**
 * This hook resets the focused app once it's getting excluded from the current applications list
 */
export default function useAutoResetFocusedApp() {
    const dispatch = useDispatch();
    const { content } = useSelector(getApplications);
    const { focusedApp, clearParams } = useProfilesParams();
    useEffect(() => {
        var _a;
        if (focusedApp !== undefined && ((_a = content.find(x => x.product === focusedApp.id)) === null || _a === void 0 ? void 0 : _a.excluded)) {
            clearParams();
        }
    }, [clearParams, content, dispatch, focusedApp]);
}
